/**
 * 路由组件出口文件
 */
import Library from 'mdoc/components/book/Library';
import EpubRead from 'mdoc/components/book/EpubRead';
import Read from 'mdoc/components/book/Read';
import MyCollection from 'mdoc/components/book/MyCollection';
import EditMyCollection from 'mdoc/components/book/EditMyCollection';
import AddToCollection from 'mdoc/components/book/AddToCollection';
import SearchBookList from 'mdoc/components/book/SearchBookList';
import Recently from 'mdoc/components/book/Recently';
import Categories from 'mdoc/components/book/Categories';
import Notify from 'mdoc/components/userInfo/Notify';
import PushList from 'mdoc/components/userInfo/PushList';
import Information from 'mdoc/components/userInfo/Information';
import Notice from 'mdoc/components/userInfo/Notice';
import Lang from 'mdoc/components/userInfo/Lang';
import Password from 'mdoc/components/userInfo/Password';
import Help from 'mdoc/components/userInfo/Help';

export default {
    Library,Read,MyCollection,Notify,PushList,Information,Notice,Lang,Password,Help,EditMyCollection,AddToCollection,
    SearchBookList,Recently,Categories,EpubRead
}