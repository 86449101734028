import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import {
    Button,
    IconButton,
    Typography,
    Divider,
    Grid,
    SvgIcon
} from '@material-ui/core';
import Star from 'mdoc/utils/Star';
import Dialog from 'mdoc/utils/myDialog';
import $ from 'jquery';
import leave_comments from 'mdoc/style/imgs/leave_comments.png';



import Toast from 'mdoc/utils/toast';
import { dictionary, getCoverPath, commentList, commentAdd, getUserInfo } from 'mdoc/axios/index';
import { getRecentlyList } from 'mdoc/axios/library';
// import Message from '@material-ui/icons/Message';
import ChatBox from 'mdoc/components/book/ChatBox';
import 'mdoc/components/book/book.less';

const Message = (props) => {
    return (
        <img src={leave_comments} {...props} alt="" style={{width:'40px',height:'40px'}} />
    );
}

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    main: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
        paddingLeft: theme.spacing.unit * 3,
    },
    grow: {
        position: 'relative',
        zIndex: 2
    },
    margin: {
        marginLeft: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    button: {
        padding: '6px 0px'
    },
    menuTitle: {
        padding: '6px 0px'
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    title: {
        marginTop: 10,
        textAlign: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        lineClamp: 2,
        WebkitBoxOrient: 'vertical'
    },
    img: {
        minWidth: '200',
        width: '90%',
        height: '272px'
    },
    noWrap: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingBottom: '10px',
    }
});

class Recently extends React.Component {
    state = {
        direction: 'row',
        dictWord: {
            'search': '',
            'my_collection': '',
            'library': '',
            'category': '',
            'recent_documents': '',
            'look_all': '',
            'cover_image': '',
            'content_title': '',
            'content_evaluation_value': '',
            'category_name': '',
            'registration_date': '',
            'update_date': '',
            'name': '',
            'self_evaluation': '',
            'evaluation_average': '',
            'read_count': '',
            'content_description': '',
            'published_user': '',
            'published_department': '',
            'price': '',
            'read': '',
            'comment': '',
            'msg_error_not_null_with_item': '',
            'load_more': '',
        },
        bookInfo: {},
        recently: [],
        //send to ChatBox
        messageObj: null,
        loading: false,
        dialogOpen: false,
        chatboxOpen: false,
        comments: [],
        commentMessage: null,
        commentFlg: 0,
        pageNumber: 1,
        pageSize: 10,
        pageTotal: 1,
        commentPage: 1,
        commentSize: 20,
    };

    componentWillMount() {
        this.start(this.state);
    }

    start = (state) => {
        let _userInfo = getUserInfo();
        let styleSetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/styleSetting")) || {};
        let commentFlg = localStorage.getItem('commentFlg') || 0;
        this.setState({
            commentFlg: commentFlg,
            styleSetting: styleSetting
        });
        this.recentlyList({
            pageSize: this.state.pageSize,
            pageNumber: this.state.pageNumber,
        })

        let dict = {}
        dictionary(state.dictWord).then(res => {
            res.map(val => {
                dict[val.dictKey] = val.dictValue;
                return val;
            })
            this.setState({
                dictWord: dict,
            })
        })
    }
    recentlyList(params = {}) {
        this.setState({
            loading: true
        }, () => {
            getRecentlyList({
                ...params
            }).then((data) => {
                let bookList = this.state.recently;
                for (let i in data.results) {
                    if (data.results[i].docTblBookDetail && data.results[i].docTblBookDetail.coverUuid) {
                        getCoverPath(
                            data.results[i].categoryId,
                            data.results[i].docTblBookDetail.fileUuid,
                            data.results[i].docTblBookDetail.coverUuid,
                            data.results[i].docType
                        ).then(imgRes => {
                            data.results[i].coverPath = imgRes;
                            bookList.push(data.results[i]);
                            this.setState({
                                recently: bookList,
                                loading: false
                            })
                        }).catch(error => {
                            Toast.info(error)
                        });
                    } else {
                        bookList.push(data.results[i]);
                    }
                }
                this.setState({
                    pageNumber: params.pageNumber,
                    pageTotal: data.pageTotal,
                    loading: false
                })
            }).catch(error => {
                Toast.info(error)
            })
        });
    }
    dialogHandleClose = () => {
        this.setState({
            dialogOpen: false
        });
    }
    handleRecentlyBookInfo = (keyId) => event => {
        let bookInfo = this.state.recently[keyId];
        if (bookInfo.docType === 'link' && bookInfo.docLink.length > 0) {
            window.open(bookInfo.docLink, "_blank");
            return;
        }
        if (bookInfo.introduction.length > 0) {
            bookInfo.introduction = bookInfo.introduction.replace(/\n|\r|\r\n/g, '<br>').replace(/\s/g, " ");
        }
        this.setState({
            bookInfo: bookInfo,
            dialogOpen: true
        });
    }

    handleDirection = (direction) => event => {
        this.setState({
            direction: direction
        });
    }



    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }
        this.setState({ categoryOpen: false });
    };
    handleMessageBox = (value) => {
        value['messageBoxTitle'] = this.state.dictWord.comment;
        value['placeholder'] = this.state.dictWord.msg_error_not_null_with_item.replace('%s', this.state.dictWord.comment);
        commentList(value.id, {
            pageNumber: 1,
            pageSize: this.state.commentSize,
        }).then(res => {
            let commentList = res.docTblUserCommentList;
            commentList.sort(function (obj1, obj2) {
                return obj1.id - obj2.id;
            });
            this.setState({
                chatboxOpen: true,
                messageObj: value,
                comments: commentList
            })
            $('#customized-dialog-content').scrollTop($('#customized-dialog-content')[0].scrollHeight);
        }).catch(error => {
            this.setState({
                chatboxOpen: true,
                messageObj: value,
                comments: []
            })
        })
    }

    receiveHandle = (res) => {
        this.setState({
            commentMessage: res
        });
    }
    sendMessage = event => {
        let commentMessage = this.state.commentMessage.replace(/(^\s*)|(\s*$)/g, "");
        commentMessage = encodeURI(commentMessage);
        if (commentMessage.length > 0) {
            commentAdd({
                bookId: this.state.messageObj.id,
                comment: commentMessage
            }).then(res => {
                let comments = this.state.comments;
                comments.push(res);
                this.setState({
                    commentMessage: null,
                    comments: comments
                });
                $('#commentMessage').val('');
            }).catch(error => {
                Toast.error(error);
            })
        }
    }
    handleLoadMessage = () => {
        let page = this.state.commentPage + 1;
        commentList(this.state.messageObj.id, {
            pageNumber: page,
            pageSize: this.state.commentSize,
        }).then(res => {
            let commentList = res.docTblUserCommentList;
            commentList.sort(function (obj1, obj2) {
                return obj1.id - obj2.id;
            });
            let comments = commentList.concat(this.state.comments);
            this.setState({
                comments: comments,
                commentPage: page
            })
        }).catch(error => {
            Toast.error(error);
        })
    }
    handleLoadBook = () => {
        if (this.state.loading) {
            return;
        }
        let page = this.state.pageNumber + 1;
        this.recentlyList({
            pageSize: this.state.pageSize,
            pageNumber: page,
        })
    }
    fatherFn = (status) => {
        this.setState({
            chatboxOpen: status,
            commentPage: 1,
        })
    }


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.main}>
                <div className={classes.appBarSpacer} />
                <div className="crossWrap" style={{ flexDirection: 'column' }}>
                    {this.state.recently.map((value, index) => (
                        <div key={index} className={"crossPage"}>
                            <div className={"leftPart"}>
                                <div className="imgCon">
                                    <img src={value.coverPath} onClick={this.handleRecentlyBookInfo(index)} alt="" />
                                </div>
                                <div className="textCon">
                                    <div className={classes.title} title={value.title}>
                                        {value.title}
                                    </div>
                                    <div className={'stars'}>
                                        <Star starPercent={value.averageScore} enableClick={false} />
                                    </div>
                                    {value.bookIcon === 'UPDATE' ? <div className={'cross-updatebox'}><span>UPDATE</span><i className="triangle">&nbsp;</i></div> : null}
                                    {value.bookIcon === 'NEW' ? <div className={'cross-newbox'}><span>NEW</span><i className="triangle">&nbsp;</i></div> : null}
                                </div>
                            </div>
                            <div className={"rightPart"} >
                                <div className={'cross-message'}>
                                    {parseInt(this.state.commentFlg) === 0 ?
                                         <Message color={'disabled'} onClick={this.handleMessageBox.bind(this, value)} />
                                        : null}
                                </div>
                            </div>
                        </div>
                    ))}
                    {this.state.pageTotal > this.state.pageNumber ?
                        <div style={{ textAlign: 'center', padding: '10px 0px 20px', color: '#2196f3', cursor: 'pointer' }} onClick={this.handleLoadBook}>{this.state.dictWord.load_more}
                        </div> : null
                    }
                </div>

                <Dialog
                    open={this.state.dialogOpen}
                    option={{ topClose: true }}
                    title={this.state.bookInfo.title}
                    content={
                        <div style={{ width: 500, height: 500 }}>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                spacing={8}
                                alignItems="center"
                            >
                                <Grid item xs={12} md={2} style={{ marginRight: '10px', maxWidth: '24%' }}>
                                    <img width={'120'} height={'150'} style={{ objectFit: 'cover' }} alt={this.state.bookInfo.title} src={this.state.bookInfo.coverPath} />
                                </Grid>
                                {/* fwf */}
                                <Grid item xs={12} md={4} className="img-right">
                                    <Typography gutterBottom className={classes.noWrap} title={this.state.bookInfo.title}>
                                        {this.state.bookInfo.title}
                                    </Typography>
                                    <div style={{ display: 'block', height: '20px', margin: '-5px 0 10px' }}><Star starPercent={this.state.bookInfo.averageScore} /></div>
                                    {this.state.bookInfo.bookIcon === 'UPDATE' ? <div className={'cross-updatebox mb10'}><span>UPDATE</span><i className={'triangle'} /></div> : null}
                                    {this.state.bookInfo.bookIcon === 'NEW' ? <div className={'cross-newbox mb10'}><span>NEW</span><i className={'triangle'} /></div> : null}
                                    <Typography className={classes.noWrap} title={this.state.bookInfo.publisher}>{this.state.dictWord.published_department}:  {this.state.bookInfo.publisher}</Typography>
                                    <Typography className={classes.noWrap} title={this.state.bookInfo.author}>{this.state.dictWord.published_user}:  {this.state.bookInfo.author}</Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item xs={12} md={6}>
                                    {this.state.bookInfo.docType === "epub" ?
                                        <Button style={{ background: this.state.styleSetting.buttonColor }} variant="contained" href={'/#/user/book/epubread/' + this.state.bookInfo.id} fullWidth className={classes.button} color="primary">
                                            {this.state.dictWord.read}
                                        </Button>
                                        : <Button style={{ background: this.state.styleSetting.buttonColor }} variant="contained" href={'/#/user/book/read/' + this.state.bookInfo.id} fullWidth className={classes.button} color="primary">
                                            {this.state.dictWord.read}
                                        </Button>}
                                </Grid>
                            </Grid>
                            <Divider style={{ margin: '10px 0px', width: '100%' }} />
                            <Grid container>
                                <Grid item xs>
                                    <Typography style={{ color: '#E5E1E1', fontSize: '12px' }}>{this.state.dictWord.content_description}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Typography variant="body2" component="p" style={{ height: '250px',width:'100%', overflowY: 'auto' }} dangerouslySetInnerHTML={{ __html: this.state.bookInfo.introduction }} />
                            </Grid>
                        </div>
                    }
                    handleOk={this.dialogHandleOK}
                    handleClose={this.dialogHandleClose}
                />
                <ChatBox
                    chatboxStatus={this.state.chatboxOpen}
                    messageObj={this.state.messageObj}
                    commentMessage={this.state.commentMessage}
                    fatherFn={this.fatherFn}
                    messageList={this.state.comments}
                    onSendMessage={this.sendMessage}
                    receiveHandle={this.receiveHandle}
                    loadMessage={this.state.dictWord.show_history}
                    onLoadMessage={this.handleLoadMessage}
                />
            </div>
        )
    }
}

Recently.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
    return {
        currentPage: state.reducer.bookListData.pageNumber || 1,
        sumPage: state.reducer.bookListData.pageTotal,
        bookListData: state.reducer.bookListData.bookList || [],
    };
}

export default withStyles(styles)(connect(mapStateToProps, null)(Recently));