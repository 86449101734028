export default [
        { key: '/user/book/library', title: 'ライブラリー', component: 'Library'},
        { key: '/user/book/EpubRead/:bookId', title: 'ライブラリー', component: 'EpubRead'},
        { key: '/user/book/read/:bookId', title: 'ライブラリー', component: 'Read'},
        { key: '/user/book/mycollection', title: 'Myコレクション', component: 'MyCollection'},
        { key: '/user/book/editmycollection/:collectionId', title: 'Myコレクション', component: 'EditMyCollection'},
        { key: '/user/book/addtocollection', title: 'Myコレクション追加', component: 'AddToCollection'},
        { key: '/user/book/searchbooklist/', title: 'Myコレクション', component: 'SearchBookList'},
        { key: '/user/book/recently', title: 'ヘルプ', component: 'Recently'},
        { key: '/user/book/categories/:categoryId', title: 'ヘルプ', component: 'Categories'},
        { key: '/user/userInfo/notify', title: 'お知らせ', component: 'Notify'},
        { key: '/user/userInfo/pushlist', title: 'プッシュ', component: 'PushList'},
        { key: '/user/userInfo/information', title: '保存情報一覧', component: 'Information'},
        { key: '/user/userInfo/notice', title: '通知', component: 'Notice'},
        { key: '/user/userInfo/lang', title: '言語', component: 'Lang'},
        { key: '/user/userInfo/password', title: 'パスワード', component: 'Password'},
        { key: '/user/userInfo/help', title: 'ヘルプ', component: 'Help'},        

    ]