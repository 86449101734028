/**
 *
 * PATH設定
 */

//データアクセスAPI
const API = '/ws/mdoc/api';
export const ADMIN_LOGIN = API+'/authentication/login'; // 登録API
export const ADMIN_LOGOFF = API+'/authentication/logoff'; // ログオフ
export const READER_CHANGE_PWD = API+'/authentication/readerchangepwd'; // ログオフ
export const ADMIN_REFRESH_TOKEN = API+'/authentication/refreshtoken' // 刷新token
export const DICTIONARY_BEFORE_LOGIN = API + '/dictionary/getloginwords'; // 文言API
export const DICTIONARY = API + '/dictionary/getlist'; // 文言API
export const CNFSYSTEM = API + '/cnfsystem'; 
export const LANGUAGE = API + '/language'; // 语言种类
export const USER_UPLANG = API + '/user/uplang'; // 用户设置语言
export const ALERTSETTING = API + '/alertsetting/'; // 获取用户通知设置
export const ALERTSETTING_UPDATE = API + '/alertsetting/update'; // 修改用户通知设置
export const SENT_PSW_EMAIL = API+'/user/pwdselfreset' // パスワードを修正してメールを送信する
export const IMG_PATH = API + '/file/download/image'; //画像のダウンロード
export const COVER_PATH = API + '/file/download/coverimage'; //画像のダウンロード
export const MP4_PATH = API +'/book/video'; //mp4のダウンロード
export const MP4_PATH_TOKEN = API +'/book/token'; //mp4のダウンロード
export const MP4_DEL_TOKEN = API +'/book/removetoken'; //mp4のダウンロード
export const READER_LOGIN = API+'/authentication/readerlogin';
export const BOOK_RECENTLY = API+'/book/recently';
export const BOOK_CATEGORY_ONE = API+'/book/categories';
export const BOOK_CATEGORY = API+'/book/categories/list';
export const BOOK_CATEGORY_LIST = API+'/book/category/list';
export const BOOK_INFO = API+'/book/preview/';
export const CHECKEUPDATE = API+'/book/checkeupdate';
export const BOOK_FILE_PATH = API+'/file/download/content/';
export const ALERT_MESSAGE = API+'/alertmessage/usermessagelist';//閲覧者のプッシュ通知取得
export const ALERT_MESSAGE_IGNORE = API+'/alertmessage/ignore';//閲覧者のプッシュ閲覧
export const MYPUSH_LIST = API+'/alertmessage/allusermessagelist';//プッシュ
export const COLLENCTION = API+'/collection/mycollectionlist';//Myコレクションリスト/詳細の取得
export const COLLENCTION_DELETE = API+'/collection/delete';//Myコレクションの削除
export const COLLENCTION_ADD = API+'/collection/add';//Myコレクションの追加
export const COLLENCTION_UPDATE = API+'/collection/update';//Myコレクションの追加
export const COLLENCTION_MOVE = API+'/collection/move';//Myコレクションの移动
export const COLLENCTION_CONTENTS = API+'/collection/mycollectioncontents';//myコレクションコンテンツリストの取得
export const COMMENT_LIST = API+'/comment/list';//コメントリスト
export const COMMENT_ADD = API+'/comment/add';//コメント
export const NOTICE_LIST = API+'/notice/usernoticelist';//お知らせ
export const RELATION = API+'/notice/userrelation';//ユーザー未読のお知らせ取得
export const NOTICEINFO = API+'/notice/user/';//閲覧者のお知らせ詳細取得
export const EFFECTIVE_LOG = API+'/book/effective/log';//
export const BOOK_SEARCH = API+'/book/search';//
export const BODY_SEARCH = API+'/book/body/search';//
export const BOOK_PRINT = API+'/book/body/preview/';//
export const PREVIEW_FILELIST = API+'/file/download/preview/image/';//
export const EVALUATE = API+'/evaluate';//
export const EVALUATECONTENT = API+'/evaluate/content';//
export const DESIGN_WEB = API+'/design/web/';//
export const DESIGN_LOGO_DOWNLOAD= API + '/file/download/client/logoimage/'; // デザインのログ
export const BODY_BACK_IMAGE = API + '/file/download/client/bodybackimage/'; 
export const CURRENT_TIME = API + '/book/getcurrenttimemillis'; 
//page
export const ROWS_PER_PAGE_OPTIONS = [5,10,25];
export const ROWS_PER_PAGE = 5;


//password secret key
export const SECRET_KEY = 'secret key 12345';

//
export const ROLE_ROUT = {
    1:'/app/management/enterprise',
    2:'/app/management/associateEnterprise',
    9:'/app/management/client',
};

export const RULE_CODE = {
    'managerCreate':1, //企業管理者作成
    'deputyManagerCreate':2,//副管理者作成
    'userCreate':3,//ユーザ作成
    'departmentManagementAuth':4,//部署管理権限
    'categoryManagementAuth':5,//カテゴリー管理権限
    'newsRelease':6,//ニュース配信
    'pushNotice':7,//プッシュ通知
    'contentRegistrationAuth':8,//コンテンツ登録権限
    'deviceControl':9,//デバイス制御
    'contentMultipleRegistration':10,//コンテンツ一括登録
    'logExport':11,//ログエクスポート
    'content':12,//コンテンツ
    'management':13,//管理メニュー
    'notice':14,//通知
    'client':15,//クライアント
}

//パスワード有効期間 0-無期限 1-3ヶ月 2-6ヶ月 3-12ヶ月
export const PASS_VALID_TIME = ['indefinitely','3_months','6_months','12_months'];

//ログイン保持期間 0-無期限 1-1日 2-1週間 3-1ヶ月
export const LOGIN_KEEP_TIME = ['indefinitely','1_day','1_week','1_months'];

//0：開始前；1：利用中；2：停止中；3：終了
export const CLIENT_STATUS = ['start_before','in_use','stopping','exit'];

//0-無効 1-有効
export const VALID = ['invalid','valid'];

//0:公開済;1:非公開;2:予約中
export const NOTIFY_STATUS = ['published','non_public','under_reservation'];
//0:公開;1:非公開
export const NOTIFY_STATUS_DETAIL = ['public','non_public'];
//0:通知済;1:非通知;2:予約中
export const PUSH_STATUS = ['notified','non_notification','under_reservation'];
 //0:通知;1:非通知
export const PUSH_STATUS_DETAIL = ['notice','non_notification'];
