import blue from '@material-ui/core/colors/blue';

export default {
    typography: {
        useNextVariants: true,
    },
    palette: {
        primary: blue,
        secondary: blue,
        table:{
            header:{
                backgroundColor:'#ece8e8',
                color:'#2096fb',
                fontWeight:'bold'
            },
            body:{
                fontSize:14
            },
        },
        buttonLink:{
            textDecoration: 'none',
        },
        buttons:{
            marginLeft:'-22px'
        },
        lableBackgroundColor:'#F9F5F5'
    },

};