import axios from 'axios';
import * as configUrl from 'mdoc/admin/axios/config';
import Toast from 'mdoc/utils/toast';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

let service = axios.create({
    baseUrl: ''
});

/*请求拦截器*/
service.interceptors.request.use(
    config => {
        const token = window.localStorage.getItem('readToken');
        config.params = {
            _t: Date.parse(new Date()) / 1000,
            ...config.params
        }
        //トップが存在するかどうかを判断する
        if (token &&
            config.url.indexOf(configUrl.ADMIN_LOGIN) < 0 &&
            config.url.indexOf(configUrl.DICTIONARY_BEFORE_LOGIN) < 0 &&
            config.url.indexOf(configUrl.SENT_PSW_EMAIL) < 0
        ) {
            //リクエスト頭にトップタイプを追加
            config.headers.APITOKEN = token;
            return config;
        } else {
            return config;
        }
    },
    //エラー操作
    err => {
        return Promise.reject(err);
    }
);

let subscribers = [];
function onAccessTokenFetched() {
    subscribers.forEach((callback) => {
        callback();
    })
    subscribers = [];
}

function addSubscriber(callback) {
    subscribers.push(callback)
}

function clearUser() {
    window.localStorage.removeItem('readRemember');
    window.localStorage.removeItem('readUser');
    window.localStorage.removeItem('readToken');
}

let isRefreshing = true;

function getRefreshToken() { // トップを更新する
    const token = window.localStorage.getItem('readToken');
    if (token) {
        return service.post(configUrl.ADMIN_REFRESH_TOKEN, {}, { headers: { APITOKEN: token } })
            .then(res => {
                if (res.data.error) {
                    clearUser();
                    let requestUrl = cookies.get('requestUrl');
                    if (!requestUrl && document.location.href.indexOf('#/login') < 0 && document.location.href.indexOf('#/404') < 0) {
                        cookies.set('requestUrl', document.location.href);
                    }
                    window.location = '/#/login';
                } else {
                    window.localStorage.setItem('readToken', res.data.token);
                    onAccessTokenFetched();
                    isRefreshing = true;
                }
            })
    } else {

    }
}

function checkStatus(response) {
    if (response.data.error) {
        if (response.data.errorCode === 'E108') {
            // という関数を更新して、スイッチを入れて、重複要求を防止する必要があります。
            if (isRefreshing) {
                getRefreshToken()
            }
            isRefreshing = false;
            // このプロフェス関数はとても重要です
            const originalRequest = response.config;
            const retryOriginalRequest = new Promise((resolve) => {
                addSubscriber(() => {
                    resolve(request(originalRequest))
                })
            });
            return retryOriginalRequest;
        } else if (response.data.errorCode === 'E201' ||
            response.data.errorCode === 'E101' ||
            response.data.errorCode === 'E102' ||
            response.data.errorCode === 'E109' ||
            response.data.errorCode === 'E106' ||
            response.data.errorCode === 'E401') {
            return response;
        } else if (response.data.errorCode === 'E104' ||
            response.data.errorCode === 'E103') {
            clearUser();
            Toast.warning(response.data.errorMessage, 3000);
            let requestUrl = cookies.get('requestUrl');
            if (!requestUrl && document.location.href.indexOf('#/login') < 0 && document.location.href.indexOf('#/404') < 0) {
                cookies.set('requestUrl', document.location.href);
            }
            window.location.href = '/#/login';
        } else if (response.data.errorCode === 'E105') {
            clearUser();
            let requestUrl = cookies.get('requestUrl');
            if (!requestUrl && document.location.href.indexOf('#/login') < 0 && document.location.href.indexOf('#/404') < 0) {
                cookies.set('requestUrl', document.location.href);
            }
            window.location.href = '/#/login';
        } else {
            let requestUrl = cookies.get('requestUrl');
            if (!requestUrl && document.location.href.indexOf('#/login') < 0 && document.location.href.indexOf('#/404') < 0) {
                cookies.set('requestUrl', document.location.href);
            }
            Toast.warning(response.data.errorMessage, 3000);
            window.location.href = '/#/404';
        }
    } else {
        return response;
    }
}
function getLang() {
    let lang = navigator.language || navigator.userLanguage;
    lang = lang.substr(0, 2);
    if (lang === "zh") {
        return '此操作无法进行';
    } else if (lang === "ja") {
        return 'この操作はできません';
    } else {
        return 'This operation is not possible';
    }
}
function request(options) {
    const token = window.localStorage.getItem('readToken');
    if (token) {
        options.headers.APITOKEN = token;
        const newOptions = { ...options };
        return axios.request(newOptions)
            .then(response => checkStatus(response))
            .catch(error => {
                if (error.config.url.indexOf('download') >= 0 || error.config.url.indexOf('upload') >= 0) {
                    let errorMsg = getLang();
                    return Promise.reject(errorMsg);
                } else {
                    window.location = '/#/404';
                }
            });
    }
}
service.interceptors.response.use(response => {
    return checkStatus(response);
},
    error => {
        if (error.config.url.indexOf('download') >= 0 || error.config.url.indexOf('upload') >= 0) {
            let errorMsg = getLang();
            return Promise.reject(errorMsg);
        } else {
            window.location = '/#/404';
        }
    });

export default service;