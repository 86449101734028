import React from 'react';
// import PropTypes from 'prop-types';
import { Divider, SvgIcon, IconButton,Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import { dictionary, collectionList, getCoverPath, collectionDelete, collectionUpdate, collectionMove,getUserInfo } from 'mdoc/axios';
import Toast from 'mdoc/utils/toast';
import MyDialog from 'mdoc/utils/myDialog';
import { messageFormat } from 'mdoc/utils';

import 'mdoc/components/book/mycollection.less';
import localforage from 'localforage';

const MoreIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z" />
        </SvgIcon>
    )
}

const PlusIcon = (props) => {
    return (
        <SvgIcon {...props} fontSize="large">
            <path fill="#fff" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
        </SvgIcon>
    )
}


class MyCollection extends React.Component {

    state = {
        dictWord: {
            'my_collection': '',
            'edit_record': '',
            'delete_record': '',
            'ok': '',
            'confirmation': '',
            'cancel': '',
            'select_content_delete': '',
            'new_insert': '',
            'insert': '',
            'title_enter': '',
            'msg_error_record_exists': '',
            'collection_name': '',
            'my_collection_name_change': '',
            'collection_name_chage': '',
            'move_to_other': '',
            'moved_to_completed': '',
            'move': '',
            'return': '',
        },
        anchorEl: null,
        // open: false,
        collections: [],
        selCollectionId: null,
        deleteDialogOpen: false,
        addDialogOpen: false,
        editDialogOpen: false,
        collenctionTitle: null,
        collectionName: null,
        collectionId: 0,
        collectionIndex: 0,
        moveDialogOpen: false,
        moveParams: {
            oldCollenctionId: 0,
            bookList: '',
        },
        styleSetting: {}
    };

    componentWillMount() {
        let _userInfo = getUserInfo();
        let styleSetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/styleSetting")) || {};
        this.setState({
            styleSetting: styleSetting
        });
        if (this.props.location.query !== undefined) {
            let oldCollectionId = this.props.location.query.collectionId;
            let bookId = this.props.location.query.bookid;
            let moveParams = {
                oldCollenctionId: oldCollectionId,
                bookList: bookId,
            }
            this.setState({
                moveParams: moveParams
            })
        }
        this.start(this.state);
        this.fetch();
    }
    start = (state) => {
        let dict = {}
        dictionary(this.state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            })
        })
    }
    fetch = (params = {}) => {
        collectionList().then(res => {
            if (parseInt(this.state.moveParams.oldCollenctionId) > 0) {
                for (let i in res.docTblBookCollectionList) {
                    if (parseInt(this.state.moveParams.oldCollenctionId) === parseInt(res.docTblBookCollectionList[i].collectionId)) {
                        res.docTblBookCollectionList.splice(i, 1);
                        break;
                    }
                }
            }
            for (let index in res.docTblBookCollectionList) {
                let item = res.docTblBookCollectionList[index]['docTblBookCollectionDetailList'];
                for (let detailIndex in item) {
                    let detailItem = item[detailIndex];
                    let coverUuid = detailItem['docTblBook']['docTblBookDetail']['coverUuid'];
                    let fileUuid = detailItem['docTblBook']['docTblBookDetail']['fileUuid'];
                    let categoryId = detailItem['docTblBook']['docMstBookCategory']['categoryId'];
                    let docType = detailItem['docTblBook']['docType'];
                    getCoverPath(categoryId, fileUuid, coverUuid,docType).then(imgRes => {
                        res.docTblBookCollectionList[index]['docTblBookCollectionDetailList'][detailIndex]['docTblBook']['imgSrc'] = imgRes;
                        this.setState({
                            collections: res.docTblBookCollectionList
                        })
                    }).catch(error => {
                        res.docTblBookCollectionList[index]['docTblBookCollectionDetailList'][detailIndex]['docTblBook']['imgSrc'] = "";
                        this.setState({
                            collections: res.docTblBookCollectionList
                        })
                    });
                }
            }

        }).catch(error => {
            Toast.info(error)
        })
    }

    handleClick = (selCollectionId, selIndex) => event => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget, selCollectionId: selCollectionId, selIndex: selIndex });
    };

    handleClose = event => {
        event.stopPropagation();
        this.setState({ anchorEl: null });
    };
    handleCollectionsDelete = event => {
        event.stopPropagation();
        this.setState({ deleteDialogOpen: true, anchorEl: null });
    };

    handleEdit = (collectionId, index) => event => {
        localforage.removeItem('searchTextCache');
        if (this.state.moveParams.oldCollenctionId > 0) {
            let collectionName = this.state.collections[index].collectionName;
            this.setState({
                moveDialogOpen: true,
                collectionId: collectionId,
                collectionName: collectionName
            });
            return;
        }
        this.props.history.push('/user/book/editmycollection/' + collectionId);
        this.setState({ anchorEl: null });
    }
    moveBookDialogOk = () => {
        let data = {
            collectionId: this.state.collectionId,
            docTblBookCollectionDetailList: []
        };
        for (let key in this.state.moveParams.bookList) {
            let value = this.state.moveParams.bookList[key];
            data.docTblBookCollectionDetailList.push({
                docTblBookCollectionDetailPK: {
                    bookId: value,
                    collectionId: this.state.moveParams.oldCollenctionId,
                }
            })
        }
        collectionMove(data).then(res => {
            Toast.info(messageFormat(this.state.dictWord.moved_to_completed, this.state.collectionName));
            this.fetch();
            this.setState({
                moveParams: {},
                moveDialogOpen: false
            })
        }).catch(error => {
            Toast.info(error)
        });
        return;
    }
    moveBookDialogClose = () => {
        this.setState({
            moveDialogOpen: false
        });
    }
    deleteCollectionDialogOk = () => {
        collectionDelete({
            collectionId: this.state.selCollectionId
        }).then(res => {
            let collections = this.state.collections;
            collections.splice(this.state.selIndex, 1);
            this.setState({
                deleteDialogOpen: false,
                selCollectionId: null,
                collections: collections
            });
        }).catch(error => {
            Toast.info(error)
        })
    }
    deleteCollectionDialogClose = () => {
        this.setState({
            deleteDialogOpen: false,
            selCollectionId: null,
        });
    }
    handleClickOpen = () => {
        this.setState({ addDialogOpen: true });
    };
    addCollectionDialogClose = () => {
        this.setState({ addDialogOpen: false });
    };
    handleEditOpen = event => {
        let index = this.state.selIndex;
        let collectionName = this.state.collections[index].collectionName;
        let collectionId = this.state.collections[index].collectionId;
        this.setState({
            editDialogOpen: true,
            collectionId: collectionId,
            collectionName: collectionName,
            anchorEl: null,
        });
        event.stopPropagation();
    };
    handleEditClose = () => {
        this.setState({ editDialogOpen: false });
    };
    handleChange = event => {
        this.setState({
            collenctionTitle: event.target.value
        });
    }
    handleEditChange = event => {
        this.setState({
            collectionName: event.target.value
        });
    }
    addCollectionDialogOk = () => {
        let title = this.state.collenctionTitle;
        if (title) {
            if (title.length > 40) {
                return;
            }
            for (let i in this.state.collections) {
                if (this.state.collections[i].collectionName === title) {
                    Toast.error(messageFormat(this.state.dictWord.msg_error_record_exists, this.state.dictWord.collection_name));
                    return;
                }
            }
            this.props.history.replace({
                pathname: '/user/book/addtocollection',
                query: {
                    collenctionTitle: this.state.collenctionTitle,
                    type: 'add'
                }
            })
        }
    }
    handleEditDialogOk = () => {
        let title = this.state.collectionName;
        if (title) {
            if (title.length > 40) {
                return;
            }
            for (let i in this.state.collections) {
                if (this.state.collections[i].collectionId !== this.state.collectionId && this.state.collections[i].collectionName === title) {
                    Toast.error(messageFormat(this.state.dictWord.msg_error_record_exists, this.state.dictWord.collection_name));
                    return;
                }
            }
            collectionUpdate({
                collectionId: this.state.collectionId,
                collectionName: title
            }).then(res => {
                this.setState({
                    collectionName: '',
                    collectionId: 0,
                    editDialogOpen: false
                });
                this.fetch();
            }).catch(error => {
                Toast.error(error)
            });
        }
    }
    handleReturn=(collectionId)=>{
        this.props.history.push('/user/book/editmycollection/' + this.state.moveParams.oldCollenctionId);
    }
    render() {
        // const { classes } = this.props;
        const { anchorEl, dictWord, collections } = this.state;
        return (
            <Typography component="div" className="my-collection">
                <Typography component="h2" variant="h5" style={{ padding: '10px 25px' }}>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12} md={8} className="h2-title">{dictWord.my_collection}</Grid>
                        {this.state.moveParams.oldCollenctionId?
                        <Grid item xs={12} md={4} style={{ textAlign: 'right' }}>
                           <Button style={{ background: this.state.styleSetting.buttonColor}} onClick={this.handleReturn} variant="contained" color="primary">{dictWord.return}</Button>
                        </Grid>:''}
                    </Grid>
                </Typography>
                <Divider />
                <Typography component="div" className="main-con">
                    <Typography component="div" className="plusCon" onClick={this.handleClickOpen}>
                        <label htmlFor="outlined-button-file">
                            <Button component="span" className="button">
                                <PlusIcon />
                            </Button>
                        </label>
                        <div className="h53" />
                    </Typography>
                    {collections.map((item, index) => {
                        return <Typography key={index} component="div">
                            <Typography component="div" className="flexDiv" onClick={this.handleEdit(item.collectionId, index)}>
                                {[0, 1, 2, 3].map((detailItem, detailIndex) => {
                                    if (item['docTblBookCollectionDetailList'][detailIndex]) {
                                        return <img key={detailIndex} src={item['docTblBookCollectionDetailList'][detailIndex]['docTblBook']['imgSrc']} alt="" className="img" style={{backgroundColor:'#fff'}} />
                                    } else {
                                        return <img key={detailIndex} src="" alt="" className="img" />
                                    }
                                })}
                                <IconButton style={{background:"#ccc"}} onClick={this.handleClick(item.collectionId, index)}>
                                    <MoreIcon color="primary" />
                                </IconButton>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleClose}
                                    disableAutoFocusItem
                                >
                                    <MenuItem onClick={this.handleEditOpen}>{dictWord.edit_record}</MenuItem>
                                    <MenuItem onClick={this.handleCollectionsDelete}>{dictWord.delete_record}</MenuItem>
                                </Menu>
                            </Typography>
                            <Typography component="div">
                                <Typography component="h3" variant="h6" style={{ overflow: 'hidden', height: '25px' }}>{item.collectionName}</Typography>
                                <Typography align="left">{item.colleationCount}</Typography>
                            </Typography>
                        </Typography>
                    })}
                </Typography>
                <MyDialog
                    // fullScreen={true}
                    open={this.state.addDialogOpen}
                    option={{
                        maxWidth: "lg",
                        ok: {
                            text: dictWord.insert
                        },
                    }}
                    titleDoc={<DialogTitle disableTypography style={{ flexGrow: 1, textAlign: 'center' }} id="responsive-dialog-title">{dictWord.new_insert}</DialogTitle>}
                    content={<div style={{ minWidth: '250px' }}><TextField autoFocus margin="dense" name="collenctionTitle" onChange={this.handleChange} label={dictWord.title_enter} type="text" inputProps={{ maxLength: 40 }} fullWidth /></div>}
                    handleOk={this.addCollectionDialogOk}
                    handleClose={this.addCollectionDialogClose}
                />
                <MyDialog
                    open={this.state.editDialogOpen}
                    option={{
                        maxWidth: "lg",
                        ok: {
                            text: dictWord.collection_name_chage
                        },
                        close: {
                            text: dictWord.cancel
                        },
                    }}
                    titleDoc={<DialogTitle disableTypography style={{ flexGrow: 1, textAlign: 'center' }} id="responsive-dialog-title">{dictWord.my_collection_name_change}</DialogTitle>}
                    content={<div style={{ minWidth: '250px' }}><TextField autoFocus margin="dense" name="collectionName" onChange={this.handleEditChange} label={dictWord.title_enter} type="text" inputProps={{ maxLength: 40 }} value={this.state.collectionName} fullWidth /></div>}
                    handleOk={this.handleEditDialogOk}
                    handleClose={this.handleEditClose}
                />

                <MyDialog
                    open={this.state.deleteDialogOpen}
                    option={{
                        ok: {
                            text: dictWord.delete_record
                        },
                        close: {
                            text: dictWord.cancel
                        },
                        // topClose: true,
                    }}
                    // title={dictWord.confirmation}
                    content={<span>{dictWord.select_content_delete}</span>}
                    handleOk={this.deleteCollectionDialogOk}
                    handleClose={this.deleteCollectionDialogClose}
                />
                <MyDialog
                    open={this.state.moveDialogOpen}
                    option={{
                        ok: {
                            text: dictWord.move
                        },
                        close: {
                            text: dictWord.cancel
                        },
                        // topClose: true,
                    }}
                    // title={dictWord.confirmation}
                    content={<span>{messageFormat(dictWord.moved_to_completed, this.state.collectionName)}</span>}
                    handleOk={this.moveBookDialogOk}
                    handleClose={this.moveBookDialogClose}
                />
            </Typography>
        )
    }
}

export default MyCollection;