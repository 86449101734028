/**
 *
 * http通用工具函数
 */
import axios from 'mdoc/axios/axiosService';

/**
 * 公用get请求
 * @param url       接口地址
 * @param data      接口参数
 * @param headers   接口所需header配置
 * @param msg       接口异常提示
 */
export const get = (url, data, headers, msg = '接口异常') =>
    new Promise((resolve, reject) => {
        axios.get(url, {
            params: data,
        }).then(res => resolve(res.data)).catch(err => {
            // window.location = '/#/404';
        })
    })

/**
 * 公用post请求
 * @param url       接口地址
 * @param data      接口参数
 * @param headers   接口所需header配置
 * @param msg       接口异常提示
 */
export const post = (url, data, headers, async = true, msg = '接口异常') =>
    new Promise((resolve, reject) => {
        axios.post(url, data).then(res => resolve(res.data)).catch(err => {
            // window.location = '/#/404';
        })
    })

/**
 * 公用delete请求
 * @param url       接口地址
 * @param data      接口参数
 * @param headers   接口所需header配置
 * @param msg       接口异常提示
 */
export const del = (url, data, headers, msg = '接口异常') =>
    new Promise((resolve, reject) => {
        axios.delete(url, { params: data }
        ).then(res => resolve(res.data)).catch(err => {
            // window.location = '/#/404';
        })
    })

/**
 * 公用put请求
 * @param url       接口地址
 * @param data      接口参数
 * @param headers   接口所需header配置
 * @param msg       接口异常提示
 */
export const put = (url, data, headers, msg = '接口异常') =>
    new Promise((resolve, reject) => {
        axios.put(url, data).then(res => resolve(res.data)).catch(err => {
            // window.location = '/#/404';
        })
    })

/**
 * 公用upload请求
 * @param url       接口地址
 * @param data      接口参数
 * @param headers   接口所需header配置
 * @param msg       接口异常提示
 */
export const upload = (url, data, headers, async = true, msg = '接口异常') =>
    new Promise((resolve, reject) => {
        axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        ).then(res => resolve(res.data)).catch(err => {
            console.log(err);
            // window.location = '/#/404';
        })
    })

/**
 * 公用downloadImg请求
 * @param url       接口地址
 * @param data      接口参数
 * @param headers   接口所需header配置
 * @param msg       接口异常提示
 */
export const downloadImg = (url, data, headers, async = true, msg = '接口异常') =>
    new Promise((resolve, reject) => {
        axios.get(url, {
            params: data,
            responseType: "arraybuffer",
            headers: {
                'Content-Type': 'multipart/form-data;charset=UTF-8'
            }
        }).then(res => resolve(res.data)).catch(err => {
            reject(false);
            // window.location = '/#/404';
        })
    })

    /**
 * 公用downloadImg请求
 * @param url       接口地址
 * @param data      接口参数
 * @param headers   接口所需header配置
 * @param msg       接口异常提示
 */
export const downloadMp4 = (url, data, headers, async = true, msg = '接口异常') =>
new Promise((resolve, reject) => {
    axios.get(url, {
        params: data,
        responseType: "blob",
        // responseType: "arraybuffer",
        headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8',
            'range': 'bytes=0-',
        },
        // headers: headers
    }).then(res => {
        // console.log(res);
        resolve(URL.createObjectURL(res.data));
        // resolve(res.data);
    }).catch(err => {
        reject(false);
        // window.location = '/#/404';
    })
})
/**
* 公用downloadHtml请求
* @param url       接口地址
* @param data      接口参数
* @param headers   接口所需header配置
* @param msg       接口异常提示
*/
export const downloadHtml = (url, data, headers, async = true, msg = '接口异常') =>
    new Promise((resolve, reject) => {
        axios.get(url, {
            params: data,
            responseType: "text",
            headers: {
                'Content-Type': 'multipart/form-data;charset=UTF-8'
            }
        }).then(res => resolve(res.data)).catch(err => {
            reject(false);
            // window.location = '/#/404';
        })
    })
/**
 * 公用download template请求
 * @param url       接口地址
 * @param data      接口参数
 * @param headers   接口所需header配置
 * @param msg       接口异常提示
 */
export const downloadTemplate = (url, data, headers, async = true, msg = '接口异常') =>
    new Promise((resolve, reject) => {
        axios.get(url, {
            params: data,
            responseType: "arraybuffer",
            headers: {
                'Content-Type': 'multipart/form-data;charset=UTF-8'
            }
        }).then(res => resolve(res.data)).catch(err => {
            // window.location = '/#/404';
        })
    })