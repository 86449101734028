import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Button,
    IconButton,
    Typography,
    Divider,
    Grid,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    List,
    ListItem,
    ListItemText,
    Collapse,
    SvgIcon,
    ListItemIcon,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
} from '@material-ui/core';

import {
    Apps,
    ChevronRight,
    ExpandLess,
    ExpandMore,
    ArrowDownward,
    ArrowUpward,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import category_green from 'mdoc/style/imgs/category_green.png';
import leave_comments from 'mdoc/style/imgs/leave_comments.png';


import Star from 'mdoc/utils/Star';
import MyDialog from 'mdoc/utils/myDialog';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';

import $ from 'jquery';

import Toast from 'mdoc/utils/toast';
import { dictionary, getCoverPath, commentList, commentAdd, getUserInfo } from 'mdoc/axios/index'
import {
    getRecentlyList,
    getCategories,
    getCategoryList,
} from 'mdoc/axios/library'

import ChatBox from 'mdoc/components/book/ChatBox';
import 'mdoc/components/book/book.less';
import localforage from 'localforage';

const FormatListCheckbox = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M21,19V17H8V19H21M21,13V11H8V13H21M8,7H21V5H8V7M4,5V7H6V5H4M3,5A1,1 0 0,1 4,4H6A1,1 0 0,1 7,5V7A1,1 0 0,1 6,8H4A1,1 0 0,1 3,7V5M4,11V13H6V11H4M3,11A1,1 0 0,1 4,10H6A1,1 0 0,1 7,11V13A1,1 0 0,1 6,14H4A1,1 0 0,1 3,13V11M4,17V19H6V17H4M3,17A1,1 0 0,1 4,16H6A1,1 0 0,1 7,17V19A1,1 0 0,1 6,20H4A1,1 0 0,1 3,19V17Z" />
        </SvgIcon>
    );
}
const Message = (props) => {
    return (
        <img src={leave_comments} {...props} alt="" style={{width:'40px',height:'40px'}} />
    );
}

function Plus(props) {
    return (
        <SvgIcon {...props}>
            <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
        </SvgIcon>
    );
}

function Minus(props) {
    return (
        <SvgIcon {...props}>
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
        </SvgIcon>
    );
}

function FolderPlusOutline(props) {
    return (
        <img src={category_green} alt="category_green" style={{width:'30px',height:'30px'}} />
    );
}

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    main: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
        paddingLeft: theme.spacing.unit * 3,
    },
    grow: {
        position: 'relative',
        zIndex: 2
    },
    margin: {
        marginLeft: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    button: {
        padding: '6px 0px',
        textTransform: 'none'
    },
    orderButton: {
        padding: '6px 0px',
        textTransform: 'none',
        minWidth: '10px'
    },
    menuTitle: {
        padding: '6px 0px'
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
        textTransform: 'none'
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
        textTransform: 'none'
    },
    title: {
        marginTop: 10,
        textAlign: 'left',
        textOverflow: '-o-ellipsis-lastline',
        overflow: 'hidden',
        display: '-webkit-box',
        'WebkitLineClamp': 2,
        'lineClamp': 2,
        'WebkitBoxOrient': 'vertical'
    },
    img: {
        minWidth: '200',
        width: '90%',
        height: '272px'
    },
    noWrap: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingBottom: '10px',
    }
});

class Library extends React.Component {
    state = {
        direction: 'row',
        dictWord: {
            'search': '',
            'my_collection': '',
            'library': '',
            'category': '',
            choose_all: '',
            cancel_all: '',
            'recent_documents': '',
            'look_all': '',
            'cover_image': '',
            'content_title': '',
            'content_evaluation_value': '',
            'category_name': '',
            'registration_date': '',
            'update_date': '',
            'name': '',
            'self_evaluation': '',
            'evaluation_average': '',
            'read_count': '',
            'content_description': '',
            'published_user': '',
            'published_department': '',
            'price': '',
            'read': '',
            'comment': '',
            'msg_error_not_null_with_item': '',
            'show_history': '',
        },
        bookInfo: {},
        recently: [],
        categoryInfo: {},
        orderSidx: [
            { id: 'createDate', dic: 'registration_date', sord: 'desc' },
            { id: 'updateDate', dic: 'update_date', sord: 'desc' },
            { id: 'contentTitle', dic: 'name', sord: 'asc' },
            { id: 'selfEvaluate', dic: 'self_evaluation', sord: 'desc' },
            { id: 'averageEvaluate', dic: 'evaluation_average', sord: 'desc' },
            { id: 'readCount', dic: 'read_count', sord: 'desc' }
        ],
        //send to ChatBox 
        messageObj: null,
        loading: false,
        dialogOpen: false,
        chatboxOpen: false,
        orderOpen: false,
        sidx: 'createDate',
        sord: 'desc',
        orderDic: 'registration_date',
        comments: [],
        commentMessage: '',
        commentFlg: 0,
        commentPage: 1,
        commentSize: 20,
        openFlag: {},
        styleSetting: {},
        categorysDataOpen: {},
        categories: [],

        categoryCheckboxValueOld: [],
        categoryIds: [],
        categoryList: [],
        categoryOpen: false,
        categoryCheckboxValue: [],
        categoryIdsAll: [],
        categoryShow: [],
        cateSelAll: true
    };

    componentWillMount() {
        this.start(this.state);
    }

    start = (state) => {
        let _userInfo = getUserInfo();
        let styleSetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/styleSetting")) || {};
        let commentFlg = localStorage.getItem(_userInfo.clientId + "/" + _userInfo.userId + '/commentFlg') || 0;
        this.setState({
            loading: true,
            commentFlg: commentFlg,
            styleSetting: styleSetting
        });
        let dict = {};
        dictionary(state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            },()=>{
                this.fetch();
            });
        })
    }


    fetch = (params = {}) => {
        this.setState({ loading: true })
        this.recentlyList({
            count: 8
        });
        getCategoryList({
            ...params
        }).then((data) => {
            if (data.results.length > 0) {
                let categoryIdsAll = [];
                for (let i in data.results) {
                    categoryIdsAll.push(data.results[i].categoryId.toString());
                }
                let results = this.sortArray(0, data.results);
                let newData = this.treeArray(data.results);

                let _userInfo = getUserInfo();
                let categoryIds = JSON.parse(window.localStorage.getItem(_userInfo.userId + "/categoryIds")) || [];

                // let categoryIds = [];
                if (categoryIds.length <= 0) {
                    for (let i in results) {
                        if (results[i].parentId === 0 && results[i].orderKey === 1) {
                            categoryIds.push(results[i].categoryId.toString());
                            break;
                        }
                    }
                }
                this.setState({
                    categoryList: results,
                    categoryIds,
                    categoryShow: newData,
                    categoryIdsAll
                }, () => {
                    this.categoriesData(categoryIds, this.state.sidx, this.state.sord);
                })
            }
        }).catch(error => {
            Toast.info(error)
        })
    }

    getCategoryName(categoryId) {
        if (categoryId < 0) return;
        let categoryName = '';
        for (let i in this.state.categoryList) {
            if (this.state.categoryList[i].categoryId === categoryId) {
                categoryName = this.state.categoryList[i].categoryName;
                break;
            }
        }
        return categoryName;
    }

    sortArray(id, oldData) {
        let newsArray = [];
        let j = 0;
        ParentArry(id);
        function ParentArry(id) {
            for (let i in oldData) {
                if (oldData[i].parentId === id) {
                    newsArray[j] = oldData[i];
                    j++;
                    ParentArry(oldData[i].categoryId, newsArray, j);
                }
            }
        }

        return newsArray;
    }

    treeArray(data) {
        let map = {};
        let val = [];
        let openFlag = {};
        data.forEach(it => {
            openFlag['top' + it.categoryId] = false;
            map[it.categoryId] = it;
        })
        data.forEach(it => {
            const parent = map[it.parentId];
            if (parent) {
                if (!Array.isArray(parent.children)) parent.children = [];
                parent.children.push(it);
            } else {
                val.push(it);
            }
        })
        this.setState({
            openFlag
        });
        return val;

    }

    ListItemCategory = (data) => {
        return data.map((item, index) => {
            if (!item.children) {
                return (
                    <Collapse key={index} in={item.parentId === 0 ? true : this.state.openFlag['top' + item.parentId]} timeout="auto" unmountOnExit>
                        <List style={{ marginLeft: (5 * item.depth) + 'px', padding: '0' }} component="div" disablePadding>
                            <ListItem style={{ border: 'none', padding: '0',cursor: 'pointer' }}>
                                <ListItemIcon style={{ marginLeft: '23px'}}><span /></ListItemIcon>
                                <ListItemText>
                                    <FormControlLabel
                                        value={item.categoryId.toString()}
                                        control={<Checkbox color="primary" onChange={this.handleCategoryChange.bind(this)} />}
                                        label={item.categoryName}
                                        labelPlacement="start"
                                        checked={this.state.categoryCheckboxValue.indexOf(item.categoryId.toString()) >= 0}
                                    />
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                )
            } else {
                if (item.parentId !== 0) {
                    return (
                        <Collapse key={index} in={this.state.openFlag['top' + item.parentId]} timeout="auto" unmountOnExit>
                            <List key={index} style={{ marginLeft: (5 * item.depth) + 'px', padding: '0' }} component="div">
                                <ListItem style={{ border: 'none', padding: '0',cursor: 'pointer' }}>
                                    <ListItemIcon onClick={this.handleClickCategory.bind(this, item.categoryId)}>{this.state.openFlag['top' + item.categoryId] ? <Minus /> : <Plus />}</ListItemIcon>
                                    <ListItemText>
                                        <FormControlLabel
                                            value={item.categoryId.toString()}
                                            control={<Checkbox color="primary" onChange={this.handleCategoryChange} />}
                                            label={item.categoryName}
                                            labelPlacement="start"
                                            checked={this.state.categoryCheckboxValue.indexOf(item.categoryId.toString()) >= 0}
                                        />
                                    </ListItemText>
                                </ListItem>
                                {this.ListItemCategory(item.children)}
                            </List>
                        </Collapse>
                    )
                } else {
                    return (
                        <List key={index} style={{ marginLeft: (5 * item.depth) + 'px', padding: '0' }} component="nav">
                            <ListItem style={{ border: 'none', padding: '0',cursor: 'pointer' }}>
                                <ListItemIcon onClick={this.handleClickCategory.bind(this, item.categoryId)}>{this.state.openFlag['top' + item.categoryId] ? <Minus /> : <Plus />}</ListItemIcon>
                                <ListItemText>
                                    <FormControlLabel
                                        value={item.categoryId.toString()}
                                        control={<Checkbox color="primary" onChange={this.handleCategoryChange} />}
                                        label={item.categoryName}
                                        labelPlacement="start"
                                        checked={this.state.categoryCheckboxValue.indexOf(item.categoryId.toString()) >= 0}
                                    />
                                </ListItemText>
                            </ListItem>
                            {this.ListItemCategory(item.children)}
                        </List>
                    )
                }
            }
        })
    }

    handleClickCategory(id, event) {
        let openFlag = this.state.openFlag;
        openFlag['top' + id] = !openFlag['top' + id];
        this.setState({
            openFlag
        });
    }



    recentlyList(params = {}) {
        getRecentlyList({
            ...params
        }).then((data) => {
            if (data.results.length > 0) {
                for (let i in data.results) {
                    if (data.results[i].docTblBookDetail && data.results[i].docTblBookDetail.coverUuid) {
                        getCoverPath(
                            data.results[i].categoryId,
                            data.results[i].docTblBookDetail.fileUuid,
                            data.results[i].docTblBookDetail.coverUuid,
                            data.results[i].docType
                        ).then(imgRes => {
                            data.results[i].coverPath = imgRes;
                            this.setState({
                                recently: data.results,
                            })
                        }).catch(error => {
                            Toast.info(error)
                        });
                    } else {
                        this.setState({
                            recently: data.results,
                        })
                    }
                }
            } else {
                this.setState({
                    recently: [],
                })
            }
        }).catch(error => {
            this.setState({
                recently: [],
            })
            Toast.info(error)
        })
    }

    categoriesData(categoryIds, sidx, sord) {
        if (categoryIds.length < 0) return;
        let _categoryIds = [];
        let categoryCheckboxValue = [];
        let categoryCheckboxValueOld = [];
        let categoryList = this.state.categoryList;
        for (let i in categoryList) {
            if (categoryIds.indexOf(categoryList[i].categoryId.toString()) > -1) {
                _categoryIds.push({
                    categoryId: categoryList[i].categoryId
                });
                categoryCheckboxValue.push(categoryList[i].categoryId.toString());
                categoryCheckboxValueOld.push(categoryList[i].categoryId.toString());
            }
        }
        let cateSelAll = this.state.cateSelAll;
        if (categoryCheckboxValue.length < this.state.categoryIdsAll.length) {
            cateSelAll = true;
        } else {
            cateSelAll = false;
        }
        this.setState({
            categoryCheckboxValue,
            categoryCheckboxValueOld,
            cateSelAll
        });

        let _userInfo = getUserInfo();
        localStorage.setItem(_userInfo.userId + '/categoryIds', JSON.stringify(categoryIds));
        getCategories({
            categoryList: _categoryIds,
            sidx: sidx ? sidx : 'createDate',
            sord: sord ? sord : 'asc',
            count: 8
        }).then((R) => {
            let results = R.results;
            if (results.length > 0) {
                let categories = [];
                for (let j in results) {
                    if (results[j].count > 0) categories.push(results[j]);
                }
                let categorysDataOpen = this.state.categorysDataOpen;
                if (categories.length > 0) {
                    for (let i in categories) {
                        categorysDataOpen[categories[i].defaultCategoryId] = false;
                        for (let l in categories[i].docTblBookList) {
                            if (categories[i].docTblBookList[l].docTblBookDetail && categories[i].docTblBookList[l].docTblBookDetail.coverUuid) {
                                getCoverPath(
                                    categories[i].docTblBookList[l].categoryId,
                                    categories[i].docTblBookList[l].docTblBookDetail.fileUuid,
                                    categories[i].docTblBookList[l].docTblBookDetail.coverUuid,
                                    categories[i].docTblBookList[l].docType
                                ).then(imgRes => {
                                    categories[i].docTblBookList[l].coverPath = imgRes;
                                    this.setState({
                                        categories: categories,
                                        categoryIds: categoryIds
                                    })
                                }).catch(error => {
                                    Toast.info(error)
                                });
                            } else {
                                this.setState({
                                    categories: categories,
                                    categoryIds: categoryIds
                                })
                            }
                        }
                    }
                    this.setState({ categorysDataOpen })
                } else {
                    this.setState({
                        categories: [],
                        categoryIds: categoryIds
                    })
                }
            } else {
                this.setState({
                    categories: [],
                    categoryIds: categoryIds
                })
            }
        }).catch(error => {
            this.setState({
                categories: [],
                categoryIds: categoryIds
            })
            Toast.error(error);
        })
    }

    dialogHandleClose = () => {
        this.setState({
            dialogOpen: false
        });
    }
    handleRecentlyLookAll = () => {
        this.props.history.push({
            pathname: '/user/book/recently',
        });
    }
    handleCategoriesLookAll = (categoryId) => event => {
        this.props.history.push({
            pathname: '/user/book/categories/' + categoryId,
        });
    }
    handleRecentlyBookInfo = (keyId) => event => {
        let bookInfo = this.state.recently[keyId];
        localforage.removeItem('searchTextCache');
        if (bookInfo.docType === 'link' && bookInfo.docLink.length > 0) {
            window.open(bookInfo.docLink, "_blank");
            return;
        }
        if (bookInfo.introduction.length > 0) {
            bookInfo.introduction = bookInfo.introduction.replace(/\n|\r|\r\n/g, '<br>').replace(/\s/g, " ");
        }
        this.setState({
            bookInfo: bookInfo,
            dialogOpen: true
        });
    }
    handleCategoriesBookInfo = (bookInfo) => event => {
        localforage.removeItem('searchTextCache');
        if (bookInfo.docType === 'link' && bookInfo.docLink.length > 0) {
            window.open(bookInfo.docLink, "_blank");
            return;
        }
        if (bookInfo.introduction.length > 0) {
            bookInfo.introduction = bookInfo.introduction.replace(/\n|\r|\r\n/g, '<br>').replace(/\s/g, " ");
        }
        this.setState({
            bookInfo: bookInfo,
            dialogOpen: true
        });
    }
    handleDirection = (direction) => event => {
        this.setState({
            direction: direction
        });
    }
    handleToggle = () => {
        this.setState(state => ({ categoryOpen: !state.categoryOpen }));
    };
    handleCategoryColse = () => {
        this.setState(state => ({ categoryOpen: !this.state.categoryOpen, categoryCheckboxValue: this.state.categoryCheckboxValueOld }));
    }
    handleCategoryOk = () => {
        this.categoriesData(this.state.categoryCheckboxValue, this.state.sidx, this.state.sord);
        this.setState(state => ({ categoryOpen: !this.state.categoryOpen }));
    }
    handleCategoryChange = (event) => {
        let categoryCheckboxValue = this.state.categoryCheckboxValue;
        let valueKey = categoryCheckboxValue.indexOf(event.target.value);
        if (valueKey > -1) {
            categoryCheckboxValue.splice(valueKey, 1);
        } else {
            categoryCheckboxValue.push(event.target.value);
        }
        let cateSelAll = this.state.cateSelAll;
        if (categoryCheckboxValue.length < this.state.categoryIdsAll.length) {
            cateSelAll = true;
        } else {
            cateSelAll = false;
        }
        this.setState({
            categoryCheckboxValue,
            cateSelAll
        });
    }
    handleSelAll() {
        let cateSelAll = !this.state.cateSelAll;
        this.setState({
            cateSelAll,
            categoryCheckboxValue: !cateSelAll ? this.state.categoryIdsAll : []
        })
    }

    handleOrderToggle = () => {
        this.setState(state => ({ orderOpen: !state.orderOpen }));
    };
    handleSidxToggle = (sidx, dic, sord) => event => {
        if (this.anchorEl2.contains(event.target)) {
            return;
        }
        this.setState({
            orderOpen: false,
            sidx: sidx,
            sord: sord,
            orderDic: dic,
        });
        this.categoriesData(this.state.categoryIds, sidx, sord);
    }
    handleOrderClose = event => {
        if (this.anchorEl2.contains(event.target)) {
            return;
        }
        this.setState({ orderOpen: false });
    };
    handleMessageBox = (value) => {
        value['messageBoxTitle'] = this.state.dictWord.comment;
        value['placeholder'] = this.state.dictWord.msg_error_not_null_with_item.replace('%s', this.state.dictWord.comment);
        commentList(value.id, {
            pageNumber: 1,
            pageSize: this.state.commentSize,
        }).then(res => {
            let commentList = res.docTblUserCommentList;
            commentList.sort(function (obj1, obj2) {
                return obj1.id - obj2.id;
            });
            this.setState({
                chatboxOpen: true,
                messageObj: value,
                comments: commentList
            })
            $('#customized-dialog-content').scrollTop($('#customized-dialog-content')[0].scrollHeight);
        }).catch(error => {
            this.setState({
                chatboxOpen: true,
                messageObj: value,
                comments: []
            })
        })
    }

    receiveHandle = (res) => {
        this.setState({
            commentMessage: res
        });
    }
    sendMessage(func){
        let commentMessage = this.state.commentMessage;
        this.setState({
            commentMessage: '',
        }, () => {
            $('#commentMessage').val('');
            commentMessage = commentMessage.replace(/(^\s*)|(\s*$)/g, "");
            commentMessage = encodeURI(commentMessage);
            if (commentMessage.length > 0) {
                commentAdd({
                    bookId: this.state.messageObj.id,
                    comment: commentMessage
                }).then(res => {
                    let comments = this.state.comments;
                    comments.push(res);
                    this.setState({
                        comments: comments
                    },()=>{
                        func();
                    });

                }).catch(error => {
                    Toast.error(error);
                })
            }
        });
    }
    handleLoadMessage = (func) => {
        let page = this.state.commentPage + 1;
        commentList(this.state.messageObj.id, {
            pageNumber: page,
            pageSize: this.state.commentSize,
        }).then(res => {
            let commentList = res.docTblUserCommentList;
            commentList.sort(function (obj1, obj2) {
                return obj1.id - obj2.id;
            });
            let comments = commentList.concat(this.state.comments);
            this.setState({
                comments: comments,
                commentPage: page
            },()=>{
                func(this.state.comments.length);
            })
        }).catch(error => {
            Toast.error(error);
        })
    }
    fatherFn = (status) => {
        this.setState({
            chatboxOpen: status,
            commentPage: 1,
        })
    }
    handleCategoryOpen(CategoryId) {
        let categorysDataOpen = this.state.categorysDataOpen;
        categorysDataOpen[CategoryId] = !categorysDataOpen[CategoryId];
        this.setState({ categorysDataOpen })
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.main}>
                <div className={classes.appBarSpacer} />
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.grow}
                >
                    <Grid item xs={4} md={10} />
                    <Grid item xs={8} md={2} style={{ textAlign: 'right' }}>
                        <Button
                            className={classes.orderButton}
                            buttonRef={node => {
                                this.anchorEl2 = node;
                            }}
                            aria-owns={this.state.orderOpen ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleSidxToggle(this.state.sidx, this.state.orderDic, 'asc')}
                        >
                            <ArrowUpward color={this.state.sord === 'asc' ? 'primary' : 'disabled'} />
                        </Button>
                        <Button
                            className={classes.orderButton}
                            buttonRef={node => {
                                this.anchorEl2 = node;
                            }}
                            aria-owns={this.state.orderOpen ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleSidxToggle(this.state.sidx, this.state.orderDic, 'desc')}
                        >
                            <ArrowDownward color={this.state.sord === 'desc' ? 'primary' : 'disabled'} />
                        </Button>
                        <Button
                            className={classes.button}
                            buttonRef={node => {
                                this.anchorEl2 = node;
                            }}
                            aria-owns={this.state.orderOpen ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleOrderToggle}
                        >
                            {this.state.dictWord[this.state.orderDic]}
                        </Button>
                        <Popper open={this.state.orderOpen} anchorEl={this.anchorEl2} transition disablePortal style={{ zIndex: 9 }}>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    id="menu-list-grow"
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={this.handleOrderClose}>
                                            <MenuList>
                                                {this.state.orderSidx.map((value, index) => (
                                                    <MenuItem key={index} onClick={this.handleSidxToggle(value.id, value.dic, value.sord)}>
                                                        {value.id === this.state.sidx ?
                                                            <span style={{ color: '#2196f3' }}>{this.state.dictWord[value.dic]}</span> : this.state.dictWord[value.dic]
                                                        }
                                                    </MenuItem>
                                                ))}
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                        <IconButton onClick={this.handleDirection('column')}>
                            <FormatListCheckbox color={this.state.direction === 'column' ? 'primary' : 'inherit'} />
                        </IconButton>
                        <IconButton color={this.state.direction === 'row' ? 'primary' : 'inherit'} onClick={this.handleDirection('row')}>
                            <Apps />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item xs={8} md={10}>
                        <div className={classes.menuTitle}>{this.state.dictWord.recent_documents}</div>
                    </Grid>
                    <Grid item xs={4} md={2} style={{ textAlign: 'right' }}>
                        <Button className={classes.button} color={"primary"} size="small" onClick={this.handleRecentlyLookAll}>
                            {this.state.dictWord.look_all}
                            <ChevronRight fontSize="small" />
                        </Button>
                    </Grid>
                </Grid>
                {/* first list */}
                {
                    (this.state.direction === 'column')
                        ?
                        <div className="crossWrap" style={{ flexDirection: 'column' }}>
                            {this.state.recently.map((value, index) => (
                                <div key={index} className={"crossPage"}>
                                    <div className={"leftPart"}>
                                        <div className="imgCon">
                                            <img src={value.coverPath} alt="" onClick={this.handleRecentlyBookInfo(index)} />
                                        </div>
                                        <div className="textCon">
                                            <div className={classes.title} title={value.title}>
                                                {value.title}
                                            </div>
                                            <div className={'stars'}>
                                                <Star starPercent={value.averageScore} enableClick={false} />
                                            </div>
                                            {value.bookIcon === 'UPDATE' ? <div className={'cross-updatebox'}><span>UPDATE</span><i className={'triangle'} /></div> : null}
                                            {value.bookIcon === 'NEW' ? <div className={'cross-newbox'}><span>NEW</span><i className={'triangle'} /></div> : null}
                                        </div>
                                    </div>
                                    <div className={"rightPart"} >
                                        <div className={'cross-message'}>
                                            {parseInt(this.state.commentFlg) === 0 ?
                                                    <Message color={'disabled'} onClick={this.handleMessageBox.bind(this, value)} />
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <div className="listWrap flex fullWidth wrap" style={{ flexDirection: 'row' }}>
                            {this.state.recently.map((value, index) => (
                                //  md-per10 sm-per33 xs-per100
                                <div key={index} className={"one-list"}>
                                    <div className="w150">
                                        <img src={value.coverPath} alt="" onClick={this.handleRecentlyBookInfo(index)} />
                                        {value.bookIcon === 'UPDATE' ? <span className={'updatebox'}><span>UPDATE</span><i className={'triangle'} /></span> : null}
                                        {value.bookIcon === 'NEW' ? <span className={'newbox'}><span>NEW</span><i className={'triangle'} /></span> : null}
                                        <span className={'message'}>
                                            {parseInt(this.state.commentFlg) === 0 ?
                                                    <Message color={'disabled'} onClick={this.handleMessageBox.bind(this, value)} />
                                                : null}
                                        </span>
                                    </div>
                                    <div className="w150-bottom" style={{ width: '150px' }}>
                                        <div className={classes.title} title={value.title}>
                                            {value.title}
                                        </div>
                                        <div className={'stars'}>
                                            <Star starPercent={value.averageScore} enableClick={false} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                }
                <Divider style={{ width: '100%' }} />
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    <Grid item xs={4} md={10}>
                        <Button
                            className={classes.button}
                            buttonRef={node => {
                                this.anchorEl = node;
                            }}
                            aria-owns={this.state.categoryOpen ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleToggle}
                            color="primary"
                            style={{ marginTop: '50px' }}
                        ><FolderPlusOutline />{this.state.dictWord.category}</Button>
                    </Grid>
                </Grid>
                {/* second list */}
                {
                    (this.state.direction === 'column') ? (
                        this.state.categories.map((value, index) => (
                            <div className="crossWrap" key={index} style={{ flexDirection: 'column' }}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                >
                                    <Grid item xs={8} md={10}>
                                        <div className={classes.menuTitle} onClick={this.handleCategoryOpen.bind(this, value.defaultCategoryId)}>
                                            {value.defaultCategoryName}
                                            {this.state.categorysDataOpen[value.defaultCategoryId] === true ? <ExpandMore style={{ verticalAlign: 'middle' }} /> : <ExpandLess style={{ verticalAlign: 'middle' }} />}
                                        </div>
                                    </Grid>
                                    <Grid item xs={4} md={2} style={{ textAlign: 'right' }}>
                                        <Button className={classes.button} color={"primary"} size="small" onClick={this.handleCategoriesLookAll(value.defaultCategoryId)}>
                                            {this.state.dictWord.look_all}
                                            <ChevronRight fontSize="small" />
                                        </Button>
                                    </Grid>
                                </Grid>
                                {this.state.categorysDataOpen[value.defaultCategoryId] === true ? null : value.docTblBookList.map((v, i) => (
                                    <div key={i} className={"crossPage"}>
                                        <div className={"leftPart"}>
                                            <div className="imgCon">
                                                <img src={v.coverPath} alt="" onClick={this.handleCategoriesBookInfo(v)} />
                                            </div>
                                            <div className="textCon">
                                                <div className={classes.title} title={v.title}>
                                                    {v.title}
                                                </div>
                                                <div className={'stars'}>
                                                    <Star starPercent={v.averageScore} enableClick={false} />
                                                </div>
                                                {v.bookIcon === 'UPDATE' ? <div className={'cross-updatebox'}><span>UPDATE</span><i className={'triangle'} /></div> : null}
                                                {v.bookIcon === 'NEW' ? <div className={'cross-newbox'}><span>NEW</span><i className={'triangle'} /></div> : null}
                                            </div>
                                        </div>
                                        <div className={"rightPart"} >
                                            <div className={'cross-message'}>
                                                {parseInt(this.state.commentFlg) === 0 ?
                                                        <Message color={'disabled'} onClick={this.handleMessageBox.bind(this, v)} />
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ))
                    ) : (
                            this.state.categories.map((value, index) => (
                                <div className="listWrap flex fullWidth wrap" key={index} style={{ flexDirection: 'row' }}>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                    >
                                        <Grid item xs={8} md={10}>
                                            <div className={classes.menuTitle} onClick={this.handleCategoryOpen.bind(this, value.defaultCategoryId)}>
                                                {value.defaultCategoryName}
                                                {this.state.categorysDataOpen[value.defaultCategoryId] === true ? <ExpandMore style={{ verticalAlign: 'middle' }} /> : <ExpandLess style={{ verticalAlign: 'middle' }} />}
                                            </div>
                                        </Grid>
                                        <Grid item xs={4} md={2} style={{ textAlign: 'right' }}>
                                            <Button className={classes.button} color={"primary"} size="small" onClick={this.handleCategoriesLookAll(value.defaultCategoryId)}>
                                                {this.state.dictWord.look_all}
                                                <ChevronRight fontSize="small" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {this.state.categorysDataOpen[value.defaultCategoryId] === true ? null : value.docTblBookList.map((v, i) => (
                                        //  md-per10 sm-per33 xs-per100
                                        <div key={i} className={"one-list"}>
                                            <div className="w150">
                                                <img src={v.coverPath} alt="" onClick={this.handleCategoriesBookInfo(v)} />
                                                {v.bookIcon === 'UPDATE' ? <div className={'updatebox'}><span>UPDATE</span><i className={'triangle'} /></div> : null}
                                                {v.bookIcon === 'NEW' ? <div className={'newbox'}><span>NEW</span><i className={'triangle'} /></div> : null}
                                                <span className={'message'}>
                                                    {parseInt(this.state.commentFlg) === 0 ?
                                                            <Message color={'disabled'} onClick={this.handleMessageBox.bind(this, v)} />
                                                        : null}
                                                </span>
                                            </div>
                                            <div className="w150-bottom" style={{ width: '150px' }}>
                                                <div className={classes.title} title={v.title}>
                                                    {v.title}
                                                </div>
                                                <div className={'stars'}>
                                                    <Star starPercent={v.averageScore} enableClick={false} />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))
                        )

                }

                <MyDialog
                    open={this.state.dialogOpen}
                    option={{
                        topClose: true
                    }}
                    title={this.state.bookInfo.title}
                    content={
                        <div style={{ width: 500, height: 500 }}>
                             <Divider style={{ margin: '10px 0px', width: '100%' }} />
                            <Grid container direction="row" style={{ height: '150px', marginBottom: '15px' }} justify="center" spacing={8} alignItems="center">
                                <Grid item xs={12} md={2} style={{ marginRight: '10px', maxWidth: '24%' }}>
                                    <img width={'120'} height={'150'} style={{ objectFit: 'cover' }} alt={this.state.bookInfo.title} src={this.state.bookInfo.coverPath} />
                                </Grid>
                                <Grid item xs={12} md={4} className="img-right">
                                    <Typography gutterBottom className={classes.noWrap} title={this.state.bookInfo.title}>
                                        {this.state.bookInfo.title}
                                    </Typography>
                                    <div style={{ display: 'block', height: '20px', margin: '-5px 0 10px' }}><Star starPercent={this.state.bookInfo.averageScore} enableClick={false} /></div>
                                    {this.state.bookInfo.bookIcon === 'UPDATE' ? <div className={'cross-updatebox mb10'}><span>UPDATE</span><i className={'triangle'} /></div> : null}
                                    {this.state.bookInfo.bookIcon === 'NEW' ? <div className={'cross-newbox mb10'}><span>NEW</span><i className={'triangle'} /></div> : null}
                                    <Typography className={classes.noWrap} title={this.state.bookInfo.publisher}>{this.state.dictWord.published_department}:  {this.state.bookInfo.publisher}</Typography>
                                    <Typography className={classes.noWrap} title={this.state.bookInfo.author}>{this.state.dictWord.published_user}:  {this.state.bookInfo.author}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item xs={12} md={6}>
                                    {this.state.bookInfo.docType === "epub" ?
                                        <Button style={{ background: this.state.styleSetting.buttonColor }} variant="contained" href={'/#/user/book/epubread/' + this.state.bookInfo.id} fullWidth className={classes.button} color="primary">
                                            {this.state.dictWord.read}
                                        </Button>
                                        : <Button style={{ background: this.state.styleSetting.buttonColor }} variant="contained" href={'/#/user/book/read/' + this.state.bookInfo.id} fullWidth className={classes.button} color="primary">
                                            {this.state.dictWord.read}
                                        </Button>}
                                </Grid>
                            </Grid>
                            <Divider style={{ margin: '10px 0px', width: '100%' }} />
                            <Grid container>
                                <Grid item xs>
                                    <Typography style={{ color: '#7c7d7d', fontSize: '12px' }}>{this.state.dictWord.content_description}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Typography variant="body2" component="p" style={{ height: '250px',width:'100%', overflowY: 'auto' }} dangerouslySetInnerHTML={{ __html: this.state.bookInfo.introduction }} />
                            </Grid>
                        </div>
                    }
                    handleClose={this.dialogHandleClose.bind(this)}
                />
                <Dialog
                    open={this.state.categoryOpen}
                    onClose={this.handleCategoryColse.bind(this)}
                    aria-labelledby="responsive-dialog-title"
                    scroll="paper"
                >
                    <Toolbar style={{ minHeight: '30px', paddingRight: '10px', fontSize: '12px' }}>
                        <DialogTitle
                            disableTypography
                            className={classes.noWrap}
                            style={{ flexGrow: 1, maxWidth: '91%', textAlign: 'center' }}
                            id="responsive-dialog-title"
                        >
                            {this.state.dictWord.category}
                        </DialogTitle>
                        <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleCategoryColse.bind(this)}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <div style={{ width: 400, height: 500 }}>
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" name="position" row>
                                    <List>
                                        {this.ListItemCategory(this.state.categoryShow)}
                                    </List>
                                </FormGroup>
                            </FormControl>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onMouseUp={this.handleSelAll.bind(this)} color="primary">
                            {this.state.cateSelAll ? this.state.dictWord.choose_all : this.state.dictWord.cancel_all}
                        </Button>
                        <Button onClick={this.handleCategoryOk.bind(this)} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <ChatBox
                    chatboxStatus={this.state.chatboxOpen}
                    messageObj={this.state.messageObj}
                    commentMessage={this.state.commentMessage}
                    fatherFn={this.fatherFn}
                    messageList={this.state.comments}
                    onSendMessage={this.sendMessage.bind(this)}
                    receiveHandle={this.receiveHandle}
                    loadMessage={this.state.dictWord.show_history}
                    onLoadMessage={this.handleLoadMessage.bind(this)}
                />
            </div>
        )
    }
}

Library.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Library);