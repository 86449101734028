import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import Dialog from 'mdoc/utils/myDialog';

import { dictionary, noticeList, noticeInfo,getUserInfo } from 'mdoc/axios';
import Menu from 'mdoc/components/userInfo/Menu';
import ChevronRight from '@material-ui/icons/ChevronRight';
import 'mdoc/components/userInfo/notify.less';


const styles = theme => ({
    content: {
        // flexGrow: 1,
        padding: theme.spacing.unit * 3,
        flex: 1
    },
    main: {
        width: '100%',
        display: 'flex'
    },
    leftmenu: {
        width: '240px'
    },
});

class Notify extends React.Component {
    state = {
        dictWord: {
            'notification': '',
            'save_information_list': '',
            'notice': '',
            'push_notification_list': '',
            'lang': '',
            'help': '',
            'password': '',
            'logoff': '',
            'load_more': ''
        },
        notices: [],
        noticesInfo: [],
        dialogOpen: false,
        pageNumber: 1,
        pageSize: 10,
        pageTotal: 0
    }
    componentWillMount() {
        this.start(this.state);
    }

    start = (state) => {
        let dict = {}
        dictionary(state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            })
        })
        let p = {
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize
        };
        let _userInfo = getUserInfo();
        let notifySetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId +"/"+_userInfo.userId +"/notify")) || {};
        if (notifySetting) {
            if (notifySetting.receiveflg === 1) {
                p = {
                    openTime: notifySetting.time,
                    pageNumber: this.state.pageNumber,
                    pageSize: this.state.pageSize
                }
            }
        }
        noticeList(p).then(res => {
            this.setState({
                notices: res.docTblNoticeList,
                pageTotal: res.pageTotal
            })
        }).catch(error => {
            this.setState({
                notices: []
            })
        })
    }

    handleLoadMore = () => {
        let page = this.state.pageNumber + 1;
        let p = {
            pageNumber: page,
            pageSize: this.state.pageSize
        };
        let _userInfo = getUserInfo();
        let notifySetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId +"/"+_userInfo.userId +"/notify")) || {};
        if (notifySetting) {
            if (notifySetting.receiveflg === 1) {
                p = {
                    openTime: notifySetting.time,
                    pageNumber: page,
                    pageSize: this.state.pageSize
                }
            }
        }
        noticeList(p).then(res => {
            if(res.docTblNoticeList){
                this.setState({
                    notices: this.state.notices.concat(res.docTblNoticeList),
                    pageNumber: page
                })
            }
        });
    }

    dialogHandleClose = () => {
        this.setState({
            dialogOpen: false
        });
    }
    dialogNoticeInfo = (noticeId) => event => {
        noticeInfo(noticeId).then(res => {
            this.setState({
                noticesInfo: res,
                dialogOpen: true
            });
        });

    }

    render() {
        const { classes } = this.props;
        const { dictWord, notices } = this.state;
        return (
            <div className={classes.main}>
                <Menu dictWord={dictWord} selectedkey="notify" />
                <div className={classes.content + ' right-con'}>
                    <h3>{dictWord.notification}</h3>
                    <ul className="information-ul">
                        {notices.map((item, index) => {
                            let textNum = item.noticeContent.indexOf('\n');
                            let noticeContentInfo = "";
                            if(textNum >= 0){
                                noticeContentInfo =  item.noticeContent.substr(0, textNum)+"...";;
                            }else{
                                noticeContentInfo = item.noticeContent;
                            }
                            return <li key={index} onClick={this.dialogNoticeInfo(item.noticeId)}>
                                <h4>
                                    {item.readFlg === 1 ?
                                        <strong style={{ color: '#aaa' }}>{item.noticeTitle}</strong>
                                        :
                                        <strong>{item.noticeTitle}</strong>
                                    }
                                </h4>
                                <div>
                                    <p className="p-content">
                                        {item.readFlg === 1 ?
                                            <span style={{ color: '#aaa' }}>{noticeContentInfo}</span>
                                            :
                                            <span>{noticeContentInfo}</span>
                                        }
                                        <IconButton className="li-btn">
                                            <ChevronRight />
                                        </IconButton>
                                    </p>
                                </div>
                                <p>
                                    <span className="date">{item.openTimeStr}</span>
                                </p>
                            </li>
                        })}
                    </ul>
                    {this.state.pageTotal > this.state.pageNumber ?
                        <div style={{ textAlign: 'center', padding: '10px 0px 20px', color: '#2196f3',cursor:'pointer' }} onClick={this.handleLoadMore}>{this.state.dictWord.load_more}
                        </div> : null
                    }
                </div>
                <Dialog
                    open={this.state.dialogOpen}
                    option={{
                        topClose: true
                    }}
                    title={this.state.noticesInfo.noticeTitle}
                    titleBg="#f5f5f5"
                    content={
                        <p style={{ width: 500, height: 500 ,whiteSpace: "pre-wrap",marginTop:'10px'}}>
                            {this.state.noticesInfo.noticeContent}
                        </p>
                    }
                    handleOk={this.dialogHandleClose}
                    handleClose={this.dialogHandleClose}
                />
            </div>
        )
    };
}

Notify.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notify)