import "@babel/polyfill";
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import { receiveData, setLeftMenuDispatch, getLeftMenuDispatch } from 'mdoc/action'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    AppBar,
    CssBaseline,
    Toolbar,
    Button,
    IconButton,
    Badge,
    Hidden,
    Grid,
    Tooltip
} from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { dictionary, pushList, pushIgnore, relation, getUserInfo, logoDown, designWeb, getBodybackImage } from 'mdoc/axios';
import Routes from 'mdoc/routes/index'
import classNames from 'classnames';
import logo from 'mdoc/style/imgs/logo.png';
import LocalLibraryOutlined from 'mdoc/style/imgs/library.svg';
import CollectionsOutlined from 'mdoc/style/imgs/my_collection.svg';
import SettingsOutlined from 'mdoc/style/imgs/set1.png';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state/index';

import SearchBtn from 'mdoc/components/common/SearchBtn'
import ReactMixin from 'react-mixin';
import MixFns from 'mdoc/utils/mixin.js'
import Toast from 'mdoc/utils/toast';
import $ from 'jquery';
let _userInfo = getUserInfo();
let styleSetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/styleSetting")) || {};
const styles = theme => ({
    '@global': {
        body: {
            background: styleSetting.bodyBackImage ? styleSetting.bodyBackImage : styleSetting.bodyBackColor,
            backgroundSize: "100% 100%",
        }
    },
    grow: {
        flexGrow: 1,
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 2
    },
    main: {
        height: '100%',
        width: '100%',
        display: 'flex',
        overflow: "auto"
    },
    margin: {
        marginLeft: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    button: {
        margin: theme.spacing.unit,
        textTransform: 'none'
    },
    leftIcon: {
        width: '42px',
        height: '42px',
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    logoSmall: {
        [theme.breakpoints.up('xs')]: {
            display: 'none',
        },
    },
    close: {
        padding: theme.spacing.unit / 2,
    },
    pushMsg: {
        backgroundColor: '#ffffff',
        color: '#000000'
    },
    verticalcenter: {
        display: 'flex',
        alignItems: 'center'
    },
    fab: {
        position: 'fixed',
        zIndex: 9,
        top: '15%',
        left: theme.spacing.unit / 2,
    },
    settingbtn:{
        margin:'0px',
        marginRight:'-20px',
        padding:'10px 0px'
    }
});

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dictWord: {
                'search': '',
                'my_collection': '',
                'library': '',
                'set': '',
                'clear': '',
            },
            loading: false,
            pushOpen: false,
            transitionTime: 5 * 60 * 1000,
            pushs: [],
            push: {},
            //toggle menu component
            open: null,
            unreadCount: 0,
            isMobile: false,
            styleSetting: {},
            logo: logo,
            readTime: (new Date()).getTime(),
        };
    }
    componentWillMount() {
        this.start(this.state);
        this.fetch();
        this.loadPushList();
        let me = this;
        setInterval(function () {
            me.loadPushList();
        }, 300000);
        this.setState({
            isMobile: false
        });
        let pathname = this.props.history.location.pathname;
        if (
            '/user/book/library'.indexOf(pathname) >= 0 ||
            '/user/book/searchbooklist'.indexOf(pathname) >= 0 ||
            '/user/book/mycollection'.indexOf(pathname) >= 0 ||
            '/user/book/read'.indexOf(pathname.substr(0, pathname.lastIndexOf('/'))) >= 0 ||
            '/user/book/epubread'.indexOf(pathname.substr(0, pathname.lastIndexOf('/'))) >= 0
        ) {
            var isMobile = /iPhone|iPod|iPad/i.test(navigator.userAgent);
            if (isMobile) {
                this.setState({
                    isMobile,
                });
                // let me = this;
                // window.setTimeout(function() {
                //     me.openApp();
                // },2000);
            }
        }
    }
    componentDidMount() {
        let pathname = this.props.history.location.pathname;
        this.updateTopButtonStyle(pathname);
    }
    componentWillReceiveProps() {
        let pathname = this.props.history.location.pathname;
        this.updateTopButtonStyle(pathname);
        this.setState({
            isMobile: false
        }, () => {
            if (
                '/user/book/library'.indexOf(pathname) >= 0 ||
                '/user/book/searchbooklist'.indexOf(pathname) >= 0 ||
                '/user/book/mycollection'.indexOf(pathname) >= 0 ||
                '/user/book/read'.indexOf(pathname.substr(0, pathname.lastIndexOf('/'))) >= 0 ||
                '/user/book/epubread'.indexOf(pathname.substr(0, pathname.lastIndexOf('/'))) >= 0
            ) {
                var isMobile = /iPhone|iPod|iPad/i.test(navigator.userAgent);
                if (isMobile) {
                    this.setState({
                        isMobile,
                    });
                    // let me = this;
                    // window.setTimeout(function() {
                    //     me.openApp();
                    // },2000);
                }
            }
        });
    }
    componentWillUpdate() {
        let { auth } = this.props;
        let _userInfo = getUserInfo();
        if (auth.data.userId && _userInfo.userId) {
            let styleSetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/styleSetting")) || {};
            if (styleSetting.bodyBackImage || styleSetting.bodyBackColor) {
                $('body').css('background', styleSetting.bodyBackImage ? styleSetting.bodyBackImage : styleSetting.bodyBackColor);
                $('body').css('background-size', '100% 100%');
            } else {
                $('body').css('background', '#FFF');
            }
        }
    }
    start = (state) => {
        let _userInfo = getUserInfo();
        let styleSetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/styleSetting")) || {};
        this.setState({
            styleSetting,
            logo: styleSetting.clientLogo ? styleSetting.clientLogo : logo
        }, () => {
            if (styleSetting.bodyBackImage || styleSetting.bodyBackColor) {
                $('body').css('background', styleSetting.bodyBackImage ? styleSetting.bodyBackImage : styleSetting.bodyBackColor);
                $('body').css('background-size', '100% 100%');
            } else {
                $('body').css('background', '#FFF');
            }
        });
        let dict = {};
        dictionary(state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue
            }
            this.setState({
                dictWord: dict,
            })
        });

    }

    updateTopButtonStyle(pathname) {
        let bliraryArr = [
            '/user/book/library',
            '/user/book/recently',
            '/user/book/categories',
        ];
        let mycollectionArr = [
            '/user/book/mycollection',
            '/user/book/editmycollection',
            '/user/book/addtocollection',
        ];
        let isBlirary = false;
        let isMycollection = false;
        for(let i in bliraryArr){
            if(pathname.indexOf(bliraryArr[i]) >= 0){
                isBlirary = true;
                break;
            }
        }
        for(let i in mycollectionArr){
            if(pathname.indexOf(mycollectionArr[i]) >= 0){
                isMycollection = true;
                break;
            }
        }
        if(isBlirary === true){
            $("#libraryButton").css('background','rgba(0, 0, 0, 0.08)');
            $("#mycollectionButton").css('background','');
        }else if(isMycollection === true){
            $("#libraryButton").css('background','');
            $("#mycollectionButton").css('background','rgba(0, 0, 0, 0.08)');
        }else if(isBlirary === false && isMycollection === false){
            $("#libraryButton").css('background','');
            $("#mycollectionButton").css('background','');
        }
    }

    updateStyle() {
        let _userInfo = getUserInfo();
        designWeb(_userInfo.clientId).then(res => {
            let styleSetting = {};
            if (res.hasOwnProperty("clientLogo")) {
                logoDown(res.clientLogo).then(files => {
                    styleSetting.clientLogo = files;
                    this.setState({
                        styleSetting
                    }, () => {
                        this.loadBGimg(res);
                    });
                });
            } else {
                styleSetting.clientLogo = null;
                this.setState({
                    styleSetting
                }, () => {
                    this.loadBGimg(res);
                });
            }
        });
    }
    loadBGimg(res) {
        let _userInfo = getUserInfo();
        let styleSetting = this.state.styleSetting;
        if (res.hasOwnProperty("webDesignId")) {
            styleSetting = Object.assign({
                headerMenuBackColor: res.headerMenuBackColor,
                bodyBackColor: res.bodyBackColor,
                buttonColor: res.buttonColor,
                contentBackColor: res.contentBackColor,
                personalMenuBackColor: res.personalMenuBackColor,
                bodyBackImage: res.bodyBackImage
            }, styleSetting);
            if (res.bodyBackImage) {
                getBodybackImage(res.bodyBackImage).then(files => {
                    styleSetting.bodyBackImage = "URL('" + files + "')";
                    localStorage.setItem(_userInfo.clientId + '/styleSetting', JSON.stringify(styleSetting));
                    localStorage.setItem(_userInfo.clientId + '/loadStyle', true);
                    this.props.history.push("/");
                });
            } else {
                localStorage.setItem(_userInfo.clientId + '/styleSetting', JSON.stringify(styleSetting));
                localStorage.setItem(_userInfo.clientId + '/loadStyle', true);
                this.props.history.push("/");
            }
        } else {
            localStorage.removeItem(_userInfo.clientId + '/styleSetting');
            localStorage.setItem(_userInfo.clientId + '/loadStyle', true);
            this.props.history.push("/");
        }
    }

    fetch = (params = {}) => {
        let _userInfo = getUserInfo();
        let notifySetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/" + _userInfo.userId + "/notify")) || {};
        if (notifySetting && notifySetting.receiveflg === 1) {
            params.openTime = notifySetting.time
        }
        relation(params).then(res => {
            this.setState({
                unreadCount: res.unreadCount
            })
        });
        this.setState({ loading: true })
    }


    loadPushList() {
        let me = this;
        pushList().then(res => {
            let data = res.results.docTblAlertMessage;
            if (data.length > 0) {
                let newPush = data.splice(0, 1);
                me.setState({
                    pushs: data,
                    push: newPush[0],
                    pushOpen: true
                })
            }
        })
    }

    handleExited() {
        let pushs = this.state.pushs;
        if (pushs.length > 0) {
            let push = this.state.push;
            pushIgnore({
                docTblAlertMessage: [{
                    messageId: push.messageId
                }]
            }).then(res => {
                let newPush = pushs.splice(0, 1);
                newPush = newPush.length > 0 ? newPush[0] : {};
                this.setState({
                    pushOpen: true,
                    pushs: pushs,
                    push: newPush,
                });
            }).catch(error => {

            })
        }
    };
    handlepushClear() {
        let pushs = this.state.pushs;
        let push = this.state.push;
        pushs.push(push);
        if (pushs.length > 0) {
            let docTblAlertMessage = [];
            for (let key in pushs) {
                docTblAlertMessage.push({
                    messageId: pushs[key]['messageId']
                });
            }
            pushIgnore({
                docTblAlertMessage: docTblAlertMessage
            }).then(res => {
                this.setState({
                    pushOpen: false,
                    pushs: [],
                    push: {}
                });
            }).catch(error => {

            })
        }
    };
    handleRead(push) {
        let me = this;
        if (parseInt(push.messageId) > 0) {
            let docTblAlertMessage = [];
            docTblAlertMessage.push({
                messageId: push.messageId
            });
            pushIgnore({
                docTblAlertMessage: docTblAlertMessage
            }).then(res => {
                let pushs = this.state.pushs;
                let newPush = pushs.splice(0, 1);
                newPush = newPush.length > 0 ? newPush[0] : {}
                me.setState({
                    pushOpen: false,
                    pushs: pushs,
                    push: newPush,
                }, () => {
                    let bookUrl = push.bookType === "epub" ? '/user/book/epubread/' + push.bookId : '/user/book/read/' + push.bookId;
                    me.props.history.push({
                        pathname: bookUrl,
                    });
                    window.location.reload();
                });
            }).catch(error => {
                Toast.info(error);
            })
        }
    }
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        let push = this.state.push;
        if (parseInt(push.messageId) > 0) {
            let docTblAlertMessage = [];
            docTblAlertMessage.push({
                messageId: push.messageId
            });

            pushIgnore({
                docTblAlertMessage: docTblAlertMessage
            }).then(res => {
                this.setState({
                    pushOpen: false,
                    push: {}
                });
            }).catch(error => {
                Toast.info(error);
            })
        }
    };


    setOpen = () => {
        this.props.setLeftMenuDispatch(true)
    }

    openApp() {
        let pathname = this.props.history.location.pathname;
        if ('/user/book/library'.indexOf(pathname) >= 0) {
            this.isInstall('DocumentApp://pagepath/libraryGrid');
        }
        if ('/user/book/searchbooklist'.indexOf(pathname) >= 0) {
            this.isInstall('DocumentApp://pagepath/search');
        }
        if ('/user/book/mycollection'.indexOf(pathname) >= 0) {
            this.isInstall('DocumentApp://pagepath/mycollection');
        }
        if ('/user/book/read'.indexOf(pathname.substr(0, pathname.lastIndexOf('/'))) >= 0) {
            let id = pathname.substr(pathname.lastIndexOf('/') + 1);
            this.isInstall('DocumentApp://pagepath/read/' + id);
        }
    }
    isInstall(schemeUrl) {
        if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
            window.location.href = schemeUrl;
        } else if (navigator.userAgent.match(/android/i)) {
            // Toast.info('没安装APP');
        }
    }

    render() {
        const { auth, classes } = this.props;
        const { push } = this.state;
        return (
            <div className="main-app">
                <CssBaseline />
                <AppBar position="static" style={{ background: this.state.styleSetting.headerMenuBackColor,height:'65px' }} color="default" className={classes.appBar}>
                    <Toolbar>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={3} md={1} style={{ width: '155px', height: '19px' }}>
                                <img src={this.state.logo} onClick={this.updateStyle.bind(this)} alt="logo" style={{ width: '100%', height: '100%', cursor: 'pointer' }} />
                            </Grid>
                            <Grid item xs={1} md={1}>
                                <Hidden mdUp implementation="css">
                                    <PopupState variant="popover" popupId="demo-popup-menu">
                                        {popupState => (
                                            <React.Fragment>
                                                <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" {...bindTrigger(popupState)}>
                                                    <MenuIcon color={'primary'} />
                                                </IconButton>
                                                <Menu {...bindMenu(popupState)}>
                                                    <MenuItem onClick={popupState.close}>
                                                        <Button href="/#/user/book/library" size="small" className={classes.button}>
                                                            <img src={LocalLibraryOutlined} alt="LocalLibraryOutlined" className={classNames(classes.leftIcon)} />
                                                            {this.state.dictWord.library}
                                                        </Button>
                                                    </MenuItem>
                                                    <MenuItem onClick={popupState.close}>
                                                        <Button href="/#/user/book/mycollection" size="small" className={classes.button}>
                                                            <img src={CollectionsOutlined} alt="CollectionsOutlined" className={classNames(classes.leftIcon)} />
                                                            {this.state.dictWord.my_collection}
                                                        </Button>
                                                    </MenuItem>
                                                    <MenuItem onClick={popupState.close}>
                                                        <SearchBtn history={this.props.history} />
                                                    </MenuItem>
                                                </Menu>
                                            </React.Fragment>
                                        )}
                                    </PopupState>
                                </Hidden>
                            </Grid>
                            <Grid item xs={7} md={9}>
                                <Hidden smDown implementation="css">
                                    <div className={(classes.verticalcenter)}>
                                        <Button href="/#/user/book/library" id="libraryButton" size="small" className={classes.button}>
                                            <img src={LocalLibraryOutlined} alt="LocalLibraryOutlined" className={classNames(classes.leftIcon)} />
                                            {this.state.dictWord.library}
                                        </Button>
                                        <Button href="/#/user/book/mycollection" id="mycollectionButton" size="small" className={classes.button}>
                                            <img src={CollectionsOutlined} alt="CollectionsOutlined" className={classNames(classes.leftIcon)} />
                                            {this.state.dictWord.my_collection}
                                        </Button>
                                        <SearchBtn history={this.props.history} />
                                    </div>
                                </Hidden>
                            </Grid>
                            <Grid item xs={1} md={1} style={{ textAlign: 'right' }}>
                                {
                                    MixFns.ifShowSettingBtn(this.props.location.pathname)
                                        ?
                                        <div>
                                            <Hidden xsDown>
                                                <Button href="/#user/userInfo/notify" size="small" className={classes.settingbtn}>
                                                    <Badge badgeContent={this.state.unreadCount > 0 ? this.state.unreadCount : null} color="error">
                                                        <Tooltip title={this.state.dictWord.set}>
                                                            <img src={SettingsOutlined} alt="SettingsOutlined" style={{ width: '31px', height: '30px' }} />
                                                        </Tooltip>
                                                    </Badge>
                                                </Button>
                                            </Hidden>
                                            <Hidden smUp>
                                                <Link to="/user/userInfo/notify">
                                                    <Badge badgeContent={this.state.unreadCount > 0 ? this.state.unreadCount : null} color="error" style={{ margin: '3px' }}>
                                                        <Tooltip title={this.state.dictWord.set}>
                                                            <img src={SettingsOutlined} alt="SettingsOutlined" style={{ width: '31px', height: '30px', margin: '-3px' }} />
                                                        </Tooltip>
                                                    </Badge>
                                                </Link>
                                            </Hidden>
                                        </div>
                                        : <div>
                                            <Hidden xsDown>
                                                <Button href="/#user/userInfo/notify" size="small" className={classes.settingbtn}>
                                                    <Badge badgeContent={this.state.unreadCount > 0 ? this.state.unreadCount : null} color="error">
                                                        <Tooltip title={this.state.dictWord.set}>
                                                            <img src={SettingsOutlined} alt="SettingsOutlined" style={{ width: '31px', height: '30px' }} />
                                                        </Tooltip>
                                                    </Badge>
                                                </Button>
                                            </Hidden>
                                            <Hidden smUp>
                                                <button type="button" onClick={this.setOpen}>
                                                    <Badge badgeContent={this.state.unreadCount > 0 ? this.state.unreadCount : null} color="error" style={{margin:'3px'}}>
                                                        <img src={SettingsOutlined} alt="SettingsOutlined" style={{width:'31px',height:'30px',margin:'-3px'}} />
                                                    </Badge>
                                                </button>
                                            </Hidden>
                                        </div>
                                }

                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <div className={classes.main}>
                    <Routes auth={auth} />
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.pushOpen}
                    // autoHideDuration={6000}
                    style={{right:'100px',whiteSpace: "pre-wrap"}}
                    onClose={this.handleClose.bind(this)}
                    onExited={this.handleExited.bind(this)}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                        className: classes.pushMsg
                    }}
                    message={
                        push.bookId ?
                            <span style={{ color: '#2196f3', cursor: 'pointer' }} id={'messageId' + push.messageId} onClick={this.handleRead.bind(this, push)}>{push.messageContent}</span>
                            :
                            <span id={'messageId' + push.messageId}>{push.messageContent}</span>
                    }
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleClose.bind(this)}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
                {this.state.isMobile ? <Fab color="secondary" onClick={this.openApp.bind(this)} className={classes.fab}>
                    APP
                </Fab> : null}
            </div>
        );
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
    container: PropTypes.object,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    const { auth = { data: {} }, responsive = { data: {} } } = state.reducer.httpData;
    // const { menuStatus } = state;
    return { auth, responsive }
}

const mapDispatchToProps = dispatch => ({
    receiveData: bindActionCreators(receiveData, dispatch),
    setLeftMenuDispatch: bindActionCreators(setLeftMenuDispatch, dispatch),
    getLeftMenuDispatch: bindActionCreators(getLeftMenuDispatch, dispatch),
})

ReactMixin(App.prototype, MixFns);
export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(App))