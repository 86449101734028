const MixFns = {
    //calc date
    calcLatestDate(then){ 
        let toDay = new Date();
        let createDate = new Date(then);
        let createDateToDay = createDate.getTime() - toDay.getTime();
        let createTodaytime = parseInt(createDateToDay / (1000 * 60 * 60 * 24));
        return createTodaytime<30 ? 1 : 0;
    },
    //check ulr show setting button
    ifShowSettingBtn(url){
        let arr = [
            '/user/userInfo/notify',
            '/user/userInfo/notice',
            '/user/userInfo/password',
            '/user/userInfo/lang',
            '/user/userInfo/help',
        ]
        return arr.indexOf(url)!==-1 ? false : true;
    }
}

export default MixFns;