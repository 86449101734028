/**
 *
 */
import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import AllComponents from 'mdoc/components';
import routesConfig from 'mdoc/routes/config';
import queryString from 'query-string';
import { getUserInfo } from 'mdoc/axios';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

let status = cookies.get('readStatus');
let rember = window.localStorage.getItem('readRemember');
if (!status) {
    cookies.set('readStatus', true);
}
if (!rember && !status) {
    window.localStorage.removeItem('readUser');
    window.localStorage.removeItem('readToken');
}

export default class CRouter extends Component {
    requireAuth = (component) => {
        const user = getUserInfo();
        if (!user) return <Redirect to={'/login'} />;
        if(user.initialPasswordFlg) return <Redirect to={'/passwordUp'} />;
        return component;
    };

    render() {
        return (
            <Switch>
                {routesConfig.map(r => {
                    const route = r => {
                        const Component = AllComponents[r.component];
                        return (
                            <Route
                                key={r.key}
                                exact
                                path={r.key}
                                render={props => {
                                    const reg = /\?\S*/g;
                                    // 匹配?及其以后字符串
                                    const queryParams = window.location.hash.match(reg);
                                    // 去除?的参数
                                    const { params } = props.match;
                                    Object.keys(params).forEach(key => {
                                        params[key] = params[key] && params[key].replace(reg, '');
                                    });
                                    props.match.params = { ...params };
                                    const merge = {
                                        ...props,
                                        query: queryParams ? queryString.parse(queryParams[0]) : {}
                                    };
                                    return this.requireAuth(<Component {...merge} />)
                                }}
                            />
                        )
                    }
                    return r.component ? route(r) : r.subs.map(r => route(r))
                }
                )}

                <Route render={() => <Redirect to="/404" />} />
            </Switch>
        )
    }
}