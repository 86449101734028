import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import CryptoJS from 'crypto-js';
import {
    TextField,
    Button,
    CssBaseline,
    InputAdornment,
    IconButton,
    Typography,
    Grid,
    Fade,
    CircularProgress,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import logo from 'mdoc/style/imgs/logo.png';
import { fetchDataReader, receiveData } from 'mdoc/action';
import { dictionary_before_login, designWeb, logoDown, getBodybackImage } from 'mdoc/axios';
import * as config from 'mdoc/axios/config';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import schema from 'async-validator';
import { messageFormat, getLang } from 'mdoc/utils';
import Cookies from 'universal-cookie';
import localforage from 'localforage';
import $ from 'jquery';
import 'mdoc/style/less/common.less';
import Toast from 'mdoc/utils/toast/'
const cookies = new Cookies();

const lang = getLang();
const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    rootMain:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height:'100%',
    },
    main: {
        alignItems: 'center',
        paddingTop: '5%',
    },
    logoDiv: {
        height: '166px',
        paddingTop: '10%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        width: '300'
    },
    submit: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        marginTop: theme.spacing.unit * 5,
        textTransform: 'none'
    },
    marginForgotPassword: {
        margin: `${theme.spacing.unit}px 0`,
        color: '#1976d2',
    },
    marginFooterText: {
        margin: `${theme.spacing.unit * 8}px 0`,
    },
    textField: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3
    },
    buttonProgress: {
        color: '#fff',
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
        opacity: 0.5
    },
    CheckboxRoot: {
        margin: `${theme.spacing.unit * 3}px 0px 0px 0px`
    },
    CheckboxLabel: {
        color: '#1976d2',
        fontSize:'1rem'
    }
});


class userLogin extends React.Component {
    state = {
        dictWord: {
            'user_id': '',
            'password': '',
            'login': '',
            'forgot_password': '',
            'msg_error_not_null_with_item': '',
            'group_id': '',
            'clerk_code': '',
            'sample_demo_input': '',
            'automatic_login_next_time': ''
        },
        clientCode: {
            value: '',
            error: false,
            errorMsg: ''
        },
        userCode: {
            value: '',
            error: false,
            errorMsg: ''
        },
        password: {
            value: '',
            error: false,
            errorMsg: ''
        },
        localeId: {
            value: lang,
            error: false,
            errorMsg: ''
        },
        remember: {
            value: false,
            error: false,
            errorMsg: ''
        },
        showPassword: false,
        disabled: false
    }

    componentWillMount() {
        localforage.clear();
        $('body').css('background', '#fff')
        const { history } = this.props;
        let token = window.localStorage.getItem('readToken');
        let user = JSON.parse(window.localStorage.getItem('readUser'));
        if (user && token) {
            let loadStyle = JSON.parse(window.localStorage.getItem(user.clientId + '/loadStyle'));
            let remember = window.localStorage.getItem('readRemember');
            if (loadStyle || remember) {
                localStorage.removeItem(user.clientId + '/loadStyle');
                history.push('user/book/library');
                return;
            }
        }
        window.localStorage.removeItem('readRemember');
        window.localStorage.removeItem('readUser');
        window.localStorage.removeItem('readToken');
        const { receiveData } = this.props;
        receiveData(null, 'auth');
        this.start();
    }
    componentDidUpdate(prevProps) {
        const { auth: nextAuth = {}, history } = this.props;
        if (nextAuth.data) { // 判断是否登陆
            window.localStorage.setItem('readToken', nextAuth.data.token);
            window.localStorage.setItem('localeId', nextAuth.data.langId);
            window.localStorage.setItem('readUser', JSON.stringify(nextAuth.data));
            if (this.state.remember.value) {
                window.localStorage.setItem('readRemember', this.state.remember.value);
            }
            designWeb(nextAuth.data.clientId).then(res => {
                let styleSetting = {};
                if (res.hasOwnProperty("webDesignId")) {
                    styleSetting = {
                        headerMenuBackColor: res.headerMenuBackColor,
                        bodyBackColor: res.bodyBackColor,
                        buttonColor: res.buttonColor,
                        contentBackColor: res.contentBackColor,
                        personalMenuBackColor: res.personalMenuBackColor,
                        // clientLogo: res.clientLogo,
                        bodyBackImage: res.bodyBackImage
                    };
                    if (res.hasOwnProperty("clientLogo")) {
                        logoDown(res.clientLogo).then(files => {
                            styleSetting.clientLogo = files;
                            this.setState({
                                styleSetting
                            }, () => {
                                this.loadBGimg(res, nextAuth.data);
                            });
                        });
                    } else {
                        styleSetting.clientLogo = null;
                        this.setState({
                            styleSetting
                        }, () => {
                            this.loadBGimg(res, nextAuth.data);
                        });
                    }

                } else {
                    localStorage.removeItem(nextAuth.data.clientId + '/styleSetting');
                    if (nextAuth.data.initialPasswordFlg === true) {
                        window.location.href = '#/passwordUp';
                    } else {
                        let requestUrl = cookies.get('requestUrl');
                        if (!requestUrl) {
                            history.push('user/book/library');
                        } else {
                            cookies.remove('requestUrl');
                            window.location.href = requestUrl;
                        }
                    }
                }
            });
        }
    }
    loadBGimg(res, userInfo) {
        let styleSetting = this.state.styleSetting;
        if (res.bodyBackImage) {
            getBodybackImage(res.bodyBackImage).then(files => {
                styleSetting.bodyBackImage = "URL('" + files + "')";
                localStorage.setItem(userInfo.clientId + '/styleSetting', JSON.stringify(styleSetting));
                if (userInfo.initialPasswordFlg === true) {
                    window.location.href = '#/passwordUp';
                } else {
                    let requestUrl = cookies.get('requestUrl');
                    if (!requestUrl) {
                        window.location.href = '#/user/book/library';
                    } else {
                        cookies.remove('requestUrl');
                        window.location.href = requestUrl;
                    }
                }
            });
        } else {
            localStorage.setItem(userInfo.clientId + '/styleSetting', JSON.stringify(styleSetting));
            if (userInfo.initialPasswordFlg === true) {
                window.location.href = '#/passwordUp';
            } else {
                let requestUrl = cookies.get('requestUrl');
                if (!requestUrl) {
                    window.location.href = '#/user/book/library';
                } else {
                    cookies.remove('requestUrl');
                    window.location.href = requestUrl;
                }
            }
        }
    }

    start = () => {
        let localeId = window.localStorage.getItem('localeId');
        if (localeId) {
            this.setState({
                localeId: Object.assign(this.state.localeId, { value: localeId }),
            })
        }
        let dict = {}
        dictionary_before_login({ localeId: this.state.localeId.value }).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            });
        });
    }

    handleChange(field, event) {
        let value = event.target.value;
        if (field === 'remember') {
            value = event.target.checked
        }
        this.setState({
            [field]: Object.assign(this.state[field], { value: value })
        });
    };
    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };
    keyUpSubmit = event => {
        if (event.keyCode === 13) {
            if (this.state.disabled) {
                return;
            }
            this.handleSubmit();
        }
    }
    handleSubmit = () => {
        if (this.state.disabled) {
            return;
        }
        const descriptor = {
            clientCode: [
                {
                    required: true,
                    message: messageFormat(this.state.dictWord.msg_error_not_null_with_item, this.state.dictWord.group_id)
                }
            ],
            userCode: [
                {
                    required: true,
                    message: messageFormat(this.state.dictWord.msg_error_not_null_with_item, this.state.dictWord.user_id)
                }
            ],
            password: [
                {
                    required: true,
                    message: messageFormat(this.state.dictWord.msg_error_not_null_with_item, this.state.dictWord.password)
                }
            ]
        }
        const validator = new schema(descriptor);
        const { userCode, clientCode, password } = this.state;
        let state = this.state;
        state['clientCode'].error = false;
        state['userCode'].error = false;
        state['password'].error = false;
        this.setState({
            state
        }, () => {
            validator.validate({
                clientCode: clientCode.value,
                userCode: userCode.value,
                password: password.value,
            }, {
                firstFields: true
            }, (errors, fields) => {
                if (errors) {
                    let state = this.state;
                    errors.forEach(item => {
                        state[item.field].errorMsg = item.message;
                        state[item.field].error = true;
                    });
                    this.setState({
                        state,
                        disabled: false
                    });
                } else {
                    const { fetchDataReader } = this.props;

                    let password = this.state.password.value;
                    let passwordEncode = CryptoJS.AES.encrypt(password, CryptoJS.enc.Utf8.parse(config.SECRET_KEY), {
                        mode: CryptoJS.mode.ECB,
                    });
                    passwordEncode = passwordEncode.ciphertext.toString();
                    this.setState({
                        disabled: true,
                    }, () => {
                        fetchDataReader({
                            funcName: 'readerLogin',
                            params: {
                                clientCode: clientCode.value,
                                userCode: userCode.value,
                                password: passwordEncode,
                                langId: this.state.localeId.value,
                            },
                            stateName: 'auth'
                        }).catch(error => {
                            this.setState({
                                disabled: false
                            }, () => {
                                Toast.error(error);
                            });
                        });
                    })
                }
            })
        });
    };
    render() {
        const { classes } = this.props;
        const { dictWord } = this.state;
        return (
            <React.Fragment>
                <CssBaseline />
                <div className={classes.logoDiv} align="center">
                    <img src={logo} alt="logo" className={classes.logo} />
                </div>
                <div className={classes.main}>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ width: '50%', margin: '0 auto' }} onKeyUp={this.keyUpSubmit}>
                            <TextField
                                id="clientCode"
                                label={this.state.dictWord.group_id}
                                className={classes.textField}
                                margin="normal"
                                error={this.state.clientCode.error}
                                name="clientCode"
                                fullWidth
                                onChange={this.handleChange.bind(this, 'clientCode')}
                            />
                            <TextField
                                id="userCode"
                                label={dictWord.user_id}
                                className={classes.textField}
                                margin="normal"
                                error={this.state.userCode.error}
                                name="userCode"
                                fullWidth
                                onChange={this.handleChange.bind(this, 'userCode')}
                            />
                            <TextField
                                id="password"
                                label={dictWord.password}
                                className={classes.textField}
                                type={this.state.showPassword ? 'text' : 'password'}
                                margin="normal"
                                error={this.state.password.error}
                                name="password"
                                fullWidth
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                            >
                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                }}
                                onChange={this.handleChange.bind(this, 'password')}
                            />
                            <Button type="submit" variant="contained" color="primary" disabled={this.state.disabled} className={classes.submit} fullWidth onClick={this.handleSubmit.bind(this)}>{dictWord.login}
                                <Fade
                                    in={this.state.disabled}
                                    style={{
                                        transitionDelay: this.state.pdfUpLoading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress size={24} className={classes.buttonProgress} />
                                </Fade>
                            </Button>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.remember.value}
                                        onChange={this.handleChange.bind(this, 'remember')}
                                        name="remember"
                                        color="primary"
                                    />
                                }
                                classes={{
                                    root: classes.CheckboxRoot,
                                    label: classes.CheckboxLabel
                                }}
                                label={dictWord.automatic_login_next_time}
                            />
                            <Grid container className={classes.marginForgotPassword}>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="subtitle1" align="center" component="div">
                                        <Link to="/passwordFind" className={classes.CheckboxLabel}>{dictWord.forgot_password}</Link>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.marginFooterText}>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="subtitle1" align="center" component="div">
                                        <span style={{ color: 'blue' }}>{dictWord.sample_demo_input}</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}


userLogin.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToPorps = state => {
    const { auth } = state.reducer.httpData
    return { auth }
}
const mapDispatchToProps = dispatch => ({
    fetchDataReader: bindActionCreators(fetchDataReader, dispatch),
    receiveData: bindActionCreators(receiveData, dispatch)
})
export default withStyles(styles)(connect(mapStateToPorps, mapDispatchToProps)(userLogin));