import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withMobileDialog from '@material-ui/core/withMobileDialog';
const styles = theme => ({
    title: {
        flexGrow: 1,
        padding: '0px'
    },
    noWrap: {
        overflow: 'hidden',
    }
})
class MyDialog extends React.Component {
    state = {
    };
    handleOk = event => {
        if (this.props.handleOk) {
            this.props.handleOk(event);
        }
    };

    handleClose = event => {
        if (this.props.handleClose) {
            this.props.handleClose(event);
        }
    };

    render() {
        const { fullScreen, content, title, titleDoc, titleBg, classes } = this.props;
        const { ok, close, maxWidth, topClose } = this.props.option;
        return (
            <Dialog
                maxWidth={maxWidth}
                fullScreen={fullScreen}
                open={this.props.open}
                onClose={this.handleClose}
                aria-labelledby="responsive-dialog-title"
                scroll="paper"
            >

                <Toolbar style={{ minHeight: '30px', paddingRight: '10px', fontSize: '12px', background: titleBg}}>
                    {title ?
                        <DialogTitle
                            disableTypography
                            className={classes.noWrap}
                            style={{ flexGrow: 1, maxWidth: '91%', textAlign: 'center'}}
                            id="responsive-dialog-title"
                        >
                            {title}
                        </DialogTitle> : null}
                    {titleDoc ? titleDoc : null}
                    {topClose ? <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose}>
                        <CloseIcon />
                    </IconButton> : null}
                </Toolbar>
                <DialogContent>
                    {content}
                </DialogContent>
                <DialogActions>
                    {ok ? <Button onMouseUp={this.handleOk} color="primary" disabled={this.props.okLoading}>
                        {this.props.okLoading === true ? <CircularProgress size={24} className={classes.buttonProgress} /> : ok.text ? ok.text : 'OK'}
                    </Button> : ''}
                    {close ? <Button onClick={this.handleClose} color="primary">
                        {close.text ? close.text : 'CLOSE'}
                    </Button> : ''}
                </DialogActions>
            </Dialog>
        );
    }
}

MyDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    okClickOpen: PropTypes.bool,
    option: PropTypes.object.isRequired,
    style: PropTypes.object,
    title: PropTypes.string,
    titleDoc: PropTypes.object,
    content: PropTypes.element.isRequired,
    handleOk: PropTypes.func,
    handleClose: PropTypes.func,
};

export default withStyles(styles)(withMobileDialog()(MyDialog));