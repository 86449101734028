import { get,post} from 'mdoc/axios/tools'
import * as config from 'mdoc/axios/config';

//最近列表
export const getRecentlyList = (param) => new Promise((resolve, reject)=> {
    get(config.BOOK_RECENTLY, param)
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve({results: response.docTblBookList, count: response.count, pageTotal: response.pageTotal})
            }
        }).catch(function (error) {
        reject(error);
    })
})

//所有类别
export const getCategories = (param) => new Promise((resolve, reject)=> {
    post(config.BOOK_CATEGORY, param)
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve({results: response.bookLists})
            }
        }).catch(function (error) {
        reject(error);
    })
})
export const getCategoriesOne = (param) => new Promise((resolve, reject)=> {
    get(config.BOOK_CATEGORY_ONE, param)
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve({results: response.docTblBookList, count: response.count, pageTotal: response.pageTotal})
            }
        }).catch(function (error) {
        reject(error);
    })
})
//子类列表
export const getCategoryList = (param) => new Promise((resolve, reject)=> {
    get(config.BOOK_CATEGORY_LIST, param)
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve({results: response.docMstBookCategoryList, count: response.count})
            }
        }).catch(function (error) {
        reject(error);
    })
})
//读取书的信息
export const getBookInfo = (id) => new Promise((resolve, reject)=> {
    get(config.BOOK_INFO+id, '')
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve({results: response})
            }
        }).catch(function (error) {
        reject(error);
    })
})