import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Dialog from 'mdoc/utils/myDialog';
import {dateFormat} from 'mdoc/utils';
import Button from '@material-ui/core/Button';

import { dictionary,getUserInfo } from 'mdoc/axios/index';
import {
    getBookInfo
} from 'mdoc/axios/library';
import CloseIcon from '@material-ui/icons/Close';
import Menu from 'mdoc/components/userInfo/Menu';
import 'mdoc/components/userInfo/notify.less';


const styles = theme => ({
    main: {
        width: '100%',
        display: 'flex'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    closeButton: {
        float: "right",
        // padding: '0px 12px 0px 12px',
        padding: '5px',
        marginRight: '7px',
    },
});

class Information extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dictWord: {
                'notification': '',
                'push_notification_list': '',
                'save_information_list': '',
                'notice': '',
                'lang': '',
                'help': '',
                'password': '',
                'logoff': '',
                'pen': '',
                'marker': '',
                'notes': '',
                'page': '',
                'label': '',
                'delete_it': '',
                'yes': '',
                'cancel': '',
                'return': '',
            },
            annotations: [],
            annotationInfo: {},
            dialogOpen: false,
            deleteOpen: false,
            itemId: '',
            bookId:'',
            styleSetting: {},
        }
    }

    componentWillMount() {
        this.start(this.state);
    }

    start = (state) => {
        let dict = {}
        dictionary(state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            })
        })
        let bookId = 0;
        if("undefined" != typeof this.props.location.query && "undefined" != typeof this.props.location.query.bookId){
            bookId = this.props.location.query.bookId;
        }
        let _userInfo = getUserInfo();
        let annotations = [];
        for (let i = 0; i < localStorage.length; i++) {
            let flg = '/'+_userInfo.userId;
            if(parseInt(bookId) > 0){
                flg = bookId + '/' + _userInfo.userId;
            }
            if (localStorage.key(i).indexOf(flg+"/annotations") >= 0 || localStorage.key(i).indexOf(flg+"/mark") >= 0) {
                let _annotation = JSON.parse(localStorage.getItem(localStorage.key(i))) || [];
                if (_annotation.length > 0) {
                    for (let j = 0; j < _annotation.length; j++) {
                        switch (_annotation[j].type) {
                            case "note":
                                _annotation[j].typeDic = 'notes';
                                _annotation[j].backgroundColor = '#999';
                                break;
                            case "highlight":
                                _annotation[j].typeDic = 'marker';
                                _annotation[j].backgroundColor = '#ffa500';
                                break;
                            case "mark":
                                _annotation[j].typeDic = 'label';
                                _annotation[j].backgroundColor = '#1e88e5';
                                break;
                            default:
                                _annotation[j].typeDic = 'pen';
                                _annotation[j].backgroundColor = '#e53935';
                                break;
                        }
                        annotations.push(_annotation[j]);
                    }
                }
            }
        }
        annotations.sort(function (obj1, obj2) {
            return obj2.time - obj1.time;
        });
        let styleSetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/styleSetting")) || {};
        this.setState({
            annotations: annotations,
            bookId: bookId,
            styleSetting
        })
    }

    handleReturn = event => {
        getBookInfo(this.state.bookId).then((data) => {
            let pathname = '/user/book/read/' + this.state.bookId;
            if(data.results.docType === "epub"){
                pathname = '/user/book/epubread/' + this.state.bookId;
            }
            this.props.history.push(pathname);
        })
    }

    handleDelete = () => event => {
        let _userInfo = getUserInfo();
        let itemId = this.state.openkey;
        let annotation = this.state.annotations[itemId];
        if (annotation.type === 'mark') {
            let _marks = JSON.parse(localStorage.getItem(annotation.bookid +'/'+_userInfo.userId+ '/mark')) || [];
            for (let i in _marks) {
                if (_marks[i].page === annotation.page) {
                    _marks.splice(i, 1);
                    break;
                }
            }
            localStorage.setItem(annotation.bookid +'/'+_userInfo.userId+ '/mark', JSON.stringify(_marks));
        } else {
            let _annotations = JSON.parse(localStorage.getItem(annotation.bookid +'/'+_userInfo.userId+ '/annotations')) || [];
            for (let i in _annotations) {
                if (_annotations[i].uuid === annotation.uuid) {
                    _annotations.splice(i, 1);
                    break;
                }
            }
            localStorage.setItem(annotation.bookid +'/'+_userInfo.userId+ '/annotations', JSON.stringify(_annotations));
        }
        let annotations = this.state.annotations;
        annotations.splice(itemId, 1);
        this.setState({
            annotations: annotations,
            deleteOpen: false
        });
    }
    handleInfo = (itemId) => event => {
        let annotation = this.state.annotations[itemId];
        if (annotation.type === 'note') {
            annotation.noteTextInfo = annotation.noteText.replace(/\n/g, "<br/>");
            this.setState({
                annotationInfo: annotation,
                dialogOpen: true,
            });
        } else {
            getBookInfo(annotation.bookid).then((data) => {
                let pathname = '/user/book/read/' + annotation.bookid;
                if(data.results.docType === "epub"){
                    pathname = '/user/book/epubread/' + annotation.bookid;
                }
                this.props.history.push({
                    pathname: pathname,
                    query: {
                        page: annotation.page
                    }
                })
            })
            
        }
    }
    dialogHandleClose = () => {
        this.setState({
            dialogOpen: false
        });
    }
    handleDeleteOpen = (index) => event => {
        event.stopPropagation();
        this.setState({
            deleteOpen: true,
            openkey: index
        });
    }
    handleDeleteClose = () => {
        this.setState({
            deleteOpen: false
        });
    }
    render() {
        const { classes } = this.props;
        const { dictWord, annotations } = this.state;
        return (
            <div className={classes.main}>
                <div className={classes.appBarSpacer} />
                <Menu dictWord={dictWord} selectedkey="information" />
                <div className={classes.content + ' right-con information'}>
                    <div style={{paddingBottom:'20px'}}>
                    <h3 style={{float:'left'}}>{dictWord.save_information_list}</h3>
                    {this.state.bookId?<Button onClick={this.handleReturn} style={{float:'right',marginRight:'50px',background: this.state.styleSetting.buttonColor}} variant="contained" color="primary">{dictWord.return}</Button>:''}
                    </div>
                    
                    <ul className="information-ul">
                        {annotations.map((item, index) => {
                            let noteTextInfo = "";
                            if(item.hasOwnProperty('noteText') === true){
                                let textNum = item.noteText.indexOf('\n');
                                if(textNum >= 0){
                                    noteTextInfo =  item.noteText.substr(0, textNum)+"...";;
                                }else{
                                    noteTextInfo = item.noteText;
                                }
                            }
                            
                            return <li key={index} onClick={this.handleInfo(index)} className={classes.rightLi}>
                                <h4>
                                    <strong>{item.title}</strong>
                                    <span className="type-dict" style={{backgroundColor:item.backgroundColor}}> {dictWord[item.typeDic]}  </span>
                                    <span className="dict-page"> {item.page}{dictWord.page} </span>
                                    <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleDeleteOpen(index)}>
                                        <CloseIcon />
                                    </IconButton>
                                </h4>
                                <div>
                                    <p className="width100 p-content" title= {item.title}>
                                       <span>{noteTextInfo}</span>
                                        <IconButton className={classes.closeButton}>
                                            <ChevronRight />
                                        </IconButton>
                                    </p>
                                </div>
                                <p>
                                    <span className="date">{dateFormat(new Date(item.time),'yyyy/MM/dd hh:mm:ss')}</span>
                                </p>
                            </li>
                        })}
                    </ul>
                </div>

                <Dialog
                    open={this.state.dialogOpen}
                    option={{
                        topClose: true
                    }}
                    title={this.state.annotationInfo.title}
                    titleBg="#f5f5f5"
                    content={
                        <div style={{ width: 500, height: 500,whiteSpace: "pre-wrap",marginTop:'10px'}} dangerouslySetInnerHTML={{ __html: this.state.annotationInfo.noteTextInfo }} />
                    }
                    handleOk={this.dialogHandleClose}
                    handleClose={this.dialogHandleClose}
                />
                <Dialog
                    open={this.state.deleteOpen}
                    option={{
                        ok: {
                            text: this.state.dictWord['yes'],
                        },
                        close: {
                            text: this.state.dictWord['cancel'],
                        },
                    }}
                    content={
                        <p>{this.state.dictWord['delete_it']}</p>
                    }
                    handleOk={this.handleDelete()}
                    handleClose={this.handleDeleteClose}
                />
            </div>
        )
    };
}

Information.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Information);