import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { dictionary,getUserInfo } from 'mdoc/axios/index'
import Menu from 'mdoc/components/userInfo/Menu';
import Switch from '@material-ui/core/Switch';
import 'mdoc/components/userInfo/notify.less';

const styles = theme => ({
    main: {
        width: '100%',
        display: 'flex'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
});

class Notice extends React.Component {
    state = {
        dictWord: {
            'notification': '',
            'save_information_list': '',
            'notice': '',
            'lang': '',
            'help': '',
            'password': '',
            'logoff': '',
            'comment': '',
            'push_notification_list': '',
            checkedB: true,
        },
        commentFlg: false,
        receiveFlg: true,
        styleSetting: {},
    }

    componentWillMount() {
        this.start(this.state);
    }
    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };


    start = (state) => {
        let dict = {}
        dictionary(state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            })
        })
        let _userInfo = getUserInfo();
        let commentFlg = localStorage.getItem(_userInfo.clientId +"/"+_userInfo.userId + "/commentFlg");
        this.setState({
            commentFlg: parseInt(commentFlg) === 1 ? false : true,
            // styleSetting: styleSetting
        });
        let notify = JSON.parse(window.localStorage.getItem(_userInfo.clientId +"/"+_userInfo.userId +"/notify")) || {};
        this.setState({
            receiveFlg: parseInt(notify.receiveflg) === 1 ? false : true
        })
        
    }

    handleAlertsetting = () => {
        let receive = {
            receiveflg: this.state.receiveFlg === true ? 1 : 0,
            time: (new Date()).getTime()
        };
        let _userInfo = getUserInfo();
        localStorage.setItem(_userInfo.clientId +"/"+_userInfo.userId +'/notify', JSON.stringify(receive));
        this.setState({ receiveFlg: this.state.receiveFlg === true ? false : true });
    }
    handleCommentSetting = () => {
        let commentFlg = this.state.commentFlg === true ? false : true;
        let _userInfo = getUserInfo();
        localStorage.setItem(_userInfo.clientId +"/"+_userInfo.userId +'/commentFlg', this.state.commentFlg === true ? 1 : 0);
        this.setState({
            commentFlg: commentFlg
        })
    }

    render() {
        const { classes } = this.props;
        const { dictWord } = this.state;
        return (
            <div className={classes.main}>
                <div className={classes.appBarSpacer} />
                <Menu dictWord={dictWord} selectedkey="notice" />
                <div className={classes.content + ' right-con notice-wrapper'}>
                    <h3>{dictWord.notice}</h3>
                    <ul>
                        <li>
                            <span className="notice-title">{dictWord.comment}</span>
                            <Switch
                                checked={this.state.commentFlg}
                                onChange={this.handleCommentSetting}
                                value="commentFlg"
                                color="primary"
                            />
                        </li>
                        <li>
                            <span className="notice-title">{dictWord.notification}</span>
                            <Switch
                                checked={this.state.receiveFlg}
                                onChange={this.handleAlertsetting}
                                value="receiveFlg"
                                color="primary"
                            />
                        </li>
                    </ul>
                </div>
            </div>
        )
    };
}

Notice.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notice);