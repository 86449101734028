import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'mdoc/serviceWorker';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import {combineReducers, createStore, applyMiddleware } from 'redux';
import {reducer} from 'mdoc/reducer';
import { AppContainer } from 'react-hot-loader';
import Page from 'mdoc/Page';

import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import themeConfig from 'mdoc/Theme';

import { composeWithDevTools } from 'redux-devtools-extension';
const theme = createMuiTheme(themeConfig);

// redux 注入操作
const middleware = [thunk,logger];
const rootReducer = combineReducers({
    reducer
  });
const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(...middleware),
));
ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <AppContainer>
            <Provider store={store}>
                <Page store={store} />
            </Provider>
        </AppContainer>
    </MuiThemeProvider>
 ,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();