import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Button,
    IconButton,
    Typography,
    Tooltip,
    InputBase,
    Divider,
    MenuItem,
    MenuList,
    ListItemIcon,
    Grow,
    Popper,
    Paper,
    DialogTitle,
    DialogContent,
    DialogActions,
    Hidden,
    Dialog,
    Radio,
    SvgIcon,
    Fade,
    CircularProgress,
    Fab,
    InputAdornment
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Slider from '@material-ui/lab/Slider';
import $ from 'jquery';

import Toast from 'mdoc/utils/toast';
import {
    getUserInfo, dictionary, getBookFilePath, bodySearch,
    bookEvaluate, getBookEvaluate, commentList, commentAdd, effectiveLog,
    checkupdate, bodyPreview, getBookPreviewImg, getMp4Path, currentTime
} from 'mdoc/axios/index'
import {
    getBookInfo,
} from 'mdoc/axios/library';
import { Document, Page, pdfjs } from 'react-pdf';
import 'mdoc/components/book/Read.less';
import { blue, green, purple, red, orange, yellow, grey } from '@material-ui/core/colors';
import { Bookmark, FindInPage, StarBorder, Menu as MenuIcon, ChevronRight, ChevronLeft, Description } from '@material-ui/icons';
import SearchOutlined from 'mdoc/style/imgs/search@1.svg';
import pen from 'mdoc/style/imgs/pen.svg';
import pen_curve from 'mdoc/style/imgs/pen_curve.svg';
import pen_straight from 'mdoc/style/imgs/pen_straight.svg';
import pen_marker from 'mdoc/style/imgs/marker.svg';
import pen_light from 'mdoc/style/imgs/pen_light.svg';
import label from 'mdoc/style/imgs/label.svg';
import label_light from 'mdoc/style/imgs/label_light.svg';
import notes from 'mdoc/style/imgs/notes.svg';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import MoreVert from '@material-ui/icons/MoreVert';
import Toolbar from '@material-ui/core/Toolbar';
import ChatBox from 'mdoc/components/book/ChatBox';
import Star from 'mdoc/utils/Star';
import { getBrowserInfo, dateFormat, replacepos, heightLight, escapeHtml, searchMatching } from 'mdoc/utils';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import localforage from 'localforage';
import { connect } from 'react-redux';
import { bookSearch, getCoverPath } from 'mdoc/axios';
import { getBookListStart, getBookListSuccess } from 'mdoc/action/index'
import comment from 'mdoc/style/imgs/comment.svg'
import large from 'mdoc/style/imgs/large.svg';
import reduction from 'mdoc/style/imgs/reduction.svg'
import star from 'mdoc/style/imgs/star.svg';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



const styles = theme => ({
    mar5: {
        margin: '5px'
    },
    fabUndo: {
        position: 'fixed',
        bottom: theme.spacing.unit * 45,
        right: theme.spacing.unit * 2,
        width: '36px',
        height: '36px'
    },
    fabDelete: {
        position: 'fixed',
        bottom: theme.spacing.unit * 38,
        right: theme.spacing.unit * 2,
        width: '36px',
        height: '36px'
    },
    fabSelect: {
        position: 'fixed',
        bottom: theme.spacing.unit * 31,
        right: theme.spacing.unit * 2,
        width: '36px',
        height: '36px'
    },
    fabTilde: {
        position: 'fixed',
        bottom: theme.spacing.unit * 24,
        right: theme.spacing.unit * 2,
        width: '36px',
        height: '36px'
    },
    fabVector: {
        position: 'fixed',
        bottom: theme.spacing.unit * 17,
        right: theme.spacing.unit * 2,
        width: '36px',
        height: '36px'
    },
    fabNoteText: {
        position: 'fixed',
        bottom: theme.spacing.unit * 10,
        right: theme.spacing.unit * 2,
        width: '36px',
        height: '36px'
    },
    fabSlider: {
        position: 'fixed',
        bottom: theme.spacing.unit * 17,
        right: theme.spacing.unit * 10,
    },
    bluecolor: {
        color: blue[600],
        '&$checked': {
            color: blue[500],
        },
    },
    greencolor: {
        color: green[600],
        '&$checked': {
            color: green[500],
        },
    },
    purplecolor: {
        color: purple[600],
        '&$checked': {
            color: purple[500],
        },
    },
    redcolor: {
        color: red[600],
        '&$checked': {
            color: red[500],
        },
    },
    orangecolor: {
        color: orange[600],
        '&$checked': {
            color: orange[500],
        },
    },
    yellowcolor: {
        color: yellow[600],
        '&$checked': {
            color: yellow[500],
        },
    },
    greycolor: {
        color: grey[50],
        '&$checked': {
            color: grey[50],
        },
    },
    checked: {},
    bookAnnotationLayer: {
        position: 'absolute',
        bottom: 0
    },
    pdfBookContent: {
        margin: '0 auto',
    },
    BookContent: {
        margin: '0 auto',
        top: '50%',
        left: '50%',
    },
    disableUserSelect: {
        webkitUserSelect: 'none',
        mozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
    },
    pd20: {
        padding: '20px'
    },
    slider: {
        padding: '22px 0px',
        overflow: 'hidden',
    },
    fontSlider: {
        padding: '22px 0px',
        overflow: 'hidden',
        minWidth: '300px'
    },
    penSlider: {
        padding: '0px 0px',
        overflow: 'hidden',
        // minWidth: '200px'
    },
    searchBooks: {
        margin: '20px 0',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: 8,
        flex: 1,
    },
    searchDivider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    paper: {
        right: theme.spacing.unit * 1,
    },
    bootstrapSearch: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '7px 6px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    }
});
const TildeIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path fill="#000000" d="M2,15C2,15 2,9 8,9C12,9 12.5,12.5 15.5,12.5C19.5,12.5 19.5,9 19.5,9H22C22,9 22,15 16,15C12,15 10.5,11.5 8.5,11.5C4.5,11.5 4.5,15 4.5,15H2" />
        </SvgIcon>
    );
}
const Message = (props) => {
    return (
        <img src={comment} alt={comment} {...props} />
    );
}
const VectorLineIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path fill="#000000" d="M15,3V7.59L7.59,15H3V21H9V16.42L16.42,9H21V3M17,5H19V7H17M5,17H7V19H5" />
        </SvgIcon>
    );
}
const SelectIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path fill="#000000" d="M14,10H19.5L14,4.5V10M5,3H15L21,9V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3M5,12V14H19V12H5M5,16V18H14V16H5Z" />
        </SvgIcon>
    );
}
const NoteTextIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path fill="#000000" d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z" />
        </SvgIcon>
    );
}
const UndoIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path fill="#000000" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
        </SvgIcon>
    );
}
const DeleteIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path fill="#000000" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M9,8H11V17H9V8M13,8H15V17H13V8Z" />
        </SvgIcon>
    );
}
const PrevPageIcon = (props) => {
    return (
        <SvgIcon viewBox="-5 -5 30 30">
            <path fill="#aaa" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
        </SvgIcon>
    );
}
const NextPageIcon = (props) => {
    return (
        <SvgIcon viewBox="-5 -5 30 30">
            <path fill="#aaa" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
        </SvgIcon>
    );
}
const ReduceIcon = (props) => {
    return (
        <img src={reduction} alt={reduction} {...props} />
    );
}
const EnlargeIcon = (props) => {
    return (
        <img src={large} alt={large} {...props} />
    );
}

const SearchDialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const ReviewDialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
        padding: 0,
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

let Paint = {};
class Read extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dictWord: {
                'directory': '',
                'search': '',
                'printing': '',
                'save_information_list': '',
                'font': '',
                'evaluation': '',
                'msg_evaluation_completed': '',
                'notes': '',
                'notes_list': '',
                'registration': '',
                'search_word_enter': '',
                'msg_error_record_not_found': '',
                'comment': '',
                'msg_error_not_null_with_item': '',
                'not_save_your_change': '',
                'cancel': '',
                'yes': '',
                'show_history': '',
                'pen': '',
                'marker': '',
                'label': '',
                'original_return': '',
                'delete_record': '',
                'curve': '',
                'straight_line': '',
                'evaluation_record': '',
                'msg_read_confirm': '',
                'page': '',
                'print_range': '',
                'msg_error_max_print_page': '',
                'load_more': '',
                'expansion': '',
                'reduction': '',
                'return': '',
                'delete_it': '',
                'notes_detail': '',
                'unsaved_line': '',
                'new_notes_insert': ''
            },
            loading: false,
            bookId: 0,
            bookInfo: {},
            bookFile: {
                page1: {
                    file: '',
                    number: 1,
                    loading: true,
                },
                page2: {
                    file: '',
                    number: 2,
                    loading: true,
                }
            },
            docType: '',
            bookPage: 1,
            totalPage: 0,
            editType: '',
            scale: 1,
            pdfWidth: 500,
            editSetting: {
                penColor: '#2196f3',
                penSize: 2,
                highlightColor: '#2196f3',
                overlay: void 0,
            },
            // Paint: {},
            sliderProgress: 50,
            anchorEl: null,
            fontsizeStatus: false,
            fontsizeProgress: 12,
            colorStatus: 'a',
            editStatus: false,
            searchStatus: false,
            printStatus: false,
            noteStatus: false,
            noteListStatus: false, //メモ
            annotations: [],
            toolOpen: {},
            formData: {
                searchText: {
                    value: '',
                    error: false,
                    errorMsg: '',
                    focus: true
                },
                noteText: {
                    value: '',
                    error: false,
                    errorMsg: ''
                },
                startPage: {
                    value: 1,
                    error: false,
                    errorMsg: ''
                },
                endPage: {
                    value: 1,
                    error: false,
                    errorMsg: ''
                },
            },
            searchResult: [],
            parsedWords: [],
            comments: [],
            commentMessage: '',
            chatboxOpen: false,
            messageObj: {},
            starReviewStatus: false,
            score: 0,
            evaluateContent: {},
            isMark: false,
            commentFlg: 0,
            fontMsgOpen: false,
            horizontalPageCount: 1,
            flip: 0,
            isPrint: false,
            printFlg: false,
            printMsgOpen: false,
            printMax: 0,
            printNum: 0,
            printMsgErr: false,
            pdfAllFiles: [],
            // seconds: 1,
            logId: 0,
            start: true,
            readTime: (new Date()).getTime(),
            statrtReadTime: (new Date()).getTime(),
            commentPage: 1,
            commentSize: 20,
            pageLoading: true,
            downloadWay: 0,
            readMsg: false,
            readPage: 1,
            videoData: '',
            styleSetting: {},
            oldList: [],
            searchText: [],
            totlePage: 1,
            contentPageNumber: 1,
            contentPageSize: 10,
            // bookId: []
            confirmOpen: false,
            noteInfo: null,
            optionOpen: false
        }

    }

    componentWillMount() {
        // let reactThis = this;
        // this.interval = setInterval(function () {
        //     if (reactThis.state.isPrint === true) return;
        //     if (reactThis.state.seconds === 30) {
        //         reactThis.saveLog();
        //     } else {
        //         if (!document.hidden) {
        //             let seconds = reactThis.state.seconds;
        //             reactThis.setState({
        //                 start: true,
        //                 seconds: seconds + 1
        //             });
        //         } else {
        //             if (!reactThis.state.start) {
        //                 return;
        //             }
        //             reactThis.saveLog();
        //             reactThis.setState({ start: false });
        //         }
        //     }
        // }, 1000);
    }
    componentDidMount() {
        this.start(this.state);
        // this.saveLog('start');
    }
    componentWillUnmount() {
        // clearInterval(this.interval);
        this.saveLog('end');
    }

    saveLog = (action) => {
        if (typeof (this.state.bookInfo.id) === "undefined") return;
        let match = this.props.match.params;
        let seconds = 0;
        let _userInfo = getUserInfo();
        let logData;
        if (action === 'end') {
            seconds = (new Date()).getTime() - this.state.statrtReadTime;
            seconds = parseInt(seconds / 1000);
            logData = {
                "docTblBookEffectiveLogList": [{
                    "id": this.state.logId,
                    "bookId": match.bookId,
                    "userId": _userInfo.userId,
                    "ip": getBrowserInfo()[2],
                    "readingTime": seconds,
                    "readStartDate": dateFormat(new Date(this.state.statrtReadTime), 'yyyy-MM-ddThh:mm:ss'),
                    "readStartTimeStamp": this.state.statrtReadTime,
                    "browser": "",
                    "clientType": 0
                }]
            };
            effectiveLog(logData);
        } else {
            logData = {
                "docTblBookEffectiveLogList": [{
                    "bookId": match.bookId,
                    "userId": _userInfo.userId,
                    "ip": getBrowserInfo()[2],
                    "readingTime": 0,
                    "readStartDate": dateFormat(new Date(this.state.statrtReadTime), 'yyyy-MM-ddThh:mm:ss'),
                    "readStartTimeStamp": this.state.statrtReadTime,
                    "browser": "",
                    "clientType": 0
                }]
            };
            effectiveLog(logData).then(res => {
                this.setState({ logId: res.id });
            });
        }
    }

    handleClick = () => event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    start = (state) => {
        let match = this.props.match.params;
        let page = this.state.bookPage;
        let userReadPage = this.getReadPage(match.bookId);
        let readMsg = parseInt(userReadPage.page) > 1 ? true : false;
        let _userInfo = getUserInfo();
        let commentFlg = localStorage.getItem(_userInfo.clientId + "/" + _userInfo.userId + '/commentFlg') ? localStorage.getItem(_userInfo.clientId + "/" + _userInfo.userId + '/commentFlg') : 0;
        let searchText = "";
        let parsedWords = [];
        if (this.props.location.query !== undefined) {
            page = this.props.location.query.page ? this.props.location.query.page : page;
            readMsg = this.props.location.query.page ? false : readMsg;
            searchText = this.props.location.query.search ? this.props.location.query.search : "";
            parsedWords = this.props.location.query.parsedWords ? this.props.location.query.parsedWords : [];
        }

        let styleSetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/styleSetting")) || {};
        this.setState({
            bookId: match.bookId,
            bookPage: page,
            // readTime: (new Date()).getTime(),
            loading: true,
            commentFlg: commentFlg,
            readPage: userReadPage.page,
            readMsg: readMsg,
            styleSetting: styleSetting,
            parsedWords: parsedWords,
            formData: Object.assign(this.state.formData, { searchText: { value: searchText } })
        })

        let dict = {};
        dictionary(state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            })
        })

        currentTime().then(res => {
            this.setState({
                readTime: res
            }, () => {
                let lastTime = this.getReadTime(match.bookId);
                checkupdate({
                    bookId: match.bookId,
                    checkDate: lastTime,
                }).then((res) => {
                    this.getBook(match.bookId, lastTime);
                }).catch(error => {
                    if (error.errorCode === "E201") {
                        Toast.error(error.errorMessage);
                        this.emptyAnnotations();
                        this.props.history.push('/user/book/library');
                    } else if (error.errorCode === "E401") {
                        Toast.error(error.errorMessage, 2000, () => {
                            this.emptyAnnotations();
                            this.setReadTime(match.bookId);
                            window.location.reload();
                        });
                    }
                });
            });
        })
    }

    //load bookinfo
    getBook = (bookId, userReadPageTime) => {
        getBookInfo(bookId).then((data) => {
            let bookInfo = data.results;
            if (parseInt(userReadPageTime) < (new Date(bookInfo.updateDate)).getTime()) {
                this.emptyAnnotations();
                this.setState({
                    bookPage: 1
                });
            }
            if (bookInfo.docType === 'mp4') bookInfo.printFlg = false;
            this.setState({
                bookInfo: bookInfo,
                docType: bookInfo.docType,
                horizontalPageCount: bookInfo.docTblBookDetail.horizontalPageCount,
                flip: bookInfo.docTblBookDetail.flip,
                downloadWay: bookInfo.docTblBookDetail.downloadWay,
                printFlg: bookInfo.printFlg,
                printMax: bookInfo.printMax,
                formData: Object.assign(this.state.formData, { endPage: { value: bookInfo.printMax } }),
                totalPage: bookInfo.docType === 'mp4' ? 0 : bookInfo.docTblBookBodyList.length,
            });
            this.saveLog('start');
            if (bookInfo.docType !== 'mp4') {
                let me = this;
                localforage.getItem('searchTextCache').then(function (value) {
                    if (value) {
                        me.handleSearchText(value);
                    }
                })
            }
            if ($("#bookStyle").length > 0) {
                $("#bookStyle").html('');
            }

            if (bookInfo.docType === 'pdf') {
                if (bookInfo.docTblBookBodyList.length < this.state.bookPage) this.setState({ bookPage: 1 });
                let bookWidth = $("#book").width();
                let pageWidth;
                if (this.state.horizontalPageCount === 0) {
                    pageWidth = parseInt(bookWidth) / 2.1;
                } else {
                    pageWidth = parseInt(bookWidth) / 1.5;
                }
                this.setState({
                    pdfWidth: pageWidth,
                    pageLoading: this.state.horizontalPageCount === 0 ? false : true,
                })
                this.loadBookHtml(this.state.bookPage);
            } else if (bookInfo.docType === 'mp4') {
                $('#video').bind('contextmenu', function () { return false; });
                let _userInfo = getUserInfo();
                let play = JSON.parse(window.localStorage.getItem(_userInfo.userId + "/play")) || [];
                let playTime = 0;
                let videoReadTime = 0;
                for (let i in play) {
                    if (play[i].videoId === this.state.bookId) {
                        playTime = play[i].currentTime;
                        videoReadTime = play[i].time;
                        break;
                    }
                }
                if (videoReadTime < (new Date(bookInfo.updateDate)).getTime()) {
                    playTime = 0;
                }
                let playMsg = playTime > 0 ? true : false;
                let videoPath = getMp4Path(bookInfo.id, bookInfo.clientId, bookInfo.categoryId);

                this.setState({
                    videoData: videoPath,
                    pageLoading: false,
                    readPage: playTime,
                    readMsg: playMsg,
                });
            }
        }).catch(error => {
            Toast.error(error);
            this.props.history.push('/user/book/library');
        });
    }
    handleSearchText = (value) => {
        if (value != "") {
            let searchText = value;
            this.setState({
                formData: Object.assign(this.state.formData, { searchText: { value: searchText } })
            })
            if (this.state.formData.searchText.value != "") {
                this.handleSearch();
            }
        }
    }

    onKeyPress(event) {
        let enter = event.keyCode;
        if (enter === 13) {
            this.handleSearch();
        }
    }

    handleChange = (event, value) => {
        if (parseInt(value) === parseInt(this.state.bookPage)) return;
        this.setState({
            bookPage: parseInt(value)
        });
        // this.handleTurningPage(parseInt(value));
    };
    handleChangeCommitted = (event) => {
        let reactThis = this;
        setTimeout(function () {
            reactThis.handleTurningPage(reactThis.state.bookPage);
        }, 500);
    };


    handleFontMsgOpen = () => {
        this.setState({
            fontMsgOpen: true
        });
    }
    
    handleChangeColor = (value) => event => {
        let editSetting = this.state.editSetting;
        editSetting.highlightColor = value;
        editSetting.penColor = value;
        this.setState({
            colorStatus: event.target.value,
            editSetting: editSetting
        });
    };
    handleEditOpen = () => {
        this.setState({
            editStatus: true
        })
    }
    handleEditClose = () => {
        this.setState({
            editStatus: false
        })
    }
    toggleEdit = () => {
        let editType = '';
        if (this.state.editStatus === false) editType = 'highlight';
        this.enableUserSelect();
        this.setState({
            editStatus: !this.state.editStatus,
            editType: editType,
            optionOpen: false
        })
    }
    getXml(string) {
        let xmlDoc = $("<html></html>");
        xmlDoc.append(string);
        return xmlDoc;
    }
    getXhtml(string) {
        //remove bom
        string = string.replace(
            String.fromCharCode('65279'), ''
        );
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(string, "text/xml");
        return $(xmlDoc);
    }
    disableUserSelect() {
        $('body').css({
            '-webkit-user-select': 'none',
            '-moz-user-select': 'none',
            '-ms-user-select': 'none',
            'user-select': 'none',
        });
        $('body a').css({
            'cursor': 'default',
        });
    }
    enableUserSelect() {
        $('body').css({
            '-webkit-user-select': 'text',
            '-moz-user-select': 'text',
            '-ms-user-select': 'text',
            'user-select': 'text',
        });
        $('body a').css({
            'cursor': 'auto',
        });
    }
    saveReadPage(page) {
        let _userInfo = getUserInfo();
        currentTime().then(res => {
            let time = res;//(new Date()).getTime();
            let read = JSON.parse(window.localStorage.getItem(_userInfo.userId + "/read")) || [];
            if (read) {
                let is_existence = 0;
                for (let i in read) {
                    if (read[i].bookid === this.state.bookId && read[i].page !== page) {
                        read[i].page = page;
                        read[i].time = time;
                        is_existence = 1;
                        break;
                    }
                    if (read[i].bookid === this.state.bookId && read[i].page === page) {
                        is_existence = 2;
                        break;
                    }
                }
                if (is_existence === 2) {
                    return;
                } else if (is_existence === 0) {
                    read.push({
                        bookid: this.state.bookId,
                        page: page,
                        time: time,
                    })
                }
                localStorage.setItem(_userInfo.userId + '/read', JSON.stringify(read));
                return;
            } else {
                read.push({
                    bookid: this.state.bookId,
                    page: page,
                    time: time,
                })
                localStorage.setItem(_userInfo.userId + '/read', JSON.stringify(read));
                return;
            }
        })
    }
    getReadPage(bookId) {
        let _userInfo = getUserInfo();
        let read = JSON.parse(window.localStorage.getItem(_userInfo.userId + "/read")) || [];
        let pages = 0;
        if (read) {
            for (let i in read) {
                if (read[i].bookid === bookId) {
                    pages = read[i];
                    break;
                }
            }
        }
        return pages;
    }
    getReadTime(bookId) {
        let _userInfo = getUserInfo();
        let read = JSON.parse(window.localStorage.getItem(_userInfo.userId + "/read")) || [];
        let readTime = this.state.readTime;
        if (read) {
            for (let i in read) {
                if (read[i].bookid === bookId) {
                    readTime = read[i].time;
                    break;
                }
            }
        }
        return readTime;
    }
    setReadTime(bookId) {
        let _userInfo = getUserInfo();
        let read = JSON.parse(window.localStorage.getItem(_userInfo.userId + "/read")) || [];
        let readTime = this.state.readTime;
        if (read) {
            for (let i in read) {
                if (read[i].bookid === bookId) {
                    read[i].time = readTime;
                    read[i].page = 1;
                    break;
                }
            }
        }
        window.localStorage.setItem(_userInfo.userId + "/read", JSON.stringify(read));
    }
    setReadSetting(setting) {
        if (!setting) return;
        let _userInfo = getUserInfo();
        let settings = JSON.parse(window.localStorage.getItem(_userInfo.userId + "/setting")) || {};
        settings[setting.key] = {
            value: setting.value
        };
        localStorage.setItem(_userInfo.userId + '/setting', JSON.stringify(settings));
    }
    getReadSetting() {
        return JSON.parse(window.localStorage.getItem(getUserInfo().userId + "/setting")) || {};
    }
    loadBookHtml = (page, isPrint = false) => {
        currentTime().then(res => {
            this.setState({
                readTime: res
            }, () => {
                checkupdate({
                    bookId: this.state.bookId,
                    checkDate: this.getReadTime(this.state.bookId),
                }).then(res => {
                    if (isNaN(page)) return;
                    page = parseInt(page) - 1;
                    if (page < 0) return;
                    if (this.state.docType === 'pdf') {
                        $(".page_horizontal").css('transform', 'scale(1)');
                        $(".page_single").css('transform', 'scale(1)');
                        this.setState({scale:1});
                        let bookInfo = this.state.bookInfo;
                        let docTblBookBodyList = bookInfo.docTblBookBodyList;
                        if (this.state.horizontalPageCount === 0 && docTblBookBodyList.length > 1 && isPrint === false) {
                            $(".react-pdf__Document svg").remove();
                            $(".react-pdf__Document canvas").remove();
                            let page1 = 0;
                            let page2 = 0;
                            let thisPage = this.state.bookPage;
                            if (this.state.flip === 0) {
                                if (parseInt(page) % 2 !== 0) {
                                    page1 = parseInt(page) - 1;
                                    page2 = page;
                                } else {
                                    page1 = page;
                                    page2 = (parseInt(page) + 1) >= docTblBookBodyList.length ? -1 : parseInt(page) + 1;
                                }
                                thisPage = page2 > 0 ? page2 + 1 : page1 + 1;
                            } else {
                                if (parseInt(page) % 2 !== 0) {
                                    page2 = parseInt(page) - 1;
                                    page1 = page;
                                } else {
                                    page2 = page;
                                    page1 = (parseInt(page) + 1) >= docTblBookBodyList.length ? -1 : parseInt(page) + 1;
                                }
                                thisPage = page1 > 0 ? page1 + 1 : page2 + 1;
                            }

                            if (page1 >= 0) {
                                getBookFilePath(
                                    bookInfo.categoryId,
                                    bookInfo.docTblBookDetail.fileUuid,
                                    docTblBookBodyList[page1].pagePath
                                ).then(pdfFile => {
                                    this.setState({
                                        bookFile: Object.assign(this.state.bookFile, { page1: { file: pdfFile, number: (page1 + 1), loading: true } }),
                                        bookPage: thisPage,
                                    });
                                    this.laodMark();
                                });
                            } else {
                                this.setState({
                                    bookFile: Object.assign(this.state.bookFile, { page1: { file: null, number: (page1 + 1), loading: false } })
                                });
                            }
                            if (page2 >= 0) {
                                getBookFilePath(
                                    bookInfo.categoryId,
                                    bookInfo.docTblBookDetail.fileUuid,
                                    docTblBookBodyList[page2].pagePath
                                ).then(pdfFile => {
                                    this.setState({
                                        bookFile: Object.assign(this.state.bookFile, { page2: { file: pdfFile, number: (page2 + 1), loading: true } }),
                                        bookPage: thisPage
                                    });
                                    this.laodMark();
                                });
                            } else {
                                this.setState({
                                    bookFile: Object.assign(this.state.bookFile, { page2: { file: null, number: (page2 + 1), loading: false } })
                                });
                            }
                        } else {
                            this.laodMark();
                            getBookFilePath(
                                bookInfo.categoryId,
                                bookInfo.docTblBookDetail.fileUuid,
                                docTblBookBodyList[page].pagePath
                            ).then(pdfFile => {
                                this.setState({
                                    bookFile: Object.assign(this.state['formData'], { page1: { file: pdfFile, number: (page + 1), loading: true } }),
                                });
                            });
                        }
                    }
                    if (isPrint === false) this.saveReadPage((page + 1));
                }).catch(error => {
                    if (error.errorCode === "E201") {
                        Toast.error(error.errorMessage);
                        this.emptyAnnotations();
                        this.props.history.push('/user/book/library');
                    } else if (error.errorCode === "E401") {
                        Toast.error(error.errorMessage, 2000, () => {
                            this.emptyAnnotations();
                            this.setReadTime(this.state.bookId);
                            window.location.reload();
                        });
                    }
                });
            })
        })

    }


    handleClose = () => {
        this.setState({
            anchorEl: null
        });
    }
    handleOpenReadMsg = () => {
        this.setState({
            readMsg: false,
        });
        if (this.state.docType === 'mp4') {
            let video = document.getElementById('video');
            video.currentTime = this.state.readPage;
        } else {
            this.handleTurningPage(parseInt(this.state.readPage));
        }
    }
    handleCloseReadMsg = () => {
        this.setState({
            readMsg: false,
        });
    }
    handleOpenFontSize = () => {
        this.setState({
            fontsizeStatus: true,
            editStatus: false,
            fontMsgOpen: false,
        })
    }
    handleFontMsgClose = () => {
        this.setState({
            fontMsgOpen: false,
        })
    }
    handleCloseFontSize = () => {
        this.setState({
            fontsizeStatus: false,
        })
    }
    handleCloseSearch = () => {
        this.setState({
            searchStatus: false
        })
    }
    handleCloseStarReview = () => {
        this.setState({
            starReviewStatus: false
        })
    }
    /*Evaluate submit*/
    handleSubmitEvaluate = () => {
        let score = this.state.score;
        let _userInfo = getUserInfo();
        bookEvaluate({
            bookId: this.state.bookInfo.id,
            content: '',
            score: score,
            userId: _userInfo.userId
        }).then(res => {
            Toast.info(this.state.dictWord.msg_evaluation_completed);
        }).catch(function (error) {
            Toast.error(error);
        });
        this.setState({
            starReviewStatus: false
        })
    }
    handleOpenStarReview = () => {
        let _userInfo = getUserInfo();
        getBookEvaluate({
            userId: _userInfo.userId,
            bookId: this.state.bookId
        }).then(res => {
            this.setState({
                score: res.score,
                starReviewStatus: true,
                editStatus: false,
            });
        }).catch(function (error) {
            Toast.error(error);
        });
    }
    handleOpenSearch = () => {
        this.setState({
            searchStatus: true,
            editStatus: false
        })
    }
    handleClosePrint = () => {
        this.setState({
            printStatus: false
        })
    }

    handlePrintMsgClose = () => {
        this.setState({
            printMsgOpen: false
        })
    }
    handlePrintMsgErrClose = () => {
        this.setState({
            printMsgErr: false
        })
    }

    handlePrintMsgOk = () => {
        let startPage = parseInt(this.state.formData.startPage.value) > 0 ? parseInt(this.state.formData.startPage.value) : 1;
        let endPage = parseInt(this.state.formData.endPage.value) > 0 ? parseInt(this.state.formData.endPage.value) : this.state.totalPage;
        if (endPage > this.state.totalPage || (endPage - startPage + 1) > parseInt(this.state.printMax)) {
            this.setState({
                printMsgErr: true
            })
            return;
        }

        if (startPage < 1) startPage = 1;
        this.handlePrint(startPage, endPage);
        this.setState({
            printMsgOpen: false
        })
    }
    handlePrintMsg = () => {
        if (this.state.docType === 'pdf' && this.state.totalPage >= parseInt(this.state.printMax)) {
            this.setState({
                printMsgOpen: true,
            });
        } else {
            this.handlePrint();
        }
    }
    handleInformation = () => {
        this.props.history.replace({
            pathname: '/user/userInfo/information/',
            query: {
                bookId: this.state.bookId,
            }
        })
    }

    handlePrint = (starPage = 1, endPage = this.state.totalPage) => {
        this.setState({
            isPrint: true,
            pageLoading: true,
        })
        // let bPageNumber = this.state.bookPage;
        if (this.state.docType === 'pdf') {
            let pageNum = endPage - starPage + 1;
            bodyPreview({
                bookId: this.state.bookId,
                starPage: starPage,
                endPage: endPage
            }).then(res => {
                let reacThis = this;
                for (let i in res.fileUuids) {
                    let pdfAllFiles = reacThis.state.pdfAllFiles;
                    getBookPreviewImg(res.folderUuid, res.fileUuids[i], this.state.docType).then(pdfFile => {
                        pdfAllFiles[i] = pdfFile;
                        reacThis.setState({
                            pdfAllFiles: pdfAllFiles,
                            printNum: pageNum,
                        })
                    })
                }
            });
            // let docTblBookBodyList = this.state.bookInfo.docTblBookBodyList;

            let printHtml = $("<div style='text-align: center;'></div>");
            let print = setInterval(function () {
                if ($("#pdfPrint img").length === pageNum) {
                    printHtml.append($('#pdfPrint').prop("outerHTML"));
                    $('body').html(printHtml);
                    window.print();
                    window.location.reload();
                    clearInterval(print);
                }
            }, 500);
        }
    }
    handleOpenPrint = () => {
        this.setState({
            printStatus: true,
            editStatus: false,
        })
    }
    handleTurningPage = (bookPage) => {
        if (isNaN(bookPage)) return;
        if (parseInt(bookPage) < 1) bookPage = 1;
        if (bookPage > this.state.totalPage) bookPage = this.state.totalPage;
        let page1 = {};
        let page2 = {};
        if (this.state.horizontalPageCount === 0 && this.state.docType === 'pdf') {
            page1 = Object.assign(this.state.bookFile.page1, { loading: true });
            page2 = Object.assign(this.state.bookFile.page2, { loading: true });
        }
        this.setState({
            bookPage: bookPage,
            searchStatus: false,
            anchorEl: null,
            pageLoading: this.state.horizontalPageCount === 0 && this.state.docType === 'pdf' ? false : true,
            bookFile: this.state.horizontalPageCount === 0 && this.state.docType === 'pdf' ? Object.assign(this.state.bookFile, { page1: page1, page2: page2 }) : this.state.bookFile
        });
        this.loadBookHtml(bookPage);
    }
    handleFlipPage = (operation) => {
        let currentBookPage = this.state.bookPage;
        let bookPage = 1;
        let pageSize = this.state.horizontalPageCount === 0 && this.state.docType === 'pdf' ? 2 : 1;
        if (this.state.flip === 0) {
            if (operation === 'next') bookPage = currentBookPage + pageSize;
            else if (operation === 'previous') bookPage = currentBookPage - pageSize;
        } else {
            if (operation === 'next') bookPage = currentBookPage - pageSize;
            else if (operation === 'previous') bookPage = currentBookPage + pageSize;
        }
        if (bookPage > this.state.totalPage) bookPage = this.state.totalPage;
        if (bookPage < 1) return;
        let page1 = {};
        let page2 = {};
        if (this.state.horizontalPageCount === 0 && this.state.docType === 'pdf') {
            if (this.state.bookFile.page1) {
                page1 = Object.assign(this.state.bookFile.page1, { loading: true });
            }
            if (this.state.bookFile.page2) {
                page2 = Object.assign(this.state.bookFile.page2, { loading: true });
            }
        }
        this.setState({
            bookPage: bookPage,
            anchorEl: null,
            pageLoading: this.state.horizontalPageCount === 0 && this.state.docType === 'pdf' ? false : true,
            bookFile: this.state.horizontalPageCount === 0 && this.state.docType === 'pdf' ? Object.assign(this.state.bookFile, { page1: page1, page2: page2 }) : this.state.bookFile
        });
        this.loadBookHtml(bookPage);
    }
    renderAnnotations = (page, position, event) => {
        let svg = $("<svg id='bookAnnotationLayer_" + page + "'></svg>");
        let viewport = {};
        if (this.state.docType === 'pdf') {
            position = position === 2 ? 'page_2' : 'page_1';
            let textLayer = $("#" + position + " .react-pdf__Page__textContent");
            // let canvasViewport = event.getViewport(this.state.scale);
            textLayer.before(svg);
            textLayer.css({
                'position': 'absolute',
                'top': 0,
                'left': 0,
                'right': 0,
                'bottom': 0,
                'opacity': 0.4,
                // 'transform': ''
                // 'width': event.width,
                // 'height': event.height,
                'transform': 'translate(0%, 0%) rotate(' + event.rotate + 'deg)'
            });
            // console.log(canvasViewport.width,canvasViewport.height);
            // console.log(event.width,event.height);
            textLayer.attr('id', 'textLayer');
            viewport = {
                height: event.height,
                width: event.width,
                viewBox: event.view,
                scale: this.state.scale,
                rotation: event.rotate
                // transform: event.transform
            }
            svg.css({
                'width': event.width,
                'height': event.height,
                'position': 'absolute',
                'top': 0,
                'left': 0,
                'right': 0,
                'bottom': 0,
                'transform': 'translate(0%, 0%) rotate(' + event.rotate + 'deg)'
            });

        }
        /*load annotations*/
        let annotations = this.getAnnotations(this.state.bookInfo.id, page);
        this.loadAnnotations(svg.get(0), viewport, annotations);
    }

    /**
     * 加载已添加的注释层
     */
    loadAnnotations = (svg, viewport, data) => {
        let reactThis = this;
        return new Promise(function (resolve, reject) { // Reset the content of the SVG
            svg.innerHTML = '';
            svg.setAttribute('data-book-annotate-container', true);
            svg.setAttribute('data-book-annotate-viewport', JSON.stringify(viewport));
            svg.removeAttribute('data-book-annotate-document');
            svg.removeAttribute('data-book-annotate-page'); // If there's no data nothing can be done
            if (!data) {
                return resolve(svg);
            }
            svg.setAttribute('data-book-annotate-document', data.documentId);
            svg.setAttribute('data-book-annotate-page', data.pageNumber); // Make sure annotations is an array
            if (!Array.isArray(data.annotations) || data.annotations.length === 0) {
                return resolve(svg);
            } // Append annotation to svg
            data.annotations.forEach(function (a) {
                (0, reactThis.appendChild)(svg, a, viewport);
            });
            resolve(svg);
        });
    }
    emptyAnnotations = () => {
        let _userInfo = getUserInfo();
        $('svg[data-book-annotate-container="true"]').empty();
        localStorage.removeItem(this.state.bookId + '/' + _userInfo.userId + '/annotations');
        localStorage.removeItem(this.state.bookId + '/' + _userInfo.userId + '/mark');
    }
    handleBookEdit = (type) => {
        if (type.length > 0 && this.state.editStatus === true) {
            if (type === 'highlight') {
                this.enableUserSelect();
            } else {
                this.disableUserSelect();
            }
            this.setState({
                editType: type
            });
        }
    }
    handleSvgMouseDown = (page) => event => {
        if (this.state.editStatus === false) return;
        let svg = (0, this.findSVGAtPoint)(event.clientX, event.clientY);
        if (!svg) {
            return;
        }
        let rect = svg.getBoundingClientRect();
        let point = (0, this.scaleDown)(svg, {
            x: event.clientX - rect.left,
            y: event.clientY - rect.top
        });
        switch (this.state.editType) {
            case 'curve':
                Paint = {
                    page: page,
                    path: null,
                    lines: [[point.x, point.y]],
                };
                break;
            case 'straightLine':
                Paint = {
                    page: page,
                    line: null,
                    lines: { x1: point.x, y1: point.y },
                };
                break;
            case 'highlight':
                Paint = {
                    page: page,
                    rect: null,
                    lines: [],
                };
                break;
            default:
                break;
        }
    }
    handleSvgMouseMove = (page) => event => {
        if (this.state.editStatus === false) return;
        if (!Paint.hasOwnProperty('lines')) return;
        if (page !== Paint.page) {
            this.outSvgPaint();
            return;
        }
        switch (this.state.editType) {
            case 'curve':
                if (!Paint.hasOwnProperty('lines')) return;
                this.savePoint(event.clientX, event.clientY);
                break;
            case 'straightLine':
                if (!Paint.hasOwnProperty('line')) return;
                this.straightLinePoint(event.clientX, event.clientY);
                break;
            case 'highlight':
                let rects = void 0;
                if ((rects = this.getSelectionRects())) {
                    let svg = (0, this.findSVGAtPoint)(rects[0].left, rects[0].top);
                    let boundingRect = svg.getBoundingClientRect();
                    let retctThis = this;
                    let rectangles = [].concat(this._toConsumableArray(rects)).map(function (r) {
                        let offset = 0;
                        return (0, retctThis.scaleDown)(svg, {
                            y: r.top + offset - boundingRect.top,
                            x: r.left - boundingRect.left,
                            width: r.width,
                            height: r.height
                        });
                    }).filter(function (r) {
                        return r.width > 0 && r.height > 0 && r.x > -1 && r.y > -1;
                    });
                    if (Paint.rect && Paint.rect.parentNode) {
                        svg.removeChild(Paint.rect);
                    }
                    Paint.rect = this.appendChild(svg, {
                        type: this.state.editType,
                        color: this.state.editSetting.highlightColor,
                        rectangles: rectangles
                    });
                }
                break;
            default:
                break;
        }
    }
    handleSvgMouseUp = (page) => event => {
        if (page !== Paint.page) return;
        if (this.state.editStatus === false) return;
        if (page !== Paint.page) {
            this.outSvgPaint();
            return;
        }
        let svg = void 0;
        let reactThis = this;
        switch (this.state.editType) {
            case 'curve':
                if (!Paint.hasOwnProperty('lines')) return;
                if (Paint.lines.length > 2 && (svg = (0, this.findSVGAtPoint)(event.clientX, event.clientY))) {
                    let _getMetadata = (0, this.getMetadata)(svg);
                    let documentId = _getMetadata.documentId;
                    let pageNumber = _getMetadata.pageNumber;
                    this.addAnnotation(documentId, pageNumber, {
                        type: 'curve',
                        width: this.state.editSetting.penSize,
                        color: this.state.editSetting.penColor,
                        lines: Paint.lines
                    }).then(function (annotation) {
                        if (Paint.path && svg === Paint.path.parentNode) {
                            svg.removeChild(Paint.path);
                        } (0, reactThis.appendChild)(svg, annotation);
                    });
                }
                Paint = {};
                break;
            case 'straightLine':
                if (!Paint.hasOwnProperty('line')) return;
                if (Paint.lines.x1 > 0 && Paint.lines.x1 > 0 && (svg = (0, this.findSVGAtPoint)(event.clientX, event.clientY))) {
                    let _getMetadata = (0, this.getMetadata)(svg);
                    let documentId = _getMetadata.documentId;
                    let pageNumber = _getMetadata.pageNumber;
                    let rect = svg.getBoundingClientRect();
                    let point = (0, this.scaleDown)(svg, {
                        x: event.clientX - rect.left,
                        y: event.clientY - rect.top
                    });
                    this.addAnnotation(documentId, pageNumber, {
                        type: 'straightLine',
                        width: this.state.editSetting.penSize,
                        color: this.state.editSetting.penColor,
                        x1: Paint.lines.x1,
                        y1: Paint.lines.y1,
                        x2: point.x,
                        y2: point.y
                    }).then(function (annotation) {
                        if (Paint.line && svg === Paint.line.parentNode) {
                            svg.removeChild(Paint.line);
                        } (0, reactThis.appendChild)(svg, annotation);
                    });
                }
                Paint = {};
                break;
            case 'highlight':
                if (!Paint.hasOwnProperty('rect')) return;
                let rects = void 0;
                if ((rects = this.getSelectionRects())) {
                    if (Paint.rect) {
                        let svg = (0, this.findSVGAtPoint)(rects[0].left, rects[0].top);
                        svg.removeChild(Paint.rect);
                    }
                    if(Paint.rect){
                        this.saveRect(this.state.editType, [].concat(this._toConsumableArray(rects)).map(function (r) {
                            return {
                                top: r.top,
                                left: r.left,
                                width: r.width,
                                height: r.height
                            };
                        }));
                    }
                    Paint = {};
                }
                break;
            default:
                break;
        }
    }
    handleSvgDelete = (page) => event => {
        if (this.state.editStatus === false) return;
        if (this.state.editType !== 'delete') return;
        let svg = this.findSVGAtPoint(event.clientX, event.clientY);
        if (svg) {
            var target = (0, this.findAnnotationAtPoint)(event.clientX, event.clientY);
            if (target) {
                let annotateType = target.getAttribute('data-book-annotate-type');
                if (annotateType !== 'highlight') return;
                let annotateId = target.getAttribute('data-book-annotate-id');
                target.remove();
                this._deleteAnnotation(this.state.bookId, annotateId);
            }
        }
    }
    handleSvgRevoke = () => {
        if (this.state.editStatus === false) return;
        let annotations = this._getAnnotations(this.state.bookId);
        annotations.sort(function (obj1, obj2) {
            return obj2.time - obj1.time;
        });
        let pages = [this.state.bookPage];
        if (this.state.docType === 'pdf' && this.state.horizontalPageCount === 0) {
            pages = [this.state.bookFile.page1.number, this.state.bookFile.page2.number]
        }
        let notUuid = document.querySelectorAll('[data-book-annotate-id="undefined"]');
        if (notUuid.length > 0) {
            for (let b in notUuid) {
                if (typeof (notUuid[b]) === "object" && notUuid[b].getAttribute('data-book-annotate-type') === 'highlight') continue;
                if (!notUuid[b].parentNode) continue;
                notUuid[b].remove();
            }
        }
        for (let i in annotations) {
            if (pages.indexOf(annotations[i].page) > -1 && (annotations[i].type === 'curve' || annotations[i].type === 'straightLine')) {
                let node = document.querySelectorAll('[data-book-annotate-id="' + annotations[i].uuid + '"]');
                if (node.length > 0) node[0].remove();
                this._deleteAnnotation(this.state.bookId, annotations[i].uuid);
                break;
            }
        }
    }
    handleOutSvgMouseMove = () => event => {
        if (this.state.editStatus === false) return;
        if (!Paint.hasOwnProperty('lines')) return;
        let svg = (0, this.findSVGAtPoint)(event.clientX, event.clientY)
        if (!svg) {
            if (Paint) {
                this.outSvgPaint();
            }
        }
    }
    outSvgPaint() {
        let reactThis = this;
        switch (this.state.editType) {
            case 'curve':
                if (!Paint.hasOwnProperty('lines')) return;
                if (Paint.lines.length <= 1) break;
                this.addAnnotation(this.state.bookId, Paint.page, {
                    type: 'curve',
                    width: this.state.editSetting.penSize,
                    color: this.state.editSetting.penColor,
                    lines: Paint.lines
                }).then(function (annotation) {
                    let svg = document.querySelector('[data-book-annotate-page="' + Paint.page + '"]');
                    if (Paint.path && svg === Paint.path.parentNode) {
                        svg.removeChild(Paint.path);
                    } (0, reactThis.appendChild)(svg, annotation);
                });
                break;
            case 'straightLine':
                if (!Paint.hasOwnProperty('line')) return;
                if (!Paint.lines.x2 || !Paint.lines.y2) break;
                this.addAnnotation(this.state.bookId, Paint.page, {
                    type: 'straightLine',
                    width: this.state.editSetting.penSize,
                    color: this.state.editSetting.penColor,
                    x1: Paint.lines.x1,
                    y1: Paint.lines.y1,
                    x2: Paint.lines.x2,
                    y2: Paint.lines.y2
                }).then(function (annotation) {
                    let svg = document.querySelector('[data-book-annotate-page="' + Paint.page + '"]');
                    if (Paint.line && svg === Paint.line.parentNode) {
                        svg.removeChild(Paint.line);
                    } (0, reactThis.appendChild)(svg, annotation);
                });
                break;
            default:
                break;
        }
        Paint = {};
    }
    handleSvgPenSize = (event, value) => {
        this.setState({
            editSetting: Object.assign(this.state.editSetting, { penSize: value })
        });
    }
    straightLinePoint = (x, y) => {
        let svg = (0, this.findSVGAtPoint)(x, y);
        if (!svg) {
            return;
        }
        let rect = svg.getBoundingClientRect();
        let point = (0, this.scaleDown)(svg, {
            x: x - rect.left,
            y: y - rect.top
        });
        if (!Paint.lines.x1 || !Paint.lines.y1) {
            Paint.lines.x1 = point.x;
            Paint.lines.y1 = point.y;
            // this.setState({
            //     Paint: Object.assign(Paint, {
            //         lines: { x1: point.x, y1: point.y }
            //     })
            // });
            // console.log(Paint.lines)
            return;
        }
        // this.setState({
        //     Paint: Object.assign(Paint, {
        //         lines: { x2: point.x, y2: point.y }
        //     })
        // });
        Paint.lines.x2 = point.x;
        Paint.lines.y2 = point.y;
        if (Paint.line && svg === Paint.line.parentNode) {
            svg.removeChild(Paint.line);
        }

        Paint.line = (0, this.appendChild)(svg, {
            type: 'straightLine',
            color: this.state.editSetting.penColor,
            width: this.state.editSetting.penSize,
            x1: Paint.lines.x1,
            y1: Paint.lines.y1,
            x2: point.x,
            y2: point.y
        });
    }
    savePoint = (x, y) => {
        let svg = (0, this.findSVGAtPoint)(x, y);
        if (!svg) {
            return;
        }
        let rect = svg.getBoundingClientRect();
        let point = (0, this.scaleDown)(svg, {
            x: x - rect.left,
            y: y - rect.top
        });
        Paint.lines.push([point.x, point.y]);
        if (Paint.lines.length <= 1) {
            return;
        }
        if (Paint.path && svg === Paint.path.parentNode) {
            svg.removeChild(Paint.path);
        }


        Paint.path = this.appendChild(svg, {
            type: 'curve',
            color: this.state.editSetting.penColor,
            width: this.state.editSetting.penSize,
            lines: Paint.lines
        });
    }
    /***********************************************************************/
    pointIntersectsRect = (x, y, rect) => {
        return y >= rect.top && y <= rect.bottom && x >= rect.left && x <= rect.right;
    }
    findSVGAtPoint = (x, y) => {
        let elements = $('svg[data-book-annotate-container="true"]');
        for (let i = 0, l = elements.length; i < l; i++) {
            let el = elements[i];
            let rect = el.getBoundingClientRect();
            if (this.pointIntersectsRect(x, y, rect)) {
                return el;
            }
        }
        return null;
    }
    saveRect = (type, rects) => {
        let svg = (0, this.findSVGAtPoint)(rects[0].left, rects[0].top);
        if (!svg) {
            return;
        }
        // let node = void 0;
        let annotation = void 0;
        let boundingRect = svg.getBoundingClientRect();
        if (!this.state.editSetting.highlightColor) {
            if (type === 'highlight') {
                Paint.editSetting = {
                    highlightColor: 'FFFF00'
                }
                // this.setState({
                //     Paint: Object.assign(this.state.editSetting, {
                //         highlightColor: 'FFFF00'
                //     })
                // });
            } else if (type === 'StraightLine') {
                Paint.editSetting = {
                    highlightColor: 'FF0000'
                }
                // this.setState({
                //     Paint: Object.assign(this.state.editSetting, {
                //         highlightColor: 'FF0000'
                //     })
                // });
            }
        }
        let reactThis = this;
        let rectangles = [].concat(this._toConsumableArray(rects)).map(function (r) {
            let offset = 0;
            return (0, reactThis.scaleDown)(svg, {
                y: r.top + offset - boundingRect.top,
                x: r.left - boundingRect.left,
                width: r.width,
                height: r.height
            });
        }).filter(function (r) {
            return r.width > 0 && r.height > 0 && r.x > -1 && r.y > -1;
        });
        annotation = {
            type: type,
            color: this.state.editSetting.highlightColor,
            rectangles: rectangles
        };
        if (annotation.rectangles.length === 0) {
            return;
        }
        let _getMetadata = (0, this.getMetadata)(svg);
        let documentId = _getMetadata.documentId;
        let pageNumber = _getMetadata.pageNumber;
        this.addAnnotation(documentId, pageNumber, annotation).then(function (annotation) {
            (0, reactThis.appendChild)(svg, annotation);
        });
    }
    scaleDown = (svg, rect) => {
        let result = {};
        let _getMetadata2 = this.getMetadata(svg);
        let viewport = _getMetadata2.viewport;
        Object.keys(rect).forEach(function (key) {
            result[key] = rect[key] / viewport.scale;
        });
        return result;
    }
    getMetadata = (svg) => {
        return {
            documentId: $(svg).attr('data-book-annotate-document'),
            pageNumber: parseInt($(svg).attr('data-book-annotate-page'), 10),
            viewport: JSON.parse($(svg).attr('data-book-annotate-viewport'))
        };
    }
    findAnnotation = (documentId, annotationId) => {
        var index = -1;
        var annotations = this._getAnnotations(documentId);
        for (var i = 0, l = annotations.length; i < l; i++) {
            if (annotations[i].uuid === annotationId) {
                index = i;
                break;
            }
        }
        return index;
    }
    _deleteAnnotation = (documentId, annotationId) => {
        let reactThis = this;
        return new Promise(function (resolve, reject) {
            var index = reactThis.findAnnotation(documentId, annotationId);
            if (index > -1) {
                var annotations = reactThis._getAnnotations(documentId);
                annotations.splice(index, 1);
                reactThis.updateAnnotations(documentId, annotations);
            }
            resolve(true);
        });
    }
    addAnnotation = (documentId, pageNumber, annotation) => {
        let reactThis = this;
        return new Promise(function (resolve, reject) {
            annotation.class = 'Annotation';
            annotation.uuid = (0, reactThis.uuid)();
            annotation.page = pageNumber;
            annotation.time = (new Date()).getTime();
            annotation.title = reactThis.state.bookInfo.title;
            annotation.bookid = documentId;
            let annotations = reactThis._getAnnotations(documentId);
            annotations.push(annotation);
            reactThis.updateAnnotations(documentId, annotations);
            resolve(annotation);
        });
    }
    findAnnotationAtPoint = (x, y) => {
        var svg = this.findSVGAtPoint(x, y);
        if (!svg) {
            return;
        }
        var elements = svg.querySelectorAll('[data-book-annotate-type]'); // Find a target element within SVG
        for (var i = 0, l = elements.length; i < l; i++) {
            var el = elements[i];
            if (this.pointIntersectsRect(x, y, this.getOffsetAnnotationRect(el))) {
                return el;
            }
        }
        return null;
    }
    getOffsetAnnotationRect = (el) => {
        var rect = this.getAnnotationRect(el);
        var _getOffset = this.getOffset(el);
        var offsetLeft = _getOffset.offsetLeft;
        var offsetTop = _getOffset.offsetTop;
        return {
            top: rect.top + offsetTop,
            left: rect.left + offsetLeft,
            right: rect.right + offsetLeft,
            bottom: rect.bottom + offsetTop
        };
    }
    getOffset = (el) => {
        var parentNode = el;
        while ((parentNode = parentNode.parentNode) && parentNode !== document) {
            if (parentNode.nodeName.toUpperCase() === 'SVG') {
                break;
            }
        }
        var rect = parentNode.getBoundingClientRect();
        return {
            offsetLeft: rect.left,
            offsetTop: rect.top
        };
    }
    getAnnotationRect = (el) => {
        var h = 0,
            w = 0,
            x = 0,
            y = 0;
        var rect = el.getBoundingClientRect(); // TODO this should be calculated somehow
        var LINE_OFFSET = 16;
        switch (el.nodeName.toLowerCase()) {
            case 'path':
                var minX = void 0,
                    maxX = void 0,
                    minY = void 0,
                    maxY = void 0;
                el.getAttribute('d').replace(/Z/, '').split('M').splice(1).forEach(function (p) {
                    var s = $.trim(p).replace('L ', '').split(' ').map(function (i) {
                        return parseInt(i, 10);
                    });
                    if (typeof minX === 'undefined' || s[0] < minX) {
                        minX = s[0];
                    }
                    if (typeof maxX === 'undefined' || s[2] > maxX) {
                        maxX = s[2];
                    }
                    if (typeof minY === 'undefined' || s[1] < minY) {
                        minY = s[1];
                    }
                    if (typeof maxY === 'undefined' || s[3] > maxY) {
                        maxY = s[3];
                    }
                });
                h = maxY - minY;
                w = maxX - minX;
                x = minX;
                y = minY;
                break;
            case 'g':
                var _getOffset2 = this.getOffset(el);
                var offsetLeft = _getOffset2.offsetLeft;
                var offsetTop = _getOffset2.offsetTop;
                h = rect.height;
                w = rect.width;
                x = rect.left - offsetLeft;
                y = rect.top - offsetTop;
                if (el.getAttribute('data-pdf-annotate-type') === 'StraightLine') {
                    h += LINE_OFFSET;
                    y -= LINE_OFFSET / 2;
                }
                break;
            case 'rect':
            case 'svg':
                h = parseInt(el.getAttribute('height'), 10);
                w = parseInt(el.getAttribute('width'), 10);
                x = parseInt(el.getAttribute('x'), 10);
                y = parseInt(el.getAttribute('y'), 10);
                break;
            default:
                break;
        }
        var result = {
            top: y,
            left: x,
            width: w,
            height: h,
            right: x + w,
            bottom: y + h
        };
        if (!['svg', 'g'].includes(el.nodeName.toLowerCase())) {
            result = this.scaleUp(this.findSVGAtPoint(rect.left, rect.top), result);
        }
        return result;
    }
    scaleUp = (svg, rect) => {
        var result = {};
        var _getMetadata = this.getMetadata(svg);
        var viewport = _getMetadata.viewport;
        Object.keys(rect).forEach(function (key) {
            result[key] = rect[key] * viewport.scale;
        });
        return result;
    }
    uuid = () => {
        let REGEXP = /[xy]/g;
        let PATTERN = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
        return PATTERN.replace(REGEXP, this.replacement);
    }
    replacement = (c) => {
        let r = Math.random() * 16 | 0;
        let v = c === 'x' ? r : r && 0x3 | 0x8;
        return v.toString(16);
    }
    _getAnnotations = (documentId) => {
        let _userInfo = getUserInfo();
        return JSON.parse(localStorage.getItem(documentId + '/' + _userInfo.userId + '/annotations')) || [];
    }
    getAnnotations = (documentId, pageNumber) => {
        let annotations = this._getAnnotations(documentId).filter(function (i) {
            return i.page === pageNumber && i.class === 'Annotation';
        });
        return ({
            documentId: documentId,
            pageNumber: pageNumber,
            annotations: annotations
        });
    }
    updateAnnotations = (documentId, annotations) => {
        let _userInfo = getUserInfo();
        localStorage.setItem(documentId + '/' + _userInfo.userId + '/annotations', JSON.stringify(annotations));
    }
    appendChild = (svg, annotation, viewport) => {
        if (!svg) return;
        if (!viewport) {
            viewport = JSON.parse(svg.getAttribute('data-book-annotate-viewport'));
        }
        let child = void 0;
        switch (annotation.type) {
            case 'highlight':
                child = (0, this.renderRect)(annotation);
                break;
            case 'straightLine':
                if (!annotation.x2 || !annotation.y2) break;
                child = (0, this.renderLine)(annotation);
                break;
            case 'curve':
                if (annotation.lines.length <= 1) return;
                child = (this.renderPath)(annotation);
                break;
            default:
                break;
        }
        if (child) { // Set attributes
            child.setAttribute('data-book-annotate-id', annotation.uuid);
            child.setAttribute('data-book-annotate-type', annotation.type);
            child.setAttribute('aria-hidden', true);
            svg.appendChild(this.transform(child, viewport));
        }
        return child;
    }
    transform = (node, viewport) => {
        let trans = this.getTranslation(viewport); // Let SVG natively transform the element
        node.setAttribute('transform', 'scale(' + viewport.scale + ') rotate(' + viewport.rotation + ') translate(' + trans.x + ', ' + trans.y + ')'); // Manually adjust x/y for nested SVG nodes
        let isFirefox = /firefox/i.test(navigator.userAgent);
        if (!isFirefox && node.nodeName.toLowerCase() === 'svg') {
            node.setAttribute('x', parseInt(node.getAttribute('x'), 10) * viewport.scale);
            node.setAttribute('y', parseInt(node.getAttribute('y'), 10) * viewport.scale);
            let x = parseInt(node.getAttribute('x', 10));
            let y = parseInt(node.getAttribute('y', 10));
            let width = parseInt(node.getAttribute('width'), 10);
            let height = parseInt(node.getAttribute('height'), 10);
            let path = node.querySelector('path');
            let svg = path.parentNode; // Scale width/height
            [node, svg, path, node.querySelector('rect')].forEach(function (n) {
                n.setAttribute('width', parseInt(n.getAttribute('width'), 10) * viewport.scale);
                n.setAttribute('height', parseInt(n.getAttribute('height'), 10) * viewport.scale);
            });
            switch (viewport.rotation % 360) {
                case 90:
                    node.setAttribute('x', viewport.width - y - width);
                    node.setAttribute('y', x);
                    svg.setAttribute('x', 1);
                    svg.setAttribute('y', 0);
                    break;
                case 180:
                    node.setAttribute('x', viewport.width - x - width);
                    node.setAttribute('y', viewport.height - y - height);
                    svg.setAttribute('y', 2);
                    break;
                case 270:
                    node.setAttribute('x', y);
                    node.setAttribute('y', viewport.height - x - height);
                    svg.setAttribute('x', -1);
                    svg.setAttribute('y', 0);
                    break;
                default:
                    break;
            }
        }
        return node;
    }
    getTranslation = (viewport) => {
        let x = void 0;
        let y = void 0;
        switch (viewport.rotation % 360) {
            case 0:
                x = y = 0;
                break;
            case 90:
                x = 0;
                y = viewport.width / viewport.scale * -1;
                break;
            case 180:
                x = viewport.width / viewport.scale * -1;
                y = viewport.height / viewport.scale * -1;
                break;
            case 270:
                x = viewport.height / viewport.scale * -1;
                y = 0;
                break;
            default:
                break;
        }
        return {
            x: x,
            y: y
        };
    }
    renderLine = (a) => {
        let line = this.createLine(a)
        let _ret = function () {
            let group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            group.appendChild(line);
            return {
                v: group
            };
        }();
        let _typeof2 = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ?
            function (obj) {
                return typeof obj;
            } : function (obj) {
                return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj;
            };
        let _typeof = typeof Symbol === "function" && _typeof2(Symbol.iterator) === "symbol" ?
            function (obj) {
                return typeof obj === 'undefined' ? 'undefined' : _typeof2(obj);
            } : function (obj) {
                return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj === 'undefined' ? 'undefined' : _typeof2(obj);
            };
        if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
    }
    createLine = (r) => {
        let line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        (0, this.setAttributes)(line, {
            x1: r.x1,
            y1: r.y1,
            x2: r.x2,
            y2: r.y2,
            strokeWidth: r.width || 1,
            stroke: (0, this.normalizeColor)(r.color || this.state.editSetting.penColor),
            fill: 'none'
        });
        return line;
    }
    renderPath = (a) => {
        let d = [];
        let path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        for (let i = 0, l = a.lines.length; i < l; i++) {
            let p1 = a.lines[i];
            let p2 = a.lines[i + 1];
            if (p2) {
                d.push('M' + p1[0] + ' ' + p1[1] + ' ' + p2[0] + ' ' + p2[1]);
            }
        } (0, this.setAttributes)(path, {
            d: d.join(' ') + 'Z',
            stroke: (0, this.normalizeColor)(a.color || '#000'),
            strokeWidth: a.width || 1,
            fill: 'none',
            'stroke-linecap': "round",
        });
        return path;
    }
    setAttributes = (node, attributes) => {
        let reactThis = this;
        Object.keys(attributes).forEach(function (key) {
            node.setAttribute(reactThis.keyCase(key), attributes[key]);
        });
    }
    keyCase = (key) => {
        let UPPER_REGEX = /[A-Z]/g; // Don't convert these attributes from camelCase to hyphenated-attributes
        let BLACKLIST = ['viewBox'];
        if (BLACKLIST.indexOf(key) === -1) {
            key = key.replace(UPPER_REGEX, function (match) {
                return '-' + match.toLowerCase();
            });
        }
        return key;
    }
    normalizeColor = (color) => {
        let REGEX_HASHLESS_HEX = /^([a-f0-9]{6}|[a-f0-9]{3})$/i;
        if (REGEX_HASHLESS_HEX.test(color)) {
            color = "#" + color;
        }
        return color;
    }
    getSelectionRects = () => {
        try {
            let selection = window.getSelection();
            let range = selection.getRangeAt(0);
            let rects = range.getClientRects();
            if (rects.length > 0 && rects[0].width > 0 && rects[0].height > 0) {
                return rects;
            }
        } catch (e) { }
        return null;
    }
    _toConsumableArray = (arr) => {
        let arr2 = [];
        for (let i = 0; i < arr.length; i++) {
            arr2[i] = arr[i];
        }
        return arr2;
    }
    renderRect = (a) => {
        let reactThis = this;
        if (a.type === 'highlight') {
            let _ret = function () {
                let group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                (0, reactThis.setAttributes)(group, {
                    fill: (0, reactThis.normalizeColor)(a.color || '#ff0'),
                    fillOpacity: 0.2
                });
                a.rectangles.forEach(function (r) {
                    group.appendChild(reactThis.createRect(r));
                });
                return {
                    v: group
                };
            }();
            let _typeof2 = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ?
                function (obj) {
                    return typeof obj;
                } : function (obj) {
                    return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj;
                };
            let _typeof = typeof Symbol === "function" && _typeof2(Symbol.iterator) === "symbol" ?
                function (obj) {
                    return typeof obj === 'undefined' ? 'undefined' : _typeof2(obj);
                } : function (obj) {
                    return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj === 'undefined' ? 'undefined' : _typeof2(obj);
                };
            if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
        } else {
            let rect = this.createRect(a);
            (0, this.setAttributes)(rect, {
                stroke: (0, this.normalizeColor)(a.color || '#f00'),
                fill: 'none'
            });
            return rect;
        }
    }
    createRect = (r) => {
        let rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        (0, this.setAttributes)(rect, {
            x: r.x,
            y: r.y,
            width: r.width,
            height: r.height
        });
        return rect;
    }

    /*book content search*/
    handleSearch = () => {
        this.setState({
            contentPageNumber: 1
        }, () => {
            let keyword = this.state.formData.searchText.value;
            if (!keyword) {
                this.setState({
                    searchResult: [],
                    totlePage: 0
                })
            } else {
                bodySearch({
                    bookId: this.state.bookInfo.id,
                    bodyContent: keyword,
                    pageSize: this.state.contentPageSize,
                    pageNum: this.state.contentPageNumber
                }).then((res) => {
                    let totlePage = res.totlePage;
                    let docTblBookBodyList = res['docTblBookBody'];
                    if (docTblBookBodyList.length > 0) {
                        let searchPages = [];
                        let parsedWords = res.parsedWords;
                        for (let i in docTblBookBodyList) {
                            let _pageNum = docTblBookBodyList[i].pageNum;
                            let htmlText = docTblBookBodyList[i].bodyContent;
                            htmlText = escapeHtml(htmlText);
                            let firstIndex = -1;
                            for (let j in parsedWords) {
                                let _keyword = searchMatching(htmlText, parsedWords[j]);
                                if (_keyword.length <= 0) continue;
                                var reg = new RegExp(_keyword, "gi");
                                let temp = reg.exec(htmlText);
                                if (firstIndex < 0 || temp.index < firstIndex) {
                                    firstIndex = temp.index
                                }
                            }
                            htmlText = htmlText.substring(firstIndex - 2, htmlText.length);
                            htmlText = htmlText.length > 30 ? htmlText.substring(0, 30) + '…' : htmlText;
                            for (let j in parsedWords) {
                                let _keyword = searchMatching(htmlText, parsedWords[j]);
                                if (_keyword.length <= 0) continue;
                                htmlText = heightLight(htmlText, _keyword, j);
                            }
                            let index = htmlText.indexOf('<span');
                            htmlText = replacepos(htmlText, index);
                            searchPages.push({
                                pageNumber: _pageNum,
                                text: htmlText
                            });
                        }
                        this.setState({
                            searchResult: searchPages,
                            totlePage,
                            bookId: this.state.bookInfo.id,
                            parsedWords: parsedWords
                        })
                    } else {
                        this.setState({
                            searchResult: [],
                            parsedWords: []
                        })
                    }
                });
            }
        })
    }
    contentLoadMore() {
        let keyword = this.state.formData.searchText.value;
        bodySearch({
            bookId: this.state.bookId,
            bodyContent: keyword,
            pageSize: this.state.contentPageSize,
            pageNum: this.state.contentPageNumber
        }).then((res) => {
            let docTblBookBodyList = res['docTblBookBody'];
            let searchPages = this.state.searchResult;
            if (docTblBookBodyList.length > 0) {
                let parsedWords = res.parsedWords;
                for (let i in docTblBookBodyList) {
                    let _pageNum = docTblBookBodyList[i].pageNum;
                    let htmlText = docTblBookBodyList[i].bodyContent;
                    htmlText = escapeHtml(htmlText);
                    let firstIndex = -1;
                    for (let j in parsedWords) {
                        let _keyword = searchMatching(htmlText, parsedWords[j]);
                        if (_keyword.length <= 0) continue;
                        var reg = new RegExp(_keyword, "gi");
                        let temp = reg.exec(htmlText);
                        if (firstIndex < 0 || temp.index < firstIndex) {
                            firstIndex = temp.index
                        }
                    }
                    htmlText = htmlText.substring(firstIndex - 2, htmlText.length);
                    htmlText = htmlText.length > 30 ? htmlText.substring(0, 30) + '…' : htmlText;

                    for (let j in parsedWords) {
                        let _keyword = searchMatching(htmlText, parsedWords[j]);
                        if (_keyword.length <= 0) continue;
                        htmlText = heightLight(htmlText, _keyword, j);
                    }
                    let index = htmlText.indexOf('<span');
                    htmlText = replacepos(htmlText, index);
                    searchPages.push({
                        pageNumber: _pageNum,
                        text: htmlText
                    });
                }
                this.setState({
                    searchResult: searchPages,
                    totlePage: res.totlePage,
                })
            }
        });
    }
    nextTotlePage() {
        let contentPageNumber = this.state.contentPageNumber;
        contentPageNumber++;
        if (contentPageNumber <= this.state.totlePage) {
            this.setState({
                contentPageNumber
            }, () => {
                this.contentLoadMore()
            });
        }
    }
    search = (str) => {
        if (str.length <= 0) {
            return false;
        }
        let textLayer = void 0;
        if (this.state.docType === 'pdf') {
            textLayer = $("#textLayer *");
        }

        let text = textLayer.text();
        str = str.toLowerCase();
        text = text.toLowerCase();
        
        if (text.indexOf(str) >= 0) {
            let NodeAll = [];
            let TextNode = [];
            let gettext = '';
            let i = 1;
            if (textLayer.length > 0) {
                textLayer.each(function () {
                    let _text = $(this).text().toLowerCase();
                    let _gettext = '';
                    for (let b = 0; b <= _text.length; b++) {
                        if (_text.substring((b - 1), b) === str.substring((i - 1), i)) {
                            _gettext += _text.substring((b - 1), b);
                            gettext += _text.substring((b - 1), b);
                            i++;
                        } else {
                            if ((gettext === str || _gettext === str) && _gettext.length > 0) {
                                TextNode.push([$(this), _gettext]);
                                NodeAll.push(TextNode);
                                TextNode = [];
                            } else if ((gettext !== str && _gettext !== str) && gettext.length > 0) {
                                TextNode = [];
                            }
                            gettext = "";
                            _gettext = "";
                            i = 1;
                        }
                    }
                    if (_gettext.length > 0 && gettext.length > 0) {
                        TextNode.push([$(this), _gettext]);
                        if (gettext === str || _gettext === str) {
                            NodeAll.push(TextNode);
                            TextNode = [];
                            gettext = "";
                            i = 1;
                        }
                    }
                });
            }
            
            let temporaryStr = "{**temporarystr**}";
            let span = $("<span></span>");
            span.addClass('serchselect');
            if (NodeAll.length > 0) {
                for (let l = 0; l < NodeAll.length; l++) {
                    for (let p = 0; p < NodeAll[l].length; p++) {
                        let oldtext = $(NodeAll[l][p][0]).html().toLowerCase();
                        let newhtml = oldtext.replace(NodeAll[l][p][1], temporaryStr);
                        $(NodeAll[l][p][0]).html(newhtml);
                    }
                }
                for (let l = 0; l < NodeAll.length; l++) {
                    for (let p = 0; p < NodeAll[l].length; p++) {
                        let oldtext = $(NodeAll[l][p][0]).html().toLowerCase();
                        span.text(NodeAll[l][p][1]);
                        let newhtml = oldtext.replace(temporaryStr, span[0].outerHTML);
                        $(NodeAll[l][p][0]).html(newhtml);
                    }
                }
            }
        }
    }
    deleteSearchHighlight = () =>{
        $(".serchselect").each(function(){
            let bookHtml = $("#textLayer").html();
            let selectHtml = $(this).prop("outerHTML");
            let reg = new RegExp(selectHtml, "gi");
            bookHtml = bookHtml.replace(reg, $(this).text());
            $("#textLayer").html(bookHtml);
        });
    }
    getScores = (score) => {
        if (score === this.state.score) score = 0;
        this.setState({
            score: score
        })
    }
    fatherFn = (status) => {
        this.setState({
            chatboxOpen: status,
            commentPage: 1,
        })
    }
    toggleMessage = () => {
        let messageObj = {
            messageBoxTitle: this.state.dictWord.comment,
            placeholder: this.state.dictWord.msg_error_not_null_with_item.replace('%s', this.state.dictWord.comment),
        }
        commentList(this.state.bookId, {
            pageNumber: 1,
            pageSize: this.state.commentSize,
        }).then(res => {
            let commentList = res.docTblUserCommentList;
            commentList.sort(function (obj1, obj2) {
                return obj1.id - obj2.id;
            });
            this.setState({
                chatboxOpen: true,
                messageObj: messageObj,
                comments: commentList
            })
            $('#customized-dialog-content').scrollTop($('#customized-dialog-content')[0].scrollHeight);
        }).catch(error => {
            this.setState({
                chatboxOpen: true,
                editStatus: false,
                messageObj: messageObj,
                comments: []
            })
        })
        this.setState({
            chatboxOpen: !this.state.chatboxOpen
        })
    }
    sendMessage = (func) => {
        let commentMessage = this.state.commentMessage;
        this.setState({
            commentMessage: '',
        }, () => {
            $('#commentMessage').val('');
            commentMessage = commentMessage.replace(/(^\s*)|(\s*$)/g, "");
            commentMessage = encodeURI(commentMessage);
            if (commentMessage.length > 0) {
                commentAdd({
                    bookId: this.state.bookId,
                    comment: commentMessage
                }).then(res => {
                    let comments = this.state.comments;
                    comments.push(res);
                    this.setState({
                        comments: comments
                    }, () => {
                        func();
                    });
                }).catch(error => {
                    Toast.error(error);
                })
            }
        });
    }
    handleLoadMessage = (func) => {
        let page = this.state.commentPage + 1;
        commentList(this.state.bookId, {
            pageNumber: page,
            pageSize: this.state.commentSize,
        }).then(res => {
            let commentList = res.docTblUserCommentList;
            commentList.sort(function (obj1, obj2) {
                return obj1.id - obj2.id;
            });
            let comments = commentList.concat(this.state.comments);
            this.setState({
                comments: comments,
                commentPage: page
            }, () => {
                func(this.state.comments.length);
            })
        }).catch(error => {
            Toast.error(error);
        })
    }
    receiveHandle = (res) => {
        this.setState({
            commentMessage: res
        });
    }
    laodMark = () => {
        let _userInfo = getUserInfo();
        let marks = JSON.parse(window.localStorage.getItem(this.state.bookId + '/' + _userInfo.userId + "/mark"));
        if (!marks) marks = [];
        if (marks.length > 0) {
            for (let i in marks) {
                if (marks[i].page === this.state.bookPage) {
                    this.setState({
                        isMark: true
                    });
                    return;
                }
            }
            this.setState({
                isMark: false
            });
        }
    }
    handleMark = () => {
        let _userInfo = getUserInfo();
        let marks = JSON.parse(window.localStorage.getItem(this.state.bookId + '/' + _userInfo.userId + "/mark"));
        if (!marks) marks = [];
        if (marks.length > 0) {
            for (let i in marks) {
                if (marks[i].page === this.state.bookPage) {
                    marks.splice(i, 1);
                    window.localStorage.setItem(this.state.bookId + '/' + _userInfo.userId + "/mark", JSON.stringify(marks));
                    this.setState({
                        isMark: false
                    });
                    return;
                }
            }
        }
        let _mark = {
            bookid: this.state.bookId,
            title: this.state.bookInfo.title,
            type: 'mark',
            time: (new Date()).getTime(),
            page: this.state.bookPage
        }
        marks.push(_mark);
        this.setState({
            isMark: true
        });
        window.localStorage.setItem(this.state.bookId + '/' + _userInfo.userId + "/mark", JSON.stringify(marks));
    }
    //メモ
    handleOpenBookNoteList() {
        let bookId = this.state.bookId;
        let _userInfo = getUserInfo();
        let annotations = [];
        for (let i = 0; i < localStorage.length; i++) {
            let flg = '/' + _userInfo.userId;
            if (parseInt(bookId) > 0) {
                flg = bookId + '/' + _userInfo.userId;
            }
            if (localStorage.key(i).indexOf(flg + "/annotations") >= 0) {
                let _annotation = JSON.parse(localStorage.getItem(localStorage.key(i))) || [];
                if (_annotation.length > 0) {
                    for (let j = 0; j < _annotation.length; j++) {
                        if (_annotation[j].type === 'note') {
                            _annotation[j].typeDic = 'notes';
                            annotations.push(_annotation[j]);
                        }
                    }
                }
            }
        }
        annotations.sort(function (obj1, obj2) {
            return obj2.time - obj1.time;
        });
        this.setState({
            annotations,
            noteListStatus: true,
            optionOpen: false
        });
    }
    handeSaveBookNote = () => {
        let noteText = this.state.formData.noteText.value;
        if (noteText.length <= 0) return;
        let annotation = {
            type: 'note',
            noteText: noteText
        }
        this.addAnnotation(this.state.bookId, this.state.bookPage, annotation);
        let formData = this.state.formData;
        formData['noteText'].value = '';
        this.setState({
            formData,
            noteStatus: false,
            confirmOpen: false
        }, () => {
            this.handleOpenBookNoteList();
        });
    }

    closeAddNote() {
        let noteText = this.state.formData.noteText.value;
        if ($.trim(noteText).length <= 0) {
            this.setState({ noteStatus: !this.state.noteStatus })
        } else {
            this.setState({ confirmOpen: true })
        }
    }

    handleNoteDelete() {
        let _userInfo = getUserInfo();
        let itemId = this.state.noteKey;
        let annotation = this.state.annotations[itemId];

        let _annotations = JSON.parse(localStorage.getItem(annotation.bookid + '/' + _userInfo.userId + '/annotations')) || [];
        for (let i in _annotations) {
            if (_annotations[i].uuid === annotation.uuid) {
                _annotations.splice(i, 1);
                break;
            }
        }

        let annotations = this.state.annotations;
        annotations.splice(itemId, 1);
        this.setState({
            annotations,
            noteDelete: false
        }, () => {
            localStorage.setItem(annotation.bookid + '/' + _userInfo.userId + '/annotations', JSON.stringify(_annotations));
        });
    }



    handleInputChange(field, event) {
        let value = event.target.value;
        let valueObj = Object.assign(this.state.formData[field], { value: value });
        let state = {
            formData: Object.assign(this.state.formData, { [field]: valueObj })
        };
        if (field === 'searchText') {
            state['searchResult'] = [];
            state['totlePage'] = 0;
        }
        this.setState(state);
    }


    handleVideoPlayIng = () => event => {
        let _userInfo = getUserInfo();
        let videoId = this.state.bookId;
        let video = document.getElementById('video');
        let duration = video.duration;
        let _key = -1;
        let reactThis = this;
        let print = setInterval(function () {
            let play = JSON.parse(window.localStorage.getItem(_userInfo.userId + "/play")) || [];
            for (let i in play) {
                if (play[i].videoId === videoId) {
                    play[i].currentTime = video.currentTime;
                    play[i].time = reactThis.state.readTime;
                    _key = i;
                    break;
                }
            }
            if (video.currentTime === duration) {
                play.splice(_key, 1);
                localStorage.setItem(_userInfo.userId + '/play', JSON.stringify(play));
                clearInterval(print);
            }
            if (_key < 0) {
                play.push({
                    videoId: videoId,
                    duration: duration,
                    currentTime: video.currentTime,
                    time: reactThis.state.readTime
                })
            }
            localStorage.setItem(_userInfo.userId + '/play', JSON.stringify(play));
        }, 5000);
    }

    handleReduce = () => {
        let scale = parseFloat(this.state.scale) * 10 - 2;
        if (scale <= 0) return;
        scale = scale / 10;
        $("#book").css('overflow', 'auto');
        if (this.state.horizontalPageCount === 0 && this.state.docType === 'pdf') {
            $(".page_horizontal").css('transform', 'scale(' + scale + ')');
        } else {
            $(".page_single").css('transform', 'scale(' + scale + ')');
        }
        this.deleteSearchHighlight();
        this.setState({ scale: scale, editStatus: false });
    }
    handleEnlarge = () => {
        let scale = parseFloat(this.state.scale) * 10 + 2;
        if (scale >= 60) return;
        scale = scale / 10;
        $("#book").css('overflow', 'auto');
        if (this.state.horizontalPageCount === 0 && this.state.docType === 'pdf') {
            $(".page_horizontal").css('transform', 'scale(' + scale + ')');
        } else {
            $(".page_single").css('transform', 'scale(' + scale + ')');
        }
        this.deleteSearchHighlight();
        this.setState({ scale: scale, editStatus: false });
    }
    bookList = (searchKeyword) => {
        const { dispatch } = this.props;
        let me = this;
        dispatch(getBookListStart());
        bookSearch({
            searchInfo: searchKeyword
        }).then(res => {
            let docTblBookList = res['docTblBookList'];
            if (docTblBookList.length < 1) {
                Toast.info(me.state.dictWord.msg_error_record_not_found);
                dispatch(getBookListSuccess(
                    {
                        searchKeyword
                    }, res, this.state.oldList
                ));
                return;
            } else {
                for (let index in docTblBookList) {
                    let item = docTblBookList[index];
                    let coverUuid = item['docTblBookDetail']['coverUuid'];
                    let fileUuid = item['docTblBookDetail']['fileUuid'];
                    let categoryId = item['categoryId'];
                    let docType = item['docType'];

                    this.state.oldList.push(item);
                    this.setState({
                        oldList: this.state.oldList
                    })
                    getCoverPath(categoryId, fileUuid, coverUuid, docType).then(imgRes => {
                        docTblBookList[index]['imgSrc'] = imgRes;
                        dispatch(getBookListSuccess(
                            {
                                searchKeyword
                            }, res, this.state.oldList
                        ));
                    }).catch(() => {
                        docTblBookList[index]['imgSrc'] = "";
                    });
                }
                dispatch(getBookListSuccess(
                    {
                        searchKeyword
                    }, res, this.state.oldList
                ));
            }
        }).catch(() => {
            Toast.info(me.state.dictWord.msg_error_record_not_found);
        })
        this.props.history.push('/user/book/searchbooklist');
    }
    pageBack = () => {
        if (this.props.history.length > 2) {
            this.props.history.goBack();
        } else {
            this.props.history.push('/');
        }
    }

    render() {
        const { classes } = this.props;
        const { dictWord, totlePage } = this.state;
        // const open = Boolean(anchorEl);
        if (this.state.editStatus === true) {
            $("#book svg").css({
                'z-index': ''
            })
        } else {
            $("#book svg").css({
                'z-index': ''
            })
        }
        let noteInfo = this.state.noteInfo;
        if (noteInfo) {
            noteInfo = noteInfo.replace(/\n/g, "<br/>");
            noteInfo = noteInfo.replace(/\s/g, "&nbsp;");
        }
        return (
            <div className="book-wrap">
                {this.state.pageLoading === false ?
                    <div className="tool-bars">
                        <div className="left-par">
                            <IconButton onClick={this.pageBack} >
                                <ArrowBackIosIcon fontSize="large" />
                            </IconButton>
                        </div>
                        <div className="center-part" title={this.state.bookInfo.title}>
                            {this.state.bookInfo.title}
                        </div>
                        <div className="right-part">
                            <Hidden mdUp implementation="css">
                                <div className="option-box">
                                    <IconButton onClick={() => { this.setState({ optionOpen: true, editStatus: false }) }}>
                                        <MoreVert fontSize="large" />
                                    </IconButton>
                                    <Popper open={this.state.optionOpen} className="option" anchorEl={this.anchorEl} role={undefined} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={() => { this.setState({ optionOpen: false, editStatus: false }) }}>
                                                        <MenuList>
                                                            {this.state.docType !== 'mp4' ?
                                                                <MenuItem onClick={this.handleReduce}>
                                                                    <ListItemIcon>
                                                                        <ReduceIcon fontSize="small" className="iconSmall" />
                                                                    </ListItemIcon>
                                                                    <Typography variant="inherit">{this.state.dictWord.reduction}</Typography>
                                                                </MenuItem> : null}
                                                            {this.state.docType !== 'mp4' ?
                                                                <MenuItem onClick={this.handleEnlarge}>
                                                                    <ListItemIcon>
                                                                        <EnlargeIcon fontSize="small" className="iconSmall" />
                                                                    </ListItemIcon>
                                                                    <Typography variant="inherit">{this.state.dictWord.expansion}</Typography>
                                                                </MenuItem>
                                                                : null}
                                                            {this.state.docType === 'pdf' ?
                                                                <MenuItem onClick={this.handleMark}>
                                                                    <ListItemIcon>
                                                                    <img src={this.state.isMark ?label_light:label} alt={this.state.dictWord.label} className="iconSmall" />
                                                                    </ListItemIcon>
                                                                    <Typography variant="inherit">{this.state.dictWord.label}</Typography>
                                                                </MenuItem>
                                                                : null}
                                                            {this.state.docType !== 'mp4' && this.state.scale === 1 ?
                                                                <MenuItem onClick={this.toggleEdit}>
                                                                    <ListItemIcon>
                                                                        <img src={pen} alt={this.state.dictWord.pen} className="iconSmall" />
                                                                    </ListItemIcon>
                                                                    <Typography variant="inherit">{this.state.dictWord.pen}</Typography>
                                                                </MenuItem>
                                                                : null}
                                                            {this.state.docType !== 'mp4' ?
                                                                <MenuItem onClick={this.handleOpenBookNoteList.bind(this)}>
                                                                    <ListItemIcon>
                                                <img src={notes} alt={this.state.dictWord.notes} className="iconSmall" />
                                                                    </ListItemIcon>
                                                                    <Typography variant="inherit">{this.state.dictWord.notes}</Typography>
                                                                </MenuItem>
                                                                : null}
                                                            {parseInt(this.state.commentFlg) === 0 ?
                                                                <MenuItem onClick={this.toggleMessage}>
                                                                    <ListItemIcon>
                                                                        <Message fontSize="small" className="iconSmall" />
                                                                    </ListItemIcon>
                                                                    <Typography variant="inherit">{this.state.dictWord.comment}</Typography>
                                                                </MenuItem>
                                                                : null}
                                                            {this.state.docType === 'mp4' ? null :
                                                                <MenuItem onClick={this.handleOpenSearch}>
                                                                    <ListItemIcon>
                                                                    <img src={SearchOutlined} className="iconSmall" alt="SearchOutlined" />
                                                                    </ListItemIcon>
                                                                    <Typography variant="inherit">{this.state.dictWord.search}</Typography>
                                                                </MenuItem>
                                                            }
                                                            <MenuItem onClick={this.handleOpenStarReview}>
                                                                <ListItemIcon>
                                                                <img src={star} className="iconSmall" alt="star" />
                                                                </ListItemIcon>
                                                                <Typography variant="inherit">{this.state.dictWord.evaluation_record}</Typography>
                                                            </MenuItem>
                                                            {this.state.docType !== 'mp4' ?
                                                                <MenuItem onClick={this.handlePrintMsg}>
                                                                    <Typography variant="inherit">{this.state.dictWord.printing}</Typography>
                                                                </MenuItem> : null}
                                                            {this.state.docType !== 'mp4' ?
                                                                <MenuItem onClick={this.handleInformation}>
                                                                    <Typography variant="inherit">{this.state.dictWord.save_information_list}</Typography>
                                                                </MenuItem> : null}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </div>
                            </Hidden>
                            <Hidden smDown implementation="css">
                                {this.state.docType !== 'mp4' ?
                                    <em>
                                        <IconButton onClick={this.handleReduce}>
                                        <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.reduction}</p>}>
                                                <ReduceIcon fontSize="large" className="iconBig" />
                                            </Tooltip>
                                        </IconButton>
                                        <IconButton onClick={this.handleEnlarge}>
                                            <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.expansion}</p>}>
                                                <EnlargeIcon fontSize="large" className="iconBig" />
                                            </Tooltip>
                                        </IconButton>
                                    </em>
                                    : ''}
                                {this.state.docType === 'pdf' ?
                                    <em>
                                         <IconButton onClick={this.handleMark}>
                                         <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{dictWord.label}</p>}>
                                                    <img src={this.state.isMark ?label_light:label} alt={this.state.dictWord.label} className="iconBig" />
                                                </Tooltip>
                                            </IconButton> 
                                    </em> : ''}
                                {this.state.docType !== 'mp4' && this.state.scale === 1 ?
                                        <IconButton onClick={this.toggleEdit}>
                                            <Tooltip title={<p style={{fontSize:'10.5px',margin:0}}>{dictWord.pen}</p>}>
                                                <img src={this.state.editStatus ?pen_light:pen} alt={this.state.dictWord.pen} className="iconBig" />
                                            </Tooltip>
                                        </IconButton>
                                    : ''}

                                {this.state.docType !== 'mp4' ?
                                    <IconButton onClick={this.handleOpenBookNoteList.bind(this)}>
                                        <Tooltip title={<p style={{fontSize:'10.5px',margin:0}}>{this.state.dictWord.notes}</p>}>
                                            <img src={notes} alt={this.state.dictWord.notes} className="iconBig" />
                                        </Tooltip>
                                    </IconButton>
                                    : ''
                                }

                                {parseInt(this.state.commentFlg) === 0 ?
                                    <IconButton onClick={this.toggleMessage}>
                                        <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{dictWord.comment}</p>}>
                                            <Message fontSize="large" className="iconBig" />
                                        </Tooltip>
                                    </IconButton>
                                    : null}
                                {this.state.docType === 'mp4' ? '' :
                                    <IconButton onClick={this.handleOpenSearch}>
                                        <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.search}</p>} >
                                        <img src={SearchOutlined} alt="SearchOutlined" className="iconBig" />
                                        </Tooltip>
                                    </IconButton>
                                }
                                <IconButton onClick={this.handleOpenStarReview}>
                                <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.evaluation_record}</p>}>
                                    <img src={star} className="iconBig" alt="star" />
                                    </Tooltip>
                                </IconButton>
                                {this.state.docType !== 'mp4' ?
                                    <div className="print-wrap">
                                        <IconButton onClick={this.handleOpenPrint}>
                                            <MenuIcon fontSize="large" />
                                        </IconButton>
                                        <Popper open={this.state.printStatus} style={{ right: "0px", position: 'absolute',zIndex:9 }} anchorEl={this.anchorEl} transition disablePortal className={classes.paper}>
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    id="menu-list-grow"
                                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' , zIndex:9}}
                                                >
                                                    <Paper>
                                                        <ClickAwayListener onClickAway={this.handleClosePrint}>
                                                            <MenuList>
                                                                {this.state.printFlg === true ?
                                                                    <MenuItem onClick={this.handlePrintMsg}>{dictWord.printing}</MenuItem> : null}
                                                                <MenuItem onClick={this.handleInformation}>{dictWord.save_information_list}</MenuItem>
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </div>
                                    : null}
                            </Hidden>
                        </div>
                    </div>
                    : null}
                <Divider />
                <div id="colorSelect" style={{ position: 'relative', width: '100%', display: this.state.editStatus ? 'flex' : 'none' }}>
                    <Radio
                        checked={this.state.colorStatus === 'a'}
                        onChange={this.handleChangeColor("#1e88e5")}
                        value="a"
                        name="radio-button-color"
                        aria-label="A"
                        classes={{
                            root: classes.bluecolor,
                            checked: classes.checked,
                        }}
                    />
                    <Radio
                        checked={this.state.colorStatus === 'b'}
                        onChange={this.handleChangeColor("#43a047")}
                        value="b"
                        name="radio-button-color"
                        aria-label="B"
                        classes={{
                            root: classes.greencolor,
                            checked: classes.checked,
                        }}
                    />
                    <Radio
                        checked={this.state.colorStatus === 'c'}
                        onChange={this.handleChangeColor("#8e24aa")}
                        value="c"
                        name="radio-button-color"
                        aria-label="C"
                        classes={{
                            root: classes.purplecolor,
                            checked: classes.checked,
                        }}
                    />
                    <Radio
                        checked={this.state.colorStatus === 'd'}
                        onChange={this.handleChangeColor("#e53935")}
                        value="d"
                        name="radio-button-color"
                        aria-label="D"
                        classes={{
                            root: classes.redcolor,
                            checked: classes.checked,
                        }}
                    />
                    <Radio
                        checked={this.state.colorStatus === 'e'}
                        onChange={this.handleChangeColor("#fb8c00")}
                        value="e"
                        name="radio-button-color"
                        aria-label="e"
                        classes={{
                            root: classes.orangecolor,
                            checked: classes.checked,
                        }}
                    />
                    <Radio
                        checked={this.state.colorStatus === 'f'}
                        onChange={this.handleChangeColor('#fdd835')}
                        value="f"
                        name="radio-button-color"
                        aria-label="f"
                        classes={{
                            root: classes.yellowcolor,
                            checked: classes.checked,
                        }}
                    />
                    <Radio
                        checked={this.state.colorStatus === 'g'}
                        onChange={this.handleChangeColor('#fafafa')}
                        value="g"
                        name="radio-button-color"
                        aria-label="g"
                        classes={{
                            root: classes.greycolor,
                            checked: classes.checked,
                        }}
                    />
                </div>
                <div id="bookContainer" onMouseMove={this.handleOutSvgMouseMove()}>

                    {/* control edit button */}
                    {
                        this.state.editStatus
                            ?
                            <div className="fab-wrap" style={{marginRight:'30px'}}>
                                {this.state.editType === 'curve' || this.state.editType === 'straightLine' ?
                                    <div className="slider-wraper">
                                        <Slider
                                            classes={{ container: classes.penSlider }}
                                            className="rigth-slider"
                                            value={this.state.editSetting.penSize}
                                            aria-labelledby="label"
                                            onChange={this.handleSvgPenSize}
                                            max={30}
                                            min={1}
                                            style={{padding:'0px 10px'}}
                                            vertical
                                        />
                                    </div>
                                    : null
                                }
                                <Fab className="option-btn fabUndo">
                                <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.original_return}</p>}>
                                    <UndoIcon onClick={this.handleSvgRevoke.bind(this)} />
                                </Tooltip>
                                </Fab>
                                {this.state.editType === 'delete' ?
                                    <Fab color="primary" className="option-btn fabDelete">
                                         <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.delete_record}</p>}>
                                        <DeleteIcon onClick={this.handleBookEdit.bind(this, 'delete')} color="primary" />
                                        </Tooltip>
                                    </Fab>
                                    :
                                    <Fab className="option-btn fabDelete">
                                        <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.delete_record}</p>}>
                                        <DeleteIcon onClick={this.handleBookEdit.bind(this, 'delete')} color="primary" />
                                        </Tooltip>
                                    </Fab>
                                }
                                {this.state.editType === 'highlight' ?
                                    <Fab color="primary" className="option-btn fabSelect" onClick={this.handleBookEdit.bind(this, 'highlight')}>
                                        <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.marker}</p>}>
                                        <img src={pen_marker} alt={dictWord.marker} style={{ width: '25px', height: '26px' }} />
                                        </Tooltip>
                                    </Fab>
                                    :
                                    <Fab className="option-btn fabSelect" onClick={this.handleBookEdit.bind(this, 'highlight')}>
                                         <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.marker}</p>}>
                                        <img src={pen_marker} alt={dictWord.marker} style={{ width: '25px', height: '26px' }} />
                                        </Tooltip>
                                    </Fab>
                                }
                                {this.state.editType === 'curve' ?
                                    <Fab color="primary" aria-label="tilde" className="option-btn fabTilde" onClick={this.handleBookEdit.bind(this, 'curve')}>
                                         <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.curve}</p>}>
                                        <img src={pen_curve} alt={dictWord.curve} style={{ width: '25px', height: '26px' }} />
                                        </Tooltip>
                                    </Fab>
                                    :
                                    <Fab aria-label="tilde" className="option-btn fabTilde" onClick={this.handleBookEdit.bind(this, 'curve')}>
                                        <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.curve}</p>}>
                                        <img src={pen_curve} alt={dictWord.curve} style={{ width: '25px', height: '26px' }} />
                                        </Tooltip>
                                    </Fab>
                                }
                                {this.state.editType === 'straightLine' ?
                                    <Fab color="primary" aria-label="vector" className="option-btn fabVector"  onClick={this.handleBookEdit.bind(this, 'straightLine')}>
                                        <Tooltip title={<p style={{margin:0,fontSize:'12px'}}>{this.state.dictWord.straight_line}</p>} >
                                        <img src={pen_straight} alt={dictWord.straight_line} style={{ width: '25px', height: '26px' }} />
                                        </Tooltip>
                                    </Fab>
                                    :
                                    <Fab aria-label="vector" className="option-btn fabVector" onClick={this.handleBookEdit.bind(this, 'straightLine')}>
                                        <Tooltip title={<p style={{margin:0,fontSize:'12px'}}>{this.state.dictWord.straight_line}</p>} >
                                        <img src={pen_straight} alt={dictWord.straight_line} style={{ width: '25px', height: '26px' }} />
                                        </Tooltip>
                                    </Fab>
                                }
                            </div>
                            : null
                    }
                    {this.state.pageLoading === true ?
                        <div className="loading-div">
                            <div className="loading">
                                <Fade in
                                    style={{
                                        transitionDelay: '800ms',
                                        margin: '0 auto',
                                        display: 'block'
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress size={80} />
                                </Fade>
                            </div>
                        </div> : null
                    }
                    {this.state.horizontalPageCount === 0 && this.state.docType === 'pdf' && this.state.isPrint === false ?
                        <div id="book" style={{ background: this.state.styleSetting.contentBackColor }}>
                            <div className="page_horizontal">
                                {this.state.bookFile.page1 ?
                                    <div id="page_1"
                                        onMouseDown={this.handleSvgMouseDown(this.state.bookFile.page1.number)}
                                        onMouseMove={this.handleSvgMouseMove(this.state.bookFile.page1.number)}
                                        onMouseUp={this.handleSvgMouseUp(this.state.bookFile.page1.number)}
                                        onClick={this.handleSvgDelete(this.state.bookFile.page1.number)}
                                        className="page_1"
                                        style={{ paddingRight: '2px' }}
                                    >
                                        {this.state.bookFile.page1.loading === true ?
                                            <div className="loading-div">
                                                <div className="loading">
                                                    <Fade in
                                                        style={{
                                                            transitionDelay: '800ms',
                                                            margin: '0 auto',
                                                            display: 'block'
                                                        }}
                                                        unmountOnExit
                                                    >
                                                        <CircularProgress size={80} />
                                                    </Fade>
                                                </div>
                                            </div> : null
                                        }
                                        {this.state.bookFile.page1.file ?
                                            <Document options={{ cMapUrl: 'cmaps/', cMapPacked: true, }} file={this.state.bookFile.page1.file} loading=" ">
                                                <Page pageNumber={1}
                                                    width={this.state.pdfWidth}
                                                    renderAnnotationLayer={false}
                                                    onLoadSuccess={(ev) => { this.renderAnnotations(this.state.bookFile.page1.number, 1, ev) }}
                                                    onRenderSuccess={(items) => {
                                                        let page1 = Object.assign(this.state.bookFile.page1, { loading: false });
                                                        this.setState({
                                                            bookFile: Object.assign(this.state.bookFile, { page1: page1 }),
                                                        }, () => {
                                                            /*keyword serarch*/
                                                            let parsedWords = this.state.parsedWords;
                                                            if (parsedWords.length > 0) {
                                                                for (let i in parsedWords) {
                                                                    let _keyword = searchMatching($("#textLayer *").text(), parsedWords[i]);
                                                                    if (_keyword.length <= 0) continue;
                                                                    this.search(_keyword);
                                                                }
                                                            }
                                                        });
                                                    }}
                                                    loading=" "
                                                />
                                            </Document> : null}
                                    </div>
                                    : null}
                                {this.state.bookFile.page2 ?
                                    <div id="page_2"
                                        onMouseDown={this.handleSvgMouseDown(this.state.bookFile.page2.number)}
                                        onMouseMove={this.handleSvgMouseMove(this.state.bookFile.page2.number)}
                                        onMouseUp={this.handleSvgMouseUp(this.state.bookFile.page2.number)}
                                        onClick={this.handleSvgDelete(this.state.bookFile.page2.number)}
                                        className="page_2"
                                    >
                                        {this.state.bookFile.page2.loading === true ?
                                            <div className="loading-div">
                                                <div className="loading">
                                                    <Fade in
                                                        style={{
                                                            transitionDelay: '800ms',
                                                            margin: '0 auto',
                                                            display: 'block'
                                                        }}
                                                        unmountOnExit
                                                    >
                                                        <CircularProgress size={80} />
                                                    </Fade>
                                                </div>
                                            </div>
                                            : null
                                        }
                                        {this.state.bookFile.page2.file ?
                                            <Document options={{ cMapUrl: 'cmaps/', cMapPacked: true, }} file={this.state.bookFile.page2.file} loading=" ">
                                                <Page pageNumber={1}
                                                    width={this.state.pdfWidth}
                                                    renderAnnotationLayer={false}
                                                    onLoadSuccess={(ev) => { this.renderAnnotations(this.state.bookFile.page2.number, 2, ev) }}
                                                    onRenderSuccess={(items) => {
                                                        let page2 = Object.assign(this.state.bookFile.page2, { loading: false });
                                                        this.setState({
                                                            bookFile: Object.assign(this.state.bookFile, { page2: page2 }),
                                                        }, () => {
                                                            /*keyword serarch*/
                                                            let parsedWords = this.state.parsedWords;
                                                            if (parsedWords.length > 0) {
                                                                for (let i in parsedWords) {
                                                                    let _keyword = searchMatching($("#textLayer *").text(), parsedWords[i]);
                                                                    if (_keyword.length <= 0) continue;
                                                                    this.search(_keyword);
                                                                }
                                                            }
                                                        });
                                                    }}
                                                    loading=" "
                                                />
                                            </Document> : null}
                                    </div>
                                    : null}
                            </div>
                        </div>
                        :
                        <div id="book"
                            onMouseDown={this.handleSvgMouseDown(this.state.bookPage)}
                            onMouseMove={this.handleSvgMouseMove(this.state.bookPage)}
                            onMouseUp={this.handleSvgMouseUp(this.state.bookPage)}
                            onClick={this.handleSvgDelete(this.state.bookPage)}
                            style={{ background: this.state.styleSetting.contentBackColor }}
                        >
                            {this.state.docType === 'mp4' ?
                                <video controls
                                    controlsList="nodownload"
                                    autoPlay="autoPlay"
                                    id="video"
                                    onPlaying={this.handleVideoPlayIng()}/**视频开始播放事件 */
                                    src={this.state.videoData}
                                    style={{maxWidth:'100%', objectFit: 'fill'}}
                                />
                                :
                                <div id="page_1" className="page_single">
                                    {this.state.docType === 'pdf' && this.state.isPrint === false && this.state.bookFile.page1.file ?
                                        <Document options={{ cMapUrl: 'cmaps/', cMapPacked: true, }} file={this.state.bookFile.page1.file} loading="">
                                            <Page pageNumber={1}
                                                width={this.state.pdfWidth}
                                                renderAnnotationLayer={false}
                                                onLoadSuccess={(ev) => { this.renderAnnotations(this.state.bookPage, 1, ev) }}
                                                onRenderSuccess={(items) => {
                                                    this.setState({
                                                        pageLoading: false,
                                                    }, () => {
                                                        /*keyword serarch*/
                                                        let parsedWords = this.state.parsedWords;
                                                        if (parsedWords.length > 0) {
                                                            for (let i in parsedWords) {
                                                                let _keyword = searchMatching($("#textLayer *").text(), parsedWords[i]);
                                                                if (_keyword.length <= 0) continue;
                                                                this.search(_keyword);
                                                            }
                                                        }
                                                    });
                                                }}
                                                loading=" "
                                            />
                                        </Document> : null}
                                </div>}
                        </div>
                    }

                    {this.state.editStatus === false && this.state.pageLoading === false && this.state.docType !== 'mp4' ?
                        <div>
                            {(this.state.horizontalPageCount === 1 && this.state.flip === 0 && this.state.bookPage > 1) ||
                                (this.state.horizontalPageCount === 0 && this.state.flip === 0 && this.state.bookPage > 2) ||
                                (this.state.horizontalPageCount === 1 && this.state.flip === 1 && this.state.bookPage < this.state.totalPage) ||
                                (this.state.horizontalPageCount === 0 && this.state.flip === 1 && this.state.bookPage < this.state.totalPage) ?
                                <span className="prev-btn page-btn" title="prev page" onClick={this.handleFlipPage.bind(this, 'previous')}>
                                    <PrevPageIcon />
                                </span> : null}
                            {(this.state.horizontalPageCount === 1 && this.state.flip === 0 && this.state.bookPage < this.state.totalPage) ||
                                (this.state.horizontalPageCount === 0 && this.state.flip === 0 && this.state.bookPage < this.state.totalPage) ||
                                (this.state.horizontalPageCount === 1 && this.state.flip === 1 && this.state.bookPage > 1) ||
                                (this.state.horizontalPageCount === 0 && this.state.flip === 1 && this.state.bookPage > 2) ?
                                <span className="next-btn page-btn" title="next page" onClick={this.handleFlipPage.bind(this, 'next')}>
                                    <NextPageIcon />
                                </span> : null}
                        </div> : null}
                    {this.state.docType === 'mp4' || this.state.pageLoading === true || this.state.editStatus === true ? null :
                        <div>
                            <div style={{ margin: '20px 0px' }}>
                                <Slider
                                    classes={{ container: classes.slider }}
                                    value={this.state.bookPage}
                                    aria-labelledby="label"
                                    onChange={this.handleChange}
                                    onDragEnd={this.handleChangeCommitted}
                                    max={parseInt(this.state.totalPage)}
                                    min={1}
                                />
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <span>page {this.state.bookPage}/{this.state.totalPage}</span>
                                <span style={{ marginLeft: 20 }}>{(Math.round(this.state.bookPage / this.state.totalPage * 10000) / 100.00) + "%"}</span>
                            </div>
                        </div>
                    }
                </div>
                {this.state.pageLoading === false ?
                    <div>
                        <Dialog onClose={this.handleCloseReadMsg} open={this.state.readMsg}>
                            <DialogContent>
                                {this.state.dictWord['msg_read_confirm']}
                            </DialogContent>
                            <DialogActions>
                                <Button onMouseUp={this.handleOpenReadMsg} color="primary">{this.state.dictWord['yes']}</Button>
                                <Button onClick={this.handleCloseReadMsg} color="primary" autoFocus>{this.state.dictWord['cancel']}</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    : null}
                {this.state.docType === 'pdf' && this.state.pdfAllFiles.length === this.state.printNum ?
                    <div id="pdfPrint">
                        {this.state.pdfAllFiles.map((item, index) => {
                            return <div id={"pdfPrintPage_" + index} key={index}>
                                <img src={item} alt="" style={{ height: '100%' }} />
                            </div>
                        })}
                    </div>
                    : null}

                {/* メモ */}
                <Dialog
                    open={this.state.noteStatus}
                >
                    <Toolbar style={{ display: 'flex' }}>
                        <ChevronLeft style={{ flex: 1, cursor: 'pointer' }} onClick={this.closeAddNote.bind(this)} />
                        <DialogTitle style={{ flex: 9, textAlign: 'center' }}>{dictWord.new_notes_insert}</DialogTitle>
                        <CloseIcon style={{ flex: 1, cursor: 'pointer' }} onClick={this.closeAddNote.bind(this)} />
                    </Toolbar>
                    <DialogContent>
                        <textarea style={{ resize: 'unset',fontFamily: 'none' }} rows={12} cols={53} onChange={this.handleInputChange.bind(this, 'noteText')} />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.handeSaveBookNote.bind(this)} style={{ background: this.state.styleSetting.buttonColor }} color="primary">
                            {dictWord.registration}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    onClose={() => { this.setState({ confirmOpen: false }) }}
                    open={this.state.confirmOpen}
                >
                    <DialogContent>
                        {dictWord.unsaved_line}
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.handeSaveBookNote.bind(this)} style={{ background: this.state.styleSetting.buttonColor }} color="primary">
                            {dictWord.yes}
                        </Button>
                        <Button variant="contained" onClick={() => {
                            let formData = this.state.formData;
                            formData['noteText'].value = '';
                            this.setState({ confirmOpen: false, noteStatus: false, formData })
                        }} style={{ background: this.state.styleSetting.buttonColor }} color="primary">
                            {dictWord.cancel}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    scroll="paper"
                    open={this.state.noteListStatus}
                >
                    <Toolbar style={{ display: 'flex' }}>
                        <AddIcon color="primary" style={{ flex: 1, cursor: 'pointer' }} onClick={() => { this.setState({ noteStatus: !this.state.noteStatus }) }} />
                        <DialogTitle style={{ flex: 9, textAlign: 'center' }}>{dictWord.notes_list}</DialogTitle>
                        <CloseIcon style={{ flex: 1, cursor: 'pointer' }} onClick={() => { this.setState({ noteListStatus: !this.state.noteListStatus }) }} />
                    </Toolbar>
                    <DialogContent style={{ width: '440px', height: '230px' }}>
                        {this.state.annotations.map((item, index) => {
                            let noteTextInfo = "";
                            if(item.hasOwnProperty('noteText') === true){
                                let textNum = item.noteText.indexOf('\n');
                                if(textNum >= 0){
                                    noteTextInfo =  item.noteText.substr(0, textNum)+"...";
                                }else{
                                    noteTextInfo = item.noteText;
                                }
                            }
                            return <div style={{ padding: '10px 0px' }} key={index}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{
                                        width: '80px',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        lineHeight: '28px',
                                        flex: 5,
                                        cursor: 'pointer'
                                    }} onClick={() => { this.setState({ noteInfo: item.noteText }) }}>{noteTextInfo}</div>
                                    <span onClick={() => { this.setState({ noteInfo: item.noteText }) }} style={{ fontSize: '12px', color: '#8a8686', flex: 2, lineHeight: '28px', textAlign: 'right', cursor: 'pointer' }}>{item.page}{this.state.dictWord.page}</span>
                                    <span style={{ flex: 1, textAlign: 'right', color: '#8a8686', cursor: 'pointer' }}><CloseIcon onClick={() => { this.setState({ noteKey: index, noteDelete: true }) }} style={{ width: '20px', height: '20px' }} /></span>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <span onClick={() => { this.setState({ noteInfo: item.noteText }) }} style={{ fontSize: '12px', color: '#8a8686', flex: 1, lineHeight: '28px', textAlign: 'left', cursor: 'pointer' }}>{dateFormat(new Date(item.time), 'yyyy/MM/dd hh:mm:ss')}</span>
                                    <span onClick={() => { this.setState({ noteInfo: item.noteText }) }} style={{ flex: 1, textAlign: 'right', color: '#8a8686', cursor: 'pointer' }}><ChevronRight /></span>
                                </div>
                                <Divider style={{ margin: '10px 0px', width: '100%' }} />
                            </div>;
                        })}

                    </DialogContent>
                </Dialog>
                <Dialog onClose={() => { this.setState({ noteInfo: null }) }} open={this.state.noteInfo ? true : false}>
                <Toolbar style={{ display: 'flex' }}>
                    <DialogTitle style={{flex: 9, textAlign: 'center' }}>{this.state.dictWord.notes_detail}</DialogTitle>
                    <CloseIcon style={{ flex: 1, cursor: 'pointer' }} onClick={() => { this.setState({ noteInfo: null }) }} />
                </Toolbar>
                    <DialogContent style={{ minWidth: '400px', minHeight: '200px' }}>
                        {this.state.noteInfo ? <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(noteInfo) }} /> : null}
                    </DialogContent>
                </Dialog>

                <Dialog onClose={() => { this.setState({ noteDelete: false }) }} open={this.state.noteDelete ? true : false}>
                    <DialogContent>{this.state.dictWord['delete_it']}</DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleNoteDelete.bind(this)} color="primary">{this.state.dictWord.yes}</Button>
                        <Button onClick={() => { this.setState({ noteDelete: false }) }} color="primary">{this.state.dictWord.cancel}</Button>
                    </DialogActions>
                </Dialog>
                {/* control search button */}
                <Dialog scroll="paper" onEntering={() => { $("#searchText").focus() }} onClose={this.handleCloseSearch} open={this.state.searchStatus}>
                    <SearchDialogTitle onClose={this.handleCloseSearch}>{dictWord.search}</SearchDialogTitle>
                    <DialogContent>
                        <Paper className={classes.searchBooks}>
                            <img src={SearchOutlined} alt="SearchOutlined" className="iconBig" style={{marginTop: '6px'}} />
                            <Divider className={classes.searchDivider} />
                            <InputBase
                                className={classes.searchInput}
                                placeholder={dictWord.search_word_enter}
                                onChange={this.handleInputChange.bind(this, 'searchText')}
                                id="searchText"
                                value={this.state.formData.searchText.value}
                                onKeyUp={this.onKeyPress.bind(this)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        {this.state.formData.searchText.value ?
                                            <CloseIcon
                                                color="disabled"
                                                style={{ cursor: 'pointer', width: '18px', height: '18px' }}
                                                onClick={(e) => {
                                                    let formData = this.state.formData;
                                                    formData['searchText'].value = '';
                                                    this.setState({ formData ,searchResult:[],totlePage:0})
                                                }}
                                            /> : ''}
                                    </InputAdornment>
                                }
                            />
                        </Paper>
                        <div className="search-list">
                            {this.state.searchResult.map((item, index) => (
                                <div className="search-item" key={index} onClick={this.handleTurningPage.bind(this, item.pageNumber)}>
                                    <div>
                                        <div style={{
                                            width: '400px',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            // textOverflow: 'ellipsis'
                                        }}
                                            dangerouslySetInnerHTML={{ __html: item.text }}
                                        />
                                        <div style={{ fontSize: '12px', color: '#8a8686' }}>{this.state.dictWord.page} {item.pageNumber}</div>
                                    </div>
                                    <ChevronRight />
                                </div>
                            ))}
                            {totlePage > this.state.contentPageNumber ?
                                <div style={{ textAlign: 'center', padding: '10px 0px 20px', color: '#2196f3', cursor: 'pointer' }} onClick={this.nextTotlePage.bind(this)}>{this.state.dictWord.load_more}
                                </div> : null}
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Button variant="contained" style={{ background: this.state.styleSetting.buttonColor }} onClick={this.handleSearch} color="primary">
                            {dictWord.search}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* control comment button */}
                <ChatBox
                    fatherFn={this.fatherFn}
                    chatboxStatus={this.state.chatboxOpen}
                    commentMessage={this.state.commentMessage}
                    messageObj={this.state.messageObj}
                    messageList={this.state.comments}
                    onSendMessage={this.sendMessage}
                    receiveHandle={this.receiveHandle}
                    loadMessage={dictWord.show_history}
                    onLoadMessage={this.handleLoadMessage}
                />

                {/* control star review button */}
                <Dialog onClose={this.handleCloseStarReview} open={this.state.starReviewStatus}>
                    <ReviewDialogTitle style={{ padding: 0 }} onClose={this.handleCloseStarReview} />
                    <div className="star-wrap">
                        <Star star={this.state.score} enableClick getScores={(score) => this.getScores(score)} />
                    </div>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button variant="contained" style={{ background: this.state.styleSetting.buttonColor }} onClick={this.handleSubmitEvaluate} color="primary">
                            {dictWord.evaluation}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog onClose={this.handleOpenFontSize} open={this.state.fontMsgOpen}>
                    <DialogContent>
                        {this.state.dictWord['not_save_your_change']}
                    </DialogContent>
                    <DialogActions>
                        <Button onMouseUp={this.handleOpenFontSize} color="primary">{this.state.dictWord['yes']}</Button>
                        <Button onClick={this.handleFontMsgClose} color="primary" autoFocus>{this.state.dictWord['cancel']}</Button>
                    </DialogActions>
                </Dialog>

                <Dialog onClose={this.handlePrintMsgClose} open={this.state.printMsgOpen}>
                    <DialogContent>
                        <div className="pd10 text-center">{this.state.dictWord.print_range}</div>
                        <InputBase
                            id="startPage"
                            value={this.state.formData.startPage.value}
                            onChange={this.handleInputChange.bind(this, 'startPage')}
                            placeholder="startPage"
                            autoComplete="on"
                            type="number"
                            classes={{
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapSearch,
                            }}
                        />
                                    ~
                                <InputBase
                            id="endPage"
                            value={this.state.formData.endPage.value}
                            onChange={this.handleInputChange.bind(this, 'endPage')}
                            placeholder="endPage"
                            autoComplete="on"
                            type="number"
                            classes={{
                                root: classes.bootstrapRoot,
                                input: classes.bootstrapSearch,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onMouseUp={this.handlePrintMsgOk} color="primary">{this.state.dictWord['yes']}</Button>
                        <Button onClick={this.handlePrintMsgClose} color="primary" autoFocus>{this.state.dictWord['cancel']}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog onClose={this.handlePrintMsgErrClose} open={this.state.printMsgErr}>
                    <DialogContent>
                        {this.state.dictWord['msg_error_max_print_page'].replace('%s', this.state.printMax)}
                    </DialogContent>
                    <DialogActions>
                        <Button style={{ background: this.state.styleSetting.buttonColor }} onMouseUp={this.handlePrintMsgErrClose} color="primary">{this.state.dictWord['yes']}</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        bookListData: state.reducer.bookListData.bookList || [],
        searchText: state.reducer.bookListData.searchKeywords || '',
    };
}
export default withStyles(styles)(connect(mapStateToProps, null)(Read));