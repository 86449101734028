import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import Dialog from 'mdoc/utils/myDialog';

import { dictionary, myPushList, pushIgnore } from 'mdoc/axios';
import Menu from 'mdoc/components/userInfo/Menu';
import ChevronRight from '@material-ui/icons/ChevronRight';
import 'mdoc/components/userInfo/notify.less';


const styles = theme => ({
    content: {
        // flexGrow: 1,
        padding: theme.spacing.unit * 3,
        flex: 1
    },
    main: {
        width: '100%',
        display: 'flex'
    },
    leftmenu: {
        width: '240px'
    },
});

class PushList extends React.Component {
    state = {
        dictWord: {
            'notification': '',
            'save_information_list': '',
            'notice': '',
            'lang': '',
            'help': '',
            'password': '',
            'logoff': '',
            'push_notification_list': '',
            'load_more': ''
        },
        pushlist: [],
        pushInfo: [],
        dialogOpen: false,
        pageNumber: 1,
        pageSize: 10,
        pageTotal: 0,
        readTime: (new Date()).getTime(),
    }
    componentWillMount() {
        this.start(this.state);
    }

    start = (state) => {
        let dict = {}
        dictionary(state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            })
        })
        myPushList({
            sord: 'asc',
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize
        }).then(res => {
            this.setState({
                pushlist: res.docTblAlertMessage,
                pageTotal: res.pageTotal
            })
        }).catch(error => {
            this.setState({
                pushlist: []
            })
        })
    }

    handleLoadMore = () => {
        let page = this.state.pageNumber + 1;
        myPushList({
            sord: 'asc',
            pageNumber: page,
            pageSize: this.state.pageSize
        }).then(res => {
            if (res.docTblAlertMessage) {
                this.setState({
                    pushlist: this.state.pushlist.concat(res.docTblAlertMessage),
                    pageNumber: page
                })
            }
        });
    }

    dialogHandleClose = () => {
        this.setState({
            dialogOpen: false
        });
    }
    dialogPushInfo(push) {
        let me = this;
        if (push.alertFlg === 0) {
            pushIgnore({
                docTblAlertMessage: [{
                    messageId: push.messageId
                }]
            }).then(res => {
                if (push.bookId) {
                    let bookUrl = push.bookType === "epub" ? '/user/book/epubread/' + push.bookId : '/user/book/read/' + push.bookId;
                    me.props.history.push({
                        pathname: bookUrl,
                    });
                } else {
                    me.setState({
                        pushInfo: push,
                        dialogOpen: true
                    });
                }
            }).catch(error => {
            });
        } else {
            if (push.bookId) {
                let bookUrl = push.bookType === "epub" ? '/user/book/epubread/' + push.bookId : '/user/book/read/' + push.bookId;
                me.props.history.push({
                    pathname: bookUrl,
                });
                // checkupdate({
                //     bookId: push.bookId,
                //     checkDate: me.state.readTime,
                // }).then(res => {
                //     me.props.history.push({
                //         pathname: '/user/book/read/' + push.bookId,
                //     });
                // }).catch(error => {
                //     Toast.error(error);
                // })
            } else {
                me.setState({
                    pushInfo: push,
                    dialogOpen: true
                });
            }
        }
    }

    render() {
        const { classes } = this.props;
        const { dictWord, pushlist } = this.state;
        return (
            <div className={classes.main}>
                <Menu dictWord={dictWord} selectedkey="pushlist" />
                <div className={classes.content + ' right-con'}>
                    <h3>{dictWord.push_notification_list}</h3>
                    <ul className="information-ul">
                        {pushlist.map((item, index) => {
                            let textNum = item.messageContent.indexOf('\n');
                            let messageContentInfo = "";
                            if(textNum >= 0){
                                messageContentInfo =  item.messageContent.substr(0, textNum)+"...";;
                            }else{
                                messageContentInfo = item.messageContent;
                            }
                            return <li key={index} onClick={this.dialogPushInfo.bind(this, item)}>
                                <div>
                                    <p className="p-content">
                                        {item.alertFlg === 1 ?
                                            <span style={{ color: '#aaa' }}>{messageContentInfo}</span>
                                            :
                                            <span>{messageContentInfo}</span>
                                        }
                                        <IconButton className="li-btn">
                                            <ChevronRight />
                                        </IconButton>
                                    </p>
                                </div>
                                <p>
                                    <span className="date">{item.alertTimeStr}</span>
                                </p>
                            </li>
                        })}
                    </ul>
                    {this.state.pageTotal > this.state.pageNumber ?
                        <div style={{ textAlign: 'center', padding: '10px 0px 20px', color: '#2196f3', cursor: 'pointer' }} onClick={this.handleLoadMore}>{this.state.dictWord.load_more}
                        </div> : null
                    }
                </div>
                <Dialog
                    open={this.state.dialogOpen}
                    option={{
                        topClose: true
                    }}
                    title=" "
                    content={
                        <p style={{ width: 500, height: 500,whiteSpace: "pre-wrap"}}>
                            {this.state.pushInfo.messageContent}
                        </p>
                    }
                    handleOk={this.dialogHandleClose}
                    handleClose={this.dialogHandleClose}
                />
            </div>
        )
    };
}

PushList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PushList)