/**
 * Not Found page
 */
import React from 'react';
import img from 'mdoc/style/imgs/notFound.png';
import 'mdoc/components/pages/NotFound.less';


class NotFound extends React.Component {
    state = {
    };
    render() {
        return (
            <div id="main">
                <div className="center">
                    <a href="/#/"><img src={img} alt="404" /></a>
                </div>
            </div>
        )
    }
}

export default NotFound;