import React from 'react';
import PropTypes from 'prop-types';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { Divider, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import 'mdoc/components/book/chatbox.less';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import { dateFormat } from 'mdoc/utils';
import $ from 'jquery';

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography style={{ textAlign: 'center' }} className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

class ChatBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = ({
            open: false,
            scroll: 'paper',
        })
    }
    handleChange = (event) => {
        this.props.receiveHandle(event.target.value);
    }
    handleClose = () => {
        this.setState({ open: false });
        this.props.fatherFn(false);
    };
    

    setCursorPos(pTextArea, str) {
        if (document.selection) {
            var sel = document.selection.createRange();
            sel.text = str;
        } else if (typeof pTextArea.selectionStart === 'number' && typeof pTextArea.selectionEnd === 'number') {
            var startPos = pTextArea.selectionStart,
                endPos = pTextArea.selectionEnd,
                cursorPos = startPos,
                tmpStr = pTextArea.value;
            pTextArea.value = tmpStr.substring(0, startPos) + str + tmpStr.substring(endPos, tmpStr.length);
            cursorPos += str.length;
            pTextArea.selectionStart = pTextArea.selectionEnd = cursorPos;
        } else {
            pTextArea.value += str;
        }
        //scroll
        pTextArea.scrollTop = pTextArea.scrollHeight + 10;
    }
    onKeyPress(event) {
        let enter = event.keyCode;
        let ctrl = event.ctrlKey;
        let shift = event.shiftKey;
        let alt = event.altKey;
        if (enter === 13 && ctrl && !shift && !alt) {
            this.setCursorPos(document.getElementById('commentMessage'), '\n');
        }
        if (enter === 13 && !ctrl && shift && !alt) {
            // this.setCursorPos(document.getElementById('commentMessage'),'\n');
        }
        if (enter === 13 && !ctrl && !shift && alt) {
            this.setCursorPos(document.getElementById('commentMessage'), '\n');
        }
        if (enter === 13 && !ctrl && !shift && !alt) {
            event.preventDefault();
            this.props.onSendMessage(() => {
                let height = document.getElementById('customized-dialog-content') ? document.getElementById('customized-dialog-content').scrollHeight : 0;
                if (height > 0) {
                    document.getElementById('customized-dialog-content').scrollTop = height + 200;
                }
            })
        }
    }
    sendMessage() {
        this.props.onSendMessage(() => {
            let height = document.getElementById('customized-dialog-content') ? document.getElementById('customized-dialog-content').scrollHeight : 0;
            if (height > 0) {
                document.getElementById('customized-dialog-content').scrollTop = height + 62;
            }
        });
    }
    loadMore() {
        this.props.onLoadMessage((count) => {
            let height = document.getElementById('customized-dialog-content') ? document.getElementById('customized-dialog-content').scrollHeight : 0;
            if (height > 0) {
                document.getElementById('customized-dialog-content').scrollTop = height - 110 * (count);
            }
        });
    }
    render() {
        const user = JSON.parse(window.localStorage.getItem('readUser'));

        return (
            <div>
                <Dialog
                    maxWidth="lg"
                    open={this.props.chatboxStatus}
                    scroll={this.state.scroll}
                    aria-labelledby="customized-dialog-title"
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}><b>{this.props.messageObj && this.props.messageObj.messageBoxTitle}</b></DialogTitle>
                    <DialogContent id="customized-dialog-content">
                        {this.props.loadMessage && this.props.messageList.length > 0 ? <div style={{ textAlign: 'center', padding: '10px 0', color: '#9e9e9e', cursor: 'pointer' }} onClick={this.loadMore.bind(this)}>{this.props.loadMessage}</div> : null}
                        <div className="chatbox-con" style={{ padding: '30px 0' }}>
                            {
                                this.props.messageList.length > 0
                                    ?
                                    this.props.messageList.map((item, index) => {
                                        let className = "guest";
                                        if (user.userId === item.userId) {
                                            className = "me";
                                        }
                                        item.comment = decodeURI(item.comment);
                                        item.comment = item.comment.replace(/\n/g, "<br/>");
                                        item.comment = item.comment.replace(/\s/g, "&nbsp;");
                                        return [
                                            <div key={'dateTime' + index} className="date-time">{dateFormat(new Date(item.createDateStr), 'yyyy/MM/dd') === dateFormat(new Date(), 'yyyy/MM/dd') ? 'Today ' + dateFormat(new Date(item.createDateStr), 'hh:mm') : item.createDateStr}</div>,
                                            <div key={index} className={className}>
                                                <div className="avatar" style={{ background: '' }}>
                                                    <AccountCircleSharpIcon className="avatar-icon" color="primary" />
                                                    <span
                                                        title={user.userId === item.userId ? user.userName : item.docTblUser.name}
                                                    >
                                                        {user.userId === item.userId ? user.userName : item.docTblUser.name}</span>
                                                </div>
                                                <span dangerouslySetInnerHTML={{ __html: decodeURIComponent(item.comment) }} />
                                                <div className="arrow" />
                                            </div>
                                        ]
                                    })
                                    : null
                            }
                        </div>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <div className="input-con">
                            <textarea
                                placeholder={this.props.messageObj && this.props.messageObj.placeholder}
                                id="commentMessage"
                                className="input"
                                onChange={this.handleChange.bind(this)}
                                value={this.props.commentMessage ? this.props.commentMessage : ''}
                                onKeyUp={this.onKeyPress.bind(this)}
                            />
                            <IconButton onClick={this.sendMessage.bind(this)}>
                                <ArrowUpward />
                            </IconButton>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ChatBox.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(ChatBox);