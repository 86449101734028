/**
 *
 */
import * as type from './type';
import * as http from '../axios/index';

const requestData = category => ({
    type: type.REQUEST_DATA,
    category
});
export const receiveData = (data, category) => ({
    type: type.RECEIVE_DATA,
    data,
    category
});
/**
 * 请求数据调用方法
 * @param funcName      请求接口的函数名
 * @param params        请求接口的参数
 */
export const fetchDataReader = ({funcName, params, stateName}) => dispatch => {
    !stateName && (stateName = funcName);
    dispatch(requestData(stateName));
    return new Promise((resolve, reject) => {
        http[funcName](params).then(function(res){
        if(!res.error){
            let user = {
                token:res.token,
                userId:res.id,
                userCode:res.userCode,
                userName:res.name,
                clientId:res.clientId,
                langId:res.langId,
                initialPasswordFlg:res.initialPasswordFlg,
            };
            dispatch(receiveData({...user}, stateName))
        }else{
            reject(res.errorMessage);
        }
    }).catch(function(error){
        reject(error);
    });
})
};

export const setLeftMenuDispatch = (param) => dispatch => {
    dispatch({
        type: type.SET_STATUS,
        menuStatus: param
    });
}
export const getLeftMenuDispatch = (fn) => dispatch => {
    dispatch({
        type: type.GET_STATUS,
        fn
    });
}

//refreshing SearchBookList's data
export const getBookListStart = () => ({
    type: type.GET_BOOKSTART,
    isFetching: true
})
export const getBookListSuccess = (params,res,bookList) => ({
    type: type.GET_BOOKSUCCESS,
    isFetching:false,
    payload: { ...params},
    res,
    bookList,
})