import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types';
import {
    AppBar,
    Button,
    InputBase,
    InputLabel,
    Toolbar,
    Typography,
    FormControl,
    FormHelperText,
    Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import logo from 'mdoc/style/imgs/logo.png';
import { fetchDataReader, receiveData } from 'mdoc/action'
import { dictionary_before_login, readerChangePwd, getUserInfo, cnfsystem } from 'mdoc/axios'
import schema from 'async-validator';
import { messageFormat, getLang } from 'mdoc/utils';
import Toast from 'mdoc/utils/toast';
import CryptoJS from 'crypto-js';
import * as config from 'mdoc/axios/config';

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        }
    },
    appBar: {
        boxSizing: 'border-box',
        backgroundColor: '#F1F9FF',
        width: '100%',
        height: 63,
    },
    wrap: {
        minHeight: '100%'
    },
    main: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '60px',
        marginTop: '60px'
    },
    footer: {
        boxSizing: 'border-box',
        backgroundColor: '#F1F9FF',
        height: 63,
        marginTop: '-64px',
        zIndex: 9999,
    },
    logo: {
        width: 200,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    margin: {
        margin: `${theme.spacing.unit * 3}px 0`,
    },
    bootstrapRoot: {
        'label + &': {
            marginTop: theme.spacing.unit * 3,
        },
    },
    bootstrapInput: {
        borderRadius: 4,
        backgroundColor: '#F1F9FF',
        border: '2px solid #B6DDFD',
        fontSize: 16,
        padding: '10px 12px',
        minWidth: '300px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    bootstrapFormLabel: {
        color: '#2699FB',
        '&:hover': {
            color: '#2699FB',
        },
        fontSize: 16,
    },
    button: {
        margin: theme.spacing.unit
    },
});

class PasswordUp extends React.Component {
    state = {
        dictWord: {
            'current_password': '',
            'new_password': '',
            'new_password_confirmation': '',
            'change': '',
            'msg_error_not_null_with_item': '',
            'msg_error_confirm_new_password': '',
            'msg_password_updated': '',
            'msg_error_pw_reuse_history': '',
            characters_more: '',
            half_letter_number_symbols: '',
            password_different_before: '',
            copyright: '',
            about: '',
            privacypolicy: '',
            help: '',
        },
        formData: {
            current_password: {
                value: '',
                error: false,
                errorMsg: ''
            },
            new_password: {
                value: '',
                error: false,
                errorMsg: ''
            },
            new_password_confirmation: {
                value: '',
                error: false,
                errorMsg: ''
            },
            localeId: {
                value: getLang(),
                error: false,
                errorMsg: ''
            },
        },
        dialogOpen: false
    }
    componentWillMount() {
        this.start();
    }
    start = () => {
        let dict = {}
        dictionary_before_login({ localeId: this.state.formData.localeId.value }).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            });
            window.localStorage.setItem('localeId', this.state.formData.localeId.value);
        });
    }
    handleChange = (field) => event => {
        let value = event.target.value;
        let valueObj = Object.assign(this.state['formData'][field], { value: value });
        this.setState({
            formData: Object.assign(this.state['formData'], { [field]: valueObj })
        });
    };

    onLocationUrl = (link_key)=> event =>{
        cnfsystem(link_key).then(res =>{
            if(res)window.open(res);
        })
    }

    handleSubmit = () => {
        const descriptor = {
            current_password: [
                {
                    required: true,
                    message: messageFormat(this.state.dictWord.msg_error_not_null_with_item, this.state.dictWord.current_password)
                }
            ],
            new_password: [
                {
                    required: true,
                    message: messageFormat(this.state.dictWord.msg_error_not_null_with_item, this.state.dictWord.new_password)
                },
            ],
            new_password_confirmation: [
                {
                    required: true,
                    message: messageFormat(this.state.dictWord.msg_error_not_null_with_item, this.state.dictWord.new_password_confirmation)
                },
                {
                    message: this.state.dictWord.msg_error_confirm_new_password,
                    validator(rule, value, callback, source, options) {
                        if (value === source.new_password) {
                            callback();
                        } else {
                            callback(new Error(rule.message));
                        }
                    }
                }
            ]
        }
        const validator = new schema(descriptor);
        const { current_password, new_password, new_password_confirmation } = this.state.formData;
        if (new_password.value === current_password.value) {
            Toast.error(this.state.dictWord.msg_error_pw_reuse_history);
            return;
        }
        validator.validate({
            current_password: current_password.value,
            new_password: new_password.value,
            new_password_confirmation: new_password_confirmation.value,
        }, {
            firstFields: true
        }, (errors, fields) => {
            let formData = this.state.formData;
            for (let index in formData) {
                formData[index] = Object.assign(this.state['formData'][index], { errorMsg: '', error: false })
            }
            if (errors) {
                errors.forEach(item => {
                    formData[item.field] = Object.assign(this.state['formData'][item.field], {
                        errorMsg: item.message,
                        error: true
                    })
                });
                this.setState({
                    formData: formData
                });
            } else {
                this.setState({
                    formData: formData
                });

                let password = formData.current_password.value;
                let passwordEncode = CryptoJS.AES.encrypt(password, CryptoJS.enc.Utf8.parse(config.SECRET_KEY), {
                    mode: CryptoJS.mode.ECB,
                });
                passwordEncode = passwordEncode.ciphertext.toString();

                let newPassword = formData.new_password.value;
                let newPasswordEncode = CryptoJS.AES.encrypt(newPassword, CryptoJS.enc.Utf8.parse(config.SECRET_KEY), {
                    mode: CryptoJS.mode.ECB,
                });
                newPasswordEncode = newPasswordEncode.ciphertext.toString();
                let userInfo = getUserInfo();

                readerChangePwd({
                    userCode: userInfo.userCode,
                    password: passwordEncode,
                    newPassword: newPasswordEncode,
                }).then(res => {
                    userInfo.initialPasswordFlg = false;
                    window.localStorage.setItem('readUser', JSON.stringify(userInfo));
                    Toast.info(this.state.dictWord.msg_password_updated);
                    window.location.href = '#/user/book/library';
                }).catch(error => {
                    Toast.error(error);
                })
            }
        });
    }
    render() {
        const { classes } = this.props;
        const { dictWord, formData } = this.state;
        return [
            <div key="top" className={classes.wrap}>
                <AppBar position="static" color="default" className={classes.appBar}>
                    <Toolbar>
                        <img src={logo} alt="logo" className={classes.logo} />
                    </Toolbar>
                </AppBar>
                <div className={classes.main}>
                    <Typography variant="subtitle2" align="left" component="div">
                        <Grid container className={classes.margin}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="current_password" className={classes.bootstrapFormLabel}>
                                    {dictWord.current_password}
                                </InputLabel>
                                <InputBase
                                    id="current_password"
                                    value={formData.current_password.value}
                                    onChange={this.handleChange('current_password')}
                                    type="password"
                                    classes={{
                                        root: classes.bootstrapRoot,
                                        input: classes.bootstrapInput,
                                    }}
                                />
                                {formData.current_password.error ? <FormHelperText error={formData.current_password.error} id="current_password-error-text">{formData.current_password.errorMsg}</FormHelperText> : ''}
                            </FormControl>
                        </Grid>
                        <Grid container className={classes.margin}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="new_password" className={classes.bootstrapFormLabel}>
                                    {dictWord.new_password}
                                </InputLabel>
                                <InputBase
                                    id="new_password"
                                    value={formData.new_password.value}
                                    onChange={this.handleChange('new_password')}
                                    type="password"
                                    classes={{
                                        root: classes.bootstrapRoot,
                                        input: classes.bootstrapInput,
                                    }}
                                />
                                {formData.new_password.error ? <FormHelperText error={formData.new_password.error} id="new_password-error-text">{formData.new_password.errorMsg}</FormHelperText> : ''}
                            </FormControl>
                        </Grid>
                        <Grid container className={classes.margin}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="new_password_confirmation" className={classes.bootstrapFormLabel}>
                                    {dictWord.new_password_confirmation}
                                </InputLabel>
                                <InputBase
                                    id="new_password_confirmation"
                                    value={formData.new_password_confirmation.value}
                                    onChange={this.handleChange('new_password_confirmation')}
                                    type="password"
                                    classes={{
                                        root: classes.bootstrapRoot,
                                        input: classes.bootstrapInput,
                                    }}
                                />
                                {formData.new_password_confirmation.error ? <FormHelperText error={formData.new_password_confirmation.error} id="new_password_confirmation-error-text">{formData.new_password_confirmation.errorMsg}</FormHelperText> : ''}
                            </FormControl>
                        </Grid>
                        <Grid container className={classes.margin}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.handleSubmit}
                                style={{ margin: 'auto' }}
                            >
                                {dictWord.change}
                            </Button>
                        </Grid>
                    </Typography>
                    <div>
                        <p>• {dictWord.characters_more}</p>
                        <p>• {dictWord.half_letter_number_symbols}</p>
                        <p>• {dictWord.password_different_before}</p>
                    </div>
                </div>
            </div>,
            <Typography key="footer" className={classes.footer} component="div">
                <Typography variant="subtitle1" align="right" style={{ color: '#2699FB' }} component="div">
                    <Button onClick={this.onLocationUrl("about_link")} variant="text" size="small" color="secondary" className={classes.button}>
                        {dictWord.about}
                    </Button>
                    <Button onClick={this.onLocationUrl("privacypolicy_link")} variant="text" size="small" color="secondary" className={classes.button}>
                        {dictWord.privacypolicy}
                    </Button>
                    <Button onClick={this.onLocationUrl("help_admin_link")} variant="text" size="small" color="secondary" className={classes.button}>
                        {dictWord.help}
                    </Button>
                </Typography>
                <Typography variant="body1" align="center" style={{ color: '#2699FB' }} gutterBottom={false}>
                    {dictWord.copyright}
                </Typography>
            </Typography>
        ];
    }
}

PasswordUp.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToPorps = state => {
    const { auth } = state.reducer.httpData
    return { auth }
}
const mapDispatchToProps = dispatch => ({
    fetchData: bindActionCreators(fetchDataReader, dispatch),
    receiveData: bindActionCreators(receiveData, dispatch)
})
export default withStyles(styles)(connect(mapStateToPorps, mapDispatchToProps)(PasswordUp));