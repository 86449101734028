import React from 'react';
import { Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchOutlined from 'mdoc/style/imgs/search@2x.png';
import CloseIcon from '@material-ui/icons/Close';
import 'mdoc/components/book/editmycollection.less';
import { dictionary, collectionContents, getCoverPath, collectionAdd, getUserInfo } from 'mdoc/axios';
import Toast from 'mdoc/utils/toast';
import OneItem from 'mdoc/components/book/OneItem';


export const messageFormat = (msg, field, search = '%s') => {
    return msg.replace(search, field);
}
export class AddToCollection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dictWord: {
                'confirmation': '',
                'cancel': '',
                'add_record': '',
                'delete_record': '',
                'msg_confirm_really_delete': '',
                'add_to': '',
                'return': '',
            },
            bookList: [],
            searchKey: '',
            bookListFilter: null,
            deleteDialogOpen: false,
            collectionTitle: '',
            collectionId: 0,
            type: '',
            selBook: null,
            styleSetting: {}
        }
    }

    componentWillMount() {
        let _userInfo = getUserInfo();
        let styleSetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/styleSetting")) || {};
        this.setState({
            styleSetting
        });
        let dict = {}
        dictionary(this.state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            })
        })
        if (this.props.location.query !== undefined) {
            let title = this.props.location.query.collenctionTitle;
            let collectionId = this.props.location.query.collectionId;
            let type = this.props.location.query.type;
            this.setState({
                collectionTitle: title,
                collectionId: collectionId,
                type: type,
            });
            collectionContents({}).then(res => {

                let docTblBookList = res['docTblBookList'];

                for (let index in docTblBookList) {
                    let item = docTblBookList[index];
                    let coverUuid = item['docTblBookDetail']['coverUuid'];
                    let fileUuid = item['docTblBookDetail']['fileUuid'];
                    let categoryId = item['categoryId'];
                    getCoverPath(categoryId, fileUuid, coverUuid, item['docType']).then(imgRes => {
                        docTblBookList[index]['imgSrc'] = imgRes;
                        this.setState({
                            bookList: res.docTblBookList
                        })
                    }).catch(error => {
                        docTblBookList[index]['imgSrc'] = "";
                        this.setState({
                            bookList: res.docTblBookList
                        })
                    });
                }
            }).catch(error => {
                Toast.info(error)
            })
        } else {
            this.props.history.replace({
                pathname: '/user/book/mycollection',
            })
        }
    }
    faterFn = (arr) => {
        this.setState({
            selBook: arr
        });
    }
    handleReturn = event => {
        if (this.state.collectionId > 0) {
            this.props.history.push('/user/book/editmycollection/' + this.state.collectionId);
        } else {
            this.props.history.push('/user/book/mycollection/');
        }
    }
    handleAdd = () => {
        let book = this.state.selBook;
        if (book) {
            let data = {
                collectionName: '',
                collectionId: 0,
                docTblBookCollectionDetailList: []
            }
            if (this.state.type === 'edit' && this.state.collectionId > 0) {
                data.collectionId = this.state.collectionId;
            } else if (this.state.type === 'add' && this.state.collectionTitle.length > 0) {
                data.collectionName = this.state.collectionTitle;
            }
            for (let key in book) {
                let value = book[key];
                data.docTblBookCollectionDetailList.push({
                    docTblBookCollectionDetailPK: {
                        bookId: value
                    }
                })
            }
            collectionAdd(data).then(res => {
                if (this.state.type === 'edit') {
                    this.props.history.replace({
                        pathname: '/user/book/editmycollection/' + this.state.collectionId,
                    })
                } else if (this.state.type === 'add') {
                    this.props.history.replace({
                        pathname: '/user/book/mycollection',
                    })
                }
            }).catch(error => {
                Toast.error(error.replace('%s', this.state.collectionTitle));
            })
        }
    }

    handleSearch(event) {
        let bookList = this.state.bookList;
        let keyWord = event.target.value;
        var reg = new RegExp(keyWord);
        let bookListFilter = bookList.filter((item) => {
            let title = item.title;
            if (title.match(reg)) return item;
            return false;
        });

        this.setState({
            searchKey: keyWord,
            bookListFilter
        })
    }

    render() {
        // const { bookList, dictWord } = this.state;
        return (
            <div className="edit-mycollection">
                <h2 className="dom-h2" style={{ background: this.state.styleSetting.bodyBackColor?this.state.styleSetting.bodyBackColor:'#fff' }}>
                    <span className="h2-title">{messageFormat(this.state.dictWord.add_to, this.state.collectionTitle)}</span>
                    <div className="search-option">
                        <Paper className="search-box">
                            <div className="search-icon">
                                <img src={SearchOutlined} style={{ width: '20px', height: '20px', marginTop: '6px' }} alt="SearchOutlined" />
                            </div>
                            <InputBase
                                className="search-input"
                                onChange={this.handleSearch.bind(this)}
                                onKeyUp={this.handleSearch.bind(this)}
                                value={this.state.searchKey}
                                autoFocus
                                endAdornment={
                                    <InputAdornment position="end">
                                        {this.state.searchKey.length ?
                                            <CloseIcon
                                                color="disabled"
                                                style={{ cursor: 'pointer', width: '18px', height: '18px', marginRight: '5px' }}
                                                onClick={(e) => {
                                                    this.setState({ bookListFilter: null, searchKey: '' })
                                                }}
                                            />
                                            : ''}
                                    </InputAdornment>
                                }
                            />
                        </Paper>
                        <div className="search-button">
                            <Button onClick={this.handleReturn} variant="contained" style={{ margin: '0px 10px', background: this.state.styleSetting.buttonColor,height:'33px' }} color="primary">{this.state.dictWord.return}</Button>
                            <Button onClick={this.handleAdd} variant="contained" style={{ background: this.state.styleSetting.buttonColor,height:'33px' }} color="primary">{this.state.dictWord.add_record}</Button>
                        </div>
                    </div>
                </h2>
                {/* <Divider /> */}
                {/* <div className="edit-row"> */}
                    <OneItem fatherData={this.state.bookListFilter ? this.state.bookListFilter : this.state.bookList} fatherFn={this.faterFn} fatherType="ADD" />
                {/* </div> */}
            </div>
        )
    }
}
export default AddToCollection;
