import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import 'mdoc/components/book/editmycollection.less';
import Button from '@material-ui/core/Button';
import { dictionary, collectionDetail, getCoverPath, collectionDelete, getUserInfo } from 'mdoc/axios';
import Toast from 'mdoc/utils/toast';
import Dialog from 'mdoc/utils/myDialog';
import OneItem from 'mdoc/components/book/OneItem';



export class EditMyCollection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dictWord: {
                'confirmation': '',
                'cancel': '',
                'delete_record': '',
                'add_record': '',
                'msg_confirm_really_delete': '',
                'move': '',
                'select_record': '',
                'return': '',
            },
            bookList: [],
            collectionName: '',
            deleteDialogOpen: false,
            moveDialogOpen: false,
            collectionId: null,
            selBook: [],
            selectedDisabled: true,
            styleSetting: {}
        }
    }

    componentWillMount() {
        let _userInfo = getUserInfo();
        let styleSetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/styleSetting")) || {};
        this.setState({
            styleSetting: styleSetting
        });
        let dict = {}
        dictionary(this.state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            })
        })

        if (this.props.match.params.collectionId !== undefined) {
            let collectionId = this.props.match.params.collectionId;
            this.setState({
                collectionId: collectionId
            });
            collectionDetail(collectionId).then(res => {
                let docTblBookList = res['docTblBookList'];

                for (let index in docTblBookList) {
                    let item = docTblBookList[index];
                    let coverUuid = item['coverPath'];
                    let fileUuid = item['docTblBookDetail']['fileUuid'];
                    let categoryId = item['categoryId'];
                    getCoverPath(categoryId, fileUuid, coverUuid, item['docType']).then(imgRes => {
                        docTblBookList[index]['imgSrc'] = imgRes;
                        this.setState({
                            bookList: res.docTblBookList,
                            collectionName: res.collectionName
                        })
                    }).catch(error => {
                        docTblBookList[index]['imgSrc'] = "";
                        this.setState({
                            bookList: res.docTblBookList
                        })
                    });
                }
            }).catch(error => {
                Toast.info(error)
            })
        } else {
            this.props.push('/user/book/mycollection');
        }
    }

    handleDelete = event => {
        let book = this.state.selBook;
        if (book.length > 0) {
            this.setState({
                deleteDialogOpen: true
            });
        }
    }

    handleMove = event => {
        let book = this.state.selBook;
        if (book.length > 0) {
            this.props.history.replace({
                pathname: '/user/book/mycollection',
                query: {
                    collectionId: this.state.collectionId,
                    bookid: this.state.selBook,
                }
            })
        }
    }
    handleSel = event => {
        let selectedDisabled = this.state.selectedDisabled;
        this.setState({
            selBook: [],
            selectedDisabled: !selectedDisabled
        });
    }

    handleReturn = event => {
        window.history.go(-1);
    }

    handleAdd = event => {
        this.props.history.replace({
            pathname: '/user/book/addtocollection',
            query: {
                collenctionTitle: this.state.collectionName,
                collectionId: this.state.collectionId,
                type: 'edit',
            }
        })
    }

    deleteBookDialogOk = () => {
        let book = this.state.selBook;
        if (book.length > 0) {
            let data = {
                collectionId: this.state.collectionId,
                docTblBookCollectionDetailList: []
            };
            for (let key in book) {
                let value = book[key];
                data.docTblBookCollectionDetailList.push({
                    docTblBookCollectionDetailPK: {
                        bookId: value
                    }
                })
            }
            collectionDelete(data).then(res => {
                let bookList = this.state.bookList;
                let newsBookList = [];
                for (let k in bookList) {
                    if (book.indexOf(bookList[k].id) <= -1) {
                        newsBookList.push(bookList[k]);
                    }
                }
                let selectedDisabled = this.state.selectedDisabled;
                this.setState({
                    deleteDialogOpen: false,
                    selBook: [],
                    bookList: newsBookList,
                    selectedDisabled: !selectedDisabled
                });
            }).catch(error => {
                Toast.info(error)
            })
        }
    }

    moveBookDialogOk = () => {
        this.props.history.replace({
            pathname: '/user/book/mycollection',
            query: {
                collectionId: this.state.collectionId,
                bookid: this.state.selBook,
            }
        })
    }
    deleteBookDialogClose = () => {
        this.setState({
            deleteDialogOpen: false,
        });
    }
    moveBookDialogClose = () => {
        this.setState({
            moveDialogOpen: false,
            selBook: [],
        });
    }

    faterFn = (arr) => {
        this.setState({
            selBook: arr
        });
    }
    jump(item) {
        if (item.docType === 'link') {
            window.open(item.docLink);
        } else if (item.docType === 'epub') {
            window.location.href = '#/user/book/epubread/' + item.id;
        } else {
            window.location.href = '#/user/book/read/' + item.id;
        }
    }
    render() {
        const { bookList, dictWord } = this.state;
        return (
            <div className="edit-mycollection">
                <h2 className="dom-h2" style={{ background: this.state.styleSetting.bodyBackColor?this.state.styleSetting.bodyBackColor:'#fff' }}>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12} md={8} className="h2-title">{this.state.collectionName}</Grid>
                        <Grid item xs={12} md={4} style={{ textAlign: 'right' }}>
                            <Button style={{ background: this.state.styleSetting.buttonColor, marginRight: '20px' }} onClick={this.handleReturn} variant="contained" color="primary">{dictWord.return}</Button>
                            <Button style={{ background: this.state.styleSetting.buttonColor, marginRight: '20px' }} onClick={this.handleSel} variant="contained" color="primary">{this.state.selectedDisabled ? dictWord.select_record : dictWord.cancel}</Button>
                            <Button
                                onClick={this.handleMove} variant="contained" color="primary"
                                disabled={this.state.selectedDisabled ? true : false}
                                style={this.state.selectedDisabled ? { marginRight: '20px' } : { background: this.state.styleSetting.buttonColor, marginRight: '20px' }}
                            >{dictWord.move}</Button>
                            <Button
                                onClick={this.handleAdd}
                                variant="contained" color="primary"
                                disabled={this.state.selectedDisabled ? false : true}
                                style={this.state.selectedDisabled ? { background: this.state.styleSetting.buttonColor, marginRight: '20px' } : { marginRight: '20px' }}
                            >{dictWord.add_record}</Button>
                            <Button onClick={this.handleDelete} variant="contained" color="primary"
                                disabled={this.state.selectedDisabled ? true : false}
                                style={this.state.selectedDisabled ? { marginRight: '20px' } : { background: this.state.styleSetting.buttonColor, marginRight: '20px' }}
                            >{dictWord.delete_record}</Button>
                        </Grid>
                    </Grid>
                </h2>
                {/* <Divider /> */}
                {/* <div className="edit-row"> */}
                    <OneItem fatherData={bookList} fatherFn={this.faterFn} fatherType="ADD" selectedDisabled={this.state.selectedDisabled} jump={this.jump} />
                {/* </div> */}
                <Dialog
                    open={this.state.deleteDialogOpen}
                    option={{
                        ok: {
                            text: dictWord.delete_record
                        },
                        close: {
                            text: dictWord.cancel
                        },
                        topClose: false,
                    }}
                    // title={dictWord.confirmation}
                    content={<span>{dictWord.msg_confirm_really_delete}</span>}
                    handleOk={this.deleteBookDialogOk}
                    handleClose={this.deleteBookDialogClose}
                />
            </div>
        )
    }
}
export default EditMyCollection;
