import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { } from '@material-ui/core';

import { dictionary } from 'mdoc/axios/index'
import Menu from 'mdoc/components/userInfo/Menu';
import 'mdoc/components/userInfo/notify.less';


const styles = theme => ({
    main: {
        width: '100%',
        display: 'flex'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
});

class Help extends React.Component {
    state = {
        dictWord: {
            'notification': '',
            'save_information_list': '',
            'notice': '',
            'lang': '',
            'help': '',
            'password': '',
            'logoff': '',
            'push_notification_list': '',
        },
    }

    componentWillMount() {
        this.start(this.state);
    }

    start = (state) => {
        let dict = {}
        dictionary(state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            })
        })
    }

    render() {
        const { classes } = this.props;
        const { dictWord } = this.state;
        return (
            <div className={classes.main}>
                <div className={classes.appBarSpacer} />
                <Menu dictWord={dictWord} selectedkey="help" />
                <div className={classes.content + ' right-con help'}>
                    <h3>{dictWord.help}</h3>
                </div>
            </div>
        )
    };
}

Help.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Help);