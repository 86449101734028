import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import {
    IconButton,
    Grid,
    SvgIcon
} from '@material-ui/core';

import Star from 'mdoc/utils/Star';
import Dialog from 'mdoc/utils/myDialog';
import $ from 'jquery';
import leave_comments from 'mdoc/style/imgs/leave_comments.png';


import Toast from 'mdoc/utils/toast';
import { replacepos, heightLight, escapeHtml, searchMatching } from 'mdoc/utils/index';
import { dictionary, commentList, commentAdd, getUserInfo } from 'mdoc/axios/index'
// import Message from '@material-ui/icons/Message';
import 'mdoc/components/book/book.less';

import { bookSearch, getCoverPath, bodySearch } from 'mdoc/axios';

import Apps from '@material-ui/icons/Apps';
import ChevronRight from '@material-ui/icons/ChevronRight';
import localforage from 'localforage';
import ChatBox from 'mdoc/components/book/ChatBox';

const FormatListCheckbox = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M21,19V17H8V19H21M21,13V11H8V13H21M8,7H21V5H8V7M4,5V7H6V5H4M3,5A1,1 0 0,1 4,4H6A1,1 0 0,1 7,5V7A1,1 0 0,1 6,8H4A1,1 0 0,1 3,7V5M4,11V13H6V11H4M3,11A1,1 0 0,1 4,10H6A1,1 0 0,1 7,11V13A1,1 0 0,1 6,14H4A1,1 0 0,1 3,13V11M4,17V19H6V17H4M3,17A1,1 0 0,1 4,16H6A1,1 0 0,1 7,17V19A1,1 0 0,1 6,20H4A1,1 0 0,1 3,19V17Z" />
            {/* <path fill="#none" d="M15.5,14H14.71L14.43,13.73C15.41,12.59 16,11.11 16,9.5A6.5,6.5 0 0,0 9.5,3A6.5,6.5 0 0,0 3,9.5A6.5,6.5 0 0,0 9.5,16C11.11,16 12.59,15.41 13.73,14.43L14,14.71V15.5L19,20.5L20.5,19L15.5,14M9.5,14C7,14 5,12 5,9.5C5,7 7,5 9.5,5C12,5 14,7 14,9.5C14,12 12,14 9.5,14M7,9H12V10H7V9Z" /> */}
        </SvgIcon>
    );
}
const Message = (props) => {
    return (
        <img src={leave_comments} {...props} alt="" style={{ width: '40px', height: '40px' }} />
    );
}

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    main: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
        paddingLeft: theme.spacing.unit * 3,
    },
    grow: {
        position: 'relative',
        zIndex: 2
    },
    margin: {
        marginLeft: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    button: {
        padding: '6px 0px'
    },
    menuTitle: {
        padding: '6px 0px'
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    rightIcon: {
        marginLeft: theme.spacing.unit,
    },
    title: {
        marginTop: 10,
        textAlign: 'left',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        lineClamp: 2,
        WebkitBoxOrient: 'vertical'
    },
    img: {
        minWidth: '200',
        width: '90%',
        height: '272px'
    },
    noWrap: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    }
});

class SearchBookList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            direction: 'row',
            dictWord: {
                'search': '',
                'my_collection': '',
                'library': '',
                'category': '',
                'recent_documents': '',
                'look_all': '',
                'cover_image': '',
                'content_title': '',
                'content_evaluation_value': '',
                'category_name': '',
                'registration_date': '',
                'update_date': '',
                'name': '',
                'self_evaluation': '',
                'evaluation_average': '',
                'read_count': '',
                'content_description': '',
                'published_user': '',
                'published_department': '',
                'price': '',
                'read': '',
                'comment': '',
                'msg_error_not_null_with_item': '',
                'load_more': '',
                page: ''
            },
            bookInfo: {},
            categoryInfo: {},
            //send to ChatBox
            messageObj: null,
            loading: false,
            dialogOpen: false,
            chatboxOpen: false,
            categoryOpen: false,
            comments: [],
            commentMessage: null,
            commentFlg: 0,
            styleSetting: {},
            bodyContent: {},
            searchResult: {},
            parsedWords: [],
            categoryList: [],
            sidx: 'createDate',
            sord: 'desc',
            pageTotal: 1,
            pageSize: 30,
            pageNumber: 1,
            bookList: [],
            searchText: '',
            totlePage: 1,
            contentPageNumber: 1,
            contentPageSize: 10,
            bookId: [],
            commentPage: 1,
            commentSize: 20,
        };
    }

    componentWillMount() {
        let me = this;
        me.start(me.state);
        let locationSearch = this.props.history.location.search;
        if (me.props.bookListData.length < 1 && locationSearch.length < 1) {
            localforage.getItem('bookListCache').then((bookList) => {
                if (bookList) {
                    me.setState({
                        bookList
                    }, () => {
                        me.loadCover(bookList);
                    });
                }
            });
            localforage.getItem('pageNumberCache').then((pageNumber) => {
                if (pageNumber) {
                    me.setState({
                        pageNumber
                    });
                }
            });
            localforage.getItem('pageTotalCache').then((pageTotal) => {
                if (pageTotal) {
                    me.setState({
                        pageTotal
                    });
                }
            });
            localforage.getItem('searchTextCache').then((searchText) => {
                if (searchText) {
                    me.setState({
                        searchText
                    });
                }
            });
            localforage.getItem('categoryCache').then((categoryList) => {
                if (categoryList) {
                    me.setState({
                        categoryList
                    });
                }
            });
        } else {
            if (locationSearch.length < 1) {
                me.setState({
                    bookList: me.props.bookListData,
                    categoryList: me.props.categoryList,
                    searchText: me.props.searchText,
                    pageTotal: me.props.pageTotal,
                }, () => {
                    me.loadCover(me.state.bookList);
                });
            }
        }

    }

    componentWillReceiveProps(nextProps) {
        let me = this;
        let bookList = me.state.bookList;
        let categoryArrProps = me.props.categoryList;
        let categoryArrNextProps = nextProps.categoryList;
        let categoryIdProps = [], categoryIdNextProps = [];
        for (let key in categoryArrProps) {
            categoryIdProps.push(categoryArrProps[key].categoryId);
        }
        for (let key in categoryArrNextProps) {
            categoryIdNextProps.push(categoryArrNextProps[key].categoryId);
        }
        let categoryIdPropsStr = (categoryIdProps.sort()).toString();
        let categoryIdNextPropsStr = (categoryIdNextProps.sort()).toString();
        let pageNumber = me.state.pageNumber;
        if (me.props.searchText !== nextProps.searchText || categoryIdPropsStr !== categoryIdNextPropsStr) {
            bookList = nextProps.bookListData;
            pageNumber = 1;
            let state = {
                bookList,
                searchText: nextProps.searchText
            };
            if (nextProps.pageTotal !== undefined) {
                state['pageTotal'] = nextProps.pageTotal;
            } else {
                state['pageTotal'] = 0;
            }
            me.setState({ ...state, pageNumber }, () => {
                me.loadCover(me.state.bookList);
            });
        } 
        else {
            // if (bookList.length < 1) {
            //     bookList = nextProps.bookListData;
            // } else {
            //     let bookIds = [];
            //     for (let key in bookList) {
            //         let value = bookList[key];
            //         bookIds.push(value.id);
            //     }
            //     for (let index in nextProps.bookListData) {
            //         let item = nextProps.bookListData[index]
            //         if (!bookIds.includes(item.id)) {
            //             bookList.push(item);
            //         }
            //     }
            // }
        }
        // let state = {
        //     bookList,
        //     searchText: nextProps.searchText
        // };
        // if (nextProps.pageTotal !== undefined) {
        //     state['pageTotal'] = nextProps.pageTotal;
        // } else {
        //     state['pageTotal'] = 0;
        // }
        // me.setState({ ...state, pageNumber }, () => {
        //     me.loadCover(me.state.bookList);
        // });
    }

    loadCover(bookList) {
        let me = this;
        for (let key in bookList) {
            let value = bookList[key];
            let coverUuid = value['docTblBookDetail']['coverUuid'];
            let fileUuid = value['docTblBookDetail']['fileUuid'];
            let categoryId = value['categoryId'];
            let docType = value['docType'];
            getCoverPath(categoryId, fileUuid, coverUuid, docType).then(imgRes => {
                bookList[key]['imgSrc'] = imgRes;
                me.setState({
                    bookList
                })
            }).catch(() => {
                bookList[key]['imgSrc'] = "";
                me.setState({
                    bookList
                })
            });
        }
    }
    start = (state) => {
        let _userInfo = getUserInfo();
        let styleSetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/styleSetting")) || {};
        let commentFlg = localStorage.getItem(_userInfo.clientId + '/' + _userInfo.userId + '/commentFlg') || 0;
        this.setState({
            commentFlg,
            styleSetting
        });
        let dict = {}
        dictionary(state.dictWord).then(res => {
            res.map(val => {
                dict[val.dictKey] = val.dictValue;
                return val;
            })
            this.setState({
                dictWord: dict,
            })
        })
    }

    dialogHandleClose = () => {
        this.setState({
            dialogOpen: false
        });
    }

    handleRecentlyBookInfo = (keyId) => event => {
        this.setState({
            contentPageNumber: 1
        }, () => {

            let bookInfo = this.state.bookList[keyId];
            let keyword = this.state.searchText;
            if (bookInfo.docType === 'link' && bookInfo.docLink.length > 0) {
                window.open(bookInfo.docLink, "_blank");
                return;
            }
            bodySearch({
                bookId: bookInfo.id,
                bodyContent: keyword,
                pageSize: this.state.contentPageSize,
                pageNum: this.state.contentPageNumber
            }).then((res) => {
                let totlePage = res.totlePage;
                let docTblBookBodyList = res['docTblBookBody'];
                if (docTblBookBodyList.length > 0) {
                    let searchPages = [];
                    let parsedWords = res.parsedWords;
                    for (let i in docTblBookBodyList) {
                        let _pageNum = docTblBookBodyList[i].pageNum;
                        let htmlText = docTblBookBodyList[i].bodyContent;
                        htmlText = escapeHtml(htmlText);
                        let firstIndex = -1;
                        for (let j in parsedWords) {
                            let _keyword = searchMatching(htmlText, parsedWords[j]);
                            if (_keyword.length <= 0) continue;
                            var reg = new RegExp(_keyword, "gi");
                            let temp = reg.exec(htmlText);
                            if (firstIndex < 0 || temp.index < firstIndex) {
                                firstIndex = temp.index
                            }
                        }
                        htmlText = htmlText.substring(firstIndex - 2, htmlText.length);
                        htmlText = htmlText.length > 30 ? htmlText.substring(0, 30) + '…' : htmlText;

                        for (let j in parsedWords) {
                            let _keyword = searchMatching(htmlText, parsedWords[j]);
                            if (_keyword.length <= 0) continue;
                            htmlText = heightLight(htmlText, _keyword);
                        }
                        let index = htmlText.indexOf('<span');
                        htmlText = replacepos(htmlText, index);
                        searchPages.push({
                            pageNumber: _pageNum,
                            text: htmlText
                        });
                    }
                    this.setState({
                        searchResult: searchPages,
                        totlePage,
                        bookId: bookInfo.id,
                        parsedWords: parsedWords
                    })
                } else {
                    if (bookInfo.introduction.length > 0) {
                        bookInfo.introduction = bookInfo.introduction.replace(/\n|\r|\r\n/g, '<br>').replace(/\s/g, " ");
                    }
                }
                if (docTblBookBodyList.length > 0) {
                    this.setState({
                        bookInfo: bookInfo,
                        dialogOpen: true
                    });
                } else {
                    this.setState({
                        bookInfo: bookInfo,
                        dialogOpen: false
                    }, () => {
                        this.bookPageInto();
                    });
                }
            });
        });
    }

    handleDirection = (direction) => event => {
        this.setState({
            direction: direction
        });
    }



    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return;
        }
        this.setState({ categoryOpen: false });
    };
    handleMessageBox = (value) => {
        value['messageBoxTitle'] = this.state.dictWord.comment;
        value['placeholder'] = this.state.dictWord.msg_error_not_null_with_item.replace('%s', this.state.dictWord.comment);
        commentList(value.id, {
            pageNumber: 1,
            pageSize: this.state.commentSize,
        }).then(res => {
            let commentList = res.docTblUserCommentList;
            commentList.sort(function (obj1, obj2) {
                return obj1.id - obj2.id;
            });
            this.setState({
                chatboxOpen: true,
                messageObj: value,
                comments: commentList
            })
            $('#customized-dialog-content').scrollTop($('#customized-dialog-content')[0].scrollHeight);
        }).catch(error => {
            this.setState({
                chatboxOpen: true,
                messageObj: value,
                comments: []
            })
        })
    }

    receiveHandle = (res) => {
        this.setState({
            commentMessage: res
        });
    }
    sendMessage = (func) => {
        let commentMessage = this.state.commentMessage.replace(/(^\s*)|(\s*$)/g, "");
        commentMessage = encodeURI(commentMessage);
        if (commentMessage.length > 0) {
            commentAdd({
                bookId: this.state.messageObj.id,
                comment: commentMessage
            }).then(res => {
                let comments = this.state.comments;
                comments.push(res);
                this.setState({
                    commentMessage: null,
                    comments: comments
                }, () => {
                    func();
                });
                $('#commentMessage').val('');
            }).catch(error => {
                Toast.error(error);
            })
        }
    }
    handleLoadMessage = (func) => {
        let page = this.state.commentPage + 1;
        commentList(this.state.messageObj.id, {
            pageNumber: page,
            pageSize: this.state.commentSize,
        }).then(res => {
            let commentList = res.docTblUserCommentList;
            commentList.sort(function (obj1, obj2) {
                return obj1.id - obj2.id;
            });
            let comments = commentList.concat(this.state.comments);
            this.setState({
                comments: comments,
                commentPage: page
            }, () => {
                func(this.state.comments.length);
            })
        }).catch(error => {
            Toast.error(error);
        })
    }
    fatherFn = (status) => {
        this.setState({
            chatboxOpen: status,
            commentPage: 1,
        })
    }
    bookPageInto = (page) => {
        let history = this.props.history;
        let bookInfo = this.state.bookInfo;
        let search = this.state.searchText;
        let parsedWords = this.state.parsedWords;
        bookInfo.docType === "epub" ?
            history.push({
                pathname: '/user/book/epubread/' + bookInfo.id,
                query: {
                    page,
                    search,
                    parsedWords
                }
            })
            :
            history.push({
                pathname: '/user/book/read/' + bookInfo.id,
                query: {
                    page,
                    search,
                    parsedWords
                }
            })

    }
    loadMore() {
        let me = this;
        bookSearch({
            searchInfo: me.state.searchText,
            categoryList: me.state.categoryList,
            pageSize: me.state.pageSize,
            pageNumber: me.state.pageNumber
        }).then(res => {
            let docTblBookList = res['docTblBookList'];
            if (docTblBookList.length < 1) {
                Toast.info(me.state.dictWord.msg_error_record_not_found);
            } else {
                let bookList = me.state.bookList;
                for (let index in docTblBookList) {
                    let item = docTblBookList[index];
                    bookList.push(item);
                }
                this.setState({
                    bookList
                }, () => {
                    me.loadCover(bookList);
                });
            }
        }).catch(() => {
            Toast.info(me.state.dictWord.msg_error_record_not_found);
        })
    }
    contentLoadMore() {
        // let keyId = this.state.bookId;
        let keyword = this.state.searchText;
        bodySearch({
            bookId: this.state.bookId,
            bodyContent: keyword,
            pageSize: this.state.contentPageSize,
            pageNum: this.state.contentPageNumber
        }).then((res) => {
            let docTblBookBodyList = res['docTblBookBody'];
            let searchPages = this.state.searchResult;
            let parsedWords = res.parsedWords;
            if (docTblBookBodyList.length > 0) {
                for (let i in docTblBookBodyList) {
                    let _pageNum = docTblBookBodyList[i].pageNum;
                    let htmlText = docTblBookBodyList[i].bodyContent;
                    htmlText = escapeHtml(htmlText);
                    let firstIndex = -1;
                    for (let j in parsedWords) {
                        let _keyword = searchMatching(htmlText, parsedWords[j]);
                        if (_keyword.length <= 0) continue;
                        var reg = new RegExp(_keyword, "gi");
                        let temp = reg.exec(htmlText);
                        if (firstIndex < 0 || temp.index < firstIndex) {
                            firstIndex = temp.index
                        }
                    }
                    htmlText = htmlText.substring(firstIndex - 2, htmlText.length);
                    htmlText = htmlText.length > 30 ? htmlText.substring(0, 30) + '…' : htmlText;

                    for (let j in parsedWords) {
                        let _keyword = searchMatching(htmlText, parsedWords[j]);
                        if (_keyword.length <= 0) continue;
                        htmlText = heightLight(htmlText, _keyword);
                    }
                    let index = htmlText.indexOf('<span');
                    htmlText = replacepos(htmlText, index);
                    searchPages.push({
                        pageNumber: _pageNum,
                        text: htmlText
                    });
                }
                this.setState({
                    searchResult: searchPages,
                    totlePage: res.totlePage,
                })
            }
        });
    }

    nextPage() {
        let pageNumber = this.state.pageNumber;
        pageNumber++;
        if (pageNumber > this.props.pageTotal) {
            return;
        }
        this.setState({
            pageNumber
        }, () => {
            this.loadMore()
        });
    }
    nextTotlePage() {
        let contentPageNumber = this.state.contentPageNumber;
        contentPageNumber++;
        if (contentPageNumber <= this.state.totlePage) {
            this.setState({
                contentPageNumber
            }, () => {
                this.contentLoadMore()
            });
        }
    }

    render() {
        const { classes } = this.props;
        const { pageTotal, totlePage } = this.state;
        const docTblBookBody = this.state.searchResult;
        return (
            <div className={classes.main}>
                <div className={classes.appBarSpacer} />
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.grow}
                >
                    <Grid item xs={4} md={10} />
                    <Grid item xs={8} md={2} style={{ textAlign: 'right' }}>
                        <IconButton onClick={this.handleDirection('column')}>
                            <FormatListCheckbox color={this.state.direction === 'column' ? 'primary' : 'inherit'} />
                        </IconButton>
                        <IconButton color={this.state.direction === 'row' ? 'primary' : 'inherit'} onClick={this.handleDirection('row')}>
                            <Apps />
                        </IconButton>
                    </Grid>
                </Grid>
                <div>
                    {
                        (this.state.direction === 'column')
                            ?
                            <div className="crossWrap" style={{ flexDirection: 'column' }}>
                                {this.state.bookList.map((value, index) => (
                                    <div key={index} className={"crossPage"}>
                                        <div className={"leftPart"}>
                                            <div className="imgCon">
                                                <img src={value.imgSrc} alt="" onClick={this.handleRecentlyBookInfo(index)} />
                                            </div>
                                            <div className="textCon">
                                                <div className={classes.title} title={value.title}>
                                                    {value.title}
                                                </div>
                                                <div className={'stars'}>
                                                    <Star starPercent={value.averageScore} enableClick={false} />
                                                </div>
                                                {value.bookIcon === 'UPDATE' ? <div className={'cross-updatebox'}><span>UPDATE</span><i className={'triangle'} /></div> : null}
                                                {value.bookIcon === 'NEW' ? <div className={'cross-newbox'}><span>NEW</span><i className={'triangle'} /></div> : null}
                                            </div>
                                        </div>
                                        <div className={"rightPart"} >
                                            <div className={'cross-message'}>
                                                {parseInt(this.state.commentFlg) === 0 ?
                                                    <Message color={'disabled'} onClick={this.handleMessageBox.bind(this, value)} />
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <div className="listWrap flex fullWidth wrap" style={{ flexDirection: 'row' }}>
                                {this.state.bookList.length > 0 ? this.state.bookList.map((value, index) => (
                                    //  md-per10 sm-per33 xs-per100
                                    <div key={index} className={"one-list"}>
                                        <div className="w150">
                                            <img src={value.imgSrc} onClick={this.handleRecentlyBookInfo(index)} alt="" />
                                            {value.bookIcon === 'UPDATE' ? <span className={'updatebox'}><span>UPDATE</span><i className={'triangle'} /></span> : null}
                                            {value.bookIcon === 'NEW' ? <span className={'newbox'}><span>NEW</span><i className={'triangle'} /></span> : null}
                                            <span className={'message'}>
                                                {parseInt(this.state.commentFlg) === 0 ?
                                                    <Message color={'disabled'} onClick={this.handleMessageBox.bind(this, value)} />
                                                    : null}
                                            </span>
                                        </div>
                                        <div style={{ width: '150px', whiteSpace: 'nowrap' }}>
                                            <div className={classes.title} title={value.title}>
                                                {value.title}
                                            </div>
                                            <div className={'stars'}>
                                                <Star starPercent={value.averageScore} enableClick={false} />
                                            </div>
                                        </div>
                                    </div>
                                )) : null}
                            </div>
                    }
                    {pageTotal > 0 && pageTotal > this.state.pageNumber ?
                        <div style={{ textAlign: 'center', padding: '10px 0px 20px', color: '#2196f3', cursor: 'pointer' }} onClick={this.nextPage.bind(this)}>{this.state.dictWord.load_more}
                        </div> : null}
                </div>
                <Dialog
                    open={this.state.dialogOpen}
                    option={{ topClose: true }}
                    title={this.state.bookInfo.title}
                    content={docTblBookBody.length > 0 ?
                        <div style={{ width: 500, height: 500 }}>
                            <div className="search-list">
                                {this.state.searchResult.map((item, index) => (
                                    <div className="search-item" key={index} onClick={this.bookPageInto.bind(this, item.pageNumber)}>
                                        <div>
                                            <div
                                                style={{
                                                    width: '400px',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    // textOverflow: 'ellipsis'
                                                }}
                                                dangerouslySetInnerHTML={{ __html: item.text }}
                                            />
                                            <div style={{ fontSize: '12px', color: '#8a8686' }}>{this.state.dictWord.page} {item.pageNumber}</div>
                                        </div>
                                        <ChevronRight />
                                    </div>
                                ))}
                                {totlePage > this.state.contentPageNumber ?
                                    <div style={{ textAlign: 'center', padding: '10px 0px 20px', color: '#2196f3', cursor: 'pointer' }} onClick={this.nextTotlePage.bind(this)}>{this.state.dictWord.load_more}
                                    </div> : null}
                            </div>
                        </div>
                        :
                        <div />
                    }
                    handleClose={this.dialogHandleClose}
                />
                <ChatBox
                    chatboxStatus={this.state.chatboxOpen}
                    messageObj={this.state.messageObj}
                    commentMessage={this.state.commentMessage}
                    fatherFn={this.fatherFn}
                    messageList={this.state.comments}
                    onSendMessage={this.sendMessage.bind(this)}
                    receiveHandle={this.receiveHandle}
                    loadMessage={this.state.dictWord.show_history}
                    onLoadMessage={this.handleLoadMessage.bind(this)}
                />
            </div>
        )
    }
}

SearchBookList.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
    return {
        categoryList: state.reducer.bookListData.categoryList || [],
        bookListData: state.reducer.bookListData.bookList || [],
        searchText: state.reducer.bookListData.searchKeywords || '',
        pageTotal: state.reducer.bookListData.pageTotal,
    };
}

export default withStyles(styles)(connect(mapStateToProps, null)(SearchBookList));