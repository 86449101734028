import { combineReducers } from 'redux';
import * as type from 'mdoc/action/type';

const handleData = (state = {isFetching: true, data: {}}, action) => {
    switch (action.type) {
        case type.REQUEST_DATA:
            return {...state, isFetching: true};
        case type.RECEIVE_DATA:
            return {...state, isFetching: false, data: action.data};
        default:
            return {...state};
    }
};
const httpData = (state = {}, action) => {
    switch (action.type) {
        case type.RECEIVE_DATA:
        case type.REQUEST_DATA:
            return {
                ...state,
                [action.category]: handleData(state[action.category], action)
            };
        default:
            return {...state};
    }
};

const menuStatus = (state,action) => {
    switch(action.type){
        case type.GET_STATUS:
            action.fn({menuStatus:true,...state});
            return { menuStatus:true };
        case type.SET_STATUS:
            return {...state,menuStatus:action.menuStatus};
        default: 
            return {...state};
    }
}

//refreshing SearchBookList's data
const bookListData =(state,action) => {
    switch(action.type){            
        case type.GET_BOOKSUCCESS:    
            let pageNumber = action.res.pageNumber;
            let pageTotal = action.res.pageTotal;
            return { 
                ...state, 
                bookList:action.bookList, 
                pageNumber,
                pageTotal,
                searchKeywords:action.payload.searchKeyword,
                categoryList:action.payload.categoryList 
            };
        default: 
            return { ...state };
    }
}


export const reducer = combineReducers({
    httpData,menuStatus,bookListData
});


// export const readReducer = combineReducers(
//     {
//         httpData,menuStatus,bookListData
//     });
  