import React from 'react';
import { connect } from 'react-redux'
import { getBookListSuccess } from 'mdoc/action/index'
import {
    Button, IconButton, InputBase, SvgIcon, Collapse,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormGroup,
} from '@material-ui/core';
// import HighlightOff from '@material-ui/icons/HighlightOff';
import CloseIcon from '@material-ui/icons/Close';
import SearchOutlined from 'mdoc/style/imgs/search.svg';
import category_green from 'mdoc/style/imgs/category_green.png';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import classNames from 'classnames';
import { dictionary } from 'mdoc/axios';

import { bookSearch } from 'mdoc/axios';
import Toast from 'mdoc/utils/toast';
import eventProxy from 'mdoc/utils/eventProxy'
import localforage from 'localforage';
import $ from 'jquery';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';

import {
    getCategoryList,
} from 'mdoc/axios/library'


const styles = theme => ({
    searchBox: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '300px',
    },
    button: {
        margin: theme.spacing.unit,
        textTransform: 'none'
    },
    leftIcon: {
        width: '42px',
        height: '42px',
        marginRight: theme.spacing.unit,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    bootstrapInput: {
        borderBottom: '1px solid'
    },
})
function FolderPlusOutline(props) {
    return (
        <img src={category_green} alt="category_green" style={{width:'30px',height:'30px'}} />
    );
}
function Plus(props) {
    return (
        <SvgIcon {...props}>
            <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
        </SvgIcon>
    );
}

function Minus(props) {
    return (
        <SvgIcon {...props}>
            <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
        </SvgIcon>
    );
}
export class SearchBtn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dictWord: {
                'search': '',
                'msg_error_record_not_found': '',
                category: '',
                choose_all: '',
                cancel_all: ''
            },
            //BTN=button status   INPUT=input status
            componentStatus: 'BTN',
            bookList: [],
            bookListData: {},
            //input's value
            searchText: '',
            pageNumber: 1,
            pageSize: 30,
            pageTotal: 1,
            searchResult: {},
            categoryOpen: false,
            categories: [],
            categoryCheckboxValue: [],
            categoryIdsAll: [],
            categoryShow: [],
            cateSelAll: true,
            loading:false
        }
    }
    componentWillMount() {
        let locationSearch = this.props.history.location.search;
        if(locationSearch.length > 0){
            let match = locationSearch.substr(1);
            let matchs = match.split("=");
            let searchInfo = '';
            if(matchs[0] === "searchInfo" && matchs[1].length > 0){
                searchInfo = decodeURI(matchs[1]);
            }
            if(searchInfo.length > 0){
                localforage.removeItem('bookListCache');
                localforage.removeItem('categoryCache');
                localforage.removeItem('searchTextCache');
                localforage.removeItem('pageNumberCache');
                localforage.removeItem('pageTotalCache');
                this.setState({
                    searchText: searchInfo,
                    componentStatus:'INPUT'
                }, () => {
                    this.bookList(searchInfo);
                });
            }
        }else{
            eventProxy.on('pagination', currentPage => {
                this.bookList(this.state.searchText);
            })
        }
        localforage.getItem('searchTextCache').then((searchText) => {
            if (searchText) {
                this.setState({
                    searchText
                });
            }
        });
        localforage.getItem('categoryCache').then((categoryList) => {
            if (categoryList) {
                let categoryCheckboxValue = [];
                for (let key in categoryList) {
                    categoryCheckboxValue.push(categoryList[key].categoryId);
                }
                this.setState({
                    categoryCheckboxValue
                });
            }
        });
    }
    componentDidMount() {
        this.start(this.state);
    }
    start = (state) => {
        let dict = {};
        dictionary(state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            })
        })

        getCategoryList({}).then((data) => {
            if (data.results.length > 0) {
                let categoryShow = this.treeArray(data.results);
                let categoryIdsAll = [];
                for (let i in data.results) {
                    categoryIdsAll.push(data.results[i].categoryId.toString());
                }
                this.setState({
                    categoryIdsAll,
                    categoryShow,
                })
            }
        }).catch(error => {
            Toast.info(error)
        })
    }
    handleSearch = (e, param) => {
        const { dispatch } = this.props;
        let me = this;
        e.stopPropagation();
        me.setState({
            componentStatus: param,
        }, () => {
            if (param === 'BTN') {
                me.setState({
                    bookList: [],
                    searchText: '',
                    categoryCheckboxValue: []
                }, () => {
                    localforage.removeItem('bookListCache');
                    localforage.removeItem('categoryCache');
                    localforage.removeItem('searchTextCache');
                    localforage.removeItem('pageNumberCache');
                    localforage.removeItem('pageTotalCache');
                    dispatch(getBookListSuccess(
                        {
                            searchKeyword: '',
                            categoryList: []
                        }, [], me.state.bookList
                    ));
                })
            }
        })
    }
    bookList(searchKeyword) {
        const { dispatch } = this.props;
        let me = this;
        let categoryList = [];
        searchKeyword = $.trim(searchKeyword) === 'undefined' ? '' : searchKeyword;
        if(me.state.loading){
            return;
        }
        me.setState({
            bookList: [],
            loading:true
        }, () => {
            localforage.removeItem('bookListCache');
            localforage.removeItem('categoryCache');
            localforage.removeItem('searchTextCache');
            localforage.removeItem('pageNumberCache');
            localforage.removeItem('pageTotalCache');

            for (let key in me.state.categoryCheckboxValue) {
                let item = me.state.categoryCheckboxValue[key];
                categoryList.push({
                    categoryId: item
                });
            }
            bookSearch({
                categoryList,
                searchInfo: searchKeyword,
                pageSize: this.state.pageSize,
                pageNumber: this.state.pageNumber
            }).then(res => {
                let docTblBookList = res['docTblBookList'];
                let bookList = [];
                if (docTblBookList.length < 1) {
                    Toast.info(me.state.dictWord.msg_error_record_not_found);
                } else {
                    for (let index in docTblBookList) {
                        let item = docTblBookList[index];
                        bookList.push(item);
                    }
                }
                localforage.setItem('bookListCache', bookList);
                localforage.setItem('categoryCache', categoryList);
                localforage.setItem('searchTextCache', searchKeyword);
                localforage.setItem('pageNumberCache', res.pageNumber);
                localforage.setItem('pageTotalCache', res.pageTotal);
                me.setState({
                    bookList,
                    loading:false
                }, () => {
                    dispatch(getBookListSuccess(
                        {
                            searchKeyword,
                            categoryList
                        }, res, bookList
                    ));
                    me.props.history.replace('/user/book/searchbooklist');
                })
            }).catch(() => {
                Toast.info(me.state.dictWord.msg_error_record_not_found);
            })
        });

    }

    handleEnterKey = (e) => {
        if (this.state.componentStatus === 'INPUT' && e.keyCode === 13) {
            this.bookList(this.state.searchText);
        }
    }
    handleFocus = (e) => {
        e.stopPropagation();
    }
    handleChange = e => {
        this.setState({
            searchText: e.target.value
        })
    }
    sortArray(id, oldData) {
        let newsArray = [];
        let j = 0;
        ParentArry(id);
        function ParentArry(id) {
            for (let i in oldData) {
                if (oldData[i].parentId === id) {
                    newsArray[j] = oldData[i];
                    j++;
                    ParentArry(oldData[i].categoryId, newsArray, j);
                }
            }
        }

        return newsArray;
    }

    treeArray(data) {
        let map = {};
        let val = [];
        let openFlag = {};
        data.forEach(it => {
            openFlag['top' + it.categoryId] = false;
            map[it.categoryId] = it;
        })
        data.forEach(it => {
            const parent = map[it.parentId];
            if (parent) {
                if (!Array.isArray(parent.children)) parent.children = [];
                parent.children.push(it);
            } else {
                val.push(it);
            }
        })
        this.setState({
            openFlag
        });
        return val;

    }
    handleClickCategory(id, event) {
        let openFlag = this.state.openFlag;
        openFlag['top' + id] = !openFlag['top' + id];
        this.setState({
            openFlag
        });
    }
    ListItemCategory = (data) => {
        return data.map((item, index) => {
            if (!item.children) {
                return (
                    <Collapse key={index} in={item.parentId === 0 ? true : this.state.openFlag['top' + item.parentId]} timeout="auto" unmountOnExit>
                        <List style={{ marginLeft: (5 * item.depth) + 'px', padding: '0' }} component="div" disablePadding>
                            <ListItem style={{ border: 'none', padding: '0' }}>
                                <ListItemIcon style={{ marginLeft: '23px' }}><span /></ListItemIcon>
                                <ListItemText>
                                    <FormControlLabel
                                        value={item.categoryId.toString()}
                                        control={<Checkbox color="primary" onChange={this.handleCategoryChange.bind(this)} />}
                                        label={item.categoryName}
                                        labelPlacement="start"
                                        checked={this.state.categoryCheckboxValue.indexOf(item.categoryId.toString()) >= 0}
                                    />
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Collapse>
                )
            } else {
                if (item.parentId !== 0) {
                    return (
                        <Collapse key={index} in={this.state.openFlag['top' + item.parentId]} timeout="auto" unmountOnExit>
                            <List key={index} style={{ marginLeft: (5 * item.depth) + 'px', padding: '0' }} component="div">
                                <ListItem style={{ border: 'none', padding: '0' }}>
                                    <ListItemIcon onClick={this.handleClickCategory.bind(this, item.categoryId)}>{this.state.openFlag['top' + item.categoryId] ? <Minus /> : <Plus />}</ListItemIcon>
                                    <ListItemText>
                                        <FormControlLabel
                                            value={item.categoryId.toString()}
                                            control={<Checkbox color="primary" onChange={this.handleCategoryChange.bind(this)} />}
                                            label={item.categoryName}
                                            labelPlacement="start"
                                            checked={this.state.categoryCheckboxValue.indexOf(item.categoryId.toString()) >= 0}
                                        />
                                    </ListItemText>
                                </ListItem>
                                {this.ListItemCategory(item.children)}
                            </List>
                        </Collapse>
                    )
                } else {
                    return (
                        <List key={index} style={{ marginLeft: (5 * item.depth) + 'px', padding: '0' }} component="nav">
                            <ListItem style={{ border: 'none', padding: '0' }}>
                                <ListItemIcon onClick={this.handleClickCategory.bind(this, item.categoryId)}>{this.state.openFlag['top' + item.categoryId] ? <Minus /> : <Plus />}</ListItemIcon>
                                <ListItemText>
                                    <FormControlLabel
                                        value={item.categoryId.toString()}
                                        control={<Checkbox color="primary" onChange={this.handleCategoryChange.bind(this)} />}
                                        label={item.categoryName}
                                        labelPlacement="start"
                                        checked={this.state.categoryCheckboxValue.indexOf(item.categoryId.toString()) >= 0}
                                    />
                                </ListItemText>
                            </ListItem>
                            {this.ListItemCategory(item.children)}
                        </List>
                    )
                }
            }
        })
    }
    handleOpen = () => {
        this.setState(state => ({
            categoryOpen: true,
        }));
    };
    handleClose = () => {
        this.setState(state => ({
            categoryOpen: false,
        }));
    };

    handleCategoryChange = (event) => {
        let categoryCheckboxValue = this.state.categoryCheckboxValue;
        let valueKey = categoryCheckboxValue.indexOf(event.target.value);
        if (valueKey > -1) {
            categoryCheckboxValue.splice(valueKey, 1);
        } else {
            categoryCheckboxValue.push(event.target.value);
        }
        let cateSelAll = this.state.cateSelAll;
        if (categoryCheckboxValue.length < this.state.categoryIdsAll.length) {
            cateSelAll = true;
        } else {
            cateSelAll = false;
        }
        this.setState({
            categoryCheckboxValue,
            cateSelAll
        });
    }
    handleSelAll() {
        let cateSelAll = !this.state.cateSelAll;
        this.setState({
            cateSelAll,
            categoryCheckboxValue: !cateSelAll ? this.state.categoryIdsAll : []
        })
    }
    render() {
        const { classes } = this.props;
        return (
            <span className={classes.button}>
                {
                    this.state.componentStatus === 'BTN'
                        ?
                        <Button className={classes.button} size="small" onClick={(e) => this.handleSearch(e, 'INPUT')}>
                            <img src={SearchOutlined} alt="SearchOutlined" className={classNames(classes.leftIcon)} />
                            {this.state.dictWord.search}
                        </Button>
                        :
                        <div className={classes.searchBox}>
                            <InputBase
                                id="searchAll"
                                size="small"
                                variant="filled"
                                classes={{
                                    root: classes.bootstrapInput,
                                }}
                                autoFocus
                                value={this.state.searchText}
                                startAdornment={
                                    <InputAdornment position="start" onClick={this.handleOpen.bind(this)} style={{ color: '#2196f3', minWidth: '90px', cursor: 'pointer', fontSize: '0.875rem' }}>
                                        <FolderPlusOutline />
                                        {this.state.dictWord.category}
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <CloseIcon
                                            color="disabled"
                                            style={{ cursor: 'pointer', width: '18px', height: '18px' }}
                                            onClick={(e) => this.handleSearch(e, 'BTN')}
                                        />
                                    </InputAdornment>
                                }
                                onClick={this.handleFocus.bind(this)}
                                onChange={this.handleChange.bind(this)}
                                onKeyUp={this.handleEnterKey.bind(this)}
                            />
                        </div>
                }
                <Dialog
                    open={this.state.categoryOpen}
                    onClose={this.handleClose.bind(this)}
                    aria-labelledby="responsive-dialog-title"
                    scroll="paper"
                >
                    <Toolbar style={{ minHeight: '30px', paddingRight: '10px', fontSize: '12px' }}>
                        <DialogTitle
                            disableTypography
                            className={classes.noWrap}
                            style={{ flexGrow: 1, maxWidth: '91%', textAlign: 'center' }}
                            id="responsive-dialog-title"
                        >
                            {this.state.dictWord.category}
                        </DialogTitle>
                        <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleClose.bind(this)}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    <DialogContent>
                        <div style={{ width: 400, height: 500 }}>
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" name="position" row>
                                    <List>
                                        {this.ListItemCategory(this.state.categoryShow)}
                                    </List>
                                </FormGroup>
                            </FormControl>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onMouseUp={this.handleSelAll.bind(this)} color="primary">
                            {this.state.cateSelAll ? this.state.dictWord.choose_all : this.state.dictWord.cancel_all}
                        </Button>
                        <Button onClick={this.handleClose.bind(this)} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </span>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentPage: state.reducer.bookListData.pageNumber || 1,
        bookListData: state.reducer.bookListData.bookList || [],
    };
}
export default withStyles(styles)(connect(mapStateToProps)(SearchBtn));