/**
 *
 */
export const REQUEST_DATA = 'REQUEST_DATA';

export const RECEIVE_DATA = 'RECEIVE_DATA';

export const ADMIN_REQUEST = 'ADMIN_REQUEST';
export const ADMIN_RECEIVE = 'ADMIN_RECEIVE';

export const GET_LATESTNUM_SUCCESS = 'GET_LATESTNUM_SUCCESS';
export const GET_LATESTNUM_FAIL = 'GET_LATESTNUM_FAIL';

//control SettingButton's leftMenu toggle in  app.js
export const GET_STATUS = 'GET_STATUS';
export const SET_STATUS = 'SET_STATUS';

//refreshing SearchBookList's data
export const GET_BOOKSTART = 'GET_BOOKSTART';
export const GET_BOOKSUCCESS = 'GET_BOOKSUCCESS';


