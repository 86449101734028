import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Typography,
    Tooltip,
    InputBase,
    Divider,
    MenuItem,
    MenuList,
    Grow,
    Popper,
    Paper,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Radio,
    SvgIcon,
    Fade,
    CircularProgress,
    Fab,
    ClickAwayListener,
    withStyles,
    Toolbar,
    InputAdornment,
    Hidden,
    ListItemIcon
} from '@material-ui/core';
import { blue, green, purple, red, orange, yellow, grey } from '@material-ui/core/colors';
import {Menu,ChevronRight, Close as CloseIcon, ChevronLeft, Add as AddIcon, MoreVert
} from '@material-ui/icons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import SearchOutlined from 'mdoc/style/imgs/search@1.svg';
import Slider from '@material-ui/lab/Slider';
import notes from 'mdoc/style/imgs/notes.svg';
import label from 'mdoc/style/imgs/label.svg';
import label_light from 'mdoc/style/imgs/label_light.svg';
import pen_curve from 'mdoc/style/imgs/pen_curve.svg';
import pen_straight from 'mdoc/style/imgs/pen_straight.svg';
import pen_marker from 'mdoc/style/imgs/marker.svg';
import large from 'mdoc/style/imgs/large.svg';
import reduction from 'mdoc/style/imgs/reduction.svg';
import comment from 'mdoc/style/imgs/comment.svg';
import star from 'mdoc/style/imgs/star.svg';
import font from 'mdoc/style/imgs/font.svg';

import { getBrowserInfo, dateFormat, replacepos, heightLight, escapeHtml, searchMatching } from 'mdoc/utils';
import Noti from 'mdoc/style/imgs/pen.svg';
import NotiHi from 'mdoc/style/imgs/pen_light.svg';
import 'mdoc/components/book/Read.less';
import Star from 'mdoc/utils/Star';
import ChatBox from 'mdoc/components/book/ChatBox';
import JSZip from 'jszip';
import Toast from 'mdoc/utils/toast';
import $ from 'jquery';

import {
    dictionary, getBookFilePath, getUserInfo, getBookEvaluate,
    bookEvaluate, commentList, commentAdd, effectiveLog, checkupdate, bodySearch, currentTime
} from 'mdoc/axios/index';
import { getBookInfo } from 'mdoc/axios/library';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import localforage from 'localforage';
import { connect } from 'react-redux';
import { bookSearch, getCoverPath } from 'mdoc/axios';
import { getBookListStart, getBookListSuccess } from 'mdoc/action/index'

const ReduceIcon = (props) => {
    return (
         <img src={reduction} alt={reduction} {...props} />
    );
}
const Message = (props) => {
    return (
        <img src={comment} alt={comment} {...props} />
    );
}
const EnlargeIcon = (props) => {
    return (
        <img src={large} alt={large} {...props} />
    );
}
const PrevPageIcon = (props) => {
    return (
        <SvgIcon viewBox="-5 -5 30 30">
            <path fill="#aaa" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
        </SvgIcon>
    );
}
const NextPageIcon = (props) => {
    return (
        <SvgIcon viewBox="-5 -5 30 30">
            <path fill="#aaa" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
        </SvgIcon>
    );
}
// const ITEM_HEIGHT = 48;
const TildeIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path fill="#000000" d="M2,15C2,15 2,9 8,9C12,9 12.5,12.5 15.5,12.5C19.5,12.5 19.5,9 19.5,9H22C22,9 22,15 16,15C12,15 10.5,11.5 8.5,11.5C4.5,11.5 4.5,15 4.5,15H2" />
        </SvgIcon>
    );
}
const VectorLineIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path fill="#000000" d="M15,3V7.59L7.59,15H3V21H9V16.42L16.42,9H21V3M17,5H19V7H17M5,17H7V19H5" />
        </SvgIcon>
    );
}
const SelectIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path fill="#000000" d="M14,10H19.5L14,4.5V10M5,3H15L21,9V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3M5,12V14H19V12H5M5,16V18H14V16H5Z" />
        </SvgIcon>
    );
}
const UndoIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path fill="#000000" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
        </SvgIcon>
    );
}
const DeleteIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path fill="#000000" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M9,8H11V17H9V8M13,8H15V17H13V8Z" />
        </SvgIcon>
    );
}
const SearchDialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const ReviewDialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
        padding: 0,
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const styles = theme => ({
    main: {
        width: '100%',
        display: 'flex'
    },
    mar5: {
        margin: '5px'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    slider: {
        padding: '22px 0px',
        overflow: 'hidden',
    },
    fontSlider: {
        padding: '22px 0px',
        overflow: 'hidden',
        minWidth: '300px'
    },
    list: {
        width: 250,
    },
    searchBooks: {
        margin: '20px 0',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    searchInput: {
        marginLeft: 8,
        flex: 1,
    },
    searchDivider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    bluecolor: {
        color: blue[600],
        '&$checked': {
            color: blue[500],
        },
    },
    greencolor: {
        color: green[600],
        '&$checked': {
            color: green[500],
        },
    },
    purplecolor: {
        color: purple[600],
        '&$checked': {
            color: purple[500],
        },
    },
    redcolor: {
        color: red[600],
        '&$checked': {
            color: red[500],
        },
    },
    orangecolor: {
        color: orange[600],
        '&$checked': {
            color: orange[500],
        },
    },
    yellowcolor: {
        color: yellow[600],
        '&$checked': {
            color: yellow[500],
        },
    },
    greycolor: {
        color: grey[50],
        '&$checked': {
            color: grey[50],
        },
    },
    checked: {},
    root: {
        background: 'transparent',
        boxShadow: 'none'
    },
    tooltipFont:{
        margin:'0!important',
        fontSize:'12px'
    }
});
let Paint = {};
class EpubRead extends React.Component {
    state = {
        dictWord: {
            'search': '',
            'search_word_enter': '',
            'page': '',
            'msg_error_record_not_found': '',
            'not_save_your_change': '',
            'font': '',
            'cancel': '',
            'yes': '',
            'directory': '',
            'evaluation_record': '',
            'evaluation': '',
            'msg_evaluation_completed': '',
            'save_information_list': '',
            'printing': '',
            'comment': '',
            'msg_error_not_null_with_item': '',
            'show_history': '',
            'pen': '',
            'marker': '',
            'label': '',
            'original_return': '',
            'delete_record': '',
            'curve': '',
            'straight_line': '',
            'msg_read_confirm': '',
            'print_range': '',
            'msg_error_max_print_page': '',
            'registration': '',
            'notes': '',
            'notes_list': '',
            'expansion': '',
            'reduction': '',
            'load_more': '',

            'help': '',
            'password': '',
            'logoff': '',
            'push': '',
            'return': '',
            'delete_it': '',
            'notes_detail': '',
            'unsaved_line': '',
            'new_notes_insert': ''
        },
        srcDoc: '',
        spine: [],//电子书目录
        ncx: [],//电子书详细目录，含标题
        menifest: [],//文件列表
        guide: {},//特定页面，封面
        bookRootPath: {},//电子书根目录
        new_zip: {},
        epubObject: {},
        bookPage: 1,
        totalPage: 0,
        horizontalPageCount: 1,
        commentFlg: 0,
        commentPage: 1,
        commentSize: 20,
        commentMessage: '',
        readTime: (new Date()).getTime(),
        statrtReadTime: (new Date()).getTime(),

        pageLoading: true,
        leftDrawer: false,
        searchStatus: false,
        searchResult: [],
        parsedWords: [],
        comments: [],
        chatboxOpen: false,
        messageObj: {},
        styleSetting: {},
        searchPages: [],
        fontMsgOpen: false,
        fontsizeStatus: false,
        fontsizeProgress: 12,
        sliderProgress: 50,
        colorStatus: 'a',
        anchorEl: null,
        editStatus: false,
        scale: 1,
        isMark: false,
        bookId: 0,
        bookInfo: {},
        printStatus: false,
        noteStatus: false,
        noteListStatus: false,
        annotations: [],
        starReviewStatus: false,
        printing: false,
        readMsg: false,
        readPage: 1,
        editType: '',
        contentPageNumber: 1,
        contentPageSize: 10,
        totlePage: 1,
        editSetting: {
            penColor: '#2196f3',
            penSize: 2,
            highlightColor: '#2196f3',
            overlay: void 0,
        },
        // Paint: {},
        formData: {
            searchText: {
                value: '',
                error: false,
                errorMsg: ''
            },
            noteText: {
                value: '',
                error: false,
                errorMsg: ''
            }
        },
        oldList: [],
        noteInfo: null,
        confirmOpen: false,
        optionOpen: false
    }
    componentWillMount() {
        this.start(this.state);
    }
    componentDidMount() {
        let me = this;
        //监听message事件
        window.addEventListener("message", function (event) {
            if (event !== undefined && me.state.editStatus === false) {
                me.loadPage(event.data.page, event.data.mark);
            }
        }, false);
        this.saveLog('start');
    }
    componentWillUnmount() {
        this.saveLog('end');
    }
    start = (state) => {
        let match = this.props.match.params;
        let page = this.state.bookPage;
        let userReadPage = this.getReadPage(match.bookId);
        let readMsg = parseInt(userReadPage.page) > 1 ? true : false;
        let _userInfo = getUserInfo();
        let commentFlg = localStorage.getItem(_userInfo.clientId + "/" + _userInfo.userId + '/commentFlg') ? localStorage.getItem(_userInfo.clientId + "/" + _userInfo.userId + '/commentFlg') : 0;
        let searchText = "";
        let parsedWords = [];
        if (this.props.location.query !== undefined) {
            page = this.props.location.query.page ? this.props.location.query.page : page;
            readMsg = this.props.location.query.page ? false : readMsg;
            searchText = this.props.location.query.search ? this.props.location.query.search : '';
            parsedWords = this.props.location.query.parsedWords ? this.props.location.query.parsedWords : [];
        }
        let styleSetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/styleSetting")) || {};

        this.setState({
            bookId: match.bookId,
            bookPage: page,
            // readTime: (new Date()).getTime(),
            loading: true,
            commentFlg: commentFlg,
            readPage: userReadPage.page,
            readMsg: readMsg,
            styleSetting: styleSetting,
            parsedWords: parsedWords,
            formData: Object.assign(this.state.formData, { searchText: { value: searchText } })
        })

        let dict = {}
        dictionary(state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            })
        });

        currentTime().then(res => {
            this.setState({
                readTime: res
            }, () => {
                checkupdate({
                    bookId: match.bookId,
                    checkDate: this.getReadTime(match.bookId),
                }).then((res) => {
                    this.getBook(match.bookId, userReadPage.time);
                }).catch(error => {
                    if (error.errorCode === "E201") {
                        Toast.error(error.errorMessage);
                        this.emptyAnnotations();
                        this.props.history.push('/user/book/library');
                    } else if (error.errorCode === "E401") {
                        Toast.error(error.errorMessage, 2000, () => {
                            this.emptyAnnotations();
                            this.setReadTime(match.bookId);
                            window.location.reload();
                        });
                    }
                });
            });
        });
    }
    //load bookinfo
    getBook = (bookId, userReadPageTime) => {
        getBookInfo(bookId).then((data) => {
            let bookInfo = data.results;
            if (parseInt(userReadPageTime) < (new Date(bookInfo.updateDate)).getTime()) {
                this.emptyAnnotations();
                this.setState({
                    bookPage: 1
                });
            }
            let bookViewHeight = $("body").height();
            bookViewHeight = (parseInt(bookViewHeight) * 65) / 100;
            $("#bookView").height(parseInt(bookViewHeight));
            this.setState({
                bookInfo: bookInfo,
                docType: bookInfo.docType,
                horizontalPageCount: bookInfo.docTblBookDetail.horizontalPageCount,
                flip: bookInfo.docTblBookDetail.flip,
                downloadWay: bookInfo.docTblBookDetail.downloadWay,
                printFlg: bookInfo.printFlg,
                printMax: bookInfo.printMax,
                formData: Object.assign(this.state.formData, { endPage: { value: bookInfo.printMax } }),
            });
            this.loadEpub();
            this.saveLog('start');
            let me = this;
            localforage.getItem('searchTextCache').then(function (value) {
                if (value) {
                    me.setState({
                        formData: Object.assign(me.state.formData, { searchText: { value } }),
                        contentPageNumber: 1
                    }, () => {
                        me.handleSearch();
                    })
                }
            })
        }).catch(error => {
            Toast.error(error);
            this.props.history.push('/user/book/library');
        });
    }
    saveLog = (action) => {
        if (typeof (this.state.bookInfo.id) === "undefined") return;
        let match = this.props.match.params;
        let seconds = 0;
        let _userInfo = getUserInfo();
        let logData;
        if (action === 'end') {
            seconds = (new Date()).getTime() - this.state.statrtReadTime;
            seconds = parseInt(seconds / 1000);
            logData = {
                "docTblBookEffectiveLogList": [{
                    "id": this.state.logId,
                    "bookId": match.bookId,
                    "userId": _userInfo.userId,
                    "ip": getBrowserInfo()[2],
                    "readingTime": seconds,
                    "readStartDate": dateFormat(new Date(this.state.statrtReadTime), 'yyyy-MM-ddThh:mm:ss'),
                    "readStartTimeStamp": this.state.statrtReadTime,
                    "browser": "",
                    "clientType": 0
                }]
            };
            effectiveLog(logData);
        } else {
            logData = {
                "docTblBookEffectiveLogList": [{
                    "bookId": match.bookId,
                    "userId": _userInfo.userId,
                    "ip": getBrowserInfo()[2],
                    "readingTime": 0,
                    "readStartDate": dateFormat(new Date(this.state.statrtReadTime), 'yyyy-MM-ddThh:mm:ss'),
                    "readStartTimeStamp": this.state.statrtReadTime,
                    "browser": "",
                    "clientType": 0
                }]
            };
            effectiveLog(logData).then(res => {
                this.setState({ logId: res.id });
            });
        }
    }
    getXml(string) {
        let xmlDoc = $("<html></html>");
        xmlDoc.append(string);
        return xmlDoc;
    }
    getXhtml(string) {
        //remove bom
        string = string.replace(
            String.fromCharCode('65279'), ''
        );
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(string, "text/xml");
        return $(xmlDoc);
    }

    /**
     * get getAbsolutePath
     * @param {*} currentPath 
     * @param {*} filePath 
     */
    getAbsolutePath(currentPath, filePath) {
        let absolutePath = '';
        let parentPath = currentPath.lastIndexOf("/") >= 0 ? currentPath.substr(0, (currentPath.lastIndexOf("/"))) : this.state.bookRootPath;
        // let parentPath = '';
        // if(currentPath.lastIndexOf("/") >= 0){
        //     parentPath = currentPath.substr(0, (currentPath.lastIndexOf("/")));
        // }else{
        //     if(parentPath+"/" === this.state.bookRootPath){
        //         parentPath = parentPath+"/";
        //     }else{
        //         parentPath = '';
        //     }
        // }

        let lastlen = -1;
        do {
            lastlen = filePath.lastIndexOf("../");
            if (filePath.substr(0, 1) === '/') {
                absolutePath = this.state.bookRootPath + filePath;
                break;
            } else if (lastlen < 0 && filePath.indexOf('./') < 0 && filePath.substr(0, 1) !== '/') {
                absolutePath = currentPath.length > 0 ? currentPath + '/' + filePath : filePath;
                break;
            } else if (lastlen < 0 && filePath.indexOf('./') >= 0) {
                absolutePath = parentPath.length > 0 && parentPath.indexOf('/') < 0 ? parentPath + '/' + filePath : parentPath + filePath;
                // absolutePath = parentPath + filePath
                absolutePath = absolutePath.split('./');
                absolutePath = absolutePath.join('');
                break;
            }
            filePath = filePath.substr(0, lastlen) + filePath.substr((lastlen + 3), filePath.length);
            if (filePath.lastIndexOf("../") >= 0) {
                parentPath = parentPath.substr(0, (parentPath.lastIndexOf("/")));
            } else {
                absolutePath = parentPath.length > 0 && parentPath.indexOf('/') < 0 ? parentPath + '/' + filePath : parentPath + filePath;
                // absolutePath = parentPath + filePath;
                if (absolutePath.indexOf('./') >= 0) {//删除当前目录符
                    absolutePath = absolutePath.split('./');
                    absolutePath = absolutePath.join('');
                }
                break;
            }
        } while (lastlen > 0);
        // if(this.state.bookRootPath.length > 0 && absolutePath.indexOf(this.state.bookRootPath) < 0){
        //     absolutePath = this.state.bookRootPath + absolutePath;
        // }
        return absolutePath;
    }

    /**
     * get CurrentPath 获取文件当前目录
     * @param {*} filePath 
     */
    getCurrentPath(filePath) {
        let currentPath = '';
        let lastlen = filePath.lastIndexOf('/');
        if (lastlen >= 0) currentPath = filePath.substr(0, lastlen) + '/';
        return currentPath;
    }
    saveReadPage(page) {
        let _userInfo = getUserInfo();
        currentTime().then(res => {
            let time = res;//(new Date()).getTime();
            let read = JSON.parse(window.localStorage.getItem(_userInfo.userId + "/read")) || [];
            if (read) {
                let is_existence = 0;
                for (let i in read) {
                    if (read[i].bookid === this.state.bookId && read[i].page !== page) {
                        read[i].page = page;
                        read[i].time = time;
                        is_existence = 1;
                        break;
                    }
                    if (read[i].bookid === this.state.bookId && read[i].page === page) {
                        is_existence = 2;
                        break;
                    }
                }
                if (is_existence === 2) {
                    return;
                } else if (is_existence === 0) {
                    read.push({
                        bookid: this.state.bookId,
                        page: page,
                        time: time,
                    })
                }
                localStorage.setItem(_userInfo.userId + '/read', JSON.stringify(read));
                return;
            } else {
                read.push({
                    bookid: this.state.bookId,
                    page: page,
                    time: time,
                })
                localStorage.setItem(_userInfo.userId + '/read', JSON.stringify(read));
                return;
            }
        });
    }
    getReadPage(bookId) {
        let _userInfo = getUserInfo();
        let read = JSON.parse(window.localStorage.getItem(_userInfo.userId + "/read")) || [];
        let pages = 0;
        if (read) {
            for (let i in read) {
                if (read[i].bookid === bookId) {
                    pages = read[i];
                    break;
                }
            }
        }
        return pages;
    }
    getReadTime(bookId) {
        let _userInfo = getUserInfo();
        let read = JSON.parse(window.localStorage.getItem(_userInfo.userId + "/read")) || [];
        let readTime = this.state.readTime;
        if (read) {
            for (let i in read) {
                if (read[i].bookid === bookId) {
                    readTime = read[i].time;
                    break;
                }
            }
        }

        return readTime;
    }
    setReadTime(bookId) {
        let _userInfo = getUserInfo();
        let read = JSON.parse(window.localStorage.getItem(_userInfo.userId + "/read")) || [];
        let readTime = this.state.readTime;
        if (read) {
            for (let i in read) {
                if (read[i].bookid === bookId) {
                    read[i].time = readTime;
                    read[i].page = 1;
                    break;
                }
            }
        }
        window.localStorage.setItem(_userInfo.userId + "/read", JSON.stringify(read));
    }
    loadEpub = () => {
        let bookInfo = this.state.bookInfo;
        let new_zip = new JSZip();
        let me = this;
        getBookFilePath(bookInfo.categoryId, bookInfo.docTblBookDetail.fileUuid, bookInfo.docTblBookDetail.fileUuid).then(res => {
            new_zip.loadAsync(res).then(function (epubObject) {
                epubObject.file('META-INF/container.xml').async("string").then(data => {
                    let xmlDoc = me.getXml(data);
                    let rootFile = $(xmlDoc).find('rootfile').attr('full-path');
                    if (rootFile.length <= 0) return;

                    let bookRootPath = rootFile.substr(0, rootFile.lastIndexOf('/'));
                    if (bookRootPath.length > 0) bookRootPath = bookRootPath + '/';

                    let spine = []; //电子书指定目录
                    let ncx = [];//ncx目录
                    let guide = {}; //特定页面，封面
                    let menifest = [];//文件列表
                    epubObject.file(rootFile).async("string").then(optData => {
                        let optXmlDoc = me.getXml(optData);
                        if ($(optXmlDoc).find("spine>itemref").length > 0) {
                            $(optXmlDoc).find('spine>itemref').each(function () {
                                spine.push($(this).attr('idref'));
                            });
                        }

                        if ($(optXmlDoc).find("guide>reference").length > 0) {
                            guide = {
                                type: $(optXmlDoc).find("guide>reference").attr("type"),
                                href: $(optXmlDoc).find("guide>reference").attr("href")
                            };
                        }

                        if ($(optXmlDoc).find("manifest>item").length > 0) {
                            let ncxPath = '';
                            $(optXmlDoc).find('manifest>item').each(function () {
                                let itemId = $(this).attr('id');
                                menifest[itemId] = {
                                    id: itemId,
                                    href: $(this).attr('href'),
                                    type: $(this).attr('media-type'),
                                    properties: $(this).attr('properties')
                                }
                                if (itemId === 'ncx') {
                                    let currentPath = me.getCurrentPath(rootFile);
                                    ncxPath = currentPath + $(this).attr('href');
                                }
                            });
                            if (ncxPath.length > 0) {
                                epubObject.file(ncxPath).async("string").then(ncxStr => {
                                    let ncxXmlDoc = me.getXml(ncxStr);
                                    $(ncxXmlDoc).find("navPoint").each(function (idx) {
                                        let ncxId = $(this).attr('id');
                                        let ncxPlayOrder = $(this).attr('playOrder');
                                        let ncxText = $(this).find('navLabel>text').eq(0).text();
                                        let ncxHref = $(this).find('content').attr('src');
                                        let mark = "";
                                        if (ncxHref.indexOf("#") >= 0) {
                                            let hrefs = ncxHref.split("#");
                                            ncxHref = hrefs[0];
                                            mark = hrefs[1];
                                        }

                                        Object.keys(menifest).some((e) => {
                                            if (menifest[e].href === ncxHref && spine.indexOf(menifest[e].id) >= 0) {
                                                ncx[idx] = {
                                                    id: ncxId,
                                                    playOrder: ncxPlayOrder,
                                                    pageNum: spine.indexOf(menifest[e].id) + 1,
                                                    text: ncxText,
                                                    url: ncxHref,
                                                    mark: mark
                                                }
                                            }
                                            return (menifest[e].href === ncxHref && spine.indexOf(menifest[e].id) >= 0);
                                        });
                                    });
                                    me.setState({
                                        ncx: ncx,
                                    })
                                });
                            }
                        }
                        me.setState({
                            epubObject: epubObject,
                            bookRootPath: bookRootPath,
                            spine: spine,
                            guide: guide,
                            menifest: menifest,
                            // bookPage: 1,
                            totalPage: spine.length,
                        });
                        // let htmlPath = bookRootPath + menifest[spine[0]].href;
                        // me.loadHtml(htmlPath);

                        me.loadPage(me.state.bookPage);
                    });
                });
            });
        });
    }

    /**
     * load epub html content
     * @param {*} page 
     */
    loadPage(page, makr = '') {
        if (isNaN(page)) return;
        //当前页不重复加载
        // if(page > 1 && page === this.state.bookPage && makr.length <= 0){   
        //     return;
        // }else 
        //当前页锚标记定位，不重复加载页面
        if (page === this.state.bookPage && makr.length > 0) {
            document.getElementById("bookView").contentWindow.document.getElementById(makr).scrollIntoView(true);
            return;
        }

        let index = parseInt(page) - 1;
        if (index < 0) return;
        let spine = this.state.spine;
        if (spine.length > 0 && spine[index]) {
            let htmlPath = this.state.bookRootPath + this.state.menifest[spine[index]].href;
            this.loadHtml(htmlPath, makr);
            this.setState({
                bookPage: page,
                anchorEl: null
            });
            this.saveReadPage(page);
        }
    }


    resetHref(htmlDoc) {
        if ($(htmlDoc).find("a").length <= 0) return htmlDoc;
        let menifest = this.state.menifest;
        let spine = this.state.spine;
        // let currentPath = me.getCurrentPath(rootFile);
        $(htmlDoc).find("a").each(function (e) {//先去掉页面所有的A标签链接
            let oldhref = $(this).attr('href');
            if (typeof (oldhref) === "undefined" || oldhref.length <= 0) return true;
            if (oldhref.indexOf('http://') >= 0 || oldhref.indexOf('https://') >= 0) {
                $(this).attr('target', "_blank");
            } else {
                let mark = "";
                let page = 1;
                if (oldhref.indexOf("#") >= 0) {
                    let hrefs = oldhref.split("#");
                    oldhref = hrefs[0];
                    mark = hrefs[1];
                }
                Object.keys(menifest).some((e) => {
                    if (menifest[e].href === oldhref && spine.indexOf(menifest[e].id) >= 0) {
                        page = parseInt(spine.indexOf(menifest[e].id)) + 1;
                        $(this).attr("onclick", "loadPage(" + page + ",'" + mark + "')");
                    }
                    return (menifest[e].href === oldhref && spine.indexOf(menifest[e].id) >= 0);
                });
                $(this).attr('href', "javascript:void(0)");
            }
        });
    }

    setCss(htmlDoc) {
        let userSetting = this.getReadSetting();
        let userStyle = '';
        if (userSetting) {
            if (userSetting.hasOwnProperty('font-size')) {
                userStyle += "font-size:" + userSetting['font-size'].value + ';';
                this.setState({
                    fontsizeProgress: parseInt(userSetting['font-size'].value) - 10
                });
            }
            if (userSetting.hasOwnProperty('font-family')) {
                userStyle += "font-family:" + userSetting['font-family'].value + ';';
            }
            // userStyle = "body{"+userStyle+"}";
        }
        let bodyStyle = "body{transform:scale(" + this.state.scale + ");transform-origin:0 0;padding:10px;background:" + this.state.styleSetting.contentBackColor + ';' + userStyle + "}";
        //设置页面图片大小限制
        bodyStyle += "img{opacity: 0.8;max-height: 100%;max-width: 100%;}";
        $("<style></style>").text(bodyStyle).appendTo($(htmlDoc).find('head'));
    }

    loadHtml(htmlPath, makr = '', printId = false) {
        let me = this;
        currentTime().then(res => {
            this.setState({
                readTime: res
            }, () => {
                checkupdate({
                    bookId: this.state.bookId,
                    checkDate: this.getReadTime(this.state.bookId),
                }).then(res => {
                    me.setState({
                        pageLoading: true
                    });
                    let epubObject = this.state.epubObject;
                    epubObject.file(htmlPath).async("string").then(htmlStr => {
                        let currentPath = '';
                        let lastlen = htmlPath.lastIndexOf('/');
                        if (lastlen >= 0) currentPath = htmlPath.substr(0, lastlen);
                        let htmlDoc = me.getXhtml(htmlStr);//获取HTML dom
                        me.resetHref(htmlDoc);//处理url
                        me.setCss(htmlDoc);//处理Css
                        if (printId === false) {
                            let scriptStr = "function loadPage(page, mark=''){window.parent.postMessage({page:page,mark:mark},'*');}";
                            $("<script type='text/javascript'></script>").html(scriptStr).appendTo($(htmlDoc).find('head'));
                            //有搜索关键字
                            let parsedWords = this.state.parsedWords;
                            if (parsedWords.length > 0) {
                                for (let i in parsedWords) {
                                    let _keyword = searchMatching($(htmlDoc).find('body').text(), parsedWords[i]);
                                    if (_keyword.length <= 0) continue;
                                    let bodyHtml = me.textSearch(_keyword, $(htmlDoc).find('body'));
                                    if (bodyHtml !== false) $(htmlDoc).find('body').html(bodyHtml);
                                }
                                this.setState({
                                    searchStatus: false,
                                });
                            }
                        }

                        htmlStr = $(htmlDoc).find('html').prop("outerHTML");
                        let oldFiles = [];
                        //获取所有的src路径
                        let srcArr = htmlStr.match(/src="([^"]*)"/g);
                        let srcPath = '';
                        if (srcArr && srcArr.length > 0) {
                            for (let m in srcArr) {
                                srcPath = '';
                                srcPath = srcArr[m].replace(/"/g, '');
                                srcPath = srcPath.replace("src=", '');
                                if (srcPath.length > 0) oldFiles.push(srcPath);
                            }
                        }

                        //获取页面所有css的src路径
                        let cssSrcArr = htmlStr.match(/(?=url\()[^)]+(?=\))/g);
                        let cssSrcPath = '';
                        if (cssSrcArr && cssSrcArr.length > 0) {
                            for (let m in cssSrcArr) {
                                cssSrcPath = '';
                                cssSrcPath = cssSrcArr[m].replace(/"/g, '');
                                cssSrcPath = cssSrcPath.replace("url(", '');
                                if (cssSrcPath.length > 0) oldFiles.push(cssSrcPath);
                            }
                        }
                        //获取所有的href路径
                        let hrefArr = htmlStr.match(/href="([^"]*)"/g);
                        let hrefPath = '';
                        if (hrefArr && hrefArr.length > 0) {
                            for (let m in hrefArr) {
                                hrefPath = '';
                                hrefPath = hrefArr[m].replace(/"/g, '');
                                hrefPath = hrefPath.replace("href=", '');
                                if (hrefPath.length > 0 && hrefPath.indexOf("#") < 0 && hrefPath.indexOf("javascript:void(0)") < 0) oldFiles.push(hrefPath);
                            }
                        }
                        //获取所有资源绝对路径并转化为blob
                        if (oldFiles && oldFiles.length > 0) {
                            let promises = [];
                            for (let i in oldFiles) {
                                if (oldFiles[i].indexOf('http://') >= 0 || oldFiles[i].indexOf('https://') >= 0) continue;
                                let absolutePath = me.getAbsolutePath(currentPath, oldFiles[i]);
                                promises[i] = new Promise((resolve, reject) => {
                                    epubObject.file(absolutePath).async("blob").then(function (imgBlob) {
                                        resolve({ path: oldFiles[i], blob: URL.createObjectURL(imgBlob) });
                                    });
                                });
                            }
                            if (promises.length > 0) {
                                Promise.all(promises).then(res => {
                                    if (res.length > 0) {
                                        for (let i = 0; i < res.length; i++) {
                                            htmlStr = htmlStr.replace(res[i].path, res[i].blob);
                                        }
                                        if (printId === false) {
                                            me.setState({
                                                srcDoc: htmlStr
                                            });
                                            this.BookLoadComplete();
                                        } else {
                                            $('#bookView').contents().find("#printPage_" + printId).html(htmlStr);
                                            // let $printIframe = $('#bookView').contents().find("#printPage_" + printId);
                                            // $printIframe.contents().find('html').html(htmlStr);
                                            // $printIframe.height($printIframe.contents().find('html').height());
                                            // $printIframe.attr('srcdoc',htmlStr);
                                            // if ($printIframe[0].attachEvent) {
                                            //     $printIframe[0].attachEvent("onload", function () { // IE  

                                            //     });
                                            // } else {
                                            //     $printIframe[0].onload = function () {
                                            //         $printIframe.width($printIframe.contents().find('html').width());
                                            //         $printIframe.height($printIframe.contents().find('html').height());
                                            //     }
                                            // }
                                        }
                                    }
                                }).catch(error => {
                                    Toast.error(error);
                                });
                            } else {
                                if (printId === false) {
                                    me.setState({
                                        srcDoc: htmlStr
                                    });
                                    this.BookLoadComplete();
                                } else {
                                    $('#bookView').contents().find("#printPage_" + printId).html(htmlStr);
                                }
                            }
                        } else {
                            if (printId === false) {
                                me.setState({
                                    srcDoc: htmlStr
                                });
                                this.BookLoadComplete();
                            } else {
                                $('#bookView').contents().find("#printPage_" + printId).html(htmlStr);
                            }
                        }
                    });
                }).catch(error => {
                    if (error.errorCode === "E201") {
                        Toast.error(error.errorMessage);
                        this.emptyAnnotations();
                        this.props.history.push('/user/book/library');
                    } else if (error.errorCode === "E401") {
                        Toast.error(error.errorMessage, 2000, () => {
                            this.emptyAnnotations();
                            this.setReadTime(this.state.bookId);
                            window.location.reload();
                        });
                    }
                });
            });
        });
    }


    BookLoadComplete = () => {
        this.laodMark();
        let me = this;
        let isAnnotations = true;
        let $iFrame = $("#bookView");
        if ($iFrame[0].attachEvent) {
            $iFrame[0].attachEvent("onload", function () { // IE  
                me.setState({
                    pageLoading: false
                });
                let bookView = $('#bookView').contents().find('body');
                if (isAnnotations === true) {
                    me.renderAnnotations();
                    $("body", this.contentDocument).click(function () {//添加点击事件
                        me.handleSvgDelete(me.state.bookPage, window.parent.event)
                    });
                    $("body", this.contentDocument).mousedown(function () {//添加鼠标按下事件
                        me.handleSvgMouseDown(me.state.bookPage, window.parent.event)
                    });
                    $("body", this.contentDocument).mousemove(function () {//添加鼠标移动事件
                        me.handleSvgMouseMove(me.state.bookPage, window.parent.event);
                    });
                    $("body", this.contentDocument).mouseup(function () {//添加鼠标松开事件
                        me.handleSvgMouseUp(me.state.bookPage, window.parent.event)
                    });
                } else {
                    if (me.state.horizontalPageCount === 0) {
                        bookView.css({
                            'column-count': '2',
                            'column-gap': '70px',
                        });
                    }
                }
            });
        } else {
            $iFrame[0].onload = function () { // 非IE  
                me.setState({
                    pageLoading: false
                });
                let bookView = $('#bookView').contents().find('body');
                // $('#bookView').width(bookView.width());
                // $('#bookView').height(bookView.outerHeight(true));
                if (isAnnotations === true) {
                    me.renderAnnotations();
                    $("body", this.contentDocument).click(function () {//添加点击事件
                        me.handleSvgDelete(me.state.bookPage, window.parent.event)
                    });
                    $("body", this.contentDocument).mousedown(function () {//添加鼠标按下事件
                        me.handleSvgMouseDown(me.state.bookPage, window.parent.event)
                    });
                    $("body", this.contentDocument).mousemove(function () {//添加鼠标移动事件
                        me.handleSvgMouseMove(me.state.bookPage, window.parent.event);
                    });
                    $("body", this.contentDocument).mouseup(function () {//添加鼠标松开事件
                        me.handleSvgMouseUp(me.state.bookPage, window.parent.event)
                    });
                } else {
                    if (me.state.horizontalPageCount === 0) {
                        bookView.css({
                            'column-count': '2',
                            'column-gap': '70px',
                        });
                    }
                }
            };
        }
    }


    renderAnnotations = () => {
        let bookView = $('#bookView').contents().find('body');
        // let bookDivView = $("<div id='showdiv'></div>");
        // let bookDivhide = $("<div id='hidediv'></div>");
        let svg = $("<svg xmlns='http://www.w3.org/2000/svg'></svg>");

        if (this.state.horizontalPageCount === 0) {
            bookView.css({
                'column-count': '2',
                'column-gap': '70px',
            });
            // bookDivView.css({
            //     'column-count': '2',
            //     'column-gap': '70px',
            // });
            // bookDivhide.css({
            //     'column-count': '2',
            //     'column-gap': '70px',
            // });
        }

        // bookDivView.html(bookView.html());
        // bookView.html('');
        // bookView.html(bookDivView.prop("outerHTML"));
        svg.css({
            top: 0,
            left: 0,
            right: 0,
            // bottom: 0,
            'z-index': '-1',
            position: 'absolute',
            'height': bookView.outerHeight(true),
            width: '100%'
            // 'width': bookView.width(),
            // 'height': bookView.height(),
            // 'position': 'absolute',
            // 'margin-top': 0 - bookView.height()
        });
        // bookDivhide.css({
        //     'opacity': '0.1',
        //     'margin-top': 0 - bookView.height(),
        // });
        // bookDivhide.html(bookDivView.html());
        bookView.append(svg);
        // bookView.append(bookDivhide);
        /*load annotations*/
        let viewport = {
            height: bookView.width(),
            width: bookView.height(),
            viewBox: [],
            scale: 1,
            rotation: 0
        }
        let annotations = this.getAnnotations(this.state.bookId, this.state.bookPage);
        this.loadAnnotations(svg.get(0), viewport, annotations);
    }

    handleFlipPage = (operation) => {
        let currentBookPage = this.state.bookPage;
        let bookPage = 1;
        let pageSize = 1;
        if (this.state.flip === 0) {
            if (operation === 'next') bookPage = currentBookPage + pageSize;
            else if (operation === 'previous') bookPage = currentBookPage - pageSize;
        } else {
            if (operation === 'next') bookPage = currentBookPage - pageSize;
            else if (operation === 'previous') bookPage = currentBookPage + pageSize;
        }
        if (bookPage > this.state.totalPage) bookPage = this.state.totalPage;
        if (bookPage < 1) return;
        this.loadPage(bookPage);
    }
    handleTurningPage = (bookPage) => {
        if (isNaN(bookPage)) return;
        if (parseInt(bookPage) < 1) bookPage = 1;
        if (bookPage > this.state.totalPage) bookPage = this.state.totalPage;
        this.loadPage(bookPage);
    }

    laodMark = () => {
        let _userInfo = getUserInfo();
        let marks = JSON.parse(window.localStorage.getItem(this.state.bookId + '/' + _userInfo.userId + "/mark"));
        if (!marks) marks = [];
        if (marks.length > 0) {
            for (let i in marks) {
                if (marks[i].page === this.state.bookPage) {
                    this.setState({
                        isMark: true
                    });
                    return;
                }
            }
            this.setState({
                isMark: false
            });
        }
    }
    handleDrawerClick = (operation) => event => {
        this.setState({
            leftDrawer: operation
        });
    }
    handleInputChange(field, event) {
        let value = event.target.value;
        let valueObj = Object.assign(this.state.formData[field], { value: value });
        let state = {
            formData: Object.assign(this.state.formData, { [field]: valueObj })
        };
        if (field === 'searchText') {
            state['searchResult'] = [];
            state['totlePage'] = 0;
        }
        this.setState(state);
    }
    handleCloseSearch = () => {
        this.setState({
            searchStatus: false
        })
    }
    handleOpenSearch = () => {
        this.setState({
            searchStatus: true,
            optionOpen: false
        })
    }
    fatherFn = (status) => {
        this.setState({
            chatboxOpen: status,
            commentPage: 1,
        })
    }
    receiveHandle = (res) => {
        this.setState({
            commentMessage: res
        });
    }
    sendMessage = (func) => {
        let commentMessage = this.state.commentMessage;
        this.setState({
            commentMessage: '',
        }, () => {
            $('#commentMessage').val('');
            commentMessage = commentMessage.replace(/(^\s*)|(\s*$)/g, "");
            commentMessage = encodeURI(commentMessage);
            if (commentMessage.length > 0) {
                commentAdd({
                    bookId: this.state.bookId,
                    comment: commentMessage
                }).then(res => {
                    let comments = this.state.comments;
                    comments.push(res);
                    this.setState({
                        comments: comments
                    }, () => {
                        func();
                    });
                }).catch(error => {
                    Toast.error(error);
                })
            }
        });
    }

    nextTotlePage() {
        let contentPageNumber = this.state.contentPageNumber;
        contentPageNumber++;
        if (contentPageNumber <= this.state.totlePage) {
            this.setState({
                contentPageNumber
            }, () => {
                this.contentLoadMore()
            });
        }
    }

    contentLoadMore() {
        let keyword = this.state.formData.searchText.value;
        bodySearch({
            bookId: this.state.bookId,
            bodyContent: keyword,
            pageSize: this.state.contentPageSize,
            pageNum: this.state.contentPageNumber
        }).then((res) => {
            let docTblBookBodyList = res['docTblBookBody'];
            let searchPages = this.state.searchResult;
            let parsedWords = res.parsedWords;
            if (docTblBookBodyList.length > 0) {
                for (let i in docTblBookBodyList) {
                    let _pageNum = docTblBookBodyList[i].pageNum;
                    let htmlText = docTblBookBodyList[i].bodyContent;
                    htmlText = escapeHtml(htmlText);
                    let firstIndex = -1;
                    for (let j in parsedWords) {
                        let _keyword = searchMatching(htmlText, parsedWords[j]);
                        if (_keyword.length <= 0) continue;
                        var reg = new RegExp(_keyword, "gi");
                        let temp = reg.exec(htmlText);
                        if (temp) {
                            if (firstIndex < 0 || temp.index < firstIndex) {
                                firstIndex = temp.index
                            }
                        }
                    }
                    htmlText = htmlText.substring(firstIndex - 2, htmlText.length);
                    htmlText = htmlText.length > 30 ? htmlText.substring(0, 30) + '…' : htmlText;

                    for (let j in parsedWords) {
                        let _keyword = searchMatching(htmlText, parsedWords[j]);
                        if (_keyword.length <= 0) continue;
                        htmlText = heightLight(htmlText, _keyword);
                    }
                    let index = htmlText.indexOf('<span');
                    htmlText = replacepos(htmlText, index);
                    searchPages.push({
                        pageNumber: _pageNum,
                        text: htmlText
                    });
                }
                this.setState({
                    searchResult: searchPages,
                    totlePage: res.totlePage,
                })
            }
        });
    }

    handleLoadMessage = (func) => {
        let page = this.state.commentPage + 1;
        commentList(this.state.bookId, {
            pageNumber: page,
            pageSize: this.state.commentSize,
        }).then(res => {
            let commentList = res.docTblUserCommentList;
            commentList.sort(function (obj1, obj2) {
                return obj1.id - obj2.id;
            });
            let comments = commentList.concat(this.state.comments);
            this.setState({
                comments: comments,
                commentPage: page
            }, () => {
                func(this.state.comments.length);
            })
        }).catch(error => {
            Toast.error(error);
        })
    }

    epubSearchFull() {
        let keyword = this.state.formData.searchText.value
        bodySearch({
            bookId: this.state.bookInfo.id,
            bodyContent: keyword,
            pageSize: this.state.contentPageSize,
            pageNum: this.state.contentPageNumber
        }).then((res) => {
            let totlePage = res.totlePage;
            let docTblBookBodyList = res['docTblBookBody'];
            if (docTblBookBodyList.length > 0) {
                let searchPages = [];
                let parsedWords = res.parsedWords;
                for (let i in docTblBookBodyList) {
                    let _pageNum = docTblBookBodyList[i].pageNum;
                    let htmlText = docTblBookBodyList[i].bodyContent;
                    htmlText = escapeHtml(htmlText);
                    let firstIndex = -1;
                    for (let j in parsedWords) {
                        let _keyword = searchMatching(htmlText, parsedWords[j]);
                        if (_keyword.length <= 0) continue;
                        var reg = new RegExp(_keyword, "gi");
                        let temp = reg.exec(htmlText);
                        if (temp) {
                            if (firstIndex < 0 || temp.index < firstIndex) {
                                firstIndex = temp.index
                            }
                        }
                    }
                    htmlText = htmlText.substring(firstIndex - 2, htmlText.length);
                    htmlText = htmlText.length > 30 ? htmlText.substring(0, 30) + '…' : htmlText;
                    for (let j in parsedWords) {
                        let _keyword = searchMatching(htmlText, parsedWords[j]);
                        if (_keyword.length <= 0) continue;
                        htmlText = heightLight(htmlText, _keyword);
                    }
                    let index = htmlText.indexOf('<span');
                    htmlText = replacepos(htmlText, index);
                    searchPages.push({
                        pageNumber: _pageNum,
                        text: htmlText
                    });
                }
                this.setState({
                    searchResult: searchPages,
                    totlePage,
                    bookId: this.state.bookInfo.id,
                    parsedWords: parsedWords
                })
            } else {
                this.setState({
                    searchResult: [],
                    parsedWords: []
                })
            }
        });
    }

    /*book content search*/
    handleSearch = () => {
        let keyword = this.state.formData.searchText.value;
        if (keyword.length <= 0) {
            this.setState({
                searchResult: [],
                totlePage: 0
            });
        }else{
            this.setState({
                contentPageNumber: 1
            }, () => {
                this.epubSearchFull();//全文检索
            });
        }
    }

    onKeyPress(event) {
        let enter = event.keyCode;
        if (enter === 13) {
            this.handleSearch();
        }
    }

    textSearch = (str, body = '') => {
        if (str.length <= 0) {
            return false;
        }
        if (!body) {
            body = $('#bookView').contents().find('body');
        }
        let bodyText = body.text();
        let bodyHtml = body.html();
        let reg = new RegExp(str, "i");
        if (bodyText.match(reg)) {
            let span = $("<span></span>");
            span.css({
                'margin': '-1px',
                'padding': '1px',
                'background-color': 'yellow',
                'border-radius': '4px'
            });
            span.addClass('serchselect');
            span.text(str);
            let spanStr = span.prop("outerHTML");
            let re = str.replace(/[\\\.\+\*^$(){}\[\]-]/gi, '\\$1');
            let reg = new RegExp('' + re + '(?=[^>]*(<|$))', 'gi');
            // bodyHtml = bodyHtml.split(str);
            bodyHtml = bodyHtml.split(reg);
            for (let i in bodyHtml) {
                if (bodyHtml[i] === "<") bodyHtml.splice(i, 1);
            }
            bodyHtml = bodyHtml.join(spanStr);
            return bodyHtml;
        } else {
            return false;
        }

    }


    handleOpenFontSize = () => {
        this.setState({
            fontsizeStatus: true,
            editStatus: false,
            fontMsgOpen: false,
        })
    }

    handleChangeFontsize = (event, value) => {
        let book = $('#bookView').contents().find('body');
        let svg = book.find('svg[data-book-annotate-container="true"]');
        let bookDivView = book.find('#showdiv');
        let bookDivhide = book.find('#hidediv');
        this.emptyAnnotations();
        value = parseInt(value) + 10;
        book.css({ "font-size": value + 'px' });
        svg.css({
            'widht': bookDivView.width(),
            'height': bookDivView.height(),
            'margin-top': 0 - bookDivView.height()
        });
        bookDivhide.css({ 'margin-top': 0 - bookDivView.height() });
        this.setReadSetting({
            key: 'font-size',
            value: value + 'px',
        });
        this.setState({ fontsizeProgress: parseInt(value) - 10 });
    }
    handleChangeFontFamily = (value) => {
        let book = $('#bookView').contents().find('body');
        let svg = book.find('svg[data-book-annotate-container="true"]');
        let bookDivView = book.find('#showdiv');
        let bookDivhide = book.find('#hidediv');
        this.emptyAnnotations();
        // book.css({ "font-size": 'inherit' });
        book.css({ "font-family": "'" + value + "'" });
        svg.css({
            'widht': bookDivView.width(),
            'height': bookDivView.height(),
            'margin-top': 0 - bookDivView.height()
        });
        bookDivhide.css({ 'margin-top': 0 - bookDivView.height() });
        this.setReadSetting({
            key: 'font-family',
            value: value,
        });
    }

    setReadSetting(setting) {
        if (!setting) return;
        let _userInfo = getUserInfo();
        let settings = JSON.parse(window.localStorage.getItem(_userInfo.userId + "/" +this.state.bookId + "/setting")) || {};
        settings[setting.key] = {
            value: setting.value
        };
        localStorage.setItem(_userInfo.userId + "/" +this.state.bookId + '/setting', JSON.stringify(settings));
    }
    getReadSetting() {
        return JSON.parse(window.localStorage.getItem(getUserInfo().userId + "/" +this.state.bookId + "/setting")) || {};
    }


    /*批注功能函数start */
    handleSvgMouseDown = (page, event) => {
        if (this.state.editStatus === false) return;
        let svg = (0, this.findSVGAtPoint)(event.clientX, event.clientY);
        if (!svg) {
            return;
        }
        let rect = svg.getBoundingClientRect();
        let point = (0, this.scaleDown)(svg, {
            x: event.clientX - rect.left,
            y: event.clientY - rect.top
        });
        switch (this.state.editType) {
            case 'curve':
                Paint = {
                    page: page,
                    path: null,
                    lines: [[point.x, point.y]],
                };
                break;
            case 'straightLine':
                Paint = {
                    page: page,
                    line: null,
                    lines: { x1: point.x, y1: point.y },
                };
                break;
            case 'highlight':
                Paint = {
                    page: page,
                    rect: null,
                    lines: [],
                };
                break;
            default:
                break;
        }
    }
    handleSvgMouseMove = (page, event) => {
        if (this.state.editStatus === false) return;
        if (!Paint.hasOwnProperty('lines')) return;
        if (page !== Paint.page) {
            this.outSvgPaint();
            return;
        }

        switch (this.state.editType) {
            case 'curve':
                if (!Paint.hasOwnProperty('lines')) return;
                this.savePoint(event.clientX, event.clientY);
                break;
            case 'straightLine':
                if (!Paint.hasOwnProperty('line')) return;
                this.straightLinePoint(event.clientX, event.clientY);
                break;
            case 'highlight':
                let rects = void 0;
                if ((rects = this.getSelectionRects())) {
                    let svg = (0, this.findSVGAtPoint)(rects[0].left, rects[0].top);
                    let boundingRect = svg.getBoundingClientRect();
                    let retctThis = this;
                    let rectangles = [].concat(this._toConsumableArray(rects)).map(function (r) {
                        let offset = 0;
                        return (0, retctThis.scaleDown)(svg, {
                            y: r.top + offset - boundingRect.top,
                            x: r.left - boundingRect.left,
                            width: r.width,
                            height: r.height
                        });
                    }).filter(function (r) {
                        return r.width > 0 && r.height > 0 && r.x > -1 && r.y > -1;
                    });
                    if (Paint.rect && Paint.rect.parentNode) {
                        svg.removeChild(Paint.rect);
                    }
                    Paint.rect = this.appendChild(svg, {
                        type: this.state.editType,
                        color: this.state.editSetting.highlightColor,
                        rectangles: rectangles
                    });
                }
                break;
            default:
                break;
        }
    }
    handleSvgMouseUp = (page, event) => {
        if (page !== Paint.page) return;
        if (this.state.editStatus === false) return;
        if (page !== Paint.page) {
            this.outSvgPaint();
            return;
        }
        let svg = void 0;
        let reactThis = this;
        switch (this.state.editType) {
            case 'curve':
                if (!Paint.hasOwnProperty('lines')) return;
                if (Paint.lines.length > 2 && (svg = (0, this.findSVGAtPoint)(event.clientX, event.clientY))) {
                    let _getMetadata = (0, this.getMetadata)(svg);
                    let documentId = _getMetadata.documentId;
                    let pageNumber = _getMetadata.pageNumber;
                    this.addAnnotation(documentId, pageNumber, {
                        type: 'curve',
                        width: this.state.editSetting.penSize,
                        color: this.state.editSetting.penColor,
                        lines: Paint.lines
                    }).then(function (annotation) {
                        if (Paint.path && svg === Paint.path.parentNode) {
                            svg.removeChild(Paint.path);
                        } (0, reactThis.appendChild)(svg, annotation);
                    });
                }
                Paint = {};
                break;
            case 'straightLine':
                if (!Paint.hasOwnProperty('line')) return;
                if (Paint.lines.x1 > 0 && Paint.lines.x1 > 0 && (svg = (0, this.findSVGAtPoint)(event.clientX, event.clientY))) {
                    let _getMetadata = (0, this.getMetadata)(svg);
                    let documentId = _getMetadata.documentId;
                    let pageNumber = _getMetadata.pageNumber;
                    let rect = svg.getBoundingClientRect();
                    let point = (0, this.scaleDown)(svg, {
                        x: event.clientX - rect.left,
                        y: event.clientY - rect.top
                    });
                    this.addAnnotation(documentId, pageNumber, {
                        type: 'straightLine',
                        width: this.state.editSetting.penSize,
                        color: this.state.editSetting.penColor,
                        x1: Paint.lines.x1,
                        y1: Paint.lines.y1,
                        x2: point.x,
                        y2: point.y
                    }).then(function (annotation) {
                        if (Paint.line && svg === Paint.line.parentNode) {
                            svg.removeChild(Paint.line);
                        } (0, reactThis.appendChild)(svg, annotation);
                    });
                }
                Paint = {};
                break;
            case 'highlight':
                if (!Paint.hasOwnProperty('rect')) return;
                let rects = void 0;
                if ((rects = this.getSelectionRects())) {
                    if (Paint.rect) {
                        let svg = (0, this.findSVGAtPoint)(rects[0].left, rects[0].top);
                        svg.removeChild(Paint.rect);
                    }
                    if(Paint.rect){
                        this.saveRect(this.state.editType, [].concat(this._toConsumableArray(rects)).map(function (r) {
                            return {
                                top: r.top,
                                left: r.left,
                                width: r.width,
                                height: r.height
                            };
                        }));
                    }
                    Paint = {};
                }
                break;
            default:
                break;
        }
    }
    handleSvgDelete = (page, event) => {
        if (this.state.editStatus === false) return;
        if (this.state.editType !== 'delete') return;
        let svg = this.findSVGAtPoint(event.clientX, event.clientY);
        if (svg) {
            var target = (0, this.findAnnotationAtPoint)(event.clientX, event.clientY);
            if (target) {
                let annotateType = target.getAttribute('data-book-annotate-type');
                if (annotateType !== 'highlight') return;
                let annotateId = target.getAttribute('data-book-annotate-id');
                target.remove();
                this._deleteAnnotation(this.state.bookId, annotateId);
            }
        }
    }

    outSvgPaint() {
        let reactThis = this;
        switch (this.state.editType) {
            case 'curve':
                if (!Paint.hasOwnProperty('lines')) return;
                if (Paint.lines.length <= 1) break;
                this.addAnnotation(this.state.bookId, Paint.page, {
                    type: 'curve',
                    width: this.state.editSetting.penSize,
                    color: this.state.editSetting.penColor,
                    lines: Paint.lines
                }).then(function (annotation) {
                    let svg = document.querySelector('[data-book-annotate-page="' + Paint.page + '"]');
                    if (Paint.path && svg === Paint.path.parentNode) {
                        svg.removeChild(Paint.path);
                    } (0, reactThis.appendChild)(svg, annotation);
                });
                break;
            case 'straightLine':
                if (!Paint.hasOwnProperty('line')) return;
                if (!Paint.lines.x2 || !Paint.lines.y2) break;
                this.addAnnotation(this.state.bookId, Paint.page, {
                    type: 'straightLine',
                    width: this.state.editSetting.penSize,
                    color: this.state.editSetting.penColor,
                    x1: Paint.lines.x1,
                    y1: Paint.lines.y1,
                    x2: Paint.lines.x2,
                    y2: Paint.lines.y2
                }).then(function (annotation) {
                    let svg = document.querySelector('[data-book-annotate-page="' + Paint.page + '"]');
                    if (Paint.line && svg === Paint.line.parentNode) {
                        svg.removeChild(Paint.line);
                    } (0, reactThis.appendChild)(svg, annotation);
                });
                break;
            default:
                break;
        }
        Paint = {};
    }
    emptyAnnotations = () => {
        let _userInfo = getUserInfo();
        $('#bookView').contents().find('svg[data-book-annotate-container="true"]').empty();
        localStorage.removeItem(this.state.bookId + '/' + _userInfo.userId + '/annotations');
        localStorage.removeItem(this.state.bookId + '/' + _userInfo.userId + '/mark');
    }
    straightLinePoint = (x, y) => {
        let svg = (0, this.findSVGAtPoint)(x, y);
        if (!svg) {
            return;
        }
        let rect = svg.getBoundingClientRect();
        let point = (0, this.scaleDown)(svg, {
            x: x - rect.left,
            y: y - rect.top
        });
        if (!Paint.lines.x1 || !Paint.lines.y1) {
            Paint.lines.x1 = point.x;
            Paint.lines.y1 = point.y;
            return;
        }
        Paint.lines.x2 = point.x;
        Paint.lines.y2 = point.y;
        if (Paint.line && svg === Paint.line.parentNode) {
            svg.removeChild(Paint.line);
        }
        Paint.line = (0, this.appendChild)(svg, {
            type: 'straightLine',
            color: this.state.editSetting.penColor,
            width: this.state.editSetting.penSize,
            x1: Paint.lines.x1,
            y1: Paint.lines.y1,
            x2: point.x,
            y2: point.y
        });
    }
    disableUserSelect() {
        let bookView = $('#bookView').contents().find('body');
        bookView.css({
            '-webkit-user-select': 'none',
            '-moz-user-select': 'none',
            '-ms-user-select': 'none',
            'user-select': 'none',
        });
        bookView.find("a").css({ 'cursor': 'default' });
    }
    enableUserSelect() {
        let bookView = $('#bookView').contents().find('body');
        bookView.css({
            '-webkit-user-select': 'text',
            '-moz-user-select': 'text',
            '-ms-user-select': 'text',
            'user-select': 'text',
        });
        bookView.find("a").css({ 'cursor': 'auto' });
    }

    savePoint = (x, y) => {
        let svg = (0, this.findSVGAtPoint)(x, y);
        if (!svg) {
            return;
        }
        let rect = svg.getBoundingClientRect();
        let point = (0, this.scaleDown)(svg, {
            x: x - rect.left,
            y: y - rect.top
        });
        Paint.lines.push([point.x, point.y]);
        if (Paint.lines.length <= 1) {
            return;
        }
        if (Paint.path && svg === Paint.path.parentNode) {
            svg.removeChild(Paint.path);
        }
        Paint.path = this.appendChild(svg, {
            type: 'curve',
            color: this.state.editSetting.penColor,
            width: this.state.editSetting.penSize,
            lines: Paint.lines
        });
    }
    /**
     * 加载已添加的注释层
     */
    loadAnnotations = (svg, viewport, data) => {
        let reactThis = this;
        return new Promise(function (resolve, reject) { // Reset the content of the SVG
            svg.innerHTML = '';
            svg.setAttribute('data-book-annotate-container', true);
            svg.setAttribute('data-book-annotate-viewport', JSON.stringify(viewport));
            svg.removeAttribute('data-book-annotate-document');
            svg.removeAttribute('data-book-annotate-page'); // If there's no data nothing can be done
            if (!data) {
                return resolve(svg);
            }
            svg.setAttribute('data-book-annotate-document', data.documentId);
            svg.setAttribute('data-book-annotate-page', data.pageNumber); // Make sure annotations is an array
            if (!Array.isArray(data.annotations) || data.annotations.length === 0) {
                return resolve(svg);
            } // Append annotation to svg
            data.annotations.forEach(function (a) {
                (0, reactThis.appendChild)(svg, a, viewport);
            });
            resolve(svg);
        });
    }
    getAnnotationRect = (el) => {
        var h = 0,
            w = 0,
            x = 0,
            y = 0;
        var rect = el.getBoundingClientRect(); // TODO this should be calculated somehow
        var LINE_OFFSET = 16;
        switch (el.nodeName.toLowerCase()) {
            case 'path':
                var minX = void 0,
                    maxX = void 0,
                    minY = void 0,
                    maxY = void 0;
                el.getAttribute('d').replace(/Z/, '').split('M').splice(1).forEach(function (p) {
                    var s = $.trim(p).replace('L ', '').split(' ').map(function (i) {
                        return parseInt(i, 10);
                    });
                    if (typeof minX === 'undefined' || s[0] < minX) {
                        minX = s[0];
                    }
                    if (typeof maxX === 'undefined' || s[2] > maxX) {
                        maxX = s[2];
                    }
                    if (typeof minY === 'undefined' || s[1] < minY) {
                        minY = s[1];
                    }
                    if (typeof maxY === 'undefined' || s[3] > maxY) {
                        maxY = s[3];
                    }
                });
                h = maxY - minY;
                w = maxX - minX;
                x = minX;
                y = minY;
                break;
            case 'g':
                var _getOffset2 = this.getOffset(el);
                var offsetLeft = _getOffset2.offsetLeft;
                var offsetTop = _getOffset2.offsetTop;
                h = rect.height;
                w = rect.width;
                x = rect.left - offsetLeft;
                y = rect.top - offsetTop;
                if (el.getAttribute('data-pdf-annotate-type') === 'StraightLine') {
                    h += LINE_OFFSET;
                    y -= LINE_OFFSET / 2;
                }
                break;
            case 'rect':
            case 'svg':
                h = parseInt(el.getAttribute('height'), 10);
                w = parseInt(el.getAttribute('width'), 10);
                x = parseInt(el.getAttribute('x'), 10);
                y = parseInt(el.getAttribute('y'), 10);
                break;
            default:
                break;
        }
        var result = {
            top: y,
            left: x,
            width: w,
            height: h,
            right: x + w,
            bottom: y + h
        };
        if (!['svg', 'g'].includes(el.nodeName.toLowerCase())) {
            result = this.scaleUp(this.findSVGAtPoint(rect.left, rect.top), result);
        }
        return result;
    }
    scaleUp = (svg, rect) => {
        var result = {};
        var _getMetadata = this.getMetadata(svg);
        var viewport = _getMetadata.viewport;
        Object.keys(rect).forEach(function (key) {
            result[key] = rect[key] * viewport.scale;
        });
        return result;
    }
    uuid = () => {
        let REGEXP = /[xy]/g;
        let PATTERN = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
        return PATTERN.replace(REGEXP, this.replacement);
    }
    replacement = (c) => {
        let r = Math.random() * 16 | 0;
        let v = c === 'x' ? r : r && 0x3 | 0x8;
        return v.toString(16);
    }
    _getAnnotations = (documentId) => {
        let _userInfo = getUserInfo();
        return JSON.parse(localStorage.getItem(documentId + '/' + _userInfo.userId + '/annotations')) || [];
    }
    getAnnotations = (documentId, pageNumber) => {
        let annotations = this._getAnnotations(documentId).filter(function (i) {
            return i.page === pageNumber && i.class === 'Annotation';
        });
        return ({
            documentId: documentId,
            pageNumber: pageNumber,
            annotations: annotations
        });
    }
    updateAnnotations = (documentId, annotations) => {
        let _userInfo = getUserInfo();
        localStorage.setItem(documentId + '/' + _userInfo.userId + '/annotations', JSON.stringify(annotations));
    }
    appendChild = (svg, annotation, viewport) => {
        if (!svg) return;
        if (!viewport) {
            viewport = JSON.parse(svg.getAttribute('data-book-annotate-viewport'));
        }
        let child = void 0;
        switch (annotation.type) {
            case 'highlight':
                child = (0, this.renderRect)(annotation);
                break;
            case 'straightLine':
                if (!annotation.x2 || !annotation.y2) break;
                child = (0, this.renderLine)(annotation);
                break;
            case 'curve':
                if (annotation.lines.length <= 1) return;
                child = (this.renderPath)(annotation);
                break;
            default:
                break;
        }
        if (child) { // Set attributes
            child.setAttribute('data-book-annotate-id', annotation.uuid);
            child.setAttribute('data-book-annotate-type', annotation.type);
            child.setAttribute('aria-hidden', true);
            svg.appendChild(this.transform(child, viewport));
        }
        return child;
    }
    transform = (node, viewport) => {
        let trans = this.getTranslation(viewport); // Let SVG natively transform the element
        node.setAttribute('transform', 'scale(' + viewport.scale + ') rotate(' + viewport.rotation + ') translate(' + trans.x + ', ' + trans.y + ')'); // Manually adjust x/y for nested SVG nodes
        let isFirefox = /firefox/i.test(navigator.userAgent);
        if (!isFirefox && node.nodeName.toLowerCase() === 'svg') {
            node.setAttribute('x', parseInt(node.getAttribute('x'), 10) * viewport.scale);
            node.setAttribute('y', parseInt(node.getAttribute('y'), 10) * viewport.scale);
            let x = parseInt(node.getAttribute('x', 10));
            let y = parseInt(node.getAttribute('y', 10));
            let width = parseInt(node.getAttribute('width'), 10);
            let height = parseInt(node.getAttribute('height'), 10);
            let path = node.querySelector('path');
            let svg = path.parentNode; // Scale width/height
            [node, svg, path, node.querySelector('rect')].forEach(function (n) {
                n.setAttribute('width', parseInt(n.getAttribute('width'), 10) * viewport.scale);
                n.setAttribute('height', parseInt(n.getAttribute('height'), 10) * viewport.scale);
            });
            switch (viewport.rotation % 360) {
                case 90:
                    node.setAttribute('x', viewport.width - y - width);
                    node.setAttribute('y', x);
                    svg.setAttribute('x', 1);
                    svg.setAttribute('y', 0);
                    break;
                case 180:
                    node.setAttribute('x', viewport.width - x - width);
                    node.setAttribute('y', viewport.height - y - height);
                    svg.setAttribute('y', 2);
                    break;
                case 270:
                    node.setAttribute('x', y);
                    node.setAttribute('y', viewport.height - x - height);
                    svg.setAttribute('x', -1);
                    svg.setAttribute('y', 0);
                    break;
                default:
                    break;
            }
        }
        return node;
    }
    getTranslation = (viewport) => {
        let x = void 0;
        let y = void 0;
        switch (viewport.rotation % 360) {
            case 0:
                x = y = 0;
                break;
            case 90:
                x = 0;
                y = viewport.width / viewport.scale * -1;
                break;
            case 180:
                x = viewport.width / viewport.scale * -1;
                y = viewport.height / viewport.scale * -1;
                break;
            case 270:
                x = viewport.height / viewport.scale * -1;
                y = 0;
                break;
            default:
                break;
        }
        return {
            x: x,
            y: y
        };
    }
    renderLine = (a) => {
        let line = this.createLine(a)
        let _ret = function () {
            let group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
            group.appendChild(line);
            return {
                v: group
            };
        }();
        let _typeof2 = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ?
            function (obj) {
                return typeof obj;
            } : function (obj) {
                return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj;
            };
        let _typeof = typeof Symbol === "function" && _typeof2(Symbol.iterator) === "symbol" ?
            function (obj) {
                return typeof obj === 'undefined' ? 'undefined' : _typeof2(obj);
            } : function (obj) {
                return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj === 'undefined' ? 'undefined' : _typeof2(obj);
            };
        if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
    }
    createLine = (r) => {
        let line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
        (0, this.setAttributes)(line, {
            x1: r.x1,
            y1: r.y1,
            x2: r.x2,
            y2: r.y2,
            strokeWidth: r.width || 1,
            stroke: (0, this.normalizeColor)(r.color || this.state.editSetting.penColor),
            fill: 'none'
        });
        return line;
    }
    renderPath = (a) => {
        let d = [];
        let path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        for (let i = 0, l = a.lines.length; i < l; i++) {
            let p1 = a.lines[i];
            let p2 = a.lines[i + 1];
            if (p2) {
                d.push('M' + p1[0] + ' ' + p1[1] + ' ' + p2[0] + ' ' + p2[1]);
            }
        } (0, this.setAttributes)(path, {
            d: d.join(' ') + 'Z',
            stroke: (0, this.normalizeColor)(a.color || '#000'),
            strokeWidth: a.width || 1,
            fill: 'none',
            'stroke-linecap': "round",
        });
        return path;
    }
    setAttributes = (node, attributes) => {
        let reactThis = this;
        Object.keys(attributes).forEach(function (key) {
            node.setAttribute(reactThis.keyCase(key), attributes[key]);
        });
    }
    keyCase = (key) => {
        let UPPER_REGEX = /[A-Z]/g; // Don't convert these attributes from camelCase to hyphenated-attributes
        let BLACKLIST = ['viewBox'];
        if (BLACKLIST.indexOf(key) === -1) {
            key = key.replace(UPPER_REGEX, function (match) {
                return '-' + match.toLowerCase();
            });
        }
        return key;
    }
    normalizeColor = (color) => {
        let REGEX_HASHLESS_HEX = /^([a-f0-9]{6}|[a-f0-9]{3})$/i;
        if (REGEX_HASHLESS_HEX.test(color)) {
            color = "#" + color;
        }
        return color;
    }
    getSelectionRects = () => {
        try {
            let selection = document.getElementById("bookView").contentWindow.getSelection();
            let range = selection.getRangeAt(0);
            let rects = range.getClientRects();

            if (rects.length > 0 && rects[0].width > 0 && rects[0].height > 0) {
                return rects;
            }
        } catch (e) { }
        return null;
    }
    _toConsumableArray = (arr) => {
        let arr2 = [];
        for (let i = 0; i < arr.length; i++) {
            arr2[i] = arr[i];
        }
        return arr2;
    }
    renderRect = (a) => {
        let reactThis = this;
        if (a.type === 'highlight') {
            let _ret = function () {
                let group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                (0, reactThis.setAttributes)(group, {
                    fill: (0, reactThis.normalizeColor)(a.color || '#ff0'),
                    fillOpacity: 0.2
                });
                a.rectangles.forEach(function (r) {
                    group.appendChild(reactThis.createRect(r));
                });
                return {
                    v: group
                };
            }();
            let _typeof2 = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ?
                function (obj) {
                    return typeof obj;
                } : function (obj) {
                    return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj;
                };
            let _typeof = typeof Symbol === "function" && _typeof2(Symbol.iterator) === "symbol" ?
                function (obj) {
                    return typeof obj === 'undefined' ? 'undefined' : _typeof2(obj);
                } : function (obj) {
                    return obj && typeof Symbol === "function" && obj.constructor === Symbol ? "symbol" : typeof obj === 'undefined' ? 'undefined' : _typeof2(obj);
                };
            if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
        } else {
            let rect = this.createRect(a);
            (0, this.setAttributes)(rect, {
                stroke: (0, this.normalizeColor)(a.color || '#f00'),
                fill: 'none'
            });
            return rect;
        }
    }
    createRect = (r) => {
        let rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
        (0, this.setAttributes)(rect, {
            x: r.x,
            y: r.y,
            width: r.width,
            height: r.height
        });
        return rect;
    }
    pointIntersectsRect = (x, y, rect) => {
        return y >= rect.top && y <= rect.bottom && x >= rect.left && x <= rect.right;
    }
    findSVGAtPoint = (x, y) => {
        let elements = $('#bookView').contents().find('svg[data-book-annotate-container="true"]');
        for (let i = 0, l = elements.length; i < l; i++) {
            let el = elements[i];
            let rect = el.getBoundingClientRect();
            if (this.pointIntersectsRect(x, y, rect)) {
                return el;
            }
        }
        return null;
    }
    saveRect = (type, rects) => {
        let svg = (0, this.findSVGAtPoint)(rects[0].left, rects[0].top);
        if (!svg) {
            return;
        }
        // let node = void 0;
        let annotation = void 0;
        let boundingRect = svg.getBoundingClientRect();
        if (!this.state.editSetting.highlightColor) {
            if (type === 'highlight') {
                Paint.editSetting = {
                    highlightColor: 'FFFF00'
                }
                // this.setState({
                //     Paint: Object.assign(this.state.editSetting, {
                //         highlightColor: 'FFFF00'
                //     })
                // });
            } else if (type === 'StraightLine') {
                Paint.editSetting = {
                    highlightColor: 'FFFF00'
                }
                // this.setState({
                //     Paint: Object.assign(this.state.editSetting, {
                //         highlightColor: 'FF0000'
                //     })
                // });
            }
        }
        let reactThis = this;
        let rectangles = [].concat(this._toConsumableArray(rects)).map(function (r) {
            let offset = 0;
            return (0, reactThis.scaleDown)(svg, {
                y: r.top + offset - boundingRect.top,
                x: r.left - boundingRect.left,
                width: r.width,
                height: r.height
            });
        }).filter(function (r) {
            return r.width > 0 && r.height > 0 && r.x > -1 && r.y > -1;
        });
        annotation = {
            type: type,
            color: this.state.editSetting.highlightColor,
            rectangles: rectangles
        };
        if (annotation.rectangles.length === 0) {
            return;
        }
        let _getMetadata = (0, this.getMetadata)(svg);
        let documentId = _getMetadata.documentId;
        let pageNumber = _getMetadata.pageNumber;
        this.addAnnotation(documentId, pageNumber, annotation).then(function (annotation) {
            (0, reactThis.appendChild)(svg, annotation);
        });
    }
    scaleDown = (svg, rect) => {
        let result = {};
        let _getMetadata2 = this.getMetadata(svg);
        let viewport = _getMetadata2.viewport;
        Object.keys(rect).forEach(function (key) {
            result[key] = rect[key] / viewport.scale;
        });
        return result;
    }
    getMetadata = (svg) => {
        return {
            documentId: $(svg).attr('data-book-annotate-document'),
            pageNumber: parseInt($(svg).attr('data-book-annotate-page'), 10),
            viewport: JSON.parse($(svg).attr('data-book-annotate-viewport'))
        };
    }
    findAnnotation = (documentId, annotationId) => {
        var index = -1;
        var annotations = this._getAnnotations(documentId);
        for (var i = 0, l = annotations.length; i < l; i++) {
            if (annotations[i].uuid === annotationId) {
                index = i;
                break;
            }
        }
        return index;
    }
    _deleteAnnotation = (documentId, annotationId) => {
        let reactThis = this;
        return new Promise(function (resolve, reject) {
            var index = reactThis.findAnnotation(documentId, annotationId);
            if (index > -1) {
                var annotations = reactThis._getAnnotations(documentId);
                annotations.splice(index, 1);
                reactThis.updateAnnotations(documentId, annotations);
            }
            resolve(true);
        });
    }
    addAnnotation = (documentId, pageNumber, annotation) => {
        let reactThis = this;
        return new Promise(function (resolve, reject) {
            annotation.class = 'Annotation';
            annotation.uuid = (0, reactThis.uuid)();
            annotation.page = pageNumber;
            annotation.time = (new Date()).getTime();
            annotation.title = reactThis.state.bookInfo.title;
            annotation.bookid = documentId;
            let annotations = reactThis._getAnnotations(documentId);
            annotations.push(annotation);
            reactThis.updateAnnotations(documentId, annotations);
            resolve(annotation);
        });
    }
    findAnnotationAtPoint = (x, y) => {
        var svg = this.findSVGAtPoint(x, y);
        if (!svg) {
            return;
        }
        var elements = svg.querySelectorAll('[data-book-annotate-type]'); // Find a target element within SVG
        for (var i = 0, l = elements.length; i < l; i++) {
            var el = elements[i];
            if (this.pointIntersectsRect(x, y, this.getOffsetAnnotationRect(el))) {
                return el;
            }
        }
        return null;
    }
    getOffsetAnnotationRect = (el) => {
        var rect = this.getAnnotationRect(el);
        var _getOffset = this.getOffset(el);
        var offsetLeft = _getOffset.offsetLeft;
        var offsetTop = _getOffset.offsetTop;
        return {
            top: rect.top + offsetTop,
            left: rect.left + offsetLeft,
            right: rect.right + offsetLeft,
            bottom: rect.bottom + offsetTop
        };
    }
    getOffset = (el) => {
        var parentNode = el;
        while ((parentNode = parentNode.parentNode) && parentNode !== document) {
            if (parentNode.nodeName.toUpperCase() === 'SVG') {
                break;
            }
        }
        var rect = parentNode.getBoundingClientRect();
        return {
            offsetLeft: rect.left,
            offsetTop: rect.top
        };
    }
    /*批注功能函数end */
    bookList = (searchKeyword) => {
        const { dispatch } = this.props;
        let me = this;
        dispatch(getBookListStart());
        bookSearch({
            searchInfo: searchKeyword
        }).then(res => {
            let docTblBookList = res['docTblBookList'];
            if (docTblBookList.length < 1) {
                Toast.info(me.state.dictWord.msg_error_record_not_found);
                dispatch(getBookListSuccess(
                    {
                        searchKeyword
                    }, res, this.state.oldList
                ));
                return;
            } else {
                for (let index in docTblBookList) {
                    let item = docTblBookList[index];
                    let coverUuid = item['docTblBookDetail']['coverUuid'];
                    let fileUuid = item['docTblBookDetail']['fileUuid'];
                    let categoryId = item['categoryId'];
                    let docType = item['docType'];

                    this.state.oldList.push(item);
                    this.setState({
                        oldList: this.state.oldList
                    })
                    getCoverPath(categoryId, fileUuid, coverUuid, docType).then(imgRes => {
                        docTblBookList[index]['imgSrc'] = imgRes;
                        dispatch(getBookListSuccess(
                            {
                                searchKeyword
                            }, res, this.state.oldList
                        ));
                    }).catch(() => {
                        docTblBookList[index]['imgSrc'] = "";
                    });
                }
                dispatch(getBookListSuccess(
                    {
                        searchKeyword
                    }, res, this.state.oldList
                ));
            }
        }).catch(() => {
            Toast.info(me.state.dictWord.msg_error_record_not_found);
        })
        this.props.history.push('/user/book/searchbooklist');
    }
    pageBack = () => {
        if (this.props.history.length > 2) {
            this.props.history.goBack();
        } else {
            this.props.history.push('/');
        }
    }

    ListItemNcx = () => {
        const { classes } = this.props;
        const { dictWord } = this.state;
        if (this.state.ncx.length > 0 && this.state.editStatus === false && this.state.pageLoading === false) {
            return (
                <div className="left-par">
                    <IconButton onClick={this.pageBack} >
                        <ArrowBackIosIcon fontSize="large" />
                    </IconButton>
                    <Button onClick={this.handleDrawerClick(true)}>{dictWord.directory}</Button>
                    <Drawer open={this.state.leftDrawer} onClose={this.handleDrawerClick(false)}>
                        <div
                            className={classes.list}
                            role="presentation"
                            onClick={this.handleDrawerClick(false)}
                            onKeyDown={this.handleDrawerClick(false)}
                        >
                            <List>
                                {this.state.ncx.map((item, index) => (
                                    <ListItem button key={index} onClick={this.loadPage.bind(this, item.pageNum, item.mark)}>
                                        <ListItemText primary={item.text} />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </Drawer>
                </div>
            );
        } else {
            return (<div className="left-par" style={{ position: 'relative' }}>
                <IconButton onClick={this.pageBack} >
                    <ArrowBackIosIcon fontSize="large" />
                </IconButton>
            </div>);
        }

    }

    SearchDiv = () => {
        const { classes } = this.props;
        const { dictWord, totlePage } = this.state;
        return (<em>
            <Hidden key="9" mdUp implementation="css">
                <MenuItem onClick={this.handleOpenSearch}>
                    <ListItemIcon>
                    <img src={SearchOutlined} className="iconSmall" alt="SearchOutlined" />
                    </ListItemIcon>
                    <Typography variant="inherit">{this.state.dictWord.search}</Typography>
                </MenuItem>
            </Hidden>
            <Hidden key="10" smDown implementation="css">
                <IconButton onClick={this.handleOpenSearch}>
                    <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.search}</p>} >
                    <img src={SearchOutlined} className="iconBig" alt="SearchOutlined" />
                    </Tooltip>
                </IconButton>
            </Hidden>
            <Dialog scroll="paper" onEntering={() => { $("#searchText").focus(); }} onClose={this.handleCloseSearch} open={this.state.searchStatus}>
                <SearchDialogTitle onClose={this.handleCloseSearch}>{dictWord.search}</SearchDialogTitle>
                <DialogContent>
                    <Paper className={classes.searchBooks}>
                        <img src={SearchOutlined} className="iconBig" style={{marginTop: '6px' }} alt="SearchOutlined" />
                        <Divider className={classes.searchDivider} />
                        <InputBase
                            className={classes.searchInput}
                            id="searchText"
                            placeholder={dictWord.search_word_enter}
                            onChange={this.handleInputChange.bind(this, 'searchText')}
                            value={this.state.formData.searchText.value}
                            onKeyUp={this.onKeyPress.bind(this)}
                            endAdornment={
                                <InputAdornment position="end">
                                    {this.state.formData.searchText.value ?
                                        <CloseIcon
                                            color="disabled"
                                            style={{ cursor: 'pointer', width: '18px', height: '18px' }}
                                            onClick={(e) => {
                                                let formData = this.state.formData;
                                                formData['searchText'].value = '';
                                                this.setState({ formData ,searchResult:[],totlePage:0})
                                            }}
                                        /> : ''}
                                </InputAdornment>
                            } />
                    </Paper>
                    <div className="search-list">
                        {this.state.searchResult.map((item, index) => (
                            <div className="search-item" key={index} onClick={this.handleTurningPage.bind(this, item.pageNumber)}>
                                <div>
                                    <div style={{
                                        width: '400px',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                    }} dangerouslySetInnerHTML={{ __html: item.text }} />
                                    <div style={{ fontSize: '12px', color: '#8a8686' }}>{this.state.dictWord.page} {item.pageNumber}</div>
                                </div>
                                <ChevronRight />
                            </div>
                        ))}
                        {totlePage > this.state.contentPageNumber ?
                            <div style={{ textAlign: 'center', padding: '10px 0px 20px', color: '#2196f3', cursor: 'pointer' }} onClick={this.nextTotlePage.bind(this)}>{this.state.dictWord.load_more}
                            </div> : null}
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant="contained" style={{ background: this.state.styleSetting.buttonColor }} onClick={this.handleSearch.bind(this)} color="primary">
                        {dictWord.search}
                    </Button>
                </DialogActions>
            </Dialog>
        </em>);
    }

    PageBtnDiv = () => {
        if (this.state.editStatus === false) {
            return (
                <div>
                    {(this.state.bookPage > 1 && this.state.flip === 0) ||
                        (this.state.flip === 1 && this.state.bookPage < this.state.totalPage) ?
                        <span className="prev-btn page-btn" title="prev page" onClick={this.handleFlipPage.bind(this, 'previous')}>
                            <PrevPageIcon />
                        </span>
                        : null}
                    {(this.state.bookPage > 1 && this.state.flip === 1) ||
                        (this.state.flip === 0 && this.state.bookPage < this.state.totalPage) ?
                        <span className="next-btn page-btn" title="next page" onClick={this.handleFlipPage.bind(this, 'next')}>
                            <NextPageIcon />
                        </span>
                        : null}
                </div>
            );
        }
    }

    ProgressBarDiv = () => {
        let sliderValue = 0;
        let handleChange = (event, value) => {
            sliderValue = parseInt(value);
        };
        let handleChangeCommitted = (event) => {
            if (sliderValue > 0) this.handleTurningPage(sliderValue);
            let me = this;
            setTimeout(function () {
                me.handleTurningPage(sliderValue);
            }, 500);
        };
        const { classes } = this.props;
        if (this.state.editStatus === false) {
            return (
                <div>
                    <div style={{ margin: '20px 0px' }}>
                        <Slider
                            classes={{ container: classes.slider }}
                            value={sliderValue ? sliderValue : this.state.bookPage}
                            aria-labelledby="label"
                            onChange={handleChange}
                            onDragEnd={handleChangeCommitted}
                            max={parseInt(this.state.totalPage)}
                            min={1}
                        />
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <span>page {this.state.bookPage}/{this.state.totalPage}</span>
                        <span style={{ marginLeft: 20 }}>{(Math.round(this.state.bookPage / this.state.totalPage * 10000) / 100.00) + "%"}</span>
                    </div>
                </div>
            );
        }
    }

    EvaluateDiv = () => {
        let handleOpenStarReview = () => {
            let _userInfo = getUserInfo();
            getBookEvaluate({
                userId: _userInfo.userId,
                bookId: this.state.bookId
            }).then(res => {
                this.setState({
                    score: res.score,
                    starReviewStatus: true,
                    editStatus: false,
                    optionOpen: false
                });
            }).catch(function (error) {
                Toast.error(error);
            });
        }
        let handleCloseStarReview = () => {
            this.setState({
                starReviewStatus: false
            })
        }
        let getScores = (score) => {
            if (score === this.state.score) score = 0;
            this.setState({
                score: score
            })
        }
        let /*Evaluate submit*/
            handleSubmitEvaluate = () => {
                let score = this.state.score;
                let _userInfo = getUserInfo();
                bookEvaluate({
                    bookId: this.state.bookInfo.id,
                    content: '',
                    score: score,
                    userId: _userInfo.userId
                }).then(res => {
                    Toast.info(this.state.dictWord.msg_evaluation_completed);
                }).catch(function (error) {
                    Toast.error(error);
                });
                this.setState({
                    starReviewStatus: false
                })
            }
        const { dictWord } = this.state;
        return (<em>
            <Hidden mdUp implementation="css">
                <MenuItem onClick={handleOpenStarReview}>
                    <ListItemIcon>
                    <img src={star} className="iconSmall" alt="star" />
                    </ListItemIcon>
                    <Typography variant="inherit">{this.state.dictWord.evaluation_record}</Typography>
                </MenuItem>
            </Hidden>
            <Hidden smDown implementation="css">
                <IconButton onClick={handleOpenStarReview} title={this.state.dictWord.evaluation_record}>
                    <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.evaluation_record}</p>}>
                        <img src={star} className="iconBig" alt="star" />
                    </Tooltip>
                </IconButton>
            </Hidden>
            <Dialog onClose={handleCloseStarReview} open={this.state.starReviewStatus}>
                <ReviewDialogTitle style={{ padding: 0 }} onClose={handleCloseStarReview} />
                <div className="star-wrap">
                    <Star star={this.state.score} enableClick getScores={(score) => getScores(score)} />
                </div>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button variant="contained" style={{ background: this.state.styleSetting.buttonColor }} onClick={handleSubmitEvaluate} color="primary">
                        {dictWord.evaluation}
                    </Button>
                </DialogActions>
            </Dialog>
        </em>);
    }
    MenuBtnDiv = () => {
        let handleOpenPrint = () => {
            this.setState({
                printStatus: true,
                editStatus: false,
            })
        }
        let handleClosePrint = () => {
            this.setState({
                printStatus: false
            })
        }
        let handleInformation = () => {
            this.props.history.replace({
                pathname: '/user/userInfo/information/',
                query: {
                    bookId: this.state.bookId,
                }
            })
        }
        let handlePrint = () => {
            this.setState({
                isPrint: true,
                pageLoading: true,
            })
            $('#bookView').contents().find('body').html('');
            let spine = this.state.spine;
            for (let i = 0; i < this.state.totalPage; i++) {
                let htmlPath = this.state.bookRootPath + this.state.menifest[spine[i]].href;
                // $('#bookView').contents().find('body').append("<iframe id='printPage_" + i + "' width='100%' frameBorder='0' />");
                $('#bookView').contents().find('body').append("<div style='padding: 20px' id='printPage_" + i + "'></div>");
                this.loadHtml(htmlPath, '', i);
            }

            let print = setInterval(function () {
                let isScc = false;
                $('#bookView').contents().find('body').children('div').each(function () {
                    // console.log($(this).contents().find('html').html())
                    if ($(this).html().length > 0) {
                        isScc = true;
                    } else {
                        isScc = false;
                        return false;
                    }
                });
                if (isScc === true) {
                    var iframe = document.getElementById('bookView').contentWindow;
                    iframe.focus();
                    iframe.print();
                    window.location.reload();
                    clearInterval(print);
                }
            }, 500);
        }
        const { classes } = this.props;
        const { dictWord } = this.state;
        return (<em>
            <Hidden mdUp implementation="css">
                {this.state.printFlg === true ? <MenuItem onClick={handlePrint}>{dictWord.printing}</MenuItem> : null}
                <MenuItem onClick={handleInformation}>{dictWord.save_information_list}</MenuItem>
            </Hidden>
            <Hidden smDown implementation="css">
                <div className="print-wrap">
                    <IconButton onClick={handleOpenPrint}>
                        <Menu fontSize="large" />
                    </IconButton>
                    <Popper open={this.state.printStatus} style={{ right: "0px", position: 'absolute',zIndex:9,top:'59px' }} anchorEl={this.anchorEl} transition disablePortal className={classes.paper}>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                id="menu-list-grow"
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClosePrint}>
                                        <MenuList>
                                            {this.state.printFlg === true ?
                                                <MenuItem onClick={handlePrint}>{dictWord.printing}</MenuItem> : null}
                                            <MenuItem onClick={handleInformation}>{dictWord.save_information_list}</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </Hidden>
        </em>);
    }
    CommentDiv = () => {
        const { dictWord } = this.state;
        let toggleMessage = () => {
            let messageObj = {
                messageBoxTitle: dictWord.comment,
                placeholder: dictWord.msg_error_not_null_with_item.replace('%s', dictWord.comment),
            }
            commentList(this.state.bookId, {
                pageNumber: 1,
                pageSize: this.state.commentSize,
            }).then(res => {
                let commentList = res.docTblUserCommentList;
                commentList.sort(function (obj1, obj2) {
                    return obj1.id - obj2.id;
                });
                this.setState({
                    chatboxOpen: true,
                    messageObj: messageObj,
                    comments: commentList
                })
                $('#customized-dialog-content').scrollTop($('#customized-dialog-content')[0].scrollHeight);
            }).catch(error => {
                this.setState({
                    chatboxOpen: true,
                    editStatus: false,
                    messageObj: messageObj,
                    comments: []
                })
            })
            this.setState({
                chatboxOpen: !this.state.chatboxOpen,
                optionOpen: false
            })
        }

        if (parseInt(this.state.commentFlg) === 0) {
            return (<em>
                <Hidden mdUp implementation="css">
                    <MenuItem onClick={toggleMessage}>
                        <ListItemIcon>
                            <Message fontSize="small" className="iconSmall" />
                        </ListItemIcon>
                        <Typography variant="inherit">{this.state.dictWord.comment}</Typography>
                    </MenuItem>
                </Hidden>
                <Hidden smDown implementation="css">
                    <IconButton onClick={toggleMessage}>
                        <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{dictWord.comment}</p>}>
                            <Message fontSize="large" className="iconBig" />
                        </Tooltip>
                    </IconButton>
                </Hidden>
            </em>);
        }
    }

    MarkDiv = () => {
        let handleMark = () => {
            let _userInfo = getUserInfo();
            let marks = JSON.parse(window.localStorage.getItem(this.state.bookId + '/' + _userInfo.userId + "/mark"));
            if (!marks) marks = [];
            if (marks.length > 0) {
                for (let i in marks) {
                    if (marks[i].page === this.state.bookPage) {
                        marks.splice(i, 1);
                        window.localStorage.setItem(this.state.bookId + '/' + _userInfo.userId + "/mark", JSON.stringify(marks));
                        this.setState({
                            isMark: false
                        });
                        return;
                    }
                }
            }
            let _mark = {
                bookid: this.state.bookId,
                title: this.state.bookInfo.title,
                type: 'mark',
                time: (new Date()).getTime(),
                page: this.state.bookPage
            }
            marks.push(_mark);
            this.setState({
                isMark: true
            });
            window.localStorage.setItem(this.state.bookId + '/' + _userInfo.userId + "/mark", JSON.stringify(marks));
        }
        const { dictWord } = this.state;
        return (<em>
            <Hidden mdUp implementation="css">
                <MenuItem onClick={handleMark}>
                    <ListItemIcon>
                        <img src={this.state.isMark ? label_light : label} alt={this.state.dictWord.label} className="iconSmall" />
                    </ListItemIcon>
                    <Typography variant="inherit">{this.state.dictWord.label}</Typography>
                </MenuItem>
            </Hidden>
            <Hidden smDown implementation="css">
                    <IconButton onClick={handleMark}>
                        <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{dictWord.label}</p>}>
                            <img src={this.state.isMark ?label_light:label} alt={this.state.dictWord.label} className="iconBig" />
                        </Tooltip>
                    </IconButton>
            </Hidden></em>)
    }

    NoteDiv = () => {
        let handleOpenBookNoteList = () => {
            let bookId = this.state.bookId;
            let _userInfo = getUserInfo();
            let annotations = [];
            for (let i = 0; i < localStorage.length; i++) {
                let flg = '/' + _userInfo.userId;
                if (parseInt(bookId) > 0) {
                    flg = bookId + '/' + _userInfo.userId;
                }
                if (localStorage.key(i).indexOf(flg + "/annotations") >= 0) {
                    let _annotation = JSON.parse(localStorage.getItem(localStorage.key(i))) || [];
                    if (_annotation.length > 0) {
                        for (let j = 0; j < _annotation.length; j++) {
                            if (_annotation[j].type === 'note') {
                                _annotation[j].typeDic = 'notes';
                                annotations.push(_annotation[j]);
                            }
                        }
                    }
                }
            }
            annotations.sort(function (obj1, obj2) {
                return obj2.time - obj1.time;
            });
            this.setState({
                annotations,
                noteListStatus: true,
                optionOpen: false
            });
        }
        let handeSaveBookNote = () => {
            let noteText = this.state.formData.noteText.value;
            if (noteText.length <= 0) return;
            let annotation = {
                type: 'note',
                noteText: noteText
            }
            this.addAnnotation(this.state.bookId, this.state.bookPage, annotation);
            let formData = this.state.formData;
            formData['noteText'].value = '';
            this.setState({
                formData,
                noteStatus: false,
                confirmOpen: false
            }, () => {
                handleOpenBookNoteList();
            });
        }
        let closeAddNote = () => {
            let noteText = this.state.formData.noteText.value;
            if ($.trim(noteText).length <= 0) {
                this.setState({ noteStatus: !this.state.noteStatus })
            } else {
                this.setState({ confirmOpen: true })
            }
        }

        let handleNoteDelete = () => {
            let _userInfo = getUserInfo();
            let itemId = this.state.noteKey;
            let annotation = this.state.annotations[itemId];

            let _annotations = JSON.parse(localStorage.getItem(annotation.bookid + '/' + _userInfo.userId + '/annotations')) || [];
            for (let i in _annotations) {
                if (_annotations[i].uuid === annotation.uuid) {
                    _annotations.splice(i, 1);
                    break;
                }
            }

            let annotations = this.state.annotations;
            annotations.splice(itemId, 1);
            this.setState({
                annotations,
                noteDelete: false
            }, () => {
                localStorage.setItem(annotation.bookid + '/' + _userInfo.userId + '/annotations', JSON.stringify(_annotations));
            });
        }
        const { dictWord } = this.state;
        let noteInfo = this.state.noteInfo;
        if (noteInfo) {
            noteInfo = noteInfo.replace(/\n/g, "<br/>");
            noteInfo = noteInfo.replace(/\s/g, "&nbsp;");
        }
        return (<em>
            <Hidden mdUp implementation="css">
                <MenuItem onClick={handleOpenBookNoteList}>
                    <ListItemIcon>
                    <img src={notes} alt={this.state.dictWord.notes} className="iconSmall" />
                    </ListItemIcon>
                    <Typography variant="inherit">{this.state.dictWord.notes}</Typography>
                </MenuItem>
            </Hidden>
            <Hidden smDown implementation="css">
                <IconButton onClick={handleOpenBookNoteList}>
                    <Tooltip title={<p style={{fontSize:'10.5px',margin:0}}>{this.state.dictWord.notes}</p>}>
                    <img src={notes} alt={this.state.dictWord.notes} className="iconBig" />
                    </Tooltip>
                </IconButton>
            </Hidden>
            <Dialog
                open={this.state.noteStatus}
            >
                <Toolbar style={{ display: 'flex' }}>
                    <ChevronLeft style={{ flex: 1, cursor: 'pointer' }} onClick={closeAddNote} />
                    <DialogTitle style={{ flex: 9, textAlign: 'center' }}>{dictWord.new_notes_insert}</DialogTitle>
                    <CloseIcon style={{ flex: 1, cursor: 'pointer' }} onClick={closeAddNote} />
                </Toolbar>
                <DialogContent>
                    <textarea rows={12} cols={53} style={{ resize: 'unset' }} onChange={this.handleInputChange.bind(this, 'noteText')} />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handeSaveBookNote} style={{ background: this.state.styleSetting.buttonColor }} color="primary">
                        {dictWord.registration}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                onClose={() => { this.setState({ confirmOpen: false }) }}
                open={this.state.confirmOpen}
            >
                <DialogContent>
                    {dictWord.unsaved_line}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handeSaveBookNote} style={{ background: this.state.styleSetting.buttonColor }} color="primary">
                        {dictWord.yes}
                    </Button>
                    <Button variant="contained" onClick={() => {
                        let formData = this.state.formData;
                        formData['noteText'].value = '';
                        this.setState({ confirmOpen: false, noteStatus: false, formData })
                    }} style={{ background: this.state.styleSetting.buttonColor }} color="primary">
                        {dictWord.cancel}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                scroll="paper"
                open={this.state.noteListStatus}
            >
                <Toolbar style={{ display: 'flex' }}>
                    <AddIcon color="primary" style={{ flex: 1, cursor: 'pointer' }} onClick={() => { this.setState({ noteStatus: !this.state.noteStatus }) }} />
                    <DialogTitle style={{ flex: 9, textAlign: 'center' }}>{dictWord.notes_list}</DialogTitle>
                    <CloseIcon style={{ flex: 1, cursor: 'pointer' }} onClick={() => { this.setState({ noteListStatus: !this.state.noteListStatus }) }} />
                </Toolbar>
                <DialogContent style={{ width: '440px', height: '230px'}}>
                    {this.state.annotations.map((item, index) => {
                        let noteTextInfo = "";
                        if(item.hasOwnProperty('noteText') === true){
                            let textNum = item.noteText.indexOf('\n');
                            if(textNum >= 0){
                                noteTextInfo =  item.noteText.substr(0, textNum)+"...";;
                            }else{
                                noteTextInfo = item.noteText;
                            }
                        }
                        return <div style={{ padding: '10px 0px' }} key={index}>
                            <div style={{ display: 'flex' }}>
                                <div style={{
                                    width: '80px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    lineHeight: '28px',
                                    flex: 5,
                                    cursor: 'pointer'
                                }} onClick={() => { this.setState({ noteInfo: item.noteText }) }}>{noteTextInfo}</div>
                                <span onClick={() => { this.setState({ noteInfo: item.noteText }) }} style={{ fontSize: '12px', color: '#8a8686', flex: 2, lineHeight: '28px', textAlign: 'right', cursor: 'pointer' }}>{item.page}{this.state.dictWord.page}</span>
                                <span style={{ flex: 1, textAlign: 'right', color: '#8a8686', cursor: 'pointer' }}><CloseIcon onClick={() => { this.setState({ noteKey: index, noteDelete: true }) }} style={{ width: '20px', height: '20px' }} /></span>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <span onClick={() => { this.setState({ noteInfo: item.noteText }) }} style={{ fontSize: '12px', color: '#8a8686', flex: 1, lineHeight: '28px', textAlign: 'left', cursor: 'pointer' }}>{dateFormat(new Date(item.time), 'yyyy/MM/dd hh:mm:ss')}</span>
                                <span onClick={() => { this.setState({ noteInfo: item.noteText }) }} style={{ flex: 1, textAlign: 'right', color: '#8a8686', cursor: 'pointer' }}><ChevronRight /></span>
                            </div>
                            <Divider style={{ margin: '10px 0px', width: '100%' }} />
                        </div>;
                    })}

                </DialogContent>
            </Dialog>
            <Dialog onClose={() => { this.setState({ noteInfo: null }) }} open={this.state.noteInfo ? true : false}>
            <Toolbar style={{ display: 'flex' }}>
                <DialogTitle style={{flex:9, textAlign: 'center' }}>{this.state.dictWord.notes_detail}</DialogTitle>
                <CloseIcon style={{ flex: 1, cursor: 'pointer' }} onClick={() => { this.setState({ noteInfo: null }) }} />
            </Toolbar>
                <DialogContent style={{ minWidth: '400px', minHeight: '200px' }}>
                    {this.state.noteInfo ? <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(noteInfo) }} /> : null}
                </DialogContent>
            </Dialog>
            <Dialog onClose={() => { this.setState({ noteDelete: false }) }} open={this.state.noteDelete ? true : false}>
                <DialogContent>{this.state.dictWord['delete_it']}</DialogContent>
                <DialogActions>
                    <Button onClick={handleNoteDelete} color="primary">{this.state.dictWord.yes}</Button>
                    <Button onClick={() => { this.setState({ noteDelete: false }) }} color="primary">{this.state.dictWord.cancel}</Button>
                </DialogActions>
            </Dialog>
        </em>);
    }
    FontDiv = () => {
        let handleFontMsgOpen = () => {
            this.setState({
                fontMsgOpen: true
            });
        }
        let handleFontMsgClose = () => {
            this.setState({
                fontMsgOpen: false,
            })
        }
        let handleCloseFontSize = () => {
            this.setState({
                fontsizeStatus: false,
            })
        }
        const { dictWord } = this.state;
        const { classes } = this.props;
        return (<em>
            <Hidden mdUp implementation="css">
                <MenuItem onClick={handleFontMsgOpen}>
                    <ListItemIcon>
                    <img src={font} alt={font} className="iconSmall" />
                    </ListItemIcon>
                    <Typography variant="inherit">{this.state.dictWord.font}</Typography>
                </MenuItem>
            </Hidden>
            <Hidden smDown implementation="css">
                <IconButton onClick={handleFontMsgOpen} >
                    <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{dictWord.font}</p>}>
                    <img src={font} alt={font} className="iconBig" />
                    </Tooltip>
                </IconButton>
                <Dialog onClose={this.handleOpenFontSize} open={this.state.fontMsgOpen}>
                    <DialogContent>
                        {dictWord['not_save_your_change']}
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onMouseUp={this.handleOpenFontSize} >{dictWord.yes}</Button>
                        <Button color="primary" onClick={handleFontMsgClose} autoFocus>{dictWord.cancel}</Button>
                    </DialogActions>
                </Dialog>
                <Dialog style={{ overflowX: 'hidden' }} onClose={handleCloseFontSize} open={this.state.fontsizeStatus} aria-labelledby="fontsize-dialog-title">
                    <Toolbar style={{ minHeight: '30px', paddingRight: '10px' }}>
                        <DialogTitle style={{ flexGrow: 1, textAlign: 'center' }}><b>{dictWord.font}</b></DialogTitle>
                        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleCloseFontSize}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    <div className="pd10 text-center">
                        <Button onClick={this.handleChangeFontFamily.bind(this, 'SimSun')} style={{ background: this.state.styleSetting.buttonColor }} variant="contained" size="small" color="primary" className={classes.mar5}>宋体</Button>
                        <Button onClick={this.handleChangeFontFamily.bind(this, 'KaiTi')} style={{ background: this.state.styleSetting.buttonColor }} variant="contained" size="small" color="primary" className={classes.mar5}>楷体</Button>
                        <Button onClick={this.handleChangeFontFamily.bind(this, 'Microsoft Yahei')} style={{ background: this.state.styleSetting.buttonColor }} variant="contained" size="small" color="primary" className={classes.mar5}>微软雅黑</Button>
                    </div>
                    <p style={{ width: '100%', padding: '0 20px', margin: '0 auto' }}>
                        <span style={{ fontSize: '17px', float: 'left' }}>Aa</span>
                        <span style={{ fontSize: '25px', float: 'right' }}>Aa</span>
                    </p>

                    <Slider
                        classes={{ container: classes.fontSlider }}
                        aria-labelledby="label"
                        style={{ padding: '0 20px' }}
                        value={this.state.fontsizeProgress}
                        onChange={this.handleChangeFontsize}
                        min={1}
                        max={140}
                    />

                </Dialog>
            </Hidden></em>);
    }

    EnlargeDiv = () => {
        let handleReduce = () => {
            let scale = parseFloat(this.state.scale) * 10 - 2;
            if (scale <= 0) return;
            scale = scale / 10;
            $('#bookView').contents().find('body').css('transform', 'scale(' + scale + ')');
            this.setState({ scale: scale, editStatus: false });
        }
        let handleEnlarge = () => {
            let scale = parseFloat(this.state.scale) * 10 + 2;
            if (scale >= 60) return;
            scale = scale / 10;
            // $('#bookView').contents().find('html').css('overflow','auto');
            $('#bookView').contents().find('body').css('transform', 'scale(' + scale + ')');
            this.setState({ scale: scale, editStatus: false });
        }
        return (<em>
            <Hidden mdUp implementation="css">
                <MenuItem onClick={handleReduce}>
                    <ListItemIcon>
                        <ReduceIcon fontSize="small" className="iconSmall" />
                    </ListItemIcon>
                    <Typography variant="inherit">{this.state.dictWord.reduction}</Typography>
                </MenuItem>
                <MenuItem onClick={handleEnlarge}>
                    <ListItemIcon>
                        <EnlargeIcon fontSize="small" className="iconSmall" />
                    </ListItemIcon>
                    <Typography variant="inherit">{this.state.dictWord.expansion}</Typography>
                </MenuItem>
            </Hidden>
            <Hidden smDown implementation="css">
                <IconButton onClick={handleReduce}>
        <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.reduction}</p>}>
                        <ReduceIcon fontSize="large" className="iconBig" />
                    </Tooltip>
                </IconButton>
                <IconButton onClick={handleEnlarge}>
        <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.expansion}</p>}>
                        <EnlargeIcon fontSize="large" className="iconBig" />
                    </Tooltip>
                </IconButton>
            </Hidden>
        </em>);
    }
    AnnotationDiv = () => {
        const { dictWord } = this.state;
        let toggleEdit = () => {
            let editType = '';
            if (this.state.editStatus === false) editType = 'highlight';
            this.enableUserSelect();
            this.setState({
                editStatus: !this.state.editStatus,
                editType: editType,
                optionOpen: false
            })
        }
        return (<em>
            <Hidden mdUp implementation="css">
                <MenuItem onClick={toggleEdit}>
                    <ListItemIcon>
                        <img src={this.state.editStatus ? NotiHi : Noti} alt={dictWord.pen} className="iconSmall" />
                    </ListItemIcon>
                    <Typography variant="inherit">{this.state.dictWord.pen}</Typography>
                </MenuItem>
            </Hidden>
            <Hidden smDown implementation="css">
                <IconButton onClick={toggleEdit} >
                    <Tooltip title={<p style={{fontSize:'10.5px',margin:0}}>{dictWord.pen}</p>}>
                        <img src={this.state.editStatus ? NotiHi : Noti} alt={dictWord.pen} className="iconBig" />
                    </Tooltip>
                </IconButton>
            </Hidden>
        </em>);
    }
    ColorDiv = () => {
        const { classes } = this.props;
        let handleChangeColor = (value) => event => {
            let editSetting = this.state.editSetting;
            editSetting.highlightColor = value;
            editSetting.penColor = value;
            this.setState({
                colorStatus: event.target.value,
                editSetting: editSetting
            });
        };

        return (
            <div id="colorSelect" style={{ position: 'relative', width: '100%', display: this.state.editStatus ? 'flex' : 'none' }}>
                <Radio
                    checked={this.state.colorStatus === 'a'}
                    onChange={handleChangeColor("#1e88e5")}
                    value="a"
                    name="radio-button-color"
                    aria-label="A"
                    classes={{
                        root: classes.bluecolor,
                        checked: classes.checked,
                    }}
                />
                <Radio
                    checked={this.state.colorStatus === 'b'}
                    onChange={handleChangeColor("#43a047")}
                    value="b"
                    name="radio-button-color"
                    aria-label="B"
                    classes={{
                        root: classes.greencolor,
                        checked: classes.checked,
                    }}
                />
                <Radio
                    checked={this.state.colorStatus === 'c'}
                    onChange={handleChangeColor("#8e24aa")}
                    value="c"
                    name="radio-button-color"
                    aria-label="C"
                    classes={{
                        root: classes.purplecolor,
                        checked: classes.checked,
                    }}
                />
                <Radio
                    checked={this.state.colorStatus === 'd'}
                    onChange={handleChangeColor("#e53935")}
                    value="d"
                    name="radio-button-color"
                    aria-label="D"
                    classes={{
                        root: classes.redcolor,
                        checked: classes.checked,
                    }}
                />
                <Radio
                    checked={this.state.colorStatus === 'e'}
                    onChange={handleChangeColor("#fb8c00")}
                    value="e"
                    name="radio-button-color"
                    aria-label="e"
                    classes={{
                        root: classes.orangecolor,
                        checked: classes.checked,
                    }}
                />
                <Radio
                    checked={this.state.colorStatus === 'f'}
                    onChange={handleChangeColor('#ffff00')}
                    value="f"
                    name="radio-button-color"
                    aria-label="f"
                    classes={{
                        root: classes.yellowcolor,
                        checked: classes.checked,
                    }}
                />
                <Radio
                    checked={this.state.colorStatus === 'g'}
                    onChange={handleChangeColor('#fafafa')}
                    value="g"
                    name="radio-button-color"
                    aria-label="g"
                    classes={{
                        root: classes.greycolor,
                        checked: classes.checked,
                    }}
                />
            </div>
        )
    }
    AnnotationBttonDiv = () => {
        const { classes } = this.props;
        // const { dictWord } = this.state;
        let handleSvgRevoke = () => {
            if (this.state.editStatus === false) return;
            let annotations = this._getAnnotations(this.state.bookId);
            annotations.sort(function (obj1, obj2) {
                return obj2.time - obj1.time;
            });
            let pages = [this.state.bookPage];
            let iframe = document.getElementById("bookView").contentDocument;
            let notUuid = iframe.querySelectorAll('[data-book-annotate-id="undefined"]');
            if (notUuid.length > 0) {
                for (let b in notUuid) {
                    if (typeof (notUuid[b]) === "object" && notUuid[b].getAttribute('data-book-annotate-type') === 'highlight') continue;
                    if (!notUuid[b].parentNode) continue;
                    notUuid[b].remove();
                }
            }
            for (let i in annotations) {
                if (pages.indexOf(annotations[i].page) > -1 && (annotations[i].type === 'curve' || annotations[i].type === 'straightLine')) {
                    let node = iframe.querySelectorAll('[data-book-annotate-id="' + annotations[i].uuid + '"]');
                    if (node.length > 0) node[0].remove();
                    this._deleteAnnotation(this.state.bookId, annotations[i].uuid);
                    break;
                }
            }
        }
        let handleBookEdit = (type) => {
            if (type.length > 0 && this.state.editStatus === true) {
                if (type === 'highlight') {
                    this.enableUserSelect();
                } else {
                    this.disableUserSelect();
                }
                this.setState({
                    editType: type
                });
            }
        }
        let handleSvgPenSize = (event, value) => {
            this.setState({
                editSetting: Object.assign(this.state.editSetting, { penSize: value })
            });
        }
        return (
            <div className="fab-wrap" style={{ display: this.state.editStatus ? 'flex' : 'none',marginRight:'30px' }}>
                {this.state.editType === 'curve' || this.state.editType === 'straightLine' ?
                    <div className="slider-wraper">
                        <Slider
                            classes={{ container: classes.penSlider }}
                            className="rigth-slider"
                            value={this.state.editSetting.penSize}
                            aria-labelledby="label"
                            onChange={handleSvgPenSize}
                            max={30}
                            min={1}
                            style={{padding:'0px 10px'}}
                            vertical
                        />
                    </div>
                    : null
                }
                <Fab className="option-btn fabUndo">
                <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.original_return}</p>}>
                    <UndoIcon onClick={handleSvgRevoke.bind(this)} />
                </Tooltip>
                </Fab>
                {this.state.editType === 'delete' ?
                    <Fab color="primary" className="option-btn fabDelete">
                        <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.delete_record}</p>}>
                        <DeleteIcon onClick={handleBookEdit.bind(this, 'delete')} color="primary" />
                        </Tooltip>
                    </Fab>
                    :
                    <Fab className="option-btn fabDelete">
                         <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.delete_record}</p>}>
                        <DeleteIcon onClick={handleBookEdit.bind(this, 'delete')} color="primary" />
                        </Tooltip>
                    </Fab>
                }
                {this.state.editType === 'highlight' ?
                    <Fab color="primary" className="option-btn fabSelect" onClick={handleBookEdit.bind(this, 'highlight')}>
                        <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.marker}</p>}>
                        <img src={pen_marker} alt={this.state.dictWord.marker} style={{ width: '25px', height: '26px' }} />
                        </Tooltip>
                    </Fab>
                    :
                    <Fab className="option-btn fabSelect" onClick={handleBookEdit.bind(this, 'highlight')}>
                        <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.marker}</p>}>
                        <img src={pen_marker} alt={this.state.dictWord.marker} style={{ width: '25px', height: '26px' }} />
                        </Tooltip>
                    </Fab>
                }
                {this.state.editType === 'curve' ?
                
                    <Fab color="primary" aria-label="tilde" className="option-btn fabTilde" onClick={handleBookEdit.bind(this, 'curve')}>
                        <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.curve}</p>}>
                        <img src={pen_curve} alt={this.state.dictWord.curve} style={{ width: '25px', height: '26px' }} />
                        </Tooltip>
                    </Fab>
                    :
                    <Fab aria-label="tilde" className="option-btn fabTilde" onClick={handleBookEdit.bind(this, 'curve')}>
                        <Tooltip title={<p style={{margin:0,fontSize:'10.5px'}}>{this.state.dictWord.curve}</p>} >
                        <img src={pen_curve} alt={this.state.dictWord.curve} style={{ width: '25px', height: '26px' }} />
                        </Tooltip>
                    </Fab>
                }
                {this.state.editType === 'straightLine' ?
                    <Fab color="primary" aria-label="vector" className="option-btn fabVector" onClick={handleBookEdit.bind(this, 'straightLine')}>
                        <Tooltip title={<p style={{margin:0,fontSize:'12px'}}>{this.state.dictWord.straight_line}</p>} >
                        <img src={pen_straight} alt={this.state.dictWord.straight_line} style={{ width: '25px', height: '26px' }} />
                        </Tooltip>
                    </Fab>
                    :
                    <Fab aria-label="vector" className="option-btn fabVector" onClick={handleBookEdit.bind(this, 'straightLine')}>
                        <Tooltip title={<p style={{margin:0,fontSize:'12px'}}>{this.state.dictWord.straight_line}</p>}>
                        <img src={pen_straight} alt={this.state.dictWord.straight_line} style={{ width: '25px', height: '26px' }} />
                        </Tooltip>
                    </Fab>
                }
            </div>
        )
    }

    DialogsDiv = () => {
        let handleOpenReadMsg = () => {
            this.setState({
                readMsg: false,
            });
            this.handleTurningPage(parseInt(this.state.readPage));
        }
        let handleCloseReadMsg = () => {
            this.setState({
                readMsg: false,
            });
        }
        if (this.state.pageLoading === false) {
            return (
                <div>
                    <Dialog onClose={handleCloseReadMsg} open={this.state.readMsg}>
                        <DialogContent>
                            {this.state.dictWord['msg_read_confirm']}
                        </DialogContent>
                        <DialogActions>
                            <Button onMouseUp={handleOpenReadMsg} color="primary">{this.state.dictWord['yes']}</Button>
                            <Button onClick={handleCloseReadMsg} color="primary" autoFocus>{this.state.dictWord['cancel']}</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        } else {
            return (
                <div className="loading-div">
                    <div className="loading">
                        <Fade in
                            style={{
                                transitionDelay: '800ms',
                                margin: '0 auto',
                                display: 'block'
                            }}
                            unmountOnExit
                        >
                            <CircularProgress size={80} />
                        </Fade>
                    </div>
                </div>
            );
        }
    }

    render() {
        // const { classes } = this.props;
        const { dictWord } = this.state;
        return (
            <div className="book-wrap" >
                <div className="tool-bars">
                    {this.ListItemNcx()}
                    <div className="center-part" title={this.state.bookInfo.title}>
                        {this.state.bookInfo.title}
                    </div>
                    {this.state.pageLoading === false ?
                        <div className="right-part">
                            <Hidden mdUp implementation="css">
                                <div className="option-box">
                                    <IconButton onClick={() => { this.setState({ optionOpen: true, editStatus: false }) }}>
                                        <MoreVert fontSize="large" />
                                    </IconButton>
                                    <Popper open={this.state.optionOpen} className="option" anchorEl={this.anchorEl} role={undefined} transition disablePortal>
                                        {({ TransitionProps, placement }) => (
                                            <Grow
                                                {...TransitionProps}
                                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                            >
                                                <Paper>
                                                    <ClickAwayListener onClickAway={() => { this.setState({ optionOpen: false, editStatus: false }) }}>
                                                        <MenuList>
                                                            {this.EnlargeDiv()}
                                                            {this.FontDiv()}
                                                            {this.MarkDiv()}
                                                            {this.state.scale === 1 ?this.AnnotationDiv():null}
                                                            {this.NoteDiv()}
                                                            {this.CommentDiv()}
                                                            {this.SearchDiv()}
                                                            {this.EvaluateDiv()}
                                                            {this.MenuBtnDiv()}
                                                        </MenuList>
                                                    </ClickAwayListener>
                                                </Paper>
                                            </Grow>
                                        )}
                                    </Popper>
                                </div>
                            </Hidden>
                            <Hidden smDown implementation="css">
                                <div className="option-box-inline">
                                    {this.EnlargeDiv()}
                                    {this.FontDiv()}
                                    {this.MarkDiv()}
                                    {this.state.scale === 1 ?this.AnnotationDiv():null}
                                    {this.NoteDiv()}
                                    {this.CommentDiv()}
                                    {this.SearchDiv()}
                                    {this.EvaluateDiv()}
                                    {this.MenuBtnDiv()}
                                </div>
                            </Hidden>
                        </div> : null}
                </div>
                <ChatBox
                    fatherFn={this.fatherFn}
                    chatboxStatus={this.state.chatboxOpen}
                    commentMessage={this.state.commentMessage}
                    messageObj={this.state.messageObj}
                    messageList={this.state.comments}
                    onSendMessage={this.sendMessage}
                    receiveHandle={this.receiveHandle}
                    loadMessage={dictWord.show_history}
                    onLoadMessage={this.handleLoadMessage}
                />
                {this.DialogsDiv()}
                <Divider />
                {this.ColorDiv()}
                <div id="bookContainer">
                    {this.state.pageLoading === false ?
                        this.AnnotationBttonDiv()
                        : null}
                    <iframe
                        title="epubRead"
                        id="bookView"
                        scrolling="auto"
                        frameBorder="0" seamless
                        srcDoc={this.state.srcDoc}
                    />
                    {this.state.pageLoading === false ? <div>
                        {this.PageBtnDiv()}
                        {this.ProgressBarDiv()}
                    </div> : null}
                </div>
            </div>
        )
    };
}

EpubRead.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
    return {
        bookListData: state.reducer.bookListData.bookList || [],
        searchText: state.reducer.bookListData.searchKeywords || '',
    };
}

export default withStyles(styles)(connect(mapStateToProps, null)(EpubRead));