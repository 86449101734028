//common component as one dom element.
import React from 'react';
import ReactMixin from 'react-mixin';
import Remove from '@material-ui/icons/Remove';
import Check from '@material-ui/icons/Check';
import Star from 'mdoc/utils/Star';
import { IconButton } from '@material-ui/core';
import MixFns from 'mdoc/utils/mixin.js'

export default class OneItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            direction: 'column',
            // recently: [],
            choosedIcons: [],
            selectedDisabled:false
        }
        this.choosedIcons = [];
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.selectedDisabled !== this.state.selectedDisabled){
            this.setState({
                choosedIcons: [],
                selectedDisabled:nextProps.selectedDisabled
            });
        }
    }
    toggleIconTag = (index) => {
        let i = this.state.choosedIcons.indexOf(index);
        if (i === -1) {
            this.state.choosedIcons.push(index);
            let newArr = [...this.state.choosedIcons];
            this.setState({
                choosedIcons: newArr
            })
        } else {
            this.state.choosedIcons.splice(i, 1);
            let newArr = this.state.choosedIcons;
            this.setState({
                choosedIcons: newArr
            })
        }
        this.props.fatherFn(this.state.choosedIcons);
    }
    
    jump = (item) => {
        this.props.jump(item);
    }

    render() {
        return (
            <div className="edit-row">
                {
                    this.props.fatherData.map((item, index) => {
                        if(this.props.selectedDisabled){
                            return (
                                <div className="item" key={index}>
                                    <div className="w180" style={{width:'150px',height:'208px'}} onClick={this.jump.bind(this, item)}><img src={item.imgSrc} alt={item.title} /></div>
                                    <p className={item.title.length < 20 ? "title one-line" : "title two-line"}>{item.title}</p>
                                    <Star starPercent={item.averageScore} enableClick={false} />
                                    {
                                        MixFns.calcLatestDate(item.createDate)
                                            ? <span className={'newbox'}><span> NEW</span><i className={'triangle'} /></span>
                                            : MixFns.calcLatestDate(item.updateDate)
                                                ? <span className={'updatebox'}><span>UPDATE</span><i className={'triangle'} /></span>
                                                : null
                                    }
                                </div>
                            )
                        }else{
                            return (
                                <div className="item" key={index}>
                                    <div className="w180" style={{width:'150px',height:'208px'}} onClick={this.toggleIconTag.bind(this, item.id)}><img src={item.imgSrc} alt="" /></div>
                                    <p className={item.title.length < 20 ? "title one-line" : "title two-line"}>{item.title}</p>
                                    <Star starPercent={item.averageScore} enableClick={false} />
                                    {
                                        MixFns.calcLatestDate(item.createDate)
                                            ? <span className={'newbox'}><span> NEW</span><i className={'triangle'} /></span>
                                            : MixFns.calcLatestDate(item.updateDate)
                                                ? <span className={'updatebox'}><span>UPDATE</span><i className={'triangle'} /></span>
                                                : null
                                    }
    
                                    {
                                        this.props.fatherType === 'ADD' && this.state.choosedIcons && this.state.choosedIcons.indexOf(item.id) !== -1
                                            ? <IconButton className="btn-choose">
                                                <Check style={{ color: '#fff' }} />
                                            </IconButton>
                                            : this.props.fatherType === 'DELETE' && this.state.choosedIcons && this.state.choosedIcons.indexOf(item.id) !== -1
                                                ? <IconButton className="btn-delete">
                                                    <Remove style={{ color: '#fff' }} />
                                                </IconButton>
                                                : null
                                    }
                                </div>
                            )
                        }
                    })
                }

            </div >
        )
    }
}
ReactMixin(OneItem.prototype, MixFns);