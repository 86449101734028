/**
 *
 * PATH設定
 */

//データアクセスAPI
const API = '/ws/mdoc/api';
export const ADMIN_LOGIN = API + '/authentication/login'; // 管理員登録API
export const ADMIN_LOGOFF = API + '/authentication/logoff'; // 管理員ログオフ
export const DICTIONARY_BEFORE_LOGIN = API + '/dictionary/getloginwords'; // 文言API
export const DICTIONARY = API + '/dictionary/getlist'; // 文言API
export const CNFSYSTEM = API + '/cnfsystem'; 
export const LANGID = API + '/language'; // LANGID
export const SENT_PSW_EMAIL = API + '/authentication/pwdselfreset' // パスワードを修正してメールを送信する
export const ADMIN_CHANGE_PWD = API + '/authentication/changepwd' // 修改密码
export const ADMIN_REFRESH_TOKEN = API + '/authentication/refreshtoken' // 刷新token
export const ENTERPRISE = API + '/adminuser/init'; // 企業管理者一覧 / 副企業管理者一覧API
export const ENTERPRISE_POST = API + '/adminuser'; // 企業管理者登録// 企業管理者登録CheckAPI
export const ENTERPRISE_DELETE = API + '/adminuser/delflg'; //企業管理者一覧削除
export const ENTERPRISEDETAIL = API + '/adminuser/detail'// 企業管理者詳細
export const ENTERPRISECHECKEMAIL = API + '/adminuser/checkemail'//企業管理者登録checkEmailAPI
export const ENTERPRISE_NUM = API + '/adminuser/checkadminCount'//企業管理者登録企業管理者生成数check
export const CLIENTS = API + '/clients/search'; // クライアント一覧API
export const CLIENTS_DETAIL = API + '/clients/detail/'; // クライアント詳細API
export const CLIENTS_ADD = API + '/clients/addclient'; // クライアント情報を登録API
export const CLIENTS_CODE_CHECK = API + '/clients/clientcheck'; // クライアントCode checkAPI
export const CLIENTS_PUT = API + '/clients/updateclient'; // クライアント変更API
export const CLIENTS_STATUS = API + '/clients/update'; // ステータスを変更API
export const USER = API + '/user'; // ユーザ一覧API//ユーザ削除API//ユーザ登録API
export const USER_DELETE = API + '/user/delflg'; //ユーザ削除API
export const USERRESET = API + '/user/resetdeviceKey'; // ユーザ一覧認証リセットAPI
export const USERCHECK = API + '/user/check'; // ユーザ登録user checkAPI
export const USER_ALL_DOWNLOAD = API + '/user/download'; // ユーザ登録フォーマット
export const USER_ALL_UPLOAD = API + '/user/upload'; // ユーザ登録upload
export const USERCHECKEMAIL = API + '/user/checkemail'; // ユーザ登録check emailAPI
export const USERDETAIL = API + '/user/detail'; // ユーザ登録詳細API
export const USER_LOG = API + '/book/effective/log'; // ユーザログAPI
export const USER_LOG_DOWNLOAD = API + '/book/effective/log/download'; // ユーザログdownloadAPI
export const DEPARTMENT_SEARCH = API + '/department/search'; // 部署検索API
export const DEPARTMENT = API + '/department/list'; // 部署API
export const DEPARTMENT_CHECK = API + '/department/check'; // 部署登録check API
export const DEPARTMENT_ADD = API + '/department/add'; // 部署登録 API
export const DEPARTMENT_DELETE = API + '/department'; // 部署登録 API
export const DEPARTMENT_UPDATA = API + '/department/updata'; // 部署変更 API
export const DEPARTMENT_CHANGE = API + '/department/change'; // 部署順序変更 API
export const USER_SEARCH = API + '/user/search'; // ユーザAPI
export const CATEGORY = API + '/book/category/list'; // カテゴリーAPI
export const CATEGORY_GET = API + '/book/category'; // カテゴリーcmbAPI
export const CATEGORY_CONTENT = API + '/book/category/content'; // カテゴリーカテゴリcmbAPI
export const CATEGORY_CHECKE = API + '/book/category/check'; // カテゴリcheckーAPI
export const CATEGORY_ADD = API + '/book/category/add'; // カテゴリ登録API
export const CATEGORY_DELETE = API + '/book/category/update'; // カテゴリ削除API
export const SEARCH_PARTENTSID = API + '/book/category/parent'; // カテゴリ所属の親カテゴリ情報を取得
export const CATEGORY_UPDATE = API + '/book/category'; // カテゴリ変更API
export const CATEGORY_ORDER = API + '/book/category/change'; // カテゴリ順序変更API
export const NOTIFY_SEARCH = API + '/notice/search'; // お知らせ配信一覧API
export const NOTIFY_DELETE = API + '/notice'; // お知らせ配信削除API
export const NOTIFY_DETAIL = API + '/notice/detail'; // お知らせ配信詳細
export const NOTIFY_LOGIN = API + '/notice/addnotice'; // お知らせ配信新规登録
export const NOTIFY_UPDATE = API + '/notice/update'; // お知らせ配信更新登録
export const PUSH_NOTIFY = API + '/alertmessage/search'; // プッシュ通知一覧
export const PUSH_DELETE = API + '/alertmessage'; // プッシュ削除API
export const PUSH_DETAIL = API + '/alertmessage/detail/'; // プッシュ詳細
export const PUSH_NEW_LOGIN = API + '/alertmessage/addalertmessage'; // プッシュ新规登録
export const PUSH_UPDATE = API + '/alertmessage/update'; // プッシュ更新登録
export const CONTENTS = API + '/book'; // コンテンツ一覧API//コンテンツ登録
export const CONTENT_DOWNLOAD = API + '/book/download/content/csv'; //コンテンツ一括登録Download
export const CONTENT_CHECK = API + '/book/check'; //コンテンツ一括登録Check
export const CONTENT_UPLOADS = API + '/file/uploads/'; //コンテンツ一括登録uploads
export const CONTENT_UPLOAD = API + '/book/upload/'; //コンテンツ一括登録files
export const CONTENT_IMG_UPLOAD = API + '/file/upload/image'; //コンテンツ詳細API
export const CONTENT_CONTENT_UPLOAD = API + '/file/upload/content'; //コンテンツ詳細API
export const IMG_PATH = API + '/file/download/image'; //画像のダウンロード
export const BOOK_EDIT_IMG = API + '/book/body/edit'; //画像のダウンロード
export const CLIENTS_INFO = API + '/clients/info'; //メモリサイズ
export const DOWNLOAD_BIGIMAGE = API + '/file/download/bigimage'; //Download Bigimage
export const DOWNLOAD_BIGIMAGE_PDF = API + '/file/download/content'; //Download Bigimage PDF
export const CONTENT_ADD_UPLOAD = API + '/file/add/uploads/'; //コンテンツ追加
export const CONTENT_ADD = API + '/book/add'; //コンテンツ追加
export const APPLICATION_DOWNLOAD = API + '/file/download/application'; //申請書フォーマットDownload
export const APPLY_UPLOAD = API + '/apply/upload'; //申請書取込
export const CONTENT_READ = API + '/apply/list'; //申請書一覧
export const CONTENT_DETAIL = API + '/apply/userlist'; //申請書一覧
export const APPLY_SAVE_APPLY_USER = API + '/apply/saveapplyuser'; //申請書ユーザーの保存
export const APPLY_DELETE_APPLY_USER = API + '/apply/deleteapplyuser'; //申請書ユーザーの削除
// export const APPLY_USER_LIST= API + '/apply/userlist'; //申請書ユーザーの
export const DOWNLOAD_APPLY_USER_CSV = API + '/apply/download/applyuser/csv'; // 申請書部署ユーザCSV出力
export const UPLOAD_APPLY_USER_CSV = API + '/apply/upload/applyuser/csv'; // 申請書部署ユーザCSV取込
export const CONTENT_UPLOAD_FILES = API + '/book/work'; //コンテンツ追加
export const CONTENT_UPLOAD_DELETE = API + '/book/work'; // コンテンツ追加
export const DESIGN_APP = API + '/design/app/'; // デザインの削除・詳細
export const DESIGN_WEB = API + '/design/web/'; // デザインの削除・詳細
export const DESIGN_APP_SAVE = API + '/design/app/save'; // デザインの保存
export const DESIGN_WEB_SAVE = API + '/design/web/save'; // デザインの保存
export const DESIGN_LOGO_UPLOAD = API + '/file/upload/clientlogo/image'; // デザインのログ
export const DESIGN_LOGO_DOWNLOAD = API + '/file/download/client/logoimage/'; // デザインのログ
export const DESIGN_BODYBACKIMAGE_UPLOAD = API + '/file/upload/bodyback/image'; // ボディの背景画像
export const DESIGN_BODYBACKIMAGE_DOWNLOAD = API + '/file/download/client/bodybackimage/'; // ボディの背景画像
export const ACTIVE_DIRECTORY = API + '/externalsetting/search';//　連携
export const ACTIVE_DIRECTORY_OPT = API + '/externalsetting';//　連携追加/削除
export const ACTIVE_DIRECTORY_MODIFICTION = API + '/externalsetting/detail';//　連携修正詳細
export const ACTIVE_DIRECTORY_TEST = API + '/externalsetting/verificationurl';//接続テスト


//page
export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25];
export const ROWS_PER_PAGE = 5;

//
export const FILE_MAX_SIZE = 2 * 1024 * 1024 * 1024;
export const IMG_MAX_SIZE = 1 * 1024 * 1024;//2*1024*1024*1024;


//password secret key
export const SECRET_KEY = 'secret key 12345';

//
export const ROLE_ROUT = {
    1: '/admin/management/enterprise',
    2: '/admin/management/associateEnterprise',
    9: '/admin/management/client',
};

export const RULE_CODE = {
    'managerCreate': 1, //企業管理者作成
    'deputyManagerCreate': 2,//副管理者作成
    'userCreate': 3,//ユーザ作成
    'departmentManagementAuth': 4,//部署管理権限
    'categoryManagementAuth': 5,//カテゴリー管理権限
    'newsRelease': 6,//ニュース配信
    'pushNotice': 7,//プッシュ通知
    'contentRegistrationAuth': 8,//コンテンツ登録権限
    'deviceControl': 9,//デバイス制御
    'contentMultipleRegistration': 10,//コンテンツ一括登録
    'logExport': 11,//ログエクスポート
    'content': 12,//コンテンツ
    'management': 13,//管理メニュー
    'notice': 14,//通知
    'client': 15,//クライアント
    'applicationImport': 25,//クライアント
    'activeDirectorySetting': 26, //activeDirectory連携
    'contentUpdateEmailDelivery': 27, //コンテンツ更新のメール配信
    'contentUpdatePushDelivery': 28 //コンテンツ更新のプッシュ配信
}

//パスワード有効期間 0-無期限 1-3ヶ月 2-6ヶ月 3-12ヶ月
export const PASS_VALID_TIME = ['indefinitely', '3_months', '6_months', '12_months'];

//ログイン保持期間 0-無期限 1-1日 2-1週間 3-1ヶ月
export const LOGIN_KEEP_TIME = ['indefinitely', '1_day', '1_week', '1_months'];

//0：開始前；1：利用中；2：停止中；3：終了
export const CLIENT_STATUS = ['start_before', 'in_use', 'stopping', 'exit'];

//0-無効 1-有効
export const VALID = ['invalid', 'valid'];

//0:公開済;1:非公開;2:予約中
export const NOTIFY_STATUS = ['published', 'non_public', 'under_reservation'];
//0:公開;1:非公開
export const NOTIFY_STATUS_DETAIL = ['public', 'non_public'];
//0:通知済;1:非通知;2:予約中
export const PUSH_STATUS = ['notified', 'non_notification', 'under_reservation'];
//0:通知;1:非通知
export const PUSH_STATUS_DETAIL = ['notice', 'non_notification'];
//0:ユーザID;1:コンテンツID
export const CONTENT_USER = ['user_id', 'user_identity','content_id', 'book_title'];
//0:ハイブリッド;1:ダウンロード;2:ストリーミング
export const DOWNLOAD_WAY = ['mixture', 'download', 'streaming'];
// 0:web,1:ios iphone,2:ios ipad,3:android phone,4:android pad
export const BROWSER_TYPE = ['web', 'ios iphone', 'ios ipad', 'android phone', 'android pad'];
//管理者パスワード有効期間 0-無期限 1-3ヶ月 2-6ヶ月 3-12ヶ月
export const PASS_VALID_TIME_FLG = ['indefinitely', '3_months', '6_months', '12_months'];
//閲覧者パスワード有効期間 0-無期限 1-3ヶ月 2-6ヶ月 3-12ヶ月
export const USER_PASS_VALID_TIME_FLG = ['indefinitely', '3_months', '6_months', '12_months'];