import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types';
import {
    AppBar,
    Button,
    // CssBaseline,
    InputBase,
    InputLabel,
    Toolbar,
    Typography,
    FormControl,
    FormHelperText,
    Grid,
    DialogTitle
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import logo from 'mdoc/style/imgs/logo.png';
import { fetchDataReader, receiveData } from 'mdoc/action'
import { dictionary_before_login, forgetPswSendEmail } from 'mdoc/axios'
import schema from 'async-validator';
import { messageFormat, getLang } from 'mdoc/utils';
import Toast from 'mdoc/utils/toast';
import Dialog from 'mdoc/utils/myDialog';

const styles = theme => ({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        }
    },
    appBar: {
        boxSizing: 'border-box',
        backgroundColor: '#F1F9FF',
        width: '100%',
        height: 63,
    },
    wrap: {
        minHeight: '100%'
    },
    main: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '60px',
        marginTop: '60px'
    },
    footer: {
        boxSizing: 'border-box',
        backgroundColor: '#F1F9FF',
        height: 63,
        marginTop: '-64px',
        zIndex: 9999,
    },
    logo: {
        width: 200,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
    margin: {
        margin: `${theme.spacing.unit * 3}px 0`,
    },
    bootstrapRoot: {
        'label + &': {
            marginTop: theme.spacing.unit * 3,
        },
    },
    bootstrapInput: {
        borderRadius: 4,
        backgroundColor: '#F1F9FF',
        border: '2px solid #B6DDFD',
        fontSize: 16,
        padding: '10px 12px',
        minWidth:'300px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
    bootstrapFormLabel: {
        color: '#2699FB',
        '&:hover': {
            color: '#2699FB',
        },
        fontSize: 16,
    },
    button: {
        margin: theme.spacing.unit
    },
});

class PasswordFind extends React.Component {
    state = {
        dictWord: {
            client_id: '',
            login_mail_address: '',
            login_mail_address_confirmation: '',
            return: '',
            send: '',
            msg_not_valid_mail_address: '',
            msg_error_mail_address_not_found: '',
            msg_mail_sent_completed: '',
            msg_error_confirm_mail: ''
        },
        formData: {
            login_mail_address: {
                value: '',
                error: false,
                errorMsg: ''
            },
            login_mail_address_confirmation: {
                value: '',
                error: false,
                errorMsg: ''
            },
            localeId: {
                value: getLang(),
                error: false,
                errorMsg: ''
            },
        },
        dialogOpen: false
    }

    componentWillMount() {
        this.start();
    }
    start = () => {
        let dict = {};
        dictionary_before_login({ localeId: this.state.formData.localeId.value }).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            });
            window.localStorage.setItem('localeId', this.state.formData.localeId.value);
        });
    }


    onLogon() {
        window.history.back();
    }

    handleChange = (field) => event => {
        let value = event.target.value;
        let valueObj = Object.assign(this.state['formData'][field], { value: value });
        this.setState({
            formData: Object.assign(this.state['formData'], { [field]: valueObj })
        });
    };

    handleSubmit = () => {
        const descriptor = {
            login_mail_address: [
                {
                    required: true,
                    message: messageFormat(this.state.dictWord.msg_error_not_null_with_item, this.state.dictWord.login_mail_address)
                },
                { type: "email", required: true, message: this.state.dictWord.msg_not_valid_mail_address }
            ],
            login_mail_address_confirmation: [
                {
                    required: true,
                    message: messageFormat(this.state.dictWord.msg_error_not_null_with_item, this.state.dictWord.login_mail_address_confirmation)
                },
                { type: "email", required: true, message: this.state.dictWord.msg_not_valid_mail_address },
                {
                    message: this.state.dictWord.msg_error_confirm_mail,
                    validator(rule, value, callback, source, options) {
                        if (value === source.login_mail_address) {
                            callback();
                        } else {
                            callback(new Error(rule.message));
                        }
                    }
                }
            ]
        }

        const validator = new schema(descriptor);
        const { login_mail_address, login_mail_address_confirmation } = this.state.formData;
        validator.validate({
            login_mail_address: login_mail_address.value,
            login_mail_address_confirmation: login_mail_address_confirmation.value,
        }, {
            firstFields: true
        }, (errors, fileds) => {
            let formData = this.state.formData;
            for (let index in formData) {
                formData[index] = Object.assign(this.state['formData'][index], { errorMsg: '', error: false })
            }
            if (errors) {
                errors.forEach(item => {
                    formData[item.field] = Object.assign(this.state['formData'][item.field], {
                        errorMsg: item.message,
                        error: true
                    })
                });
                this.setState({
                    formData: formData
                });
            } else {
                this.setState({
                    formData: formData
                }, () => {
                    let lang = getLang();
                    forgetPswSendEmail({
                        email: login_mail_address.value
                    }, String(lang)).then(res => {
                        this.setState({
                            dialogOpen: true
                        });
                    }).catch(error => {
                        Toast.error(error);
                    })
                });
            }
        })

    }


    dialogHandleOK = () => {
        window.history.back();
    }


    render() {
        const { classes } = this.props;
        const { dictWord, formData } = this.state;
        return [
            <div key="top" className={classes.wrap}>
                <AppBar position="static" color="default" className={classes.appBar}>
                    <Toolbar>
                        <img src={logo} alt="logo" className={classes.logo} />
                    </Toolbar>
                </AppBar>
                <div className={classes.main}>
                    <Typography variant="subtitle2" align="left" component="div">
                        <Grid container className={classes.margin}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="login_mail_address" className={classes.bootstrapFormLabel}>
                                    {dictWord.login_mail_address}
                                </InputLabel>
                                <InputBase
                                    id="login_mail_address"
                                    value={formData.login_mail_address.value}
                                    onChange={this.handleChange('login_mail_address')}
                                    classes={{
                                        root: classes.bootstrapRoot,
                                        input: classes.bootstrapInput,
                                    }}
                                />
                                {formData.login_mail_address.error ? <FormHelperText error={formData.login_mail_address.error} id="login_mail_address-error-text">{formData.login_mail_address.errorMsg}</FormHelperText> : ''}
                            </FormControl>
                        </Grid>
                        <Grid container className={classes.margin}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="login_mail_address_confirmation" className={classes.bootstrapFormLabel}>
                                    {dictWord.login_mail_address_confirmation}
                                </InputLabel>
                                <InputBase
                                    id="login_mail_address_confirmation"
                                    value={formData.login_mail_address_confirmation.value}
                                    onChange={this.handleChange('login_mail_address_confirmation')}
                                    classes={{
                                        root: classes.bootstrapRoot,
                                        input: classes.bootstrapInput,
                                    }}
                                />
                                {formData.login_mail_address_confirmation.error ? <FormHelperText error={formData.login_mail_address_confirmation.error} id="login_mail_address_confirmation-error-text">{formData.login_mail_address_confirmation.errorMsg}</FormHelperText> : ''}
                            </FormControl>
                        </Grid>
                        <Grid container className={classes.margin}>
                            <Button
                                // type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.handleSubmit}
                                style={{ margin: 'auto' }}
                            >
                                {dictWord.send}
                            </Button>
                            <Button
                                // type="submit"
                                // fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.onLogon}
                                style={{ margin: 'auto' }}
                            >
                                {dictWord.return}
                            </Button>
                        </Grid>
                        <Dialog
                            open={this.state.dialogOpen}
                            option={{
                                ok: {
                                    text: this.state.dictWord['yes'],
                                },
                            }}
                            titleDoc={<DialogTitle disableTypography id="" className={classes.title} />}
                            content={
                                <p>{this.state.dictWord['msg_mail_sent_completed']}</p>
                            }
                            handleOk={this.dialogHandleOK}
                        />
                    </Typography>
                </div>
            </div>,
            <Typography key="footer" className={classes.footer} component="div">
                <Typography variant="body1" align="center" style={{ color: '#2699FB' }} gutterBottom={false}>
                    Copyright
            </Typography>
            </Typography>

        ];
    }
}

PasswordFind.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToPorps = state => {
    const { auth } = state.reducer.httpData
    return { auth }
}
const mapDispatchToProps = dispatch => ({
    fetchData: bindActionCreators(fetchDataReader, dispatch),
    receiveData: bindActionCreators(receiveData, dispatch)
})
export default withStyles(styles)(connect(mapStateToPorps, mapDispatchToProps)(PasswordFind));