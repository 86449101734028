import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from 'mdoc/utils/myDialog';

import { dictionary, getLanguage, changeLanguage, logout, getUserInfo } from 'mdoc/axios/index'
import Toast from 'mdoc/utils/toast';
import { messageFormat } from 'mdoc/utils';
import Menu from 'mdoc/components/userInfo/Menu';
import Check from '@material-ui/icons/Check';
import 'mdoc/components/userInfo/notify.less';


const styles = theme => ({
    main: {
        width: '100%',
        display: 'flex'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
});
class Lang extends React.Component {
    state = {
        dictWord: {
            'notification': '',
            'save_information_list': '',
            'notice': '',
            'lang': '',
            'help': '',
            'password': '',
            'logoff': '',
            'set_to': '',
            'cancel': '',
            'ok': '',
            'push_notification_list': '',
        },
        language: [],
        dialogOpen: false,
        localeId: 0,
        changLocale: {
            localeId: 0,
            langName: ''
        },
    }

    componentWillMount() {
        this.start(this.state);
        this.fetch();
    }

    start = (state) => {
        let dict = {}
        dictionary(state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            })
        })
        let localeId = window.localStorage.getItem('localeId');
        this.setState({
            localeId: localeId,
        })
    }
    fetch = (params) => {
        getLanguage({
            ...params
        }).then((res) => {
            this.setState({
                language: res.results
            })
        }).catch(error => {
            Toast.error(error);
        })
    }

    handleChangeLanguage = (langId, langName) => event => {
        if (parseInt(langId) === parseInt(this.state.localeId)) return;
        let changLocale = {
            localeId: langId,
            langName: langName,
        }
        this.setState({
            dialogOpen: true,
            changLocale: changLocale
        })
    }
    dialogHandleOK = () => {
        let langId = this.state.changLocale.localeId;
        if (!langId) return;
        changeLanguage({
            lang: langId
        }).then(res => {
            let userinfo = getUserInfo();
            userinfo.langId = langId;
            window.localStorage.setItem('readUser', JSON.stringify(userinfo));
            window.localStorage.setItem('localeId', langId);
            this.setState({
                localeId: langId,
            });
            logout();
        }).catch(error => {
            Toast.error(error);
        });

    }
    dialogHandleClose = () => {
        this.setState({
            dialogOpen: false
        })
    }

    render() {
        const { classes } = this.props;
        const { dictWord, language } = this.state;
        return (
            <div className={classes.main}>
                <div className={classes.appBarSpacer} />
                <Menu dictWord={dictWord} selectedkey="lang" />
                <div className={classes.content + ' right-con lang'}>
                    <h3>{dictWord.lang}</h3>
                    <ul>
                        {language.map((item, index) => (
                            <li key={index} onClick={this.handleChangeLanguage(item.id, item.name)}>
                                {item.name}
                                {parseInt(item.id) === parseInt(this.state.localeId) ? <span><Check color="primary" /></span> : null}
                            </li>
                        ))}
                    </ul>
                </div>

                <Dialog
                    open={this.state.dialogOpen}
                    option={{
                        ok: {
                            text: this.state.dictWord['yes'],
                        },
                        close: {
                            text: this.state.dictWord['cancel'],
                        },
                    }}
                    content={
                        <p>{messageFormat(this.state.dictWord['set_to'], this.state.changLocale.langName)}</p>
                    }
                    handleOk={this.dialogHandleOK}
                    handleClose={this.dialogHandleClose}
                />
            </div>
        )
    };
}

Lang.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Lang);