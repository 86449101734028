import React,{Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {SvgIcon}from '@material-ui/core';

const styles = theme => ({
    star:{
        display: 'inline-block'
    },
    starWrapOrigin:{
        position: 'relative',
        width: '120px',
    },
    showPercent: {
        height:'24px',
        position: 'absolute',
        top:0,
        left:0,
        zIndex: 1,
        overflow: 'hidden'
    },
    fiveStars:{        
        position: 'absolute',
        top:0,
        left:0,
        zIndex: 2,
        width:'120px'
    },
});
const StarIconPath = 'M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z';
const StarHalfIconPath = 'M12,15.4V6.1L13.71,10.13L18.09,10.5L14.77,13.39L15.76,17.67M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z';
const StarFullIconPath = 'M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z';
class Star extends Component{
    constructor(props){
        super(props);
        this.state={
            starNum:[StarIconPath,StarIconPath,StarIconPath,StarIconPath,StarIconPath],
            star:-1
        }
    }
    componentDidMount(){
        this.getStar(Math.round(this.props.star)+1);
    }

    componentDidUpdate(){
        let star = this.state.star;

        if(star !== this.props.star){
            this.setState({
                star:this.props.star
            })
            this.getStar(Math.round(this.props.star)+1);
        }
    }
    getStar(num){
        
        let newStar = this.state.starNum.map((item)=>{
            --num;
            return num>=1?StarFullIconPath:((num>0)?StarHalfIconPath:StarIconPath);
        })
        this.setState({
            starNum:newStar
        })
    }
    setValue(val){
        if(this.props.enableClick !== false){
            this.getStar(val + 2);
            if(this.props.getScores)this.props.getScores(val + 1);
        }
    }
    resolvePercent(percent){
        let res = 0;
        let a = percent * 10 ;
        let b = parseInt(percent) * 10;
        let c = a - b;
        if(c > 0){
            if(c >= 3 && c <= 7){
                b = b + 5;
            }else if(c > 7){
                b = b + 10;
            }
        }
        res = b * 2;
        return res+ "%";
    }
    render(){
        const { classes } = this.props;
        return (
        <div className={classes.starWrapOrigin}>
            <span>
                {
                    this.state.starNum.map((item, index)=>{
                        return (
                            <SvgIcon viewBox="2 2 20 20" key={index} onClick={()=>this.setValue(index)}>
                                <path d={this.props.starPercent ? StarIconPath : item} fill="#FDD835" style={{fontSize:'10px'}} />                            
                            </SvgIcon>
                        )
                    })
                }
            </span>
            { 
                this.props.starPercent             
                    ?
                    <div className={classes.showPercent} style={{width:this.resolvePercent(this.props.starPercent)}}>
                        <div className={classes.fiveStars}>
                                <SvgIcon viewBox="2 2 20 20">
                                    <path d={StarFullIconPath} fill="#FDD835" style={{fontSize:'10px'}} />
                                </SvgIcon>
                                <SvgIcon viewBox="2 2 20 20">
                                    <path d={StarFullIconPath} fill="#FDD835" style={{fontSize:'10px'}} />
                                </SvgIcon>
                                <SvgIcon viewBox="2 2 20 20">
                                    <path d={StarFullIconPath} fill="#FDD835" style={{fontSize:'10px'}} />
                                </SvgIcon>
                                <SvgIcon viewBox="2 2 20 20">
                                    <path d={StarFullIconPath} fill="#FDD835" style={{fontSize:'10px'}} />
                                </SvgIcon>
                                <SvgIcon viewBox="2 2 20 20">
                                    <path d={StarFullIconPath} fill="#FDD835" style={{fontSize:'10px'}} />
                                </SvgIcon>
                        </div>
                    </div>
                    :
                null
            }
        </div>
        )
    }
}
Star.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Star);