/**
 *
 */
import { get, post, put, downloadImg } from 'mdoc/axios/tools'
import * as config from 'mdoc/axios/config'
import epub_404 from 'mdoc/style/imgs/404_EPUB.png'
import pdf_404 from 'mdoc/style/imgs/404_PDF.png'
import mp4_404 from 'mdoc/style/imgs/404_MP4.png'
import link_404 from 'mdoc/style/imgs/404_LINK.png'
import localforage from 'localforage';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


// 閲覧者登録
export const readerLogin = (param) => post(config.READER_LOGIN, param)

//閲覧者パスワードを修正する
export const readerChangePwd = (param) => new Promise((resolve, reject) => {
    post(config.READER_CHANGE_PWD, param)
        .then(function (response) {
            if (response.error) {
                if (response.errorCode === 'E201') {
                    reject(response.errorMessage);
                } else {
                    reject(response.errorMessage);
                }
            } else {
                resolve({ results: response })
            }
        }).catch(function (error) {
            reject(error);
        })
})

//before login
export const dictionary_before_login = (param) => new Promise((resolve, reject) => {
    get(config.DICTIONARY_BEFORE_LOGIN, { ...param, 'typeFlg': 1 })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response.docMstLanguage)
            }
        }).catch(function (error) {
            reject(error);
        })
})
//get user
export const getUserInfo = () => {
    const _user = JSON.parse(window.localStorage.getItem('readUser')) || {};
    let arr = Object.keys(_user);
    if (arr.length === 0) {
        let requestUrl = cookies.get('requestUrl');
        console.log(document.location.href);
        let currentUrl = document.location.href;
        let re = new RegExp("/","g");
        let arr = currentUrl.match(re);
        if (!requestUrl && arr.length > 3 && currentUrl.indexOf('#/login') < 0 && currentUrl.indexOf('#/404') < 0) {
            cookies.set('requestUrl', currentUrl);
        }
        window.location = '/#/login';
    }
    return _user ? _user : null;
}

//logout
export const logout = () => {
    window.location = '/#/login';
    window.localStorage.removeItem('readUser');
    window.localStorage.removeItem('readToken');
    cookies.remove('requestUrl');
    post(config.ADMIN_LOGOFF).then(response => {
    });

}

// 文言获取
//param format
const paramFormat = (param) => {
    let params = [];
    for (let key in param) {
        params.push(key);
    }
    return params;
}

export const dictionary = (param) => new Promise((resolve, reject) => {
    const _localeId = window.localStorage.getItem('localeId');
    post(config.DICTIONARY, { 'dictKeyList': (paramFormat(param)), 'typeFlg': 1 }, {
        LANG: parseInt(_localeId),
    }).then(function (response) {
        if (response.error) {
            reject(response.errorMessage);
        } else {
            resolve(response.docMstLanguage);
        }
    }).catch(function (error) {
        reject(error);
    })
})

export const cnfsystem = (param) => new Promise((resolve, reject)=> {
    get(config.CNFSYSTEM, {'configGroup':'system','configKey': param}).then(
        function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response.configValue);
            }
        }).catch(function (error) {
            reject(error);
    })
})

//获取语言种类
export const getLanguage = (param) => new Promise((resolve, reject) => {
    get(config.LANGUAGE, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve({ results: response.docMstLocaleList })
            }
        }).catch(function (error) {
            reject(error);
        })
})
//变更语言设定
export const changeLanguage = (param) => new Promise((resolve, reject) => {
    get(config.USER_UPLANG, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve({ results: response })
            }
        }).catch(function (error) {
            reject(error);
        })
})
//获取用户通知设定
export const getAlertsetting = (deviceKey) => new Promise((resolve, reject) => {
    get(config.ALERTSETTING + deviceKey)
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response)
            }
        }).catch(function (error) {
            reject(error);
        })
})

//閲覧者のプッシュ閲覧
export const setAlertsetting = (param) => new Promise((resolve, reject) => {
    post(config.ALERTSETTING_UPDATE, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve({ results: response })
            }
        }).catch(function (error) {
            reject(error);
        })
})

//画像のpath
export const getCoverPath = (categoryId, folderUuid, fileUuid, docType, reload) => new Promise((resolve, reject) => {
    if (fileUuid === 'DEFAULT') {
        if (docType === 'epub') {
            resolve(epub_404);
        } else if (docType === 'pdf') {
            resolve(pdf_404);
        } else if (docType === 'mp4') {
            resolve(mp4_404);
        } else if (docType === 'link') {
            resolve(link_404);
        }
    } else {
        let imgKey = categoryId + folderUuid + fileUuid + docType;
        localforage.getItem(imgKey, function (err, value) {
            if (err == null && value && reload !== true) {
                resolve(value);
            } else {
                downloadImg(config.COVER_PATH + '/' + categoryId + '/' + folderUuid + '/' + fileUuid, {})
                    .then(res => {
                        return 'data:image/png;base64,' + btoa(
                            new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), '')
                        );
                    })
                    .then(data => {
                        localforage.setItem(imgKey, data);
                        resolve(data);
                    }).catch(function (error) {
                        let img;
                        if (docType === 'epub') {
                            img = epub_404;
                        } else if (docType === 'pdf') {
                            img = pdf_404;
                        } else if (docType === 'mp4') {
                            img = mp4_404;
                        } else if (docType === 'link') {
                            img = link_404;
                        }
                        localforage.setItem(imgKey, img);
                        resolve(img);
                    });
            }
        });
    }
})

export const getBookFilePath = (categoryId, folderUuid, fileUuid) => new Promise((resolve, reject) => {
    downloadImg(config.BOOK_FILE_PATH + categoryId + '/' + folderUuid + '/' + fileUuid)
        .then(res => {
            resolve(res);
        })
})

export const setMp4Check = (token) => new Promise((resolve, reject) => {
    let user = JSON.parse(window.localStorage.getItem('readUser')) || {};
    get(config.MP4_PATH_TOKEN + "?token=" + token + "&userId=" + user.userId, {})
        .then(data => {
            resolve(data);
        })
})

export const getMp4Path = (bookId, clientId, categoryId, token) => {
    let user = JSON.parse(window.localStorage.getItem('readUser')) || {};
    return config.MP4_PATH + "?bookId=" + bookId + "&clientId=" + clientId + "&categoryId="
        + categoryId + "&token=" + token + "&userId=" + user.userId;
}

export const delMp4Token = (bookId) => new Promise((resolve, reject) => {
    get(config.MP4_DEL_TOKEN + "?bookId=" + bookId, {})
        .then(data => {
            resolve(data);
        })
})

export const getBookPreviewImg = (folderUuid, fileUuid, docType) => new Promise((resolve, reject) => {
    if (fileUuid === 'DEFAULT') {
        if (docType === 'epub') {
            resolve(epub_404);
        } else if (docType === 'pdf') {
            resolve(pdf_404);
        } else if (docType === 'mp4') {
            resolve(mp4_404);
        } else if (docType === 'link') {
            resolve(link_404);
        }
    } else {
        downloadImg(config.PREVIEW_FILELIST + folderUuid + '/' + fileUuid, {})
            .then(res => {
                return 'data:image/png;base64,' + btoa(
                    new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
            }).then(data => {
                resolve(data);
            }).catch(function (error) {
                if (docType === 'epub') {
                    resolve(epub_404);
                } else if (docType === 'pdf') {
                    resolve(pdf_404);
                } else if (docType === 'mp4') {
                    resolve(mp4_404);
                } else if (docType === 'link') {
                    resolve(link_404);
                }
            })
    }
})

//找回密码发送邮件
export const forgetPswSendEmail = (param, lang) => new Promise((resolve, reject) => {
    post(config.SENT_PSW_EMAIL, { ...param, lang: lang })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve({ results: response })
            }
        }).catch(function (error) {
            reject(error);
        })
})

//閲覧者のプッシュ通知取得
export const pushList = (param) => new Promise((resolve, reject) => {
    get(config.ALERT_MESSAGE, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve({ results: response })
            }
        }).catch(function (error) {
            reject(error);
        })
})
//閲覧者のプッシュ閲覧
export const pushIgnore = (param) => new Promise((resolve, reject) => {
    put(config.ALERT_MESSAGE_IGNORE, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve({ results: response })
            }
        }).catch(function (error) {
            reject(error);
        })
})

//Myコレクションリストの取得
export const collectionList = (param) => new Promise((resolve, reject) => {
    get(config.COLLENCTION, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})
//Myコレクションの取得
export const collectionDetail = (collectionId, param) => new Promise((resolve, reject) => {
    get(config.COLLENCTION + '/' + collectionId, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})
//Myコレクションの削除
export const collectionDelete = (param) => new Promise((resolve, reject) => {
    post(config.COLLENCTION_DELETE, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})

//myコレクションコンテンツリストの取得
export const collectionContents = (collectionId, param) => new Promise((resolve, reject) => {
    get(config.COLLENCTION_CONTENTS, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})
//Myコレクションの追加
export const collectionAdd = (param) => new Promise((resolve, reject) => {
    post(config.COLLENCTION_ADD, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})

//コレクション情報を変更
export const collectionUpdate = (param) => new Promise((resolve, reject) => {
    post(config.COLLENCTION_UPDATE, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})
//コレクション情報を移动
export const collectionMove = (param) => new Promise((resolve, reject) => {
    post(config.COLLENCTION_MOVE, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})

//コメントリスト
export const commentList = (bookId, param) => new Promise((resolve, reject) => {
    get(config.COMMENT_LIST + '/' + bookId, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})
//コメント
export const commentAdd = (param) => new Promise((resolve, reject) => {
    post(config.COMMENT_ADD, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})

//style 
export const designWeb = (clientId) => new Promise((resolve, reject) => {
    get(config.DESIGN_WEB + clientId, {})
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})

//コメント
export const noticeList = (param) => new Promise((resolve, reject) => {
    get(config.NOTICE_LIST, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})
//プッシュ
export const myPushList = (param) => new Promise((resolve, reject) => {
    get(config.MYPUSH_LIST, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})
//ユーザー未読のお知らせ取得
export const relation = (param) => new Promise((resolve, reject) => {
    get(config.RELATION, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})
//ユーザー未読のお知らせ取得
export const noticeInfo = (param) => new Promise((resolve, reject) => {
    get(config.NOTICEINFO + param, '')
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})

//get BigImage
export const logoDown = (fileUuid) => new Promise((resolve, reject) => {
    downloadImg(config.DESIGN_LOGO_DOWNLOAD + fileUuid)
        .then(res => {
            return 'data:image/png;base64,' + btoa(
                new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
        })
        .then(data => {
            resolve(data);
        }).catch(function (error) {
            reject(error);
        })
})

//get BigImage
export const getBodybackImage = (fileUuid) => new Promise((resolve, reject) => {
    downloadImg(config.BODY_BACK_IMAGE + fileUuid)
        .then(res => {
            // return URL.createObjectURL(res);
            return 'data:image/png;base64,' + btoa(
                new Uint8Array(res).reduce((data, byte) => data + String.fromCharCode(byte), '')
            );
        })
        .then(data => {
            // var arr = data.split(','), mime = arr[0].match(/:(.*?);/)[1],
            //     bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            // while (n--) {
            //     u8arr[n] = bstr.charCodeAt(n);
            // }
            // let blob = new Blob([u8arr], { type: mime })
            // // return new Blob([u8arr], { type: mime });
            // resolve(URL.createObjectURL(blob));
            resolve(data)
        }).catch(function (error) {
            reject(error);
        })
})

//read log
export const effectiveLog = (param) => new Promise((resolve, reject) => {
    post(config.EFFECTIVE_LOG, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})
//コンテンツ検索
export const bookSearch = (param) => new Promise((resolve, reject) => {
    post(config.BOOK_SEARCH, { ...param })
        .then(function (response) {
            console.log(response)
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            console.log(error)
            reject(error);
        })
})
//コンテンツ検索
export const bodySearch = (param) => new Promise((resolve, reject) => {
    get(config.BODY_SEARCH, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})

export const bodyPreview = (param) => new Promise((resolve, reject) => {
    get(config.BOOK_PRINT, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})

export const checkupdate = (param) => new Promise((resolve, reject) => {
    get(config.CHECKEUPDATE, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response);
            } else {
                resolve(response.docTblBookBody);
            }
        }).catch(function (error) {
            reject(error);
        })
})

export const bookEvaluate = (param) => new Promise((resolve, reject) => {
    post(config.EVALUATE, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})

export const getBookEvaluate = (param) => new Promise((resolve, reject) => {
    get(config.EVALUATECONTENT, { ...param })
        .then(function (response) {
            if (response.error) {
                reject(response.errorMessage);
            } else {
                resolve(response);
            }
        }).catch(function (error) {
            reject(error);
        })
})
export const currentTime = (param) => new Promise((resolve, reject) => {
    get(config.CURRENT_TIME, { ...param })
        .then(function (response) {
            // if (response.error) {
            //     reject(response.errorMessage);
            // } else {
                resolve(response);
            // }
        }).catch(function (error) {
            reject(error);
        })
})