import React from 'react'
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import {
    Drawer,
    Grid,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Divider,
    List,
    Hidden,
} from '@material-ui/core';
import AccountCircleSharpIcon from '@material-ui/icons/AccountCircleSharp';
import { Link } from 'react-router-dom'
import blue from '@material-ui/core/colors/blue';
import { getUserInfo, logout, dictionary,relation, cnfsystem } from 'mdoc/axios'
import 'mdoc/components/userInfo/menu.less';
import Dialog from 'mdoc/utils/myDialog';

import { setLeftMenuDispatch, getLeftMenuDispatch } from 'mdoc/action/';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const drawerWidth = 240;
const styles = theme => ({
    drawer: {
        display: 'flex',
        height: '100%',
    },
    padding: {
        padding: `0 ${theme.spacing.unit * 2}px 0 0`
    },
    listSel: {
        backgroundColor: `${blue[500]}`
    },
    drawerPaper: {
        width: drawerWidth,
        height: '100%',
        top: '63px',
        backgroundColor: '#f5f5f5',
        position: 'static'
    },
    mobileDrawer: {
        width: drawerWidth,
        height: '100%',
        top: '0px',
        backgroundColor: '#f5f5f5',
        position: 'fixed'
    },
    userInfo: {
        backgroundColor: '#ffffff'
    },
    avatar: {
        margin: 10,
        // color: '#fff',
        // backgroundColor: blue[500],
        width: 80,
        height: 80,
    },
    menuUl: {
        paddingTop: '0'
    }
});
const ListItemLink = ({ to, primary, className, icon, secondary, onClick, num }) => (
    
    <li className={className}>
        <ListItem button onClick={onClick} component={to ? props => <Link replace to={to} {...props} /> : null}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primary={primary} secondary={secondary} />
            {num ?
                <span style={{color:'#fff', background: 'red', borderRadius: '10px', minWidth: '20px', justifyContent: 'center', display: 'flex', height: '20px' }}>{num}</span>
                : null}
        </ListItem>
    </li>
);

class Menu extends React.Component {
    state = {
        dictWord: {
            'ok': '',
            'cancel': '',
            'msg_confirm_logoff': '',
        },
        userName: '',
        open: true,
        confirmOpen: false,
        unreadCount: 0,
        styleSetting: {}
    }
    componentWillMount() {
        this.start(this.state);
        this.getOpen();
        let _userInfo = getUserInfo();
        let notifySetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/" + _userInfo.userId + "/notify")) || {};
        let params = {};
        if (notifySetting && notifySetting.receiveflg === 1) {
            params.openTime = notifySetting.time
        }
        relation(params).then(res => {
            this.setState({
                unreadCount: res.unreadCount
            })
        });
    }
    componentDidMount() {
        const user = getUserInfo();
        this.setState({
            userName: user.userName
        });
    }
    closeDrawer = () => () => {
        this.setState({
            open: false,
        });
        this.setOpen(false);
    };
    setOpen = (bool) => {
        this.props.setLeftMenuDispatch(bool);
    }
    getOpen = () => {
        this.props.getLeftMenuDispatch((param) => { })
    }
    handleLogout = () => event => {
        this.setState({
            confirmOpen: true
        })
    }
    dialogHandleOK = () => {
        logout();
    }
    dialogHandleClose = () => {
        this.setState({
            confirmOpen: false
        })
    }
    onLocationUrl = (link_key)=> event =>{
        cnfsystem(link_key).then(res =>{
            if(res)window.open(res);
        })
    }
    start = (state) => {
        let _userInfo = getUserInfo();
        let styleSetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/styleSetting")) || {};
        this.setState({ loading: true, styleSetting: styleSetting });
        let dict = {}
        dictionary(state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue
            }
            this.setState({
                dictWord: dict,
            })
        })
    }
    render() {
        const { classes, dictWord, selectedkey } = this.props;
        const { userName } = this.state;
        return (
            <div>
                <Hidden xsDown>
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}

                    >
                        <div style={{ height: '100%', background: this.state.styleSetting.personalMenuBackColor }}>
                            <Grid container className={classes.userInfo}>
                                <Grid item xs={12} md={12} style={{ display: 'flex', alignItems: 'center' }}>
                                    <AccountCircleSharpIcon className={classes.avatar} color="primary" />
                                    <Typography
                                        align="left"
                                        variant="subtitle1"
                                        title={userName}
                                        style={{
                                            marginLeft: '10px',
                                            fontWeight: "bold",
                                            fontSize: '1.2rem',
                                            width: '80px',
                                            overflow: 'hidden',
                                            lineHeight: '28px',
                                            flex: 5,
                                            cursor: 'pointer',
                                            wordBreak:"break-all"
                                            
                                        }}
                                    >
                                        {userName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                            <List className={classes.menuUl}>
                                {selectedkey === 'notify' ? <ListItemLink to="/user/userInfo/notify" className="listSelet" primary={dictWord.notification} num={this.state.unreadCount} />
                                    : <ListItemLink to="/user/userInfo/notify" primary={dictWord.notification} num={this.state.unreadCount} />}
                                {selectedkey === 'pushlist' ? <ListItemLink to="/user/userInfo/pushlist" className="listSelet" primary={dictWord.push_notification_list} /> : <ListItemLink to="/user/userInfo/pushlist" primary={dictWord.push_notification_list} />}
                                {selectedkey === 'information' ? <ListItemLink to="/user/userInfo/information" className="listSelet" primary={dictWord.save_information_list} /> : <ListItemLink to="/user/userInfo/information" primary={dictWord.save_information_list} />}
                                {selectedkey === 'notice' ? <ListItemLink to="/user/userInfo/notice" className="listSelet" primary={dictWord.notice} /> : <ListItemLink to="/user/userInfo/notice" primary={dictWord.notice} />}
                                {selectedkey === 'lang' ? <ListItemLink to="/user/userInfo/lang" className="listSelet" primary={dictWord.lang} /> : <ListItemLink to="/user/userInfo/lang" primary={dictWord.lang} />}
                                {selectedkey === 'password' ? <ListItemLink to="/user/userInfo/password" className="listSelet" primary={dictWord.password} /> : <ListItemLink to="/user/userInfo/password" primary={dictWord.password} />}
                                {selectedkey === 'logoff' ? <ListItemLink onClick={this.handleLogout()} className="listSelet" primary={dictWord.logoff} /> : <ListItemLink onClick={this.handleLogout()} primary={dictWord.logoff} />}
                                {selectedkey === 'help' ? <ListItemLink onClick={this.onLocationUrl("help_user_link")} className="listSelet" primary={dictWord.help} /> : <ListItemLink onClick={this.onLocationUrl("help_user_link")} primary={dictWord.help} />}
                            </List>
                        </div>
                    </Drawer>
                </Hidden>
                <Hidden smUp>
                    <Drawer
                        open={this.props.menuStatus.menuStatus}
                        className={classes.drawer}
                        classes={{
                            paper: classes.mobileDrawer,
                        }}
                        onClose={this.closeDrawer(false)}
                    >
                        <Grid container className={classes.userInfo}>
                            <Grid item xs={12} md={12}>
                                <AccountCircleSharpIcon className={classes.avatar} color="primary" />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <Typography align="left" variant="subtitle1" style={{ marginLeft: '10px' }}>
                                    {userName}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <List>
                            {selectedkey === 'notify' ? <ListItemLink to="/user/userInfo/notify" className="listSelet" primary={dictWord.notification} />
                                : <ListItemLink to="/user/userInfo/notify" primary={dictWord.notification} />}
                            {selectedkey === 'pushlist' ? <ListItemLink to="/user/userInfo/pushlist" className="listSelet" primary={dictWord.push_notification_list} /> : <ListItemLink to="/user/userInfo/pushlist" primary={dictWord.push_notification_list} />}
                            {selectedkey === 'information' ? <ListItemLink to="/user/userInfo/information" className="listSelet" primary={dictWord.save_information_list} /> : <ListItemLink to="/user/userInfo/information" primary={dictWord.save_information_list} />}
                            {selectedkey === 'notice' ? <ListItemLink to="/user/userInfo/notice" className="listSelet" primary={dictWord.notice} /> : <ListItemLink to="/user/userInfo/notice" primary={dictWord.notice} />}
                            {selectedkey === 'lang' ? <ListItemLink to="/user/userInfo/lang" className="listSelet" primary={dictWord.lang} /> : <ListItemLink to="/user/userInfo/lang" primary={dictWord.lang} />}
                            {selectedkey === 'password' ? <ListItemLink to="/user/userInfo/password" className="listSelet" primary={dictWord.password} /> : <ListItemLink to="/user/userInfo/password" primary={dictWord.password} />}
                            {selectedkey === 'logoff' ? <ListItemLink onClick={this.handleLogout()} className="listSelet" primary={dictWord.logoff} /> : <ListItemLink onClick={this.handleLogout()} primary={dictWord.logoff} />}
                            {selectedkey === 'help' ? <ListItemLink to="/user/userInfo/help" className="listSelet" primary={dictWord.help} /> : <ListItemLink to="/user/userInfo/help" primary={dictWord.help} />}
                        </List>
                    </Drawer>
                </Hidden>

                <Dialog
                    open={this.state.confirmOpen}
                    option={{
                        ok: {
                            text: this.state.dictWord['yes'],
                        },
                        close: {
                            text: this.state.dictWord['cancel'],
                        },
                    }}
                    content={
                        <p>{this.state.dictWord['msg_confirm_logoff']}</p>
                    }
                    handleOk={this.dialogHandleOK}
                    handleClose={this.dialogHandleClose}
                />
            </div>
        )
    }
}

Menu.propTypes = {
    classes: PropTypes.object.isRequired,
    dictWord: PropTypes.object.isRequired,
    selectedkey: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        menuStatus: state.reducer.menuStatus
    };
}

const mapDispatchToProps = dispatch => ({
    setLeftMenuDispatch: bindActionCreators(setLeftMenuDispatch, dispatch),
    getLeftMenuDispatch: bindActionCreators(getLeftMenuDispatch, dispatch),
})
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Menu))