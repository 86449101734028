import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import CryptoJS from 'crypto-js';
import * as config from 'mdoc/axios/config';
import Toast from 'mdoc/utils/toast';
import { TextField, Button, FormHelperText, } from '@material-ui/core';
import { dictionary, readerChangePwd, getUserInfo } from 'mdoc/axios/index'
import Menu from 'mdoc/components/userInfo/Menu';
import schema from 'async-validator';
import { messageFormat } from 'mdoc/utils';
import 'mdoc/components/userInfo/notify.less';


const styles = theme => ({
    main: {
        width: '100%',
        display: 'flex'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
    },
    textField: {
        width: '100%',
    }
});

class Password extends React.Component {
    state = {
        dictWord: {
            'notification': '',
            'save_information_list': '',
            'notice': '',
            'lang': '',
            'help': '',
            'password': '',
            'logoff': '',
            'current_password': '',
            'new_password': '',
            'new_password_confirmation': '',
            'change': '',
            'msg_error_not_null_with_item': '',
            'msg_error_confirm_new_password': '',
            'msg_password_updated': '',
            'msg_error_pw_reuse_history': '',
            'characters_more': '',
            'half_letter_number_symbols': '',
            'password_different_before': '',
            'push_notification_list': '',
        },
        formData: {
            current_password: {
                value: '',
                error: false,
                errorMsg: ''
            },
            new_password: {
                value: '',
                error: false,
                errorMsg: ''
            },
            new_password_confirmation: {
                value: '',
                error: false,
                errorMsg: ''
            },
        },
    }

    componentWillMount() {
        this.start(this.state);
    }

    start = (state) => {
        let _userInfo = getUserInfo();
        let styleSetting = JSON.parse(window.localStorage.getItem(_userInfo.clientId + "/styleSetting")) || {};
        this.setState({
            styleSetting: styleSetting,
        })
        let dict = {}
        dictionary(state.dictWord).then(res => {
            for (let key in res) {
                let val = res[key];
                dict[val.dictKey] = val.dictValue;
            }
            this.setState({
                dictWord: dict,
            })
        })
    }

    handleChange = (field) => event => {
        let value = event.target.value;
        let valueObj = Object.assign(this.state['formData'][field], { value: value });
        this.setState({
            formData: Object.assign(this.state['formData'], { [field]: valueObj })
        });
    };

    handleSubmit = () => event => {

        const descriptor = {
            current_password: [
                {
                    required: true,
                    message: messageFormat(this.state.dictWord.msg_error_not_null_with_item, this.state.dictWord.current_password)
                }
            ],
            new_password: [
                {
                    required: true,
                    message: messageFormat(this.state.dictWord.msg_error_not_null_with_item, this.state.dictWord.new_password)
                },
            ],
            new_password_confirmation: [
                {
                    required: true,
                    message: messageFormat(this.state.dictWord.msg_error_not_null_with_item, this.state.dictWord.new_password_confirmation)
                },
                {
                    message: this.state.dictWord.msg_error_confirm_new_password,
                    validator(rule, value, callback, source, options) {
                        if (value === source.new_password) {
                            callback();
                        } else {
                            callback(new Error(rule.message));
                        }
                    }
                }
            ]
        }
        const validator = new schema(descriptor);
        const { current_password, new_password, new_password_confirmation } = this.state.formData;
        if(new_password.value === current_password.value){
            Toast.error(this.state.dictWord.msg_error_pw_reuse_history);
            return;
        }
        validator.validate({
            current_password: current_password.value,
            new_password: new_password.value,
            new_password_confirmation: new_password_confirmation.value,
        }, {
                firstFields: true
            }, (errors, fields) => {
                let formData = this.state.formData;
                for (let index in formData) {
                    formData[index] = Object.assign(this.state['formData'][index], { errorMsg: '', error: false })
                }
                if (errors) {
                    errors.forEach(item => {
                        formData[item.field] = Object.assign(this.state['formData'][item.field], {
                            errorMsg: item.message,
                            error: true
                        })
                    });
                    this.setState({
                        formData: formData
                    });
                } else {
                    this.setState({
                        formData: formData
                    });

                    let password = formData.current_password.value;
                    let passwordEncode = CryptoJS.AES.encrypt(password, CryptoJS.enc.Utf8.parse(config.SECRET_KEY), {
                        mode: CryptoJS.mode.ECB,
                    });
                    passwordEncode = passwordEncode.ciphertext.toString();

                    let newPassword = formData.new_password.value;
                    let newPasswordEncode = CryptoJS.AES.encrypt(newPassword, CryptoJS.enc.Utf8.parse(config.SECRET_KEY), {
                        mode: CryptoJS.mode.ECB,
                    });
                    newPasswordEncode = newPasswordEncode.ciphertext.toString();
                    let userInfo = getUserInfo();

                    readerChangePwd({
                        userCode: userInfo.userCode,
                        password: passwordEncode,
                        newPassword: newPasswordEncode,
                    }).then(res => {
                        Toast.info(this.state.dictWord.msg_password_updated);
                        window.location.href = '#/user/book/library';
                    }).catch(error => {
                        Toast.error(error);
                    })
                }
            });
    }

    render() {
        const { classes } = this.props;
        const { dictWord, formData } = this.state;
        return (
            <div className={classes.main}>
                <div className={classes.appBarSpacer} />
                <Menu dictWord={dictWord} selectedkey="password" />
                <div className={classes.content + ' right-con password'}>
                    <div>
                        <TextField className={classes.textField}
                            label={dictWord.current_password}
                            type="password"
                            onChange={this.handleChange('current_password')}
                            margin="normal"
                        />
                        {formData.current_password.error ?
                            <FormHelperText error={formData.current_password.error} id="current_password-error-text">{formData.current_password.errorMsg}</FormHelperText> : ''}
                    </div>
                    <div>
                        <TextField className={classes.textField}
                            onChange={this.handleChange('new_password')}
                            label={dictWord.new_password}
                            type="password"
                            margin="normal"
                        />
                        {formData.new_password.error ?
                            <FormHelperText error={formData.new_password.error} id="new_password-error-text">{formData.new_password.errorMsg}</FormHelperText> : ''}
                    </div>
                    <div>
                        <TextField className={classes.textField}
                            onChange={this.handleChange('new_password_confirmation')}
                            type="password"
                            label={dictWord.new_password_confirmation}
                            margin="normal"
                        />
                        {formData.new_password_confirmation.error ?
                            <FormHelperText error={formData.new_password_confirmation.error} id="new_password_confirmation-error-text">{formData.new_password_confirmation.errorMsg}</FormHelperText> : ''}
                    </div>
                    <div style={{ textAlign: 'right', padding: '30px' }}>
                        <Button style={{background:this.state.styleSetting.buttonColor}} variant="contained" onClick={this.handleSubmit()} color="primary">{dictWord.change}</Button>
                    </div>
                    <div>
                        <p>• {dictWord.characters_more}</p>
                        <p>• {dictWord.half_letter_number_symbols}</p>
                        <p>• {dictWord.password_different_before}</p>
                    </div>
                </div>

            </div>
        )
    };
}

Password.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Password);