import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import NotFound from 'mdoc/components/pages/NotFound';
import App from 'mdoc/App';
import Login from 'mdoc/components/pages/Login';
import PasswordFind from 'mdoc/components/pages/PasswordFind';
import PasswordUp from 'mdoc/components/pages/PasswordUp';

export default () => (
    <Router>
        <Switch>
            <Route exact path="/" render={() => <Redirect to="/login" push />} />
            <Route path="/user" component={App} />
            <Route path="/404" component={NotFound} />
            <Route path="/login" component={Login} />
            <Route path="/passwordFind" component={PasswordFind} />
            <Route path="/passwordUp" component={PasswordUp} />
            <Route component={NotFound} />
        </Switch>
    </Router>
)