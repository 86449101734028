import { transformFile } from "babel-core";

/**
 *
 */
// 获取url的参数
export const queryString = () => {
    let _queryString = {};
    const _query = window.location.search.substr(1);
    const _vars = _query.split('&');
    _vars.forEach((v, i) => {
        const _pair = v.split('=');
        if (!_queryString.hasOwnProperty(_pair[0])) {
            _queryString[_pair[0]] = decodeURIComponent(_pair[1]);
        } else if (typeof _queryString[_pair[0]] === 'string') {
            const _arr = [_queryString[_pair[0]], decodeURIComponent(_pair[1])];
            _queryString[_pair[0]] = _arr;
        } else {
            _queryString[_pair[0]].push(decodeURIComponent(_pair[1]));
        }
    });
    return _queryString;
};
const isdate = (intYear, intMonth, intDay) => {

    if (isNaN(intYear) || isNaN(intMonth) || isNaN(intDay)) return false;

    if (intMonth > 12 || intMonth < 1) return false;

    if (intDay < 1 || intDay > 31) return false;

    if ((intMonth === 4 || intMonth === 6 || intMonth === 9 || intMonth === 11) && (intDay > 30)) return false;

    if (intMonth === 2) {
        if (intDay > 29) return false;
        if ((((intYear % 100 === 0) && (intYear % 400 !== 0)) || (intYear % 4 !== 0)) && (intDay > 28)) return false;
    }
    return true;

}

//check date
export const checkDate = (time) => {
    var matchArray = time.match(/^([0-9]{4})-([0-1][0-9])-([0-3][0-9])$/);
    if (matchArray == null) {
        return false;
    } else {
        if (!isdate(matchArray[1], matchArray[2], matchArray[3])) {
            return false;
        }
    }
    return true;
}

//message format
export const messageFormat = (msg, field, search = '%s') => {
    return msg.replace(search, field);
}

//date time format
export const dateFormat = (date, fmt) => {
    var o = {
        "M+": date.getMonth() + 1,//月份
        "d+": date.getDate(),//日
        "h+": date.getHours(),//小时
        "m+": date.getMinutes(),//分
        "s+": date.getSeconds(),//秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        "S": date.getMilliseconds()//毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}

//borwser info
export const getBrowserInfo = () => {
    let agent = navigator.userAgent.toLowerCase();
    let arr = [];
    let system = agent.split(' ')[1].split(' ')[0].split('(')[1];
    arr.push(system);
    let regStr_edge = /edge\/[\d.]+/gi;
    let regStr_ie = /trident\/[\d.]+/gi;
    let regStr_ff = /firefox\/[\d.]+/gi;
    let regStr_chrome = /chrome\/[\d.]+/gi;
    let regStr_saf = /safari\/[\d.]+/gi;
    let regStr_opera = /opr\/[\d.]+/gi;
    //IE
    if (agent.indexOf("trident") > 0) {
        arr.push(agent.match(regStr_ie)[0].split('/')[0]);
        arr.push(agent.match(regStr_ie)[0].split('/')[1]);
        return arr;
    }
    //Edge
    if (agent.indexOf('edge') > 0) {
        arr.push(agent.match(regStr_edge)[0].split('/')[0]);
        arr.push(agent.match(regStr_edge)[0].split('/')[1]);
        return arr;
    }
    //firefox
    if (agent.indexOf("firefox") > 0) {
        arr.push(agent.match(regStr_ff)[0].split('/')[0]);
        arr.push(agent.match(regStr_ff)[0].split('/')[1]);
        return arr;
    }
    //Opera
    if (agent.indexOf("opr") > 0) {
        arr.push(agent.match(regStr_opera)[0].split('/')[0]);
        arr.push(agent.match(regStr_opera)[0].split('/')[1]);
        return arr;
    }
    //Safari
    if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
        arr.push(agent.match(regStr_saf)[0].split('/')[0]);
        arr.push(agent.match(regStr_saf)[0].split('/')[1]);
        return arr;
    }
    //Chrome
    if (agent.indexOf("chrome") > 0) {
        arr.push(agent.match(regStr_chrome)[0].split('/')[0]);
        arr.push(agent.match(regStr_chrome)[0].split('/')[1]);
        return arr;
    } else {
        // arr.push('please use chrome,firefox,opera,safari,IE,Edge!')
        return arr;
    }
}

//get borwser lang
export const getLang = () => {
    let lang = localStorage.getItem('localeId');
    if (!lang) {
        lang = navigator.language || navigator.userLanguage;
        lang = lang.substr(0, 2);
        if (lang === "zh") {
            return 2;
        } else if (lang === "en") {
            return 3;
        } else {
            return 1;
        }
    }
    return lang;
}

//正数
export const checkPNumber = (value) => {
    if (/^(\d+)(\.\d+)?$/.test(value) === false &&
        /^(-?\d+)(\.\d+)?$/.test(value) === false) {
        return false;
    } else {
        return true;
    }
}
//整数
export const checkInteger = (value) => {
    if (/^(-)?[0-9]{1,}$/.test(value) === false) {
        return false;
    } else {
        return true;
    }
}

// 特徴な文字チェック
export const checkStripScript = (value) => {
    let pattern = new RegExp(".*[ＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺａｂｃｄｅｆｇｈｉｊｋｌｍｎｏｐｑｒｓｔｕｖｗｘｙｚ１２３４５６７８９０―]+.*");
    let rs = value.match(pattern);
    if (rs.length > 0) {
        return true;
    }
    return false;
}

//GB format to Byte
export const GBFormatToByte = (value) => {
    return Math.ceil(parseFloat(value) * 1024 * 1024 * 1024);
}
//Byte format to GB
export const ByteFormatToGB = (value) => {
    return (value / 1024 / 1024 / 1024).toFixed(2);
}

export const checkDecimal = (val, formal) => {
    var formalDefault = formal.length < 1 ? [2, 1] : formal;
    var returnVal = true;
    val = val.toString();
    var ArrMen = val.split(".");
    if (ArrMen.length > 1) {
        if (ArrMen[0].length > formalDefault[0]) {
            returnVal = false;
        }
        if (ArrMen[1].length > formalDefault[1]) {
            returnVal = false;
        }
    }
    var num = Number(val).toFixed(formalDefault[1]);
    if (isNaN(num)) {
        returnVal = false;
    }
    var forStr = '';
    for (var i = 0; i < formalDefault[0]; i++) {
        forStr += '9';
    }
    forStr += '.';
    for (var j = 0; j < formalDefault[1]; j++) {
        forStr += '9';
    }
    var ele = Number(forStr);
    if (ele < Number(num)) {
        returnVal = false;
    }
    return returnVal;
}

export const testColor = (color) => {
    if (color.trim() === '') {
        return false;
    }
    var re1 = /^([0-9a-f]{6}|[0-9a-f]{3}||[0-9a-f]{4})$/i
    // var re2 = /^rgb\(([0-9]|[0-9][0-9]|25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9])\,([0-9]|[0-9][0-9]|25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9])\,([0-9]|[0-9][0-9]|25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9])\)$/i
    // var re3 = /^rgba\(([0-9]|[0-9][0-9]|25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9])\,([0-9]|[0-9][0-9]|25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9])\,([0-9]|[0-9][0-9]|25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9])\,(1|1.0|0.[0-9])\)$/i
    return re1.test(color);// || re2.test(color) || re3.test(color);
}

export const replacepos = (text, start) => {
    let mystr = text.substring(start - 2, text.length);
    return mystr;
}

export const heightLight = (string, keyword) => {
    var reg = new RegExp(keyword, "gi");
    string = string.replace(reg, function (txt) {
        return '<span className="serchselect" style="background:yellow;">' + txt + '</span>';
    })
    return string;
}
export const escapeHtml = (unsafe) => {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}


//全角转半角
const ToCDB = (str) => {
    var tmp = "";
    for (var i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 65248 && str.charCodeAt(i) < 65375) {
            tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
        }
        else {
            tmp += String.fromCharCode(str.charCodeAt(i));
        }
    }
    return tmp
}

//半角转全角
const ToDBC = (txtstring) => {
    var tmp = "";
    for (var i = 0; i < txtstring.length; i++) {
        if (txtstring.charCodeAt(i) === 32) {
            tmp = tmp + String.fromCharCode(12288);
        } else if (txtstring.charCodeAt(i) < 127) {
            tmp = tmp + String.fromCharCode(txtstring.charCodeAt(i) + 65248);
        }
    }
    return tmp;
}

//片假名全角轉半角
const toHankanaCase = (str) => {
    var MAPPING = {
        0x30A1: 0xFF67,
        0x30A3: 0xFF68,
        0x30A5: 0xFF69,
        0x30A7: 0xFF6A,
        0x30A9: 0xFF6B,
        0x30FC: 0xFF70,
        0x30A2: 0xFF71,
        0x30A4: 0xFF72,
        0x30A6: 0xFF73,
        0x30A8: 0xFF74,
        0x30AA: 0xFF75,
        0x30AB: 0xFF76,
        0x30AD: 0xFF77,
        0x30AF: 0xFF78,
        0x30B1: 0xFF79,
        0x30B3: 0xFF7A,
        0x30B5: 0xFF7B,
        0x30B7: 0xFF7C,
        0x30B9: 0xFF7D,
        0x30BB: 0xFF7E,
        0x30BD: 0xFF7F,
        0x30BF: 0xFF80,
        0x30C1: 0xFF81,
        0x30C4: 0xFF82,
        0x30C6: 0xFF83,
        0x30C8: 0xFF84,
        0x30CA: 0xFF85,
        0x30CB: 0xFF86,
        0x30CC: 0xFF87,
        0x30CD: 0xFF88,
        0x30CE: 0xFF89,
        0x30CF: 0xFF8A,
        0x30D2: 0xFF8B,
        0x30D5: 0xFF8C,
        0x30D8: 0xFF8D,
        0x30DB: 0xFF8E,
        0x30DE: 0xFF8F,
        0x30DF: 0xFF90,
        0x30E0: 0xFF91,
        0x30E1: 0xFF92,
        0x30E2: 0xFF93,
        0x30E4: 0xFF94,
        0x30E6: 0xFF95,
        0x30E8: 0xFF96,
        0x30E9: 0xFF97,
        0x30EA: 0xFF98,
        0x30EB: 0xFF99,
        0x30EC: 0xFF9A,
        0x30ED: 0xFF9B,
        0x30EF: 0xFF9C,
        0x30F2: 0xFF66,
        0x30F3: 0xFF9D,
        0x30C3: 0xFF6F
    };
    var i, f, c, a = [], m = MAPPING;
    for (i = 0, f = str.length; i < f;) {
        c = str.charCodeAt(i++);
        switch (true) {
            case (c in m):
                a.push(m[c]);
                break;
            case (0x30AB <= c && c <= 0x30C9):
                a.push(m[c - 1], 0xFF9E);
                break;
            case (0x30CF <= c && c <= 0x30DD):
                a.push(m[c - c % 3], [0xFF9E, 0xFF9F][c % 3 - 1]);
                break;
            default:
                a.push(c);
                break;
        };
    };

    return String.fromCharCode.apply(null, a);
};

//片假名半角轉全角
const toZenkanaCase = (src) => {
    var i, f, c, a = [], m = {
        0xFF67: 0x30A1,
        0xFF68: 0x30A3,
        0xFF69: 0x30A5,
        0xFF6A: 0x30A7,
        0xFF6B: 0x30A9,
        0xFF70: 0x30FC,
        0xFF71: 0x30A2,
        0xFF72: 0x30A4,
        0xFF73: 0x30A6,
        0xFF74: 0x30A8,
        0xFF75: 0x30AA,
        0xFF76: 0x30AB,
        0xFF77: 0x30AD,
        0xFF78: 0x30AF,
        0xFF79: 0x30B1,
        0xFF7A: 0x30B3,
        0xFF7B: 0x30B5,
        0xFF7C: 0x30B7,
        0xFF7D: 0x30B9,
        0xFF7E: 0x30BB,
        0xFF7F: 0x30BD,
        0xFF80: 0x30BF,
        0xFF81: 0x30C1,
        0xFF82: 0x30C4,
        0xFF83: 0x30C6,
        0xFF84: 0x30C8,
        0xFF85: 0x30CA,
        0xFF86: 0x30CB,
        0xFF87: 0x30CC,
        0xFF88: 0x30CD,
        0xFF89: 0x30CE,
        0xFF8A: 0x30CF,
        0xFF8B: 0x30D2,
        0xFF8C: 0x30D5,
        0xFF8D: 0x30D8,
        0xFF8E: 0x30DB,
        0xFF8F: 0x30DE,
        0xFF90: 0x30DF,
        0xFF91: 0x30E0,
        0xFF92: 0x30E1,
        0xFF93: 0x30E2,
        0xFF94: 0x30E4,
        0xFF95: 0x30E6,
        0xFF96: 0x30E8,
        0xFF97: 0x30E9,
        0xFF98: 0x30EA,
        0xFF99: 0x30EB,
        0xFF9A: 0x30EC,
        0xFF9B: 0x30ED,
        0xFF9C: 0x30EF,
        0xFF9D: 0x30F3,
        0xFF9E: 0x309B,
        0xFF9F: 0x309C,
        0xFF66: 0x30F2,
        65383: 12449, //ァ
        65384: 12451, //ィ
        65385: 12453, //ゥ
        65386: 12455, //ェ
        65387: 12457, //ォ
        65391: 12483, //ッ
        65388: 12515, //ャ
        65389: 12517, //ュ
        65390: 12519, //ョ
    };

    for (i = 0, f = src.length; i < f; i++) {
        c = src.charCodeAt(i);
        a.push(m[c] || c);
    };

    return String.fromCharCode.apply(null, a);
};

//片假名转平假名
const katakanaToHiragana = (src) => {
    let resultStr = src.replace(/[\u30a1-\u30f6]/g, function (match) {
        let chr = match.charCodeAt(0) - 0x60;
        return String.fromCharCode(chr);
    });
    return resultStr;
};

//平假名转片假名
const hiraganaToKatagana = (src) => {
    let resultStr = src.replace(/[\u3041-\u3096]/g, function (match) {
        let chr = match.charCodeAt(0) + 0x60;
        return String.fromCharCode(chr);
    });
    return resultStr;
}

export const searchMatching = (htmlText, keyword) => {
    let _keyword = "";
    let reg = new RegExp(keyword, "i");
    if (htmlText.match(reg)) {/*直接搜索关键字不区分大小写 */
        _keyword = keyword;
    } else if (htmlText.match(ToCDB(keyword))) {/*英數全角转半角*/
        _keyword = ToCDB(keyword);
    } else if (ToDBC(keyword) > 0 && htmlText.match(ToDBC(keyword))) {/*英数半角转全角 */
        _keyword = ToDBC(keyword);
    } else if (htmlText.indexOf(katakanaToHiragana(keyword)) >= 0) {/*片假名转平假名 */
        _keyword = katakanaToHiragana(keyword);
    } else if (htmlText.indexOf(hiraganaToKatagana(keyword)) >= 0) {/*平假名转片假名 */
        _keyword = hiraganaToKatagana(keyword);
    } else if (htmlText.indexOf(toHankanaCase(keyword)) >= 0) {/*片假名全角转半角 */
        _keyword = toHankanaCase(keyword);
    } else if (htmlText.indexOf(toZenkanaCase(keyword)) >= 0) {/*片假名半角转全角 */
        _keyword = toZenkanaCase(keyword);
    } else if (htmlText.indexOf(katakanaToHiragana(toZenkanaCase(keyword))) >= 0) {/*半角to全角to平假名 */
        _keyword = katakanaToHiragana(toZenkanaCase(keyword));
    } else if (htmlText.indexOf(toHankanaCase(hiraganaToKatagana(keyword))) >= 0) {/*平假名to片假名to半角 */
        _keyword = toHankanaCase(hiraganaToKatagana(keyword));
    }
    return _keyword;
}